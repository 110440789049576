import { useAtom } from "jotai";
import { baysChurnLimitAtom } from "src/pages/Bays/store/atoms";
import { omniAtom, useOmniState } from "src/utils/atoms";

const coreRangeAtom = omniAtom({
	key: "coreRange",
	value: [] as string[],
});

export const useChurnLimit = () => {
	const [churnLimit, setChurnLimit] = useOmniState(baysChurnLimitAtom);

	const getChurnLimit = () => churnLimit / 100;

	return { churnLimit, setChurnLimit, getChurnLimit };
};

export const useCoreRange = () => {
	const [coreRange, setCoreRange] = useAtom(coreRangeAtom);

	const addItem = (item: string) => {
		setCoreRange(state => [...state, item]);
	};

	const addItems = (item: string[]) => {
		setCoreRange(state => [...state, ...item]);
	};

	const removeItem = (item: string) => {
		setCoreRange(state => state.filter(stateItem => stateItem !== item));
	};

	const removeItems = (items: string[]) => {
		setCoreRange(state => state.filter(stateItem => !items.includes(stateItem)));
	};

	return {
		coreRange,
		addItem,
		addItems,
		removeItem,
		removeItems,
	};
};

const coreRangeFiltersAtom = omniAtom({
	key: "coreRange/filters",
	value: {} as { [column: string]: string[] | undefined },
});

export const useCoreRangeFilters = () => {
	const [coreRangeFilters, setCoreRangeFilters] = useAtom(coreRangeFiltersAtom);

	const addFilter = (column: string, value: string) => {
		setCoreRangeFilters(state => {
			const filters = { ...state };

			if (filters[column] === undefined) {
				filters[column] = [value];
			} else {
				filters[column] = [...filters[column]!, value];
			}

			return filters;
		});
	};

	const removeFilter = (column: string, value: string) => {
		setCoreRangeFilters(state => {
			const filters = { ...state };

			if (filters[column] !== undefined) {
				const filtered = [...filters[column]!];
				filtered.splice(state[column]!.indexOf(value), 1);
				filters[column] = filtered;

				if (filtered.length === 0) {
					delete filters[column];
				}
			}

			return filters;
		});
	};

	return {
		coreRangeFilters,
		addFilter,
		removeFilter,
	};
};
