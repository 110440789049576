import { useEffect, useRef } from "react";
import { Box, Button, NumberInput, Text } from "src/elements";
import { NumberInputElement } from "src/elements/Input/NumberInput";
import { ShelfType } from "src/types";
import { Color } from "src/utils";

import { Panel, PanelGroup } from "../../Panel/Panel";
import { useBayPanel, usePog, useShelfPanel } from "../store/actions";
import { Shelf } from "./components/Shelf";

export const BayPanel = () => {
	const { bay, isOpen, close, modified, setModified } = useBayPanel();
	const { open: openShelfPanel } = useShelfPanel();
	const { saveBay, deleteBay } = usePog();

	const refBayNo = useRef<NumberInputElement>(null);
	const refBayHeight = useRef<NumberInputElement>(null);
	const refFirstNotchY = useRef<NumberInputElement>(null);
	const refShelfStep = useRef<NumberInputElement>(null);

	useEffect(() => {
		refBayNo.current?.updateValue(bay?.bay_no);
		refBayHeight.current?.updateValue(bay?.bay_height);
		refFirstNotchY.current?.updateValue(bay?.first_notch_y);
		refShelfStep.current?.updateValue(bay?.shelf_step);
	}, [bay]);

	return (
		<Panel isOpen={isOpen}>
			<Box flex="1" padding="20px 10px 10px 10px" gap="30px" column>
				<Box justify="between" align="center">
					<Text variant="h4">{bay?.bay_no ? `Bay No. ${bay?.bay_no}` : "New Bay"}</Text>

					{bay?.bay_no ? (
						<Button
							width="35px"
							icon="delete"
							theme="danger"
							padding="0"
							variant="inverted"
							onClick={() => {
								deleteBay(bay.bay_no);
								close();
							}}
						/>
					) : null}
				</Box>

				<Box column gap="10px">
					<PanelGroup title="Dimensions">
						<NumberInput
							ref={refBayHeight}
							label="Bay Height"
							defaultValue={bay?.bay_height}
							onChange={value => {
								bay!.bay_height = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refFirstNotchY}
							label="First Notch Y"
							defaultValue={bay?.first_notch_y}
							onChange={value => {
								bay!.first_notch_y = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refShelfStep}
							label="Shelf Step"
							defaultValue={bay?.shelf_step}
							onChange={value => {
								bay!.shelf_step = value;
								setModified();
							}}
						/>
					</PanelGroup>
				</Box>

				{bay?.bay_no ? (
					<Box column gap="10px">
						<Text variant="h3" color={Color.textMain}>
							Shelves
						</Text>

						{bay?.shelves.map((shelf, index) => (
							<Shelf key={index} bayNo={bay.bay_no} shelf={shelf} />
						))}

						<Button
							width="100%"
							theme="primary"
							variant="inverted"
							onClick={() =>
								openShelfPanel(bay.bay_no, {
									shelf_no: 0,
									shelf_type: ShelfType.REGULAR,
									shelf_width: 0,
									shelf_height: 0,
									shelf_depth: 0,
									shelf_slope: 0,
									slope_height: 0,
									shelf_thickness: 0,
									finger_space: 0,
									notch_no: 0,
									shelf_y: 0,
									items: [],
								})
							}
						>
							Add New Shelf
						</Button>
					</Box>
				) : null}
			</Box>

			<Box padding="10px" column gap="10px">
				{/* <Button variant="danger" inverted width="100%" icon="delete">
					Delete Bay
				</Button> */}

				<Box gap="10px">
					<Button
						theme={modified ? "red" : "greenSmoke"}
						variant="inverted"
						width="75px"
						onClick={close}
					>
						{modified ? "Cancel" : "Back"}
					</Button>

					{/* <Button variant="danger" inverted>
						Delete
					</Button> */}

					<Box flex="1">
						<Button
							width="100%"
							onClick={() => {
								saveBay(bay!);
								close();
							}}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</Panel>
	);
};
