import { zIndex as zIndexLevel } from "src/utils";
import styled from "styled-components";

interface Props {
	background?: string;
	zIndex?: zIndexLevel;
}

export const Mask = styled.div<Props>`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: ${({ zIndex = zIndexLevel.MODALS }) => zIndex};

	align-items: center;
	justify-content: center;
	background: ${({ background = "rgba(0,0,0,0.4)" }) => background};
	user-select: none;
`;
