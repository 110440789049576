import { merchantAtom } from "src/pages/Bays/store/atoms";
import { useOmniValue } from "src/utils/atoms";
import styled, { StyledComponent } from "styled-components";

const ProductImageElement: StyledComponent<
	"object",
	any,
	{
		merchant: string;
		itemId: string;
	},
	never
> = styled.object.attrs<{
	merchant: string;
	itemId: string;
}>(({ merchant, itemId }) => ({
	data: `https://omnistream-storage-public-dev.s3.ap-southeast-1.amazonaws.com/merchants/${merchant}/product-images/${itemId}.jpg`,
}))`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

interface Props {
	itemId: string;
}

export const ProductImage: React.FC<Props> = ({ itemId }) => {
	const merchant = useOmniValue(merchantAtom);

	return <ProductImageElement merchant={merchant} itemId={itemId} />;
};
