import { ItemColor } from "src/pages/Bays/store/types";
import { Mudmap, Pog } from "src/types";
import { omniAtom } from "src/utils/atoms";

import { BayPanel, ItemPanel, ShelfPanel } from "../types";

const prefix = "bays";

export const baysMudmapAtom = omniAtom({
	prefix,
	key: "mudmap",
	value: null as Mudmap | null,
});

export const baysColorsAtom = omniAtom({
	prefix,
	key: "colors",
	value: null as ItemColor | null,
});

export const baysPogAtom = omniAtom({
	prefix,
	key: "pog",
	value: null as Pog | null,
});

export const baysEditModeAtom = omniAtom({
	prefix,
	key: "edit",
	value: false,
});

export const baysPanelBayAtom = omniAtom({
	prefix,
	key: "panels/bay",
	value: {
		bay: null,
		open: false,
	} as BayPanel,
});

export const baysPanelShelfAtom = omniAtom({
	prefix,
	key: "panels/shelf",
	value: {
		bayNo: 0,
		shelf: null,
		open: false,
	} as ShelfPanel,
});

export const baysPanelItemAtom = omniAtom({
	prefix,
	key: "panels/item",
	value: {
		item: null,
		open: false,
	} as ItemPanel,
});
