import React from "react";
import { Box, Text } from "src/elements";
import { IconType, IconV2 } from "src/elements/IconV2";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { Tab as TabType } from "./types";

const TabsElement = styled.div<{ width?: string }>`
	user-select: none;
	align-self: center;
	width: ${({ width }) => width};
`;

const Tab: any = styled.div.attrs<ItemProps>(props => ({
	style: { width: props.width },
}))`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2px;
	padding: 10px 30px;
	transition: background-color ${Transition.hover}, color ${Transition.hover};
	cursor: pointer;

	// For automatic same width tabs. Don't touch this.
	flex: 1 1 0;
	min-width: 0;

	> * {
		pointer-events: none;
	}

	&[data-icon] {
		padding: 5px 20px;
	}

	&[data-theme="default"] {
		border: 1px solid ${Color.primary};
		text-transform: uppercase;

		// Default state
		background: ${Color.white};
		color: ${Color.primary};
		path {
			fill: ${Color.primary};
		}

		// Hover state
		&:hover {
			background: ${Color.greenSmoke};
		}

		// Active state
		&:active,
		&[data-active] {
			background: ${Color.primary};
			color: ${Color.white};

			path {
				fill: ${Color.white};
			}
		}

		&:not(:first-of-type) {
			border-left: 0;
		}

		&:first-of-type {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		&:last-of-type {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	&[data-theme="steps"] {
		border: 1px solid ${Color.lightGrayHover};
		color: ${Color.textSecondary};

		&:not(:first-of-type) {
			border-left: 0;
		}

		&:first-of-type {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		&:last-of-type {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		.checkmark {
			background-color: ${Color.greenSmoke};
			border: 1px solid ${Color.greenSmokeHover};
			color: ${Color.primary};
		}

		&:hover {
			background-color: ${Color.lightGray};
		}

		&[data-active] {
			color: ${Color.primary};

			.checkmark {
				background-color: ${Color.primary};
				color: ${Color.white};
				border-color: ${Color.primary};
			}
		}

		&[data-disabled] {
			.checkmark {
				background-color: ${Color.lightGrayHover};
				border-color: ${Color.lightGrayHover};
				color: ${Color.spaceGray};
			}
		}
	}

	&[data-disabled] {
		color: ${Color.textDisabled};
		pointer-events: none;
	}
`;

const Checkmark: React.FC<{
	index: number;
}> = ({ index }) => (
	<Box
		className="checkmark"
		width="18px"
		height="18px"
		justify="center"
		align="center"
		borderRadius="50%"
		background={Color.lightGrayHover}
		border={`1px solid ${Color.spaceGrayDisabled}`}
	>
		<Text variant="caption3" color="inherit">
			{index + 1}
		</Text>
	</Box>
);

type TabsTheme = "default" | "steps";

interface ItemProps {
	active: boolean;
	width?: string;
	icon?: IconType;
}

interface Props<T> {
	tabs: TabType<T>[];
	width?: string;
	tab: any;
	setTab: (tab: any) => void;
	tabWidth?: string;
	theme?: TabsTheme;
}

export const Tabs = <T,>({
	tab: activeTab,
	tabWidth,
	setTab,
	tabs,
	width,
	theme = "default",
}: Props<T>) => {
	const changeTab = (tab: any) => tab !== activeTab && setTab(tab);

	const isActive = (tab: any) => activeTab === tab;

	const isCompleted = (tab: any) => activeTab > tab;

	return (
		<TabsElement width={width}>
			{tabs
				.filter(({ hidden }) => !hidden)
				.map(({ tab, title, icon, disabled }, index) => (
					<Tab
						key={tab}
						width={tabWidth}
						data-theme={theme}
						{...(icon && { "data-icon": icon })}
						{...(isActive(tab) && { "data-active": "" })}
						{...(isCompleted(tab) && { "data-completed": "" })}
						{...(disabled && { "data-disabled": "" })}
						onClick={() => !disabled && changeTab(tab)}
					>
						{icon && <IconV2 name={icon} size={18} />}

						<Box gap="7px" align="center">
							{theme === "steps" && <Checkmark index={index} />}

							<Text variant={icon ? "caption1" : "small1"} color="inherit" whiteSpace="nowrap">
								{title || String(tab)}
							</Text>
						</Box>
					</Tab>
				))}
		</TabsElement>
	);
};
