export const verifyFileTypes = (files: FileList) => {
	let extension: string | null = null;

	for (let i = 0; i < files.length; i++) {
		if (extension === null) {
			extension = files[i].type;
		} else if (files[i].type !== extension) {
			return false;
		}
	}

	if (extension === "text/csv") return "csv";
	if (extension === "application/json") return "json";
	return false;
};
