import React, { useState } from "react";
import { Color } from "src/utils";

import { ErrorBlock } from "../ErrorBlock";
import { IconType } from "../IconV2";
import { Icon } from "./components/Icon";
import { InputElement, InputInnerContainer, InputOuterContainer } from "./components/Input";
import { Label } from "./components/Label";

interface Props {
	value: string;
	setValue: (value: string) => void;
	label?: string;
	type?: "text" | "email" | "password";
	placeholder?: string;
	error?: { message: string | null };
	disabled?: boolean;
	width?: string;
	leftIcon?: { icon: IconType; onClick?: () => void; tooltip?: string };
	rightIcon?: { icon: IconType; onClick?: () => void; tooltip?: string };
	refInput?: React.ForwardedRef<HTMLInputElement>;
	onFocus?: () => void;
}

export const TextInputV2: React.FC<Props> = ({
	label,
	value,
	setValue,
	type = "text",
	placeholder,
	error,
	disabled,
	width,
	leftIcon,
	rightIcon,
	refInput,
	onFocus,
}) => {
	const [focused, setFocused] = useState(false);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.currentTarget.value);
	};

	const onFocusHandler = () => {
		setFocused(true);
		if (onFocus) onFocus();
	};

	const onBlur = () => {
		setFocused(false);
	};

	return (
		<InputOuterContainer width={width} label={label}>
			<InputInnerContainer>
				<Label label={label} active={focused} />

				<InputElement
					ref={refInput}
					type={type}
					placeholder={placeholder}
					value={value}
					disabled={disabled}
					leftIcon={leftIcon?.icon ? true : false}
					rightIcon={rightIcon?.icon ? true : false}
					onChange={onChange}
					onFocus={onFocusHandler}
					onBlur={onBlur}
				/>

				{leftIcon && (
					<Icon
						icon={leftIcon.icon}
						error={error?.message}
						side="left"
						tooltip={leftIcon.tooltip}
						onClick={leftIcon.onClick}
					/>
				)}

				{rightIcon && (
					<Icon
						icon={rightIcon.icon}
						error={error?.message}
						side="right"
						tooltip={rightIcon.tooltip}
						onClick={rightIcon.onClick}
					/>
				)}
			</InputInnerContainer>

			{error && <ErrorBlock>{error.message}</ErrorBlock>}
		</InputOuterContainer>
	);
};
