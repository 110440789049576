import { Dispatch, SetStateAction } from "react";
import { emitCustomEvent } from "react-custom-events";

import { TOOLTIP_TIP_SIZE } from "../components/Tooltip";
import { TooltipSide } from "../types";

export const TOOLTIP_EDGE_LIMITER = 10;

export const EVENT_TOOLTIP = "tooltip";

const TOOLTIP_DEFAULT_LOCATION = TooltipSide.BOTTOM;

let target: HTMLElement | null = null;
export let content: any = null;
export let side: TooltipSide | null = null;
let tooltipElement: HTMLElement | null = null;
let tooltipShower: NodeJS.Timeout | null = null;
let tooltipHider: NodeJS.Timeout | null = null;
let tooltipRemover: NodeJS.Timeout | null = null;
let extraEnterLeaveCheck: boolean = false;

export const tooltip = ({
	event,
	data,
	delay = 0,
	side: sid,
}: {
	event: React.MouseEvent;
	data: string | object | any;
	delay?: number;
	side?: TooltipSide;
}) => {
	if (tooltipRemover) clearTimeout(tooltipRemover);
	if (tooltipHider) clearTimeout(tooltipHider);

	// Set tooltip values.
	target = event.target as HTMLElement;
	content = data;
	side = sid || TOOLTIP_DEFAULT_LOCATION;

	// Set tooltip hider.
	setTooltipHider(target);

	// Emit event to rerender tooltip.
	tooltipShower = setTimeout(() => {
		emitCustomEvent(EVENT_TOOLTIP);
	}, delay);

	tooltipElement?.removeAttribute("data-hide");

	setTimeout(() => {
		extraEnterLeaveCheck = true;
	}, 10);
};

const setTooltipHider = (element: HTMLElement) => {
	element.addEventListener(
		"mouseleave",
		() => {
			extraEnterLeaveCheck = false;

			tooltipHider = setTimeout(() => {
				if (extraEnterLeaveCheck) return;
				if (tooltipShower) clearTimeout(tooltipShower);
				tooltipElement?.setAttribute("data-hide", "");
			}, 150);

			tooltipRemover = setTimeout(() => {
				if (extraEnterLeaveCheck) return;
				content = null;
				side = null;
				emitCustomEvent(EVENT_TOOLTIP);
			}, 300);
		},
		{ once: true },
	);
};

export const showTooltip = (
	refTooltip: any,
	setSideOverride: Dispatch<SetStateAction<TooltipSide | null>>,
) => {
	if (target === null) return;

	tooltipElement = refTooltip.current;

	let xModifier = 0;
	let yModifier = 0;
	let xInitial = 0;
	let yInitial = 0;

	const { width: tooltipWidth, height: tooltipHeight } = refTooltip.current.getBoundingClientRect();

	const {
		left: hoverX,
		top: hoverY,
		width: hoverWidth,
		height: hoverHeight,
	} = target.getBoundingClientRect();

	const { clientWidth: screenWidth, clientHeight: screenHeight } = document.body;

	// Tooltip vertical X coordinates
	if (side === TooltipSide.BOTTOM || side === TooltipSide.TOP) {
		xInitial = hoverX - tooltipWidth / 2 + hoverWidth / 2;
		xModifier = getVerticalXModifier(hoverX, tooltipWidth, screenWidth);
	}

	// Tooltip horizontal Y coordinates
	if (side === TooltipSide.LEFT || side === TooltipSide.RIGHT) {
		yInitial = hoverY - tooltipHeight / 2 + hoverHeight / 2;
		yModifier = getHorizontalYModifier(hoverY, tooltipHeight, screenHeight);
	}

	// Tooltip top Y coordinates
	if (side === TooltipSide.BOTTOM) {
		yInitial = getYTop(hoverY, hoverHeight) + TOOLTIP_EDGE_LIMITER;

		if (yInitial + tooltipHeight > screenHeight - TOOLTIP_EDGE_LIMITER) {
			yInitial = getYBottom(hoverY, tooltipHeight);
			setSideOverride(TooltipSide.TOP);
		} else {
			setSideOverride(null);
		}

		// Tooltip bottom Y coordinates
	} else if (side === TooltipSide.TOP) {
		yInitial = hoverY - tooltipHeight - TOOLTIP_TIP_SIZE - TOOLTIP_EDGE_LIMITER;

		if (yInitial < TOOLTIP_EDGE_LIMITER) {
			yInitial = hoverY + hoverHeight + TOOLTIP_TIP_SIZE;
			setSideOverride(TooltipSide.BOTTOM);
		} else {
			setSideOverride(null);
		}

		// Tooltip left X coordinates
	} else if (side === TooltipSide.RIGHT) {
		xInitial = getXLeft(hoverX, tooltipWidth) + TOOLTIP_EDGE_LIMITER;

		if (xInitial - tooltipWidth < TOOLTIP_EDGE_LIMITER) {
			xInitial = getXRight(hoverX, hoverWidth);
			setSideOverride(TooltipSide.LEFT);
		} else {
			setSideOverride(null);
		}

		// Tooltip right X coordinates
	} else if (side === TooltipSide.LEFT) {
		xInitial = getXRight(hoverX, hoverWidth) - TOOLTIP_EDGE_LIMITER;

		if (xInitial + tooltipWidth > screenWidth - TOOLTIP_EDGE_LIMITER) {
			xInitial = getXLeft(hoverX, tooltipWidth);
			setSideOverride(TooltipSide.RIGHT);
		} else {
			setSideOverride(null);
		}
	}

	// Find y coordinate from top
	refTooltip.current.style.top = `${yInitial + yModifier}px`;

	// Tooltip x coordinate
	refTooltip.current.style.left = `${xInitial + xModifier}px`;

	// Set active with timeout to prevent animating initial tooltip coordinates.
	setTimeout(() => {
		refTooltip.current?.setAttribute("data-active", "");
	}, 10);

	// Tip x coordinate
	if (side === "top" || side === "bottom") {
		// refTip.current.style.left = `${
		// 	tooltipWidth / 2 - TOOLTIP_TIP_SIZE / 2 - xModifier
		// }px`;
	}

	// Tip y coordinate
	if (side === "left" || side === "right") {
		// refTip.current.style.top = `${
		// 	tooltipHeight / 2 - TOOLTIP_TIP_SIZE / 2
		// }px`;
	}
};

export const getYTop = (hoverY: number, hoverHeight: number) =>
	hoverY + hoverHeight + TOOLTIP_TIP_SIZE;

export const getYBottom = (hoverY: number, tooltipHeight: number) =>
	hoverY - tooltipHeight - TOOLTIP_TIP_SIZE;

export const getXLeft = (hoverX: number, tooltipWidth: number) =>
	hoverX - tooltipWidth - TOOLTIP_TIP_SIZE;

export const getXRight = (hoverX: number, hoverWidth: number) =>
	hoverX + hoverWidth + TOOLTIP_TIP_SIZE;

export const getVerticalXModifier = (hoverX: number, tooltipWidth: number, screenWidth: number) => {
	if (hoverX + tooltipWidth / 2 > screenWidth - TOOLTIP_EDGE_LIMITER) {
		return -1 * (hoverX + tooltipWidth / 2 - (screenWidth - TOOLTIP_EDGE_LIMITER));
	}

	if (hoverX - tooltipWidth / 2 < TOOLTIP_EDGE_LIMITER) {
		return -1 * (hoverX - tooltipWidth / 2 - TOOLTIP_EDGE_LIMITER);
	}

	return 0;
};

export const getHorizontalYModifier = (
	hoverY: number,
	tooltipHeight: number,
	screenHeight: number,
) => {
	if (hoverY + tooltipHeight / 2 > screenHeight - TOOLTIP_EDGE_LIMITER) {
		return -1 * (hoverY + tooltipHeight / 2 - (screenHeight - 2 * TOOLTIP_EDGE_LIMITER));
	}

	if (hoverY - tooltipHeight / 2 < TOOLTIP_EDGE_LIMITER) {
		return -1 * (hoverY - tooltipHeight / 2 - TOOLTIP_EDGE_LIMITER - 100);
	}

	return 0;
};
