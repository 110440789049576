import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { logsTabAtom } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import { useApi } from "src/pages/Containers/store/api";
import { containersGroupsResponseAtom } from "src/pages/Containers/store/atoms";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { FilesUploader } from "../components/FilesUploader";

const GenerateButton = () => {
	const groupsResponse = useOmniValue(containersGroupsResponseAtom);
	const isNextStepDisabled = () => !groupsResponse;
	const { step0GroupAndSizeVariants } = useApi();

	return (
		<Button
			theme={isNextStepDisabled() ? "primary" : "greenSmoke"}
			onClick={step0GroupAndSizeVariants}
		>
			Generate Groups
		</Button>
	);
};

export const PanelGroupAndSizeVariants = () => {
	const setLogsTab = useSetOmniValue(logsTabAtom);
	const { openModal } = useModals();

	const openLogs = () => {
		setLogsTab(LogsTab.CONTAINERS_GROUP_AND_SIZE_VARIANTS);
		openModal(ModalType.LOGS);
	};

	const openMergeRules = () => {
		openModal(ModalType.MERGE_RULES);
	};

	const openGroupsParameters = () => {
		openModal(ModalType.GROUPS_PARAMETERS);
	};

	return (
		<>
			<FilesUploader />

			<Box gap="10px">
				<Button theme="greenSmoke" variant="inverted" onClick={openLogs}>
					Logs
				</Button>

				<Button
					theme="yellow"
					dropdown={[
						{ title: "Merge Rules", onClick: openMergeRules },
						{ title: "Parameters", onClick: openGroupsParameters },
					]}
				>
					Configuration
				</Button>

				<GenerateButton />
			</Box>
		</>
	);
};
