import { Box, IconV2, Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

const HeaderElement = styled.div`
	background-color: ${Color.lightGray};
	border-radius: 5px 5px 0 0;
	border-bottom: 1px solid ${Color.lightGrayHover};
	padding: 10px 9px 10px 15px;
	justify-content: space-between;
	align-items: center;
`;

const HeaderButton = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	transition-property: transform, background-color, box-shadow;
	transition-duration: 0.1s;
	transition-timing-function: ease;
	user-select: none;
	cursor: pointer;

	* {
		pointer-events: none;
	}

	&:hover {
		background-color: ${Color.white};
		box-shadow: 0px 4px 7px rgba(157, 173, 184, 0.2);
		transform: scale(1.1);
	}

	&:active {
		background-color: ${Color.white};
		box-shadow: inset 0px 4px 7px rgba(157, 173, 184, 0.2);
		transform: scale(1);
	}
`;

interface Props {
	close: () => void;
	children: string;
}

export const Header: React.FC<Props> = ({ close, children }) => {
	return (
		<HeaderElement className="modal-header">
			<Text variant="h4" color={Color.textMain}>
				{children}
			</Text>

			<Box gap="5px">
				{/* <HeaderButton>
				<IconV2 name={IconType.maximize} color={Color.textSecondary} />
			</HeaderButton> */}

				<HeaderButton onClick={close}>
					<IconV2 name="close" color={Color.textSecondary} />
				</HeaderButton>
			</Box>
		</HeaderElement>
	);
};
