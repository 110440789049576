import { useEffect, useRef } from "react";
import { Scroller } from "src/elements";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { Container } from "./components/Container/Container";
import { ControlPanel } from "./components/ControlPanel/ControlPanel";
import { Planograms } from "./components/Planograms/Planograms";
import {
	containersFilesResponseAtom,
	containersGroupsResponseAtom,
	containersPlaceContainersPlanogramNoAtom,
	containersPlaceContainersResponseAtom,
	containersPlaceVariantsResponseAtom,
	containersStepAtom,
} from "./store/atoms";
import { ContainersStep } from "./types";

const ContainersContainer = styled.div<{ background: boolean }>`
	min-height: 0;
	height: 100%;
	--scale: 1;
	background-image: ${({ background }) => background && "url(containers.svg)"};
	background-size: cover;
	background-position: center;
`;

export const PageContainers = () => {
	const step = useOmniValue(containersStepAtom);
	const filesResponse = useOmniValue(containersFilesResponseAtom);
	const groupsResponse = useOmniValue(containersGroupsResponseAtom);
	const placeContainersResponse = useOmniValue(containersPlaceContainersResponseAtom);
	const placeVariantsResponse = useOmniValue(containersPlaceVariantsResponseAtom);

	let refContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (refContainer.current && filesResponse?.freezer) {
			const screenHeight = refContainer.current.getBoundingClientRect().height;
			const containerHeight = filesResponse.freezer.height;
			const scale = screenHeight / containerHeight;
			refContainer.current.setAttribute("style", `--scale: ${scale}`);
		}
	}, [filesResponse]);

	return (
		<>
			<ControlPanel />

			<ContainersContainer ref={refContainer} background={filesResponse === null}>
				<Scroller>
					{step === ContainersStep.FILES && filesResponse !== null && (
						<Container
							freezer={filesResponse.freezer}
							cumulativeShelfHeights={filesResponse.cumulative_shelf_heights}
						/>
					)}

					{filesResponse !== null &&
						(step === ContainersStep.GROUPS ||
							(step === ContainersStep.PLACE_CONTAINERS && placeContainersResponse === null)) && (
							<Container
								freezer={filesResponse.freezer}
								cumulativeShelfHeights={filesResponse.cumulative_shelf_heights}
								groups={groupsResponse?.groups}
							/>
						)}

					{((step === ContainersStep.PLACE_CONTAINERS && placeContainersResponse !== null) ||
						(step === ContainersStep.PLACE_VARIANTS &&
							placeContainersResponse !== null &&
							placeVariantsResponse === null)) && <Planograms />}

					{step === ContainersStep.PLACE_VARIANTS &&
						filesResponse !== null &&
						placeVariantsResponse !== null &&
						placeVariantsResponse.planogram_responses.length > 0 && (
							<Container
								freezer={placeVariantsResponse.planogram_responses[0].planogram.freezer}
								cumulativeShelfHeights={filesResponse.cumulative_shelf_heights}
							/>
						)}
				</Scroller>
			</ContainersContainer>
		</>
	);
};
