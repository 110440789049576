import React from "react";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

interface Props {
	display?: "block" | "inline" | "grid" | "flex" | "none";
	visibility?: "visible" | "hidden";
	direction?: "row" | "column" | "row-reverse";
	width?: string | number;
	maxWidth?: string | number;
	minWidth?: string | number;
	height?: string | number;
	minHeight?: string | number;
	maxHeight?: string | number;
	margin?: string;
	marginTop?: string;
	padding?: string;
	gap?: string;
	background?: string | false;
	justify?:
		| "normal"
		| "center"
		| "left"
		| "right"
		| "baseline"
		| "evenly"
		| "between"
		| "around"
		| "stretch"
		| "start"
		| "end";
	align?: "normal" | "start" | "end" | "center" | "baseline" | "stretch";
	alignSelf?: "normal" | "start" | "end" | "center" | "baseline" | "stretch";
	border?: string;
	borderRadius?: string;
	borderWidth?: string;
	wrap?: "nowrap" | "wrap" | "wrap-reverse";
	flex?: string;
	overflow?: "auto" | "visible" | "hidden" | "scroll";
	cursor?: "inherit" | "auto" | "pointer" | "default";
	position?: "static" | "relative" | "absolute" | "fixed" | "sticky";
	top?: string | number;
	right?: string | number;
	bottom?: string | number;
	left?: string | number;
	textAlign?: "start" | "end" | "left" | "right" | "center";
	opacity?: number;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	gridColumn?: string;
	gridRow?: string;
	gridColumns?: string;
	whiteSpace?: string;
	pointerEvents?: "auto" | "none" | "all";
	rotate?: number;
	userSelect?: "all" | "auto" | "none" | "text";
	boxShadow?: string;
	wordBreak?: "normal" | "break-all" | "break-word" | "keep-all";
	zIndex?: number;
	hoverColor?: Color | string;
	activeColor?: Color | string;
	flexItems?: boolean;
	column?: boolean;
}

export const Box = styled.div<Props>`
	display: ${({ display = "flex" }) => display};
	visibility: ${({ visibility }) => visibility};
	flex-direction: ${({ direction, column }) => direction || (column === true && "column")};
	width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
	max-width: ${({ maxWidth }) => maxWidth};
	min-width: ${({ minWidth }) => minWidth};
	height: ${({ height }) => (typeof height === "number" ? `${height}px` : height)};
	min-height: ${({ minHeight }) => minHeight};
	margin: ${({ margin }) => margin};
	margin-top: ${({ marginTop }) => marginTop};
	padding: ${({ padding }) => padding};
	gap: ${({ gap }) => gap};
	background: ${({ background }) => background};
	justify-content: ${({ justify }) =>
		(justify === "around" && "space-around") ||
		(justify === "between" && "space-between") ||
		(justify === "evenly" && "space-evenly") ||
		justify};
	align-items: ${({ align }) => align};
	align-self: ${({ alignSelf }) => alignSelf};
	border: ${({ border }) => border};
	border-radius: ${({ borderRadius }) => borderRadius};
	border-width: ${({ borderWidth }) => borderWidth};
	flex-wrap: ${({ wrap }) => wrap};
	flex: ${({ flex }) => flex};
	overflow: ${({ overflow }) => overflow};
	cursor: ${({ cursor, onClick }) => cursor || (onClick && "pointer")};
	position: ${({ position }) => position};
	top: ${({ top }) => (typeof top === "number" ? `${top}px` : top)};
	right: ${({ right }) => (typeof right === "number" ? `${right}px` : right)};
	bottom: ${({ bottom }) => (typeof bottom === "number" ? `${bottom}px` : bottom)};
	left: ${({ left }) => (typeof left === "number" ? `${left}px` : left)};
	text-align: ${({ textAlign }) => textAlign};
	opacity: ${({ opacity }) => opacity};
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	white-space: ${({ whiteSpace }) => whiteSpace};
	pointer-events: ${({ pointerEvents }) => pointerEvents};
	transform: ${({ rotate }) => rotate && `rotate(${rotate}deg)`};
	user-select: ${({ userSelect, onClick }) => userSelect || (onClick && "none")};
	box-shadow: ${({ boxShadow }) => boxShadow};
	word-break: ${({ wordBreak }) => wordBreak};
	z-index: ${({ zIndex }) => zIndex};

	${({ flexItems }) => flexItems && "> * { flex: 1; min-width: 0; }"};

	${({ hoverColor }) =>
		hoverColor && `:hover *, :hover *[data-variant] {color: ${hoverColor}; fill: ${hoverColor};}`}

	${({ activeColor }) =>
		activeColor &&
		`:active *, :active *[data-variant] {color: ${activeColor}; fill: ${activeColor};}`}

	${({ hoverColor, activeColor }) =>
		(hoverColor || activeColor) &&
		`* { transition: color ${Transition.fast}, fill ${Transition.fast}; }`}
`;
