import React from "react";

import { HoverSide } from "../types";
import {
	PLANOGRAM_BAY,
	PLANOGRAM_ITEM,
	PLANOGRAM_ITEMS,
	PLANOGRAM_SHELF,
	UNRRANGED_ITEMS,
} from "./classes";

interface DraggableHTMLElement extends HTMLElement {
	xModifier: number;
	yModifier: number;
}

type DnD = (payload: {
	currentItem: {
		id: string;
		bayNo: number | "unranged";
		shelfNo: number | "unranged";
	};
	targetItem:
		| {
				id: string | "empty";
				bayNo: number;
				shelfNo: number;
				side: HoverSide | "empty";
		  }
		| "unranged";
}) => void;

let originalItem: HTMLElement | null = null;
let draggable: DraggableHTMLElement | null = null;
let placeholder: HTMLElement | null = null;
let unrangedItem: HTMLElement | null = null;

export const onPlanogramItemMouseDown = (
	event: React.MouseEvent<HTMLElement>,
	dragAndDrop: DnD,
) => {
	// Not left click.
	if (event.nativeEvent.button !== 0) return;

	// Not planogram item.
	if (!(event.target as HTMLElement).classList.contains(PLANOGRAM_ITEM)) return;

	// Mouse up listener to detect item editing and dropping.
	document.body.addEventListener("mouseup", () => onPlanogramMouseUp(dragAndDrop), { once: true });

	// Keep reference to original item.
	originalItem = event.target as HTMLElement;
};

export const onPlanogramMouseMove = (event: React.MouseEvent<HTMLElement>) => {
	if (!originalItem) return;

	// Generate placeholder on first move event.
	if (!draggable) {
		// Find original element position on screen.
		const { x, y } = originalItem.getBoundingClientRect();

		// Generate placeholder.
		placeholder = originalItem.cloneNode(true) as HTMLElement;
		placeholder.setAttribute("data-placeholder", "");
		originalItem.parentNode?.insertBefore(placeholder, originalItem);

		// Generate draggable.
		draggable = originalItem.cloneNode(true) as DraggableHTMLElement;
		draggable.setAttribute("data-dragging", "");
		draggable.xModifier = event.screenX - x;
		draggable.yModifier = event.screenY - y;
		draggable.style.top = `${y}px`;
		draggable.style.left = `${x}px`;
		document.body.append(draggable);

		// Hide original item.
		originalItem.setAttribute("data-hidden", "");

		// Dragging cursor and events.
		document.body.setAttribute("data-dragging", "");
	}

	if (!draggable) return;

	const { screenX, screenY } = event;
	draggable.style.top = `${screenY - draggable.yModifier}px`;
	draggable.style.left = `${screenX - draggable.xModifier}px`;
};

export const onPlanogramMouseUp = (dragAndDrop: DnD) => {
	// Original item should actually exist.
	if (!originalItem) return;

	// Mouse was clicked down but not moved (edit).
	if (!draggable) {
		originalItem = null;
		return;
	}

	const unrangedItemsPanel = document.querySelector(`.${UNRRANGED_ITEMS}`);
	const movingToUnrangedItems = unrangedItemsPanel?.hasAttribute("data-dropping");

	const currentId = originalItem.getAttribute("data-id") as string;
	const currentBay = originalItem.closest(`.${PLANOGRAM_BAY}`)?.getAttribute("data-no");
	const currentShelf = originalItem.closest(`.${PLANOGRAM_SHELF}`)?.getAttribute("data-no");

	const targetId = placeholder?.getAttribute("data-target-id");
	const targetSide = placeholder?.getAttribute("data-target-side") as HoverSide | null;
	const targetBay = placeholder?.closest(`.${PLANOGRAM_BAY}`)?.getAttribute("data-no");
	const targetShelf = placeholder?.closest(`.${PLANOGRAM_SHELF}`)?.getAttribute("data-no");

	const domItems = placeholder?.closest(`.${PLANOGRAM_ITEMS}`);

	if (movingToUnrangedItems) {
		dragAndDrop({
			currentItem: {
				id: currentId,
				bayNo: currentBay ? parseInt(currentBay) : "unranged",
				shelfNo: currentShelf ? parseInt(currentShelf) : "unranged",
			},
			targetItem: "unranged",
		});
	} else if (targetBay && targetShelf) {
		// Dragging item to same location without actually moving it.
		if (domItems && domItems.childElementCount >= 3 && !targetSide) {
		} else {
			dragAndDrop({
				currentItem: {
					id: currentId,
					bayNo: currentBay ? parseInt(currentBay) : "unranged",
					shelfNo: currentShelf ? parseInt(currentShelf) : "unranged",
				},
				targetItem: movingToUnrangedItems
					? "unranged"
					: {
							id: targetId || "empty",
							bayNo: parseInt(targetBay),
							shelfNo: parseInt(targetShelf),
							side: targetSide || "empty",
					  },
			});
		}
	}

	unrangedItemsPanel?.removeAttribute("data-dropping");

	originalItem?.removeAttribute("data-hidden");
	originalItem = null;

	placeholder?.remove();
	placeholder = null;

	draggable?.remove();
	draggable = null;

	unrangedItem?.removeAttribute("data-placeholder");
	unrangedItem = null;

	document.body.removeAttribute("data-dragging");
};

export const onShelfMouseMove = (event: React.MouseEvent<HTMLElement>) => {
	if (!draggable) return;

	// No placeholder, item was dragged from panel.
	if (!placeholder) {
		placeholder = draggable.cloneNode(true) as HTMLElement;
		placeholder.removeAttribute("data-dragging");
		placeholder.removeAttribute("style");
		placeholder.setAttribute("data-placeholder", "");
	}

	// Initial target as shelf.
	let shelf: HTMLElement | null = event.target as HTMLElement;

	// If target is not shelf, try to find it.
	if (!shelf.classList.contains(PLANOGRAM_SHELF)) {
		shelf = shelf.closest(`.${PLANOGRAM_SHELF}`);
	}

	// Make sure element is shelf.
	if (!shelf || !shelf.classList.contains(PLANOGRAM_SHELF)) return;

	// Cursor position relative to shelf.
	const { clientX: cursorX } = event;

	// Find all items on the shelf.
	const items = shelf.querySelectorAll(".planogram-item");

	// Empty shelf or shelf that previously had the original item.
	if (items.length === 0 || (items.length === 1 && items[0] === originalItem)) {
		shelf.querySelector(`.${PLANOGRAM_ITEMS}`)?.appendChild(placeholder);

		// Remove target id, since the item is added based on bay and shelf numbers.
		placeholder.removeAttribute("data-target-id");
		placeholder.removeAttribute("data-target-side");
		return;
	}

	// Empty shelf with placeholder.
	if (items.length === 1 && items[0] === placeholder) return;

	let closest: HTMLElement | null = null;
	let closestDistance = 0;

	// Find closest item to cursor.
	for (const item of items) {
		if (item.hasAttribute("data-hidden") || item.hasAttribute("data-placeholder")) continue;
		const bounds = item.getBoundingClientRect();
		const distance = Math.abs(bounds.x + bounds.width - cursorX);

		if (closest === null || distance < closestDistance) {
			closest = item as HTMLElement;
			closestDistance = distance;
		}
	}

	// Closest is already placeholder, no need to move placeholder element.
	if (closest === placeholder) return;

	// Closest item found.
	if (closest && placeholder) {
		// Check if items needs to go before or after closest item.
		const bounds = closest.getBoundingClientRect();

		if (cursorX < bounds.x + bounds.width / 2) {
			// Insert left of closest item.
			if (closest.previousSibling === placeholder) return;
			closest.parentNode?.insertBefore(placeholder, closest);
			placeholder.setAttribute("data-target-side", HoverSide.LEFT);
		} else {
			// Insert right of closest item.
			if (closest.nextSibling === placeholder) return;
			closest.parentNode?.insertBefore(placeholder, closest.nextSibling);
			placeholder.setAttribute("data-target-side", HoverSide.RIGHT);
		}

		// Save target info to placeholder.
		placeholder.setAttribute("data-target-id", closest.getAttribute("data-id") as string);
	}
};

export const onUnrangedItemMouseDown = (event: React.MouseEvent<HTMLElement>, dragAndDrop: DnD) => {
	// Not left click.
	if (event.nativeEvent.button !== 0) return;

	// Make unranged item placeholder.
	unrangedItem = event.target as HTMLElement;
	unrangedItem.setAttribute("data-placeholder", "");

	// Reference to original item.
	originalItem = unrangedItem.querySelector(`.${PLANOGRAM_ITEM}`);
	if (!originalItem) return;

	// Find original element position on screen.
	const x = event.clientX - originalItem.getBoundingClientRect().width / 2;
	const y = event.clientY - originalItem.getBoundingClientRect().height / 2;

	// Create draggable.
	draggable = originalItem.cloneNode(true) as DraggableHTMLElement;
	draggable.setAttribute("data-dragging", "");
	draggable.xModifier = event.screenX - x;
	draggable.yModifier = event.screenY - y;
	draggable.style.top = `${y}px`;
	draggable.style.left = `${x}px`;
	document.body.append(draggable);

	document.body.setAttribute("data-dragging", "");
	document.body.addEventListener("mouseup", () => onPlanogramMouseUp(dragAndDrop), { once: true });
};

export const onUnrangedPanelMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
	if (!draggable) return;

	const panel = (event.target as HTMLElement).classList.contains(UNRRANGED_ITEMS)
		? (event.target as HTMLElement)
		: ((event.target as HTMLElement).closest(`.${UNRRANGED_ITEMS}`) as HTMLElement);

	panel.setAttribute("data-dropping", "");

	// Remove placeholder as item wont be dropped to the shelf any longer.
	placeholder?.remove();
	placeholder = null;
};

export const onUnrangedPanelMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
	if (!draggable) return;

	const panel = (event.target as HTMLElement).classList.contains(UNRRANGED_ITEMS)
		? (event.target as HTMLElement)
		: ((event.target as HTMLElement).closest(`.${UNRRANGED_ITEMS}`) as HTMLElement);

	panel.removeAttribute("data-dropping");
};
