import { PlanogramBay, PlanogramItem, PlanogramShelf } from "src/types";

export interface ColorCandidate {
	variant: string;
	segment: string;
	block: string;
}

export enum VisualizerColorBy {
	SEGMENT = "segment",
	BLOCK = "block",
	SEG_BLOCK = "seg_block",
}

export const enum HoverSide {
	RIGHT = "right",
	LEFT = "left",
}

export const enum PlanogramVariant {
	PLANOGRAM,
	MUDMAP,
}

export type DragAndDropPayload = {
	currentItem: {
		id: string;
		bayNo: number | "unranged";
		shelfNo: number | "unranged";
	};
	targetItem:
		| {
				id: string | "empty";
				bayNo: number;
				shelfNo: number;
				side: HoverSide | "empty";
		  }
		| "unranged";
};

export interface BayPanel {
	bay: PlanogramBay | null;
	open: boolean;
	modified: boolean;
}

export interface ShelfPanel {
	bayNo: number;
	shelf: PlanogramShelf | null;
	open: boolean;
	modified: boolean;
}

export interface ItemPanel {
	item: PlanogramItem | null;
	open: boolean;
	modified: boolean;
}
