import { GroupParametersOptions, GroupsParameters } from "src/pages/Containers/store/types";
import { omniAtom } from "src/utils/atoms";

const prefix = "modals/groupsParameters";

export const containerWidthLimitMinAtom = omniAtom({
	prefix,
	key: "variantWidthLimit/min",
	value: 0,
});

export const containerWidthLimitMaxAtom = omniAtom({
	prefix,
	key: "containerWidthLimit/max",
	value: 1000,
});

export const containerWidthLimitOptionAtom = omniAtom({
	prefix,
	key: "containerWidthLimit/option",
	value: "min" as GroupParametersOptions,
});

export const containerHeightLimitMinAtom = omniAtom({
	prefix,
	key: "containerHeightLimit/min",
	value: 0,
});

export const containerHeightLimitMaxAtom = omniAtom({
	prefix,
	key: "containerHeightLimit/max",
	value: 3,
});

export const containerHeightLimitOptionAtom = omniAtom({
	prefix,
	key: "containerHeightLimit/option",
	value: "min" as GroupParametersOptions,
});

export const extendOptionAtom = omniAtom({
	prefix,
	key: "extendOption",
	value: 1,
});

export const containersGroupsParametersAtom = omniAtom({
	key: prefix,
	value: {
		container_width_limit: {
			min: 0,
			max: 1000,
			option: "min",
		},
		container_height_limit: {
			min: 0,
			max: 3,
			option: "min",
		},
		extend_option: 1,
	} as GroupsParameters,
});
