import { usePog } from "src/components/Bays/store/actions";
import { baysMudmapAtom } from "src/components/Bays/store/atoms";
import { Button } from "src/elements";
import { baysPoggerVersionAtom } from "src/pages/Bays/store/atoms";
import { useGeneratePlanogram, useRebuildPlanogram } from "src/store/bays";
import { useOmniValue } from "src/utils/atoms";

interface Props {}

export const ButtonGeneratePlanogram: React.FC<Props> = () => {
	const mudmap = useOmniValue(baysMudmapAtom);
	const poggerVersion = useOmniValue(baysPoggerVersionAtom);

	const { pog } = usePog();
	const { generatePlanogram } = useGeneratePlanogram();
	const { rebuildPlanogram } = useRebuildPlanogram();

	return (
		<Button
			theme="primary"
			watermark={poggerVersion}
			onClick={pog ? rebuildPlanogram : generatePlanogram}
			disabled={!mudmap}
		>
			{pog ? "Rebuild Planogram" : "Generate Planogram"}
		</Button>
	);
};
