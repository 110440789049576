import { FreezerSection, useFreezers } from "src/store/freezers";
import styled, { StyledComponent } from "styled-components";

import { onFreezerSectionMouseUp, onFrezerSectionMouseEnter } from "../utils";
import { FREEZER_SECTION } from "../utils/classes";

interface FreezerSectionProps {
	top: number;
	left: number;
	width: number;
	height: number;
}

const SectionElement: StyledComponent<
	"div",
	any,
	FreezerSectionProps,
	never
> = styled.div.attrs<FreezerSectionProps>(({ top, left, width, height }) => ({
	style: {
		bottom: `calc(var(--scale) * ${top}px)`,
		left: `calc(var(--scale) * ${left}px)`,
		width: `calc(var(--scale) * ${width}px)`,
		height: `calc(var(--scale) * ${height}px)`,
	},
}))`
	position: absolute;
`;

const BorderRight = styled.div`
	width: 10px;
	height: 100%;
	background-image: url("freezer-border.svg");
	position: absolute;
	top: 0;
	right: -5px;
	pointer-events: none;
`;

const BorderBottom = styled.div`
	width: 100%;
	height: 10px;
	background-image: url("freezer-border.svg");
	position: absolute;
	top: -5px;
	right: 0;
	pointer-events: none;
`;

interface Props {
	section: FreezerSection;
	borderRight: boolean;
	borderBottom: boolean;
	type: "mudmap" | "planogram";
	children?: React.ReactNode;
}

export const Section: React.FC<Props> = ({
	section,
	borderRight,
	borderBottom,
	type,
	children,
}) => {
	const { moveFreezerItem } = useFreezers(type);

	return (
		<SectionElement
			className={FREEZER_SECTION}
			data-id={section.section_id}
			top={section.start_y}
			left={section.start_x}
			width={section.width}
			height={section.height}
			onMouseEnter={event => onFrezerSectionMouseEnter(event)}
			onMouseUp={event => onFreezerSectionMouseUp(event, moveFreezerItem)}
		>
			{children}
			{borderRight && <BorderRight />}
			{borderBottom && <BorderBottom />}
		</SectionElement>
	);
};
