import React from "react";
import { Box, textVariants } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

const CellElement = styled(Box)<{ variant: any; align: any }>`
	width: 100%;
	height: 100%;

	border-right: 1px solid ${Color.lightGrayHover};
	border-bottom: 1px solid ${Color.lightGrayHover};

	padding: 10px;

	font-size: ${({ variant }) => textVariants[variant].fontSize};
	font-weight: ${({ variant }) => textVariants[variant].fontWeight};
	line-height: ${({ variant }) => textVariants[variant].lineHeight};
	color: ${Color.textSecondary};
`;

export const Cell: React.FC<{
	variant?: any;
	justify?: "center" | "right";
	align?: "center";
	children: React.ReactNode;
}> = ({ variant, justify, align, children }) => (
	<CellElement className="table-cell" variant={variant} justify={justify} align={align}>
		{children}
	</CellElement>
);
