import { IconV2 } from "src/elements";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { inputHeight } from "../data/constants";

const NumberArrowsElement = styled.div<{ visible?: boolean }>`
	position: absolute;
	top: 0;
	right: 2px;
	width: 14px;
	height: ${inputHeight};
	flex-direction: column;
	justify-content: center;
	user-select: none;
	transition: opacity ${Transition.fast};
	opacity: ${({ visible }) => (visible ? 1 : 0.5)};
`;

const NumberArrow = styled.div`
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	transition: background-color ${Transition.fast};
	opacity: 1;
	cursor: pointer;

	path {
		transition: fill ${Transition.fast};
	}

	&:hover {
		background: ${Color.greenSmoke};
		box-shadow: 0px 4px 7px rgba(157, 173, 184, 0.2);

		path {
			fill: ${Color.primaryHover};
		}
	}

	&:active {
		background: ${Color.greenSmokeHover};
		box-shadow: 0px 4px 7px rgba(157, 173, 184, 0.2);

		path {
			fill: ${Color.primaryActive};
		}
	}
`;

interface Props {
	value: number;
	setValue: (value: number) => void;
	visible?: boolean;
	min?: number;
	max?: number;
	increment?: number;
}

export const NumberArrows: React.FC<Props> = ({
	value,
	setValue,
	min,
	max,
	increment = 1,
	visible,
}) => {
	const increaseValue = () => {
		if (max !== undefined) {
			setValue(Math.min(value + increment, max));
		} else {
			setValue(value + increment);
		}
	};

	const decreaseValue = () => {
		if (min !== undefined) {
			setValue(Math.max(value - increment, min));
		} else {
			setValue(value - increment);
		}
	};

	return (
		<NumberArrowsElement visible={visible}>
			<NumberArrow onClick={increaseValue}>
				<IconV2 name="expandUp" />
			</NumberArrow>

			<NumberArrow onClick={decreaseValue}>
				<IconV2 name="expandDown" />
			</NumberArrow>
		</NumberArrowsElement>
	);
};
