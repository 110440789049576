import React, { useEffect, useRef } from "react";
import { Freezers as FreezersType } from "src/store/freezers";
import styled from "styled-components";

import { Freezer } from "./components/Freezer";
import { FreezerItem } from "./components/Item";
import { Overlaps } from "./components/Overlaps";
import { Section } from "./components/Section";
import { onFreezerItemMouseMove } from "./utils";
import { FREEZERS } from "./utils/classes";
import { freezerBorder } from "./utils/data";

const FreezersContainer = styled.div`
	flex-direction: column;
	gap: 10px;
	transform-origin: top left;
	transform: scale(var(--freezers-zoom));
`;

interface Props {
	data: FreezersType | null;
	type: "mudmap" | "planogram";
}

export const Freezers: React.FC<Props> = ({ data, type }) => {
	const refFreezers = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleResize() {
			if (!data || !refFreezers.current) return;

			const { offsetWidth: contentWidth, offsetHeight: contentHeight } = (
				refFreezers.current as any
			).parentElement;

			const ratioHeight =
				(contentHeight - 2 * freezerBorder) /
				Math.max.apply(
					null,
					data.planogram.freezers.map(freezer => freezer.height),
				);

			const ratioWidth =
				(contentWidth - 2 * freezerBorder) /
				Math.max.apply(
					null,
					data.planogram.freezers.map(freezer => freezer.width),
				);

			const scale =
				Math.floor(Math.min(ratioHeight, ratioWidth)) || Math.min(ratioHeight, ratioWidth);

			refFreezers.current.setAttribute("style", `--scale: ${scale}`);
			refFreezers.current.setAttribute("data-scale", `${scale}`);
		}

		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [data]);

	useEffect(() => {
		document.body.addEventListener("mousemove", onFreezerItemMouseMove as any);

		return () => {
			document.body.removeEventListener("mousemove", onFreezerItemMouseMove as any);
		};
	}, []);

	if (!data) {
		return null;
	}

	return (
		<FreezersContainer ref={refFreezers} className={FREEZERS}>
			{data.planogram.freezers.map(freezer => (
				<Freezer key={freezer.freezer_id} freezer={freezer}>
					{freezer.sections.map(section => {
						let borderRight = false;
						let borderBottom = false;

						for (const sectionCheck of freezer.sections) {
							if (section === sectionCheck) continue;
							if (section.start_x + section.width === sectionCheck.start_x) borderRight = true;
							if (section.start_y + section.height === sectionCheck.start_y) borderBottom = true;
						}

						return (
							<Section
								key={`${freezer.freezer_id}_${section.section_id}`}
								section={section}
								{...{ borderRight, borderBottom }}
								type={type}
							></Section>
						);
					})}

					{freezer.sections.map(section =>
						section.items.map((item, index) => (
							<FreezerItem
								key={index}
								freezerId={freezer.freezer_id}
								sectionId={section.section_id}
								item={item}
								type={type}
							/>
						)),
					)}

					<Overlaps freezer={freezer} />
				</Freezer>
			))}
		</FreezersContainer>
	);
};
