import React, { useRef, useState } from "react";
import { useOnClickOutside } from "src/hooks";
import { Color } from "src/utils";
import styled from "styled-components";

import { Label } from "../Input";
import { Menu } from "./components/Menu";
import { Option } from "./components/Option";
import { DropdownProps, MenuState, Option as OptionType } from "./types";

const DropdownContainer = styled.div<{ width?: string }>`
	position: relative;
	flex-direction: column;
	min-width: ${({ width }) => width};
	cursor: pointer;
	z-index: 1;
`;

const DropdownInner = styled.div`
	border: 1px solid ${Color.spaceGray};
	border-radius: 4px;
	overflow: hidden;
`;

interface Props extends DropdownProps {
	value: any;
	setValue: (value: any) => void;
}

export const Dropdown: React.FC<Props> = ({
	value,
	setValue,
	label,
	placeholder,
	useSearch,
	width,
	data,
}) => {
	const [menuState, setMenuState] = useState<MenuState>({
		open: false,
	});

	const refDropdown = useRef<HTMLDivElement>(null);
	const refMenu = useRef<HTMLDivElement>(null);

	const open = (event: React.MouseEvent) => {
		event.stopPropagation();

		if (!menuState.open && refDropdown.current) {
			const { x, y, width } = refDropdown.current.getBoundingClientRect();

			setMenuState({
				open: true,
				x,
				y,
				width,
			});
		}
	};

	const close = () => {
		if (menuState.open) {
			setMenuState({
				open: false,
			});
		}
	};

	useOnClickOutside(refMenu, close);

	return (
		<DropdownContainer width={width} onClick={open}>
			<Label label={label} active={menuState.open} />

			<DropdownInner ref={refDropdown} onClick={open}>
				<Option icon="expandDown" placeholder>
					{data.find(option => option.value === value)?.title ||
						data.find(option => option.value === value)?.value ||
						placeholder ||
						""}
				</Option>
			</DropdownInner>

			<Menu
				refMenu={refMenu}
				state={menuState}
				value={value}
				setValue={setValue}
				close={close}
				placeholder={placeholder}
				useSearch={useSearch}
				data={data}
			/>
		</DropdownContainer>
	);
};
