import { Facing } from "src/components/Bays/components/Facing";
import { PlanogramItem } from "src/components/Bays/components/PlanogramItem";
import { Box, Text } from "src/elements";
import { PlanogramItem as PlanogramItemType, ShelfType } from "src/types";
import { Color } from "src/utils";
import styled from "styled-components";

const UnrangedItemElement = styled.div`
	width: 100%;
	align-items: center;
	gap: 10px;
	background: ${Color.lightGrayDisabled};
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
	padding: 10px;
	cursor: grab;
	user-select: none;
	pointer-events: all;

	> * {
		pointer-events: none;
	}

	&[data-placeholder] {
		opacity: 0.5;
	}
`;

interface Props {
	item: PlanogramItemType;
	onMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
	onMouseEnter: (event: React.MouseEvent<HTMLElement>) => void;
}

export const UnrangedItem: React.FC<Props> = ({ item, onMouseDown, onMouseEnter }) => (
	<UnrangedItemElement onMouseDown={onMouseDown} onMouseEnter={onMouseEnter}>
		<Box>
			<Box width="0" height="0" overflow="hidden">
				<PlanogramItem item={item}>
					<Facing
						variant=""
						shelf_type={ShelfType.REGULAR}
						item={{ ...item, id: item.item_id, segment: item.cdt1, block: item.cdt2 }}
					/>
				</PlanogramItem>
			</Box>

			<Facing
				variant=""
				shelf_type={ShelfType.REGULAR}
				item={{ ...item, id: item.item_id, segment: item.cdt1, block: item.cdt2 }}
				fixedSize
			/>
		</Box>

		<Text variant="small3" color={Color.textMain}>
			{item.item_id}, {item.cdt1}, {item.cdt2}
		</Text>
	</UnrangedItemElement>
);
