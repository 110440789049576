import React from "react";
import { Box, IconType, IconV2, Text } from "src/elements";
import { Color, Transition, tooltip } from "src/utils";
import styled from "styled-components";

import { inputHeight } from "../Input";

const TOGGLE_WIDTH = 32;
const TOGGLE_HANDLE_SIZE = 12;
const TOGGLE_PADDING = 3;

const ToggleElem = styled.div`
	width: 100%;
	min-height: ${inputHeight};
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	gap: 7px;
	user-select: none;

	&:not([data-disabled]) {
		cursor: pointer;
	}
`;

const ToggleBackground = styled.div`
	align-items: center;
	padding: ${TOGGLE_PADDING}px;
	min-width: ${TOGGLE_WIDTH}px;
	width: ${TOGGLE_WIDTH}px;
	border-radius: ${TOGGLE_WIDTH}px;
	transition: background-color ${Transition.fast};
	background: ${Color.spaceGray};

	&[data-checked] {
		background-color: ${Color.primary};
	}

	&[data-disabled] {
		background-color: ${Color.textDisabled};
	}
`;

const ToggleHandle = styled.div`
	width: ${TOGGLE_HANDLE_SIZE}px;
	height: ${TOGGLE_HANDLE_SIZE}px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	transition: transform ${Transition.fast}, background-color ${Transition.fast};
	background-color: ${Color.white};
	transform: translateX(0);

	svg {
		transition: opacity ${Transition.fast};
	}

	:not([data-checked]) {
		svg {
			opacity: 0;
		}
	}

	&[data-checked] {
		transform: translateX(${TOGGLE_WIDTH - TOGGLE_HANDLE_SIZE - 2 * TOGGLE_PADDING}px);
	}

	&[data-disabled] {
		background-color: ${Color.lightGray};
	}
`;

export interface ToggleProps {
	checked: boolean;
	setChecked: (checked: boolean) => void;
	label: string;
	icon?: IconType;
	disabled?: boolean;
	tooltip?: string;
}

export const Toggle: React.FC<ToggleProps> = ({
	checked,
	setChecked,
	label,
	icon,
	disabled = false,
	tooltip: tooltipContent,
}) => {
	return (
		<ToggleElem
			{...(disabled && { "data-disabled": "" })}
			onClick={() => {
				if (!disabled) {
					setChecked(!checked);
				}
			}}
			onMouseEnter={event => tooltipContent && tooltip({ event, data: tooltipContent, delay: 600 })}
		>
			<Box gap="10px" align="center">
				{/* Icon */}
				{icon && <IconV2 name={icon} size={14} />}

				{/* Toggle label */}
				{label && (
					<Text
						variant="body3"
						color={disabled ? Color.textDisabled : Color.textSecondary}
						whiteSpace="nowrap"
					>
						{label}
					</Text>
				)}
			</Box>

			{/* Toggle */}
			<ToggleBackground
				{...(checked && { "data-checked": "" })}
				{...(disabled && { "data-disabled": "" })}
			>
				<ToggleHandle
					{...(checked && { "data-checked": "" })}
					{...(disabled && { "data-disabled": "" })}
				>
					<IconV2
						name="checkmark"
						size={TOGGLE_HANDLE_SIZE}
						color={disabled ? Color.textDisabled : Color.primary}
					/>
				</ToggleHandle>
			</ToggleBackground>
		</ToggleElem>
	);
};
