import { Objectives, Pog } from "src/types";

export const exportPlanogram = (planogram: Pog, objectives: Objectives) => {
	const download = document.createElement("a");

	download.setAttribute(
		"href",
		"data:text/json;charset=utf-8," +
			encodeURIComponent(
				JSON.stringify(
					{
						...planogram,
						...{ meta: { objective: objectives || planogram.meta.objective } },
					},
					null,
					2,
				),
			),
	);
	download.setAttribute("download", "export.json");

	download.style.display = "none";
	document.body.appendChild(download);

	download.click();

	document.body.removeChild(download);
};
