import hexToRgba from "hex-to-rgba";
import React from "react";
import { Text } from "src/elements";
import { Color } from "src/utils";
import styled, { StyledComponent } from "styled-components";

const ValidAreaElement: StyledComponent<"div", any, Props, never> = styled.div.attrs<Props>(
	({ shelfWidth: width, shelfHeight: height, fingerSpace, shelfThickness: thickness }) => ({
		style: {
			width: `calc(${width}px * var(--scale))`,
			height: `calc(${height - fingerSpace - thickness}px * var(--scale)`,
		},
	}),
)`
	position: absolute;
	left: 0;
	bottom: 0;
	border: 1px dashed ${hexToRgba(Color.red, 0.8)};
	border-radius: 2px;
	background-image: url("/dotted.png");
	pointer-events: none;
	z-index: 1;

	&:not([data-invalid]) {
		visibility: hidden;
	}
`;

const ValidAreaLabel = styled.div`
	position: absolute;
	top: -1px;
	right: -1px;
	background-color: ${Color.red};
	padding: 3px;
	border-radius: 0 2px;
`;

interface Props {
	readonly shelfWidth: number;
	readonly shelfHeight: number;
	readonly shelfThickness: number;
	readonly fingerSpace: number;
}

export const ValidArea = React.forwardRef<HTMLDivElement | null, Props>(
	({ shelfWidth, shelfHeight, shelfThickness, fingerSpace }, ref) => (
		<ValidAreaElement
			ref={ref}
			className="shelf-valid-area"
			shelfWidth={shelfWidth}
			shelfHeight={shelfHeight}
			shelfThickness={shelfThickness}
			fingerSpace={fingerSpace}
		>
			<ValidAreaLabel>
				<Text variant="caption2" color={Color.white} transform="uppercase">
					Overflowing
				</Text>
			</ValidAreaLabel>
		</ValidAreaElement>
	),
);
