export const enum Merchant {
	FOOD_PANDA = "1",
	SEVEN_ELEVEN = "2",
	SPAR = "3",
	COLES = "4",
	BIGW = "5",
	HEB = "6",
	OMNI = "7",
	WOW = "8",
}
