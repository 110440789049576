import React from "react";
import { Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

export const CodeElement = styled.div`
	display: inline;
	background: ${Color.lightGrayHover};
	padding: 1px 3px;
	border-radius: 4px;
`;

interface Props {
	children: React.ReactNode;
}

export const Code: React.FC<Props> = ({ children }) => (
	<CodeElement>
		<Text variant="caption1" color={Color.textSecondary}>
			{children}
		</Text>
	</CodeElement>
);
