import { PlanogramBay } from "src/types";

export const verifyPlanogram = (json: any) => {
	try {
		if (!json.planogram.bays) {
			throw { message: "No bays found" };
		}

		for (const bay of json.planogram.bays as PlanogramBay[]) {
			if (bay.bay_height === undefined) {
				throw { message: "bay_height missing", data: bay };
			}

			for (const shelf of bay.shelves) {
				if (shelf.shelf_width === undefined) {
					throw { message: "shelf_width missing", data: shelf };
				} else if (shelf.shelf_height === undefined) {
					throw { message: "shelf_height missing", data: shelf };
				} else if (shelf.shelf_thickness === undefined) {
					throw { message: "shelf_thickness missing", data: shelf };
				} else if (shelf.finger_space === undefined) {
					throw { message: "finger_space missing", data: shelf };
				}

				for (const item of shelf.items) {
					if (item.facings === undefined) {
						throw { message: "item.facings missing", data: item };
					} else if (item.height === undefined) {
						throw { message: "item.height missing", data: item };
					} else if (item.width === undefined) {
						throw { message: "item.width missing", data: item };
					} else if (item.num_rows === undefined) {
						throw { message: "item.num_rows missing", data: item };
					}
				}
			}
		}

		return {
			verified: true,
		};
	} catch (error) {
		return { verified: false, message: (error as any).message };
	}
};
