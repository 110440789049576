import { useEffect } from "react";
import { Bays, Page } from "src/components";
import { Mudmap } from "src/components/Bays/Mudmap";
import { usePog } from "src/components/Bays/store/actions";
import { baysMudmapAtom } from "src/components/Bays/store/atoms";
import { Scroller } from "src/elements";
import { useFiles } from "src/store/bays";
import { useOmniState, useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { ControlPanel } from "./components/ControlPanel/ControlPanel";
import { Objectives } from "./components/Objectives/Objectives";
import { UnrangedItems } from "./components/UnrangedItems/UnrangedItems";
import {
	baysFilesAtom,
	baysFixturesFileAtom,
	baysProductsFileAtom,
	baysStepAtom,
} from "./store/atoms";
import { BaysStep } from "./store/types";

const BaysInner = styled.div<{ background: boolean }>`
	flex: 1;
	min-height: 0;
	width: 100%;
	background: ${({ background }) => background && "url(bays.svg)"};
	background-size: cover;
	background-position: center;
`;

export const PageBays = () => {
	const [step, setStep] = useOmniState(baysStepAtom);
	const fixtureFile = useOmniValue(baysFixturesFileAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);
	const masterItems = useOmniValue(baysFilesAtom);
	const mudmap = useOmniValue(baysMudmapAtom);

	const { pog } = usePog();
	const { startUsingFiles } = useFiles();

	// If both fixture and master files are selected, get master items.
	useEffect(() => {
		if (fixtureFile && masterFile) startUsingFiles();
	}, [fixtureFile, masterFile]);

	// After master items are available, move to mudmap generation.
	useEffect(() => {
		if (masterItems) setStep(BaysStep.MUDMAP);
	}, [masterItems]);

	// After mudmap is generated or planogram opened, move to planogram generation.
	useEffect(() => {
		if (mudmap || pog) setStep(BaysStep.PLANOGRAM);
	}, [mudmap, pog]);

	return (
		<>
			<ControlPanel />

			<BaysInner background={step === BaysStep.FILES || (!mudmap && !pog) ? true : false}>
				<Scroller gutter="auto" padding="0" margin={0}>
					{mudmap && (step === BaysStep.MUDMAP || (step === BaysStep.PLANOGRAM && !pog)) && (
						<Mudmap data={mudmap} />
					)}

					{pog?.planogram && step === BaysStep.PLANOGRAM && <Bays data={pog.planogram} />}
				</Scroller>

				<UnrangedItems />
				<Objectives />
			</BaysInner>
		</>
	);
};
