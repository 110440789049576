import { Color } from "src/utils";

export const variants: {
	[variant: string]: {
		default: Color;
		hover: Color;
		active: Color;
		disabled: Color;
		text?: Color;
		textHover?: Color;
		textActive?: Color;
		textDisabled?: Color;
		textInverted?: Color;
	};
} = {
	primary: {
		default: Color.primary,
		hover: Color.primaryHover,
		active: Color.primaryActive,
		disabled: Color.primaryDisabled,
		text: Color.white,
	},

	yellow: {
		default: Color.yellow,
		hover: Color.yellowHover,
		active: Color.yellowActive,
		disabled: Color.yellowDisabled,
		text: Color.white,
	},

	danger: {
		default: Color.danger,
		hover: Color.dangerHover,
		active: Color.dangerActive,
		disabled: Color.dangerDisabled,
		text: Color.white,
	},

	red: {
		default: Color.red,
		hover: Color.redHover,
		active: Color.redActive,
		disabled: Color.redDisabled,
		text: Color.white,
	},

	greenSmoke: {
		default: Color.greenSmoke,
		hover: Color.greenSmokeHover,
		active: Color.greenSmokeActive,
		disabled: Color.greenSmokeDisabled,

		text: Color.primary,
		textHover: Color.primaryHover,
		textActive: Color.primaryActive,
		textDisabled: Color.primaryDisabled,
		textInverted: Color.primary,
	},
};
