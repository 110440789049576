import { OmniState, useOmniState } from "src/utils/atoms";

import { RangeV2 } from "./Range";

interface Props {
	state: OmniState<number>;
	title?: string;
	description?: string;
	min?: number;
	max?: number;
	step?: number;
	unit?: string;
	theme?: "default" | "round";
	withoutValueLabel?: boolean;
}

export const StateRange: React.FC<Props> = ({
	state,
	title,
	description,
	min = 0,
	max = 100,
	step = 1,
	unit = "",
	theme = "default",
	withoutValueLabel,
}) => {
	const [value, setValue] = useOmniState(state);

	return (
		<RangeV2
			value={value}
			setValue={setValue}
			title={title}
			description={description}
			min={min}
			max={max}
			step={step}
			unit={unit}
			theme={theme}
			withoutValueLabel={withoutValueLabel}
		/>
	);
};
