import { Freezer as FreezerType } from "src/store/freezers";
import { Color } from "src/utils";
import styled, { StyledComponent } from "styled-components";

import { FREEZER } from "../utils/classes";
import { freezerBorder } from "../utils/data";

interface FreezerContainerProps {
	width: number;
	height: number;
}

const FreezerElement: StyledComponent<
	"div",
	any,
	FreezerContainerProps,
	never
> = styled.div.attrs<FreezerContainerProps>(({ width, height }) => ({
	style: {
		width: `calc(var(--scale) * ${width}px + ${freezerBorder * 2}px)`,
		height: `calc(var(--scale) * ${height}px + ${freezerBorder * 2}px)`,
	},
}))`
	position: relative;
	background-color: ${Color.opacitySkyDisabled};
	border: ${freezerBorder}px solid ${Color.opacitySkyHover};
	border-radius: ${freezerBorder}px;
`;

interface Props {
	freezer: FreezerType;
	children: React.ReactNode;
}

export const Freezer: React.FC<Props> = ({ freezer, children }) => {
	return (
		<FreezerElement
			className={FREEZER}
			data-id={freezer.freezer_id}
			height={freezer.height}
			width={freezer.width}
		>
			{children}
		</FreezerElement>
	);
};
