import { ReactElement } from "react";
import { Box } from "src/elements/Box";
import { inputHeight } from "src/elements/Input";
import { Text } from "src/elements/Text/Text";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

const RadioElement = styled.div`
	min-height: ${inputHeight};
	gap: 6px;
	position: relative;
	align-items: center;
	cursor: pointer;
	user-select: none;

	:hover .radio-icon::after {
		transform: scale(1);
	}

	:active .radio-icon::after {
		opacity: 0.8;
	}
`;

const RadioIcon = styled.div`
	width: 14px;
	min-width: 14px;
	height: 14px;
	min-height: 14px;
	background: ${Color.white};
	border: 1px solid ${Color.primary};
	border-radius: 50%;
	margin: 2px 0;
	align-items: center;
	justify-content: center;

	// Inner circle inside radio
	::after {
		content: "";
		width: 8px;
		height: 8px;
		background: ${Color.primary};
		transform: scale(0);
		opacity: 0.5;
		border-radius: 50%;
		transition: opacity ${Transition.fast}, transform ${Transition.fast};
	}

	&[data-checked]::after {
		opacity: 1 !important;
		transform: scale(1) !important;
	}
`;

interface Props {
	label: string | ReactElement;
	description?: string;
	value: any;
	checked: boolean;
	setChecked: (checked: any) => void;
}

export const Radio: React.FC<Props> = ({ label, description, value, checked, setChecked }) => (
	<RadioElement onClick={() => setChecked(value)}>
		<RadioIcon className="radio-icon" {...(checked && { "data-checked": "" })} />

		<Box direction="column">
			{typeof label === "string" ? (
				<Text variant="body2" color={Color.textMain}>
					{label}
				</Text>
			) : (
				label
			)}

			{description && (
				<Text variant="caption2" color={Color.textSecondary}>
					{description}
				</Text>
			)}
		</Box>
	</RadioElement>
);
