import { useEffect, useRef } from "react";
import { Box, Button, Dropdown, NumberInput, Text, TextInput } from "src/elements";
import { NumberInputElement } from "src/elements/Input/NumberInput";
import { TextInputElement } from "src/elements/Input/TextInput";
import { ItemOrientation } from "src/types/item";

import { Panel, PanelGroup } from "../../Panel/Panel";
import { useItemPanel, usePog } from "../store/actions";

export const ItemPanel = () => {
	const { item, isOpen, close, modified, setModified } = useItemPanel();
	const { saveItem } = usePog();

	const refId = useRef<TextInputElement>(null);
	const refSegment = useRef<TextInputElement>(null);
	const refBlock = useRef<TextInputElement>(null);
	const refWidth = useRef<NumberInputElement>(null);
	const refHeight = useRef<NumberInputElement>(null);
	const refDepth = useRef<NumberInputElement>(null);
	const refRows = useRef<NumberInputElement>(null);
	const refFacings = useRef<NumberInputElement>(null);

	useEffect(() => {
		refId.current?.updateValue(item?.item_id);
		refSegment.current?.updateValue(item?.cdt1);
		refBlock.current?.updateValue(item?.cdt2);
		refWidth.current?.updateValue(item?.width);
		refHeight.current?.updateValue(item?.height);
		refDepth.current?.updateValue(item?.depth);
		refRows.current?.updateValue(item?.num_rows);
		refFacings.current?.updateValue(item?.facings);
	}, [item]);

	return (
		<Panel isOpen={isOpen}>
			<Box flex="1" padding="10px" gap="30px" column>
				<Box column gap="20px">
					<Text variant="h4">Product Item "{item?.item_id}"</Text>

					<PanelGroup title="Identificators">
						{/* <TextInput
							label="ID"
							ref={refId}
							onChange={value => {
								item!.item_id = value;
								setModified();
							}}
							disabled
						/> */}

						<TextInput
							label="Segment"
							ref={refSegment}
							onChange={value => {
								item!.cdt1 = value;
								setModified();
							}}
						/>

						<TextInput
							label="Block"
							ref={refBlock}
							onChange={value => {
								item!.cdt2 = value;
								setModified();
							}}
						/>
					</PanelGroup>

					<PanelGroup title="Dimensions">
						<NumberInput
							label="Width"
							ref={refWidth}
							onChange={value => {
								item!.width = value;
								setModified();
							}}
						/>

						<NumberInput
							label="Height"
							ref={refHeight}
							onChange={value => {
								item!.height = value;
								setModified();
							}}
						/>

						<NumberInput
							label="Depth"
							ref={refDepth}
							onChange={value => {
								item!.depth = value;
								setModified();
							}}
						/>
					</PanelGroup>

					<PanelGroup title="Quantity">
						<NumberInput
							label="Rows"
							ref={refRows}
							onChange={value => {
								refFacings.current?.updateValue((item!.facings / item!.num_rows) * value);
								item!.facings = (item!.facings / item!.num_rows) * value;
								item!.num_rows = value;
								setModified();
							}}
							min={1}
						/>

						<NumberInput
							label="Facings"
							ref={refFacings}
							onChange={value => {
								item!.facings = value;
								setModified();
							}}
							min={1}
						/>
					</PanelGroup>

					<PanelGroup title="Facing">
						<Dropdown
							data={Object.values(ItemOrientation).map(orientation => ({ value: orientation }))}
							label="Orientation"
							value={item?.orientation}
							setValue={value => {
								item!.orientation = value;
								setModified();
							}}
						/>

						{/* <Toggle
							label="Rotated"
							ref={refRotated}
							onChange={value => {
								item!.rotated = value;
								setModified();
							}}
						/> */}
					</PanelGroup>
				</Box>
			</Box>

			<Box padding="10px" gap="10px">
				<Button
					theme={modified ? "red" : "greenSmoke"}
					variant="inverted"
					width="75px"
					onClick={close}
				>
					{modified ? "Cancel" : "Back"}
				</Button>

				<Button
					width="100%"
					onClick={() => {
						saveItem(item!);
						close();
					}}
				>
					Save
				</Button>
			</Box>
		</Panel>
	);
};
