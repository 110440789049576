import { Route, Routes } from "react-router";
import { Modals, Toasts, Tooltips } from "src/components";
import { Loader } from "src/components";
import { IsAuth } from "src/store/auth";
import { GlobalStyle, pages } from "src/utils";

import { Box } from "./elements";
import { Dashboard, Freezers, Login, Logout, PageBays, PageContainers, Playground } from "./pages";

export const App = () => (
	<Box className="workbench">
		<GlobalStyle />

		<Routes>
			<Route path={pages.login} element={<Login />} />

			<Route element={<IsAuth />}>
				<Route path={pages.logout} element={<Logout />} />
				<Route path={pages.home} element={<Dashboard />} />
				<Route path={pages.bays} element={<PageBays />} />
				<Route path={pages.freezers} element={<Freezers />} />
				<Route path={pages.playground} element={<Playground />} />
				<Route path={pages.containers} element={<PageContainers />} />
			</Route>
		</Routes>

		<Loader />
		<Toasts />
		<Tooltips />
		<Modals />
	</Box>
);
