import React from "react";
import { Box, Text, textVariants } from "src/elements";
import { Color } from "src/utils";

interface Props {
	children: React.ReactNode;
}

export const ErrorBlock: React.FC<Props> = ({ children }) => {
	if (typeof children === "boolean") return null;

	return (
		<Box margin="5px 0 0 0" minHeight={textVariants.small2.lineHeight}>
			<Text variant="small2" color={Color.red}>
				{children}
			</Text>
		</Box>
	);
};
