import React, { RefObject } from "react";
import { Text } from "src/elements";
import { inputHeight } from "src/elements/Input";
import { Color, Transition, zIndex } from "src/utils";
import styled from "styled-components";

import { ButtonDropdown } from "../types/types";
import { Arrows } from "./Arrows";

const SPACE_BETWEEN_BUTTON_DROPDOWN = 5;

const DropdownContainer = styled.div`
	position: fixed;
	padding-top: calc(${inputHeight} + ${SPACE_BETWEEN_BUTTON_DROPDOWN}px);
	transition: opacity ${Transition.fast}, margin-top ${Transition.fast};
	z-index: ${zIndex.DROPDOWNS};
	cursor: default;

	&:not([data-open]) {
		pointer-events: none;
		margin-top: -${SPACE_BETWEEN_BUTTON_DROPDOWN}px;
		opacity: 0;
	}
`;

const DropdownElement = styled.div`
	min-width: 100%;
	border: 1px solid var(--border);
	border-radius: 3px;
	background: ${Color.white};
	flex-direction: column;
`;

const DropdownOption = styled.div`
	padding: 10px;
	cursor: pointer;
	white-space: nowrap;

	&:hover {
		background-color: var(--dropdown-color-hover);
	}

	&:active {
		background-color: var(--dropdown-color-active);
	}
`;

const DropdownDivider = styled.div`
	height: 1px;
	width: calc(100% - 10px);
	margin-left: 5px;
	background-color: ${Color.lightGray};
`;

interface Props {
	dropdown: ButtonDropdown[];
	refDropdown: RefObject<HTMLDivElement>;
}

export const Dropdown: React.FC<Props> = ({ dropdown, refDropdown }) => (
	<>
		<Arrows open={false} />

		<DropdownContainer ref={refDropdown}>
			<DropdownElement>
				{dropdown.map(({ title, onClick }, index) => (
					<React.Fragment key={index}>
						<DropdownOption onClick={onClick}>
							<Text variant="small1" color="var(--dropdown-text)">
								{title}
							</Text>
						</DropdownOption>

						<DropdownDivider />
					</React.Fragment>
				))}
			</DropdownElement>
		</DropdownContainer>
	</>
);
