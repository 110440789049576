import { Fixture } from "src/store/bays";

export interface Candidate {
	item_id: string;
	variant: string;
	segment: string;
	block: string;
	value: number;
	width: number;
	height: number;
	depth: number;
	quantity: number;
	min_days_of_supply: number;
	min_on_shelf: number;
	min_facings: number;
	max_facings: number;
	notch_offset_x: number;
	notch_offset_y: number;
	valid_orientation: string[];
	variant_priority: number;
	segment_priority: number;
	block_priority: number;
	is_in_core_range: boolean;
}

export type FilesSection = {
	section_id: number;
	start_x: number;
	start_y: number;
	width: number;
	height: number;
	depth: number;
	num_shelves: number;
	cumulative_shelf_heights: number[];
};

export type PlanogramSection = {
	section_id: number;
	start_x: number;
	start_y: number;
	width: number;
	height: number;
	items: Item[];
	cumulative_shelf_heights: number[];
};

export type FilesFreezer = {
	freezer_id: string;
	width: number;
	height: number;
	sections: FilesSection[];
};

export type PlanogramFreezer = {
	freezer_id: string;
	width: number;
	height: number;
	sections: PlanogramSection[];
};

export type Item = {
	item_id: string;
	start_x: number;
	start_y: number;
	width: number;
	height: number;
	rotated: boolean;
	cdt0: string;
	cdt1: string;
	cdt2: string;
};

export type Group = {
	group_id: string;
	variants: [
		{
			name: string;
			width: number;
			height: number;
			x: number;
			y: number;
		},
	];
	width: number;
	height: number;
	x: number;
	y: number;
};

export type Container = {
	id: string;
	variants: string[];
	start_x: number;
	end_x: number;
	start_y: number;
	end_y: number;
};

export type ContainersFilesPayload = {
	fixturesFile: File;
	candidatesFile: File;
};

export type ContainersFilesResponse = {
	freezer: FilesFreezer;
	candidates: Candidate[];
	cumulative_shelf_heights: number[];
	variants: string[];
	fixtures: Fixture[];
};

export type ContainersGroupsPayload = {
	version: string;
	freezer: FilesFreezer;
	candidates: Candidate[];
	cumulative_shelf_heights: number[];
	merge_rules: MergeRule[];
	parameters: GroupsParameters;
};

export type ContainersGroupsResponse = {
	groups: Group[];
};

export type ContainersPlaceContainersPayload = {
	version: string;
	freezer: FilesFreezer;
	cumulative_shelf_heights: number[];
	groups: Group[];
};

export type ContainersPlaceVariantsPayload = {
	version: string;
	freezer: FilesFreezer;
	candidates: Candidate[];
};

export type Planogram = {
	freezer: {
		freezer_id: string;
		width: number;
		height: number;
		sections: PlanogramSection[];
	};
};

export type ContainersPlaceContainersResponse = {
	planogram_responses: {
		planogram: Planogram;
		meta: {
			item_stats: {
				[key: string]: {
					item_id: string;
					total_facings: number;
				};
			};
			objective: {
				[key: string]: number;
			};
			solution_time: number;
		};
	}[];
};

export type ContainersPlaceVariantsResponse = {
	planogram_responses: {
		planogram: Planogram;
		meta: {
			item_stats: {
				[key: string]: {
					item_id: string;
					total_facings: number;
				};
			};
			objective: {
				[key: string]: number;
			};
			solution_time: number;
		};
	}[];
};

export const enum ColorsBy {
	VARIANT = "VARIANT",
	SEGMENT = "SEGMENT",
	BLOCK = "BLOCK",
}

export type MergeRule = {
	variants: string[];
};

export type CustomMergeRule = {
	id: number;
	variants: string[];
};

export type GroupsParameters = {
	container_width_limit: {
		min: number;
		max: number;
		option: GroupParametersOptions;
	};
	container_height_limit: {
		min: number;
		max: number;
		option: GroupParametersOptions;
	};
	extend_option: number;
};

export type GroupParametersOptions = "min" | "max" | "minmax";
