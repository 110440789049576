import React from "react";
import styled from "styled-components";

const FormElement = styled.form<StyleProps>`
	width: 100%;
	flex-direction: column;
	gap: ${({ gap }) => gap};
`;

interface StyleProps {
	gap?: string;
}

interface Props extends StyleProps {
	onSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void;
	children?: React.ReactNode;
}

export const Form: React.FC<Props> = ({ gap = "20px", onSubmit, children }) => (
	<FormElement
		gap={gap}
		onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (onSubmit) onSubmit(event);
		}}
	>
		{children}
	</FormElement>
);
