export const enum ToastType {
	ERROR = "ERROR",
	WARNING = "WARNING",
	SUCCESS = "SUCCESS",
	PENDING = "PENDING",
}

export interface Toast {
	id: number | string;
	title: string;
	description?: string;
	type: ToastType;
	autoHide: number;
	hidden: boolean;
}
