import { useEffect, useRef } from "react";
import { Box, Button, NumberInput, Text } from "src/elements";
import { NumberInputElement } from "src/elements/Input/NumberInput";

import { Panel } from "../../Panel/Panel";
import { usePog, useShelfPanel } from "../store/actions";

export const ShelfPanel = () => {
	const { bayNo, shelf, isOpen, close, modified, setModified } = useShelfPanel();
	const { saveShelf, deleteShelf } = usePog();

	const refShelfWidth = useRef<NumberInputElement>(null);
	const refShelfHeight = useRef<NumberInputElement>(null);
	const refShelfDepth = useRef<NumberInputElement>(null);
	const refShelfThickness = useRef<NumberInputElement>(null);
	const refFingerSpace = useRef<NumberInputElement>(null);
	const refSlopeHeight = useRef<NumberInputElement>(null);
	const refNotchNo = useRef<NumberInputElement>(null);
	const refShelfY = useRef<NumberInputElement>(null);

	useEffect(() => {
		refShelfWidth.current?.updateValue(shelf?.shelf_width);
		refShelfHeight.current?.updateValue(shelf?.shelf_height);
		refShelfDepth.current?.updateValue(shelf?.shelf_depth);
		refShelfThickness.current?.updateValue(shelf?.shelf_thickness);
		refFingerSpace.current?.updateValue(shelf?.finger_space);
		refSlopeHeight.current?.updateValue(shelf?.slope_height);
		refNotchNo.current?.updateValue(shelf?.notch_no);
		refShelfY.current?.updateValue(shelf?.shelf_y);
	}, [shelf]);

	return (
		<Panel isOpen={isOpen}>
			<Box flex="1" padding="10px" gap="30px" column>
				<Box column gap="10px">
					<Box justify="between" align="center">
						<Text variant="h4">
							{shelf?.shelf_no ? `Shelf No. ${shelf?.shelf_no}` : "New Shelf"}
						</Text>

						{shelf?.shelf_no ? (
							<Button
								width="35px"
								icon="delete"
								theme="danger"
								padding="0"
								variant="inverted"
								onClick={() => {
									deleteShelf(bayNo, shelf.shelf_no);
									close();
								}}
							/>
						) : null}
					</Box>

					<Box column gap="15px">
						{/* <SelectV2 /> */}

						<NumberInput
							ref={refShelfWidth}
							label="Shelf Width"
							defaultValue={shelf?.shelf_width}
							onChange={value => {
								shelf!.shelf_width = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refShelfHeight}
							label="Shelf Height"
							defaultValue={shelf?.shelf_height}
							onChange={value => {
								shelf!.shelf_height = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refShelfDepth}
							label="Shelf Depth"
							defaultValue={shelf?.shelf_depth}
							onChange={value => {
								shelf!.shelf_depth = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refShelfThickness}
							label="Shelf Thickness"
							defaultValue={shelf?.shelf_thickness}
							onChange={value => {
								shelf!.shelf_thickness = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refFingerSpace}
							label="Finger Space"
							defaultValue={shelf?.finger_space}
							onChange={value => {
								shelf!.finger_space = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refSlopeHeight}
							label="Slope Height"
							defaultValue={shelf?.slope_height}
							onChange={value => {
								shelf!.slope_height = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refNotchNo}
							label="Notch Number"
							defaultValue={shelf?.notch_no}
							onChange={value => {
								shelf!.notch_no = value;
								setModified();
							}}
						/>

						<NumberInput
							ref={refShelfY}
							label="Shelf Y"
							defaultValue={shelf?.shelf_y}
							onChange={value => {
								shelf!.shelf_y = value;
								setModified();
							}}
						/>
					</Box>
				</Box>
			</Box>

			<Box padding="10px" gap="10px">
				<Button
					theme={modified ? "red" : "greenSmoke"}
					variant="inverted"
					width="75px"
					onClick={close}
				>
					{modified ? "Cancel" : "Back"}
				</Button>

				<Button
					width="100%"
					onClick={() => {
						saveShelf(bayNo, shelf!);
						close();
					}}
				>
					Save
				</Button>
			</Box>
		</Panel>
	);
};
