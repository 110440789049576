import React from "react";
import { Text } from "src/elements";
import styled from "styled-components";

const ShelfElement = styled.div<{ width: number; height: number; x: number; y: number }>`
	position: absolute;
	justify-content: center;
	align-items: center;
	width: ${({ width }) => `calc(var(--scale) * ${width}px)`};
	height: ${({ height }) => `calc(var(--scale) * ${height}px)`};
	bottom: ${({ y }) => `calc(var(--scale) * ${y}px)`};
	left: ${({ x }) => `calc(var(--scale) * ${x}px)`};
	box-shadow: inset 0 0 0 1px rgba(0, 100, 100, 0.5);
	z-index: 1;
`;

interface Props {
	sectionNo: number;
	shelfNo: number;
	width: number;
	height: number;
	x: number;
	y: number;
}

export const Shelf: React.FC<Props> = ({ sectionNo, shelfNo, width, height, x, y }) => (
	<ShelfElement className="shelf" width={width} height={height} x={x} y={y}>
		<Text variant="small3" color="green">
			b{sectionNo}_s{shelfNo + 1}
		</Text>
	</ShelfElement>
);
