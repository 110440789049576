import { zIndex } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { atomToasts } from "../store/atoms";
import { Toast } from "./Toast";

const ToastsContainer = styled.div`
	flex-direction: column;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 10px;
	z-index: ${zIndex.TOASTS};
	pointer-events: none;
`;

export const Toasts = () => {
	const toasts = useOmniValue(atomToasts);

	return (
		<ToastsContainer>
			{toasts.map((toast, index) => (
				<Toast
					key={index}
					id={toast.id}
					title={toast.title}
					description={toast.description}
					type={toast.type}
					hidden={toast.hidden}
					autoHide={toast.autoHide}
				/>
			))}
		</ToastsContainer>
	);
};
