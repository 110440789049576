import { useState } from "react";
import { Modal } from "src/components";
import { usePog } from "src/components/Bays/store/actions";
import { baysMudmapAtom } from "src/components/Bays/store/atoms";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { ToastType, useToasts } from "src/components/Toasts";
import { Textarea } from "src/elements";
import {
	baysFilesAtom,
	baysFixturesFileAtom,
	baysObjectivesAtom,
	baysOutputFileAtom,
	baysProductsFileAtom,
	useGenerateColors,
} from "src/pages/Bays/store/atoms";
import { useSetOmniValue } from "src/utils/atoms";

import { useLogs } from "../Logs/store/atoms";

export const ModalOutputEditor = () => {
	const setMasterItems = useSetOmniValue(baysFilesAtom);
	const setFixtureFile = useSetOmniValue(baysFixturesFileAtom);
	const setMasterFile = useSetOmniValue(baysProductsFileAtom);
	const setMudmap = useSetOmniValue(baysMudmapAtom);
	const setOutputFile = useSetOmniValue(baysOutputFileAtom);
	const setObjectives = useSetOmniValue(baysObjectivesAtom);

	// Actions.
	const { setPog } = usePog();
	const { generateColors } = useGenerateColors();
	const { setPlanogramResponse } = useLogs();
	const { closeModal } = useModals();
	const { toast } = useToasts();

	const [output, setOutput] = useState("");

	const saveOutput = () => {
		try {
			const pog = JSON.parse(output);
			if (!pog.planogram.bays) {
				throw "Invalid POG";
			}

			setFixtureFile(null);
			setMasterFile(null);
			setMudmap(null);
			setObjectives(null);
			setMasterItems(null);
			setOutputFile(null);
			setPog(pog);
			setPlanogramResponse(null);
			generateColors({ pog });

			closeModal(ModalType.OUTPUT_EDITOR);
		} catch {
			toast({ title: "Invalid JSON", description: "Failed to import pog", type: ToastType.ERROR });
		}
	};

	return (
		<Modal
			height="100%"
			modal={ModalType.OUTPUT_EDITOR}
			buttons={[{ label: "Save", onClick: saveOutput }]}
		>
			<Textarea height="100%" title="Output" value={output} setValue={setOutput} />
		</Modal>
	);
};
