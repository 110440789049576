import emotionStyled from "@emotion/styled";
import hexToRgba from "hex-to-rgba";
import { getMudmapCdtColorByIndex } from "src/constants/mudmapCdtColors";
import { Text } from "src/elements";
import { baysColorsByAtom } from "src/pages/Bays/store/atoms";
import { MudmapItem as MudmapItemType } from "src/types";
import { Color, tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled, { StyledComponent } from "styled-components";

import { baysColorsAtom } from "../store/atoms";
import { VisualizerColorBy } from "../types";
import { MUDMAP_ITEM } from "../utils/classes";

interface ItemProps {
	background: string;
	flex: number;
}

const MudmapItemElement: StyledComponent<
	"div",
	any,
	ItemProps,
	never
> = styled.div.attrs<ItemProps>(({ background, flex }) => ({
	style: {
		backgroundColor: background,
		flex,
	},
}))`
	position: relative;
	height: 100%;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.07);
	border-radius: 2px;
	user-select: none;
	overflow: hidden;
	pointer-events: all;
	z-index: 1;
`;

const MudmapItemContent = emotionStyled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 5px;
	gap: 3px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	overflow: hidden;

	@container (max-width: 35px) {
		display: none;
	}
`;

interface TriangleProps {
	background: string;
}

const MudmapTriangle: StyledComponent<
	"div",
	any,
	TriangleProps,
	never
> = styled.div.attrs<TriangleProps>(({ background }) => ({
	style: {
		borderLeftColor: background,
		borderBottomColor: background,
	},
}))`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px;
	border-right-color: transparent;
	border-top-color: transparent;
	filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.8));
`;

interface Props {
	variant: string;
	item: MudmapItemType;
	showTooltips: boolean;
	flex: number;
}

export const MudmapItem: React.FC<Props> = ({ item, showTooltips, flex }) => {
	const colors = useOmniValue(baysColorsAtom);
	const colorsBy = useOmniValue(baysColorsByAtom);

	const getColor = (colorsBy: VisualizerColorBy) => {
		if (colors && colorsBy === VisualizerColorBy.SEGMENT) {
			return colors.segment[`${item.segment}`];
		}

		if (colors && colorsBy === VisualizerColorBy.BLOCK) {
			return colors.block[`${item.segment}-${item.block}`];
		}

		return getMudmapCdtColorByIndex(0);
	};

	const color = getColor(
		colorsBy === VisualizerColorBy.SEG_BLOCK ? VisualizerColorBy.BLOCK : colorsBy,
	);

	return (
		<MudmapItemElement
			className={MUDMAP_ITEM}
			flex={flex}
			background={color}
			onMouseEnter={event =>
				showTooltips &&
				tooltip({
					event,
					data: {
						Segment: item.segment,
						Block: item.block,
						Width: item.width,
						Height: item.height,
						Depth: item.depth,
						Orientation: item.orientation,
						Rotated: item.rotated,
						Facings: item.facings,
						Stacking: item.stacking,
						"Deep stacking": item.deep_stacking,
					},
				})
			}
		>
			<MudmapItemContent>
				<Text variant="caption2" color={hexToRgba(Color.textMain, 0.85)} align="center">
					{item.segment}
				</Text>
				<Text variant="caption2" color={hexToRgba(Color.textMain, 0.85)} align="center">
					{item.block}
				</Text>
			</MudmapItemContent>

			{colorsBy === VisualizerColorBy.SEG_BLOCK && (
				<MudmapTriangle background={getColor(VisualizerColorBy.SEGMENT)} />
			)}
		</MudmapItemElement>
	);
};
