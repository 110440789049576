import Lottie from "react-lottie";
import { Mask } from "src/elements";
import { useOmniValue } from "src/utils/atoms";

import animationData from "./data/preloader_2.json";
import { loaderState } from "./store/atoms";

const options = {
	loop: true,
	autoplay: true,
	animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

const Loader = () => {
	const loader = useOmniValue(loaderState);

	if (loader.length === 0) {
		return null;
	}

	return (
		<Mask background="rgba(255,255,255,0.7)">
			<Lottie options={options} height="80px" width="80px" />
		</Mask>
	);
};

export { Loader };
