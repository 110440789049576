import { Box, IconV2, Text } from "src/elements";
import { Color, tooltip } from "src/utils";
import styled from "styled-components";

import { Handle } from "./components/Handle";
import { Track } from "./components/Track";

const RangeElement = styled.div<{ withoutValueLabel?: boolean }>`
	position: relative;
	width: 100%;
	height: ${({ withoutValueLabel }) => !withoutValueLabel && "40px"};
	align-items: flex-end;

	--track-left: ${Color.primary};
	--track-right: ${Color.textDisabled};
	--handle: ${Color.spaceGray};
`;

const Input = styled.input.attrs({ type: "range" })`
	width: 100%;
	appearance: none;
	cursor: grab;

	::-webkit-slider-thumb {
		height: 17px;
		width: 17px;
		background: transparent;
		-webkit-appearance: none;
	}

	::-moz-range-thumb {
		border: none;
		height: 17px;
		width: 17px;
		background: transparent;
	}

	::-webkit-slider-thumb:active {
		cursor: grabbing;
	}

	&:hover ~ * {
		--track-left: #4fa67e;
		--track-right: ${Color.lightGrayHover};
		--handle: #bac9c2;
	}

	&:active ~ * {
		--track-left: ${Color.primaryActive};
		--track-right: ${Color.spaceGray};
		--handle: ${Color.primary};
	}
`;

interface Props {
	value: number;
	setValue: (value: number) => void;
	title?: string;
	description?: string;
	min?: number;
	max?: number;
	step?: number;
	unit?: string;
	theme?: "default" | "round";
	withoutValueLabel?: boolean;
	// Should not be used manually, used internally by datapoints range.
	getValue?: () => string | number;
}

export const RangeV2: React.FC<Props> = ({
	value,
	setValue,
	title,
	description,
	min = 0,
	max = 100,
	step = 1,
	unit,
	theme = "default",
	withoutValueLabel,
	getValue,
}) => {
	const percentage = ((value - min) * 100) / (max - min);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(parseFloat(event.target.value));
	};

	return (
		<Box direction="column">
			<Box justify="between" align="center">
				<Text variant="small1">{title}</Text>

				{description && (
					<Box onMouseEnter={event => tooltip({ event, data: description })}>
						<Box pointerEvents="none">
							<IconV2 name="info" color={Color.textDisabled} size={14} />
						</Box>
					</Box>
				)}
			</Box>

			<RangeElement withoutValueLabel={withoutValueLabel}>
				<Input min={min} max={max} step={step} onChange={onChange} />

				<Box width="100%" height="17px" position="absolute" pointerEvents="none">
					<Track theme={theme} percentage={percentage} />

					<Handle
						percentage={percentage}
						value={getValue ? getValue() : `${value}${unit}`}
						variant={theme}
						withoutValueLabel={withoutValueLabel}
					/>
				</Box>
			</RangeElement>
		</Box>
	);
};
