export const mudmapCdtColors = [
	"#70A0E8",
	"#E58181",
	"#A187EB",
	"#99D150",
	"#FFD76F",
	"#E18ED4",
	"#A2B2C1",
	"#5FC6B5",
	"#F8A528",
	"#6DC1F0",
	"#FF8B8B",
	"#9997EC",
	"#74AA66",
	"#FCEE76",
	"#DDB5D9",
	"#D2D1D1",
	"#40DBBB",
	"#F9A271",
	"#72D5FF",
	"#F8A59A",
	"#BAA0FF",
	"#93DF7F",
	"#F8E328",
	"#EFB2F9",
	"#C5D6CC",
	"#8CCCBD",
	"#E2B759",
	"#95E5F7",
	"#E8A7A7",
	"#C5BAE3",
	"#8FC798",
	"#FCF2BF",
	"#D9A4E1",
	"#E1F0D7",
	"#D0F5EF",
	"#E9CB2F",
	"#98CCE2",
	"#FBBBBB",
	"#D2C2F3",
	"#A9BE99",
	"#E5F96C",
	"#D9B8DE",
	"#DDE3E9",
	"#B3F8EA",
	"#FABA96",
];

let nameOld: string = "";
let index: number = 0;

export const getMudmapCdtColor = (nameNew: string) => {
	if (nameOld === nameNew) {
		return mudmapCdtColors[index];
	} else {
		if (index + 1 > mudmapCdtColors.length - 1) index = 0;
		nameOld = nameNew;
		return mudmapCdtColors[index++];
	}
};

export const getMudmapCdtColorByIndex = (index: number) => {
	return mudmapCdtColors[index % mudmapCdtColors.length];
};
