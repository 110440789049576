import { Box, Text } from "src/elements";
import { Color } from "src/utils";

interface Props {
	placeholder: string;
}

export const NoDataPlaceholder: React.FC<Props> = ({ placeholder }) => (
	<Box width="100%" height="100%" justify="center" align="center" padding="60px">
		<Text variant="h3" color={Color.spaceGrayHover}>
			{placeholder}
		</Text>
	</Box>
);
