import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { logsTabAtom } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import { useApi } from "src/pages/Containers/store/api";
import { containersPlaceContainersResponseAtom } from "src/pages/Containers/store/atoms";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { FilesUploader } from "../components/FilesUploader";

export const PanelPlaceContainers = () => {
	const placeContainersResponse = useOmniValue(containersPlaceContainersResponseAtom);

	const setLogsTab = useSetOmniValue(logsTabAtom);

	const { step1PlaceContainers } = useApi();
	const { openModal } = useModals();

	const isNextStepDisabled = () => !placeContainersResponse;

	const openLogs = () => {
		setLogsTab(LogsTab.CONTAINERS_PLACE_CONTAINERS);
		openModal(ModalType.LOGS);
	};

	return (
		<>
			<FilesUploader />

			<Box gap="10px">
				<Button theme="greenSmoke" variant="inverted" onClick={openLogs}>
					Logs
				</Button>

				<Button
					theme={isNextStepDisabled() ? "primary" : "greenSmoke"}
					onClick={step1PlaceContainers}
				>
					Place Containers
				</Button>
			</Box>
		</>
	);
};
