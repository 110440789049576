import { Facing } from "src/components/Bays/components/Facing";
import { Box, Checkbox, Text } from "src/elements";
import { MasterItem } from "src/store/bays";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { useCoreRange } from "../store/atoms";

const ItemElement = styled.div`
	position: relative;
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
	padding: 10px;
	justify-content: space-between;
	align-items: center;
	transition: background-color ${Transition.fast};
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: ${Color.opacitySky};
		border-color: ${Color.opacitySkyHover};
	}
`;

interface Props {
	item: MasterItem;
}

export const Item: React.FC<Props> = ({ item }) => {
	const { coreRange, addItem, removeItem } = useCoreRange();

	return (
		<ItemElement>
			<Box gap="10px" align="center">
				<Facing item={item} fixedSize />

				<Text variant="small2" color={Color.textSecondary}>
					{item.id}, {item.segment}, {item.block}
				</Text>
			</Box>

			<Checkbox
				useParent
				checked={coreRange.filter(coreRangeItemId => coreRangeItemId === item.id).length > 0}
				setChecked={checked => {
					if (checked) {
						addItem(item.id);
					} else {
						removeItem(item.id);
					}
				}}
			/>
		</ItemElement>
	);
};
