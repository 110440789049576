import React, { useEffect, useRef } from "react";
import { useToasts } from "src/components/Toasts";
import { Box, IconType, IconV2, Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

import { Toast as ToastProps, ToastType } from "../types/types";

const ToastContainer = styled.div`
	align-items: flex-end;
	height: 0;
	transition: height 300ms;

	&[data-hide] {
		height: 0px !important;
	}
`;

const ToastElement = styled.div`
	position: relative;

	flex-direction: column;
	width: 300px;
	background: ${Color.white};
	box-shadow: 0px 10px 25px rgba(22, 22, 22, 0.15);
	border-radius: 4px;
	overflow: hidden;

	&[data-show] {
		pointer-events: all;
		animation: toast-show 0.5s forwards;
	}

	&[data-hide] {
		pointer-events: none;
		animation: toast-hide 0.5s forwards;
	}

	@keyframes toast-show {
		from {
			opacity: 0;
			transform: translateX(-100%);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

	@keyframes toast-hide {
		from {
			transform: translateY(0%);
			opacity: 1;
		}
		to {
			transform: translateY(100%);
			opacity: 0;
		}
	}
`;

const TimerBar = styled.div<{ autoHide: number; color: Color }>`
	height: 4px;
	width: calc(100% + 8px);
	margin-left: -4px;
	background: ${({ color }) => color};
	border-radius: 4px;

	${({ autoHide }) =>
		autoHide > 0 &&
		`&[data-timer] {
			animation: timer ${autoHide}ms 0.5s linear forwards;
		}
	`}

	@keyframes timer {
		to {
			transform: translateX(-100%);
		}
	}
`;

const IconSpinner = styled.div`
	align-self: flex-start;

	&[data-loading] {
		animation: spinner 1s ease-in-out infinite;
	}

	@keyframes spinner {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

const LoadingBar = styled.div`
	width: calc(200%);
	height: 4px;
	background-image: url("loading.png");
	animation: loading 15s infinite linear;

	@keyframes loading {
		from {
			transform: translateX(-50%);
		}
		to {
			transform: translateX(0%);
		}
	}
`;

const ToastClamp = styled.div<{ maxHeight: string; clamp: number }>`
	span {
		display: -webkit-box;
		-webkit-line-clamp: ${({ clamp }) => clamp};
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		color: ${Color.textSecondary};
		max-height: ${({ maxHeight }) => maxHeight};
	}
`;

const getToastStyle: (type: ToastType) => {
	color: Color;
	icon: IconType;
	time: string;
} = (type: ToastType) => {
	const time = new Date().toLocaleTimeString(undefined, {
		hour: "numeric",
		minute: "numeric",
	});

	switch (type) {
		case ToastType.ERROR:
			return { color: Color.red, icon: "toastError", time };
		case ToastType.WARNING:
			return { color: Color.yellow, icon: "toastWarning", time };
		case ToastType.SUCCESS:
			return { color: Color.toastSuccess, icon: "toastSuccess", time };
		case ToastType.PENDING:
			return { color: Color.toastPending, icon: "toastPending", time };
	}
};

export const Toast: React.FC<ToastProps> = ({
	id,
	title,
	description,
	type = ToastType.SUCCESS,
	hidden,
	autoHide,
}) => {
	const { hideToast } = useToasts();

	const refContainer = useRef<HTMLDivElement>(null);
	const refToast = useRef<HTMLDivElement>(null);

	const { color, icon, time } = getToastStyle(type);

	// Effect to dynamically animate toast container height to match toast content.
	useEffect(() => {
		if (!refContainer.current || !refToast.current) return;

		refContainer.current.style.height = `${refToast.current.offsetHeight + 10}px`;
	}, []);

	/**
	 * Toast auto hider.
	 */
	useEffect(() => {
		if (autoHide > 0) {
			setTimeout(() => {
				hideToast(id);
			}, autoHide + 500);
		}
	}, [autoHide]);

	return (
		<ToastContainer {...(hidden && { "data-hide": "" })} ref={refContainer}>
			<ToastElement data-show="" {...(hidden && { "data-hide": "" })} ref={refToast}>
				<Box padding="10px" gap="6px" flex="1" height="calc(100% - 4px)">
					<IconSpinner {...(type === ToastType.PENDING && { "data-loading": "" })}>
						<IconV2 name={icon} size={18} />
					</IconSpinner>

					<Box direction="column" flex="1" gap="10px" width="calc(100% - 40px)">
						<Box direction="column" gap="5px" flex="1">
							<ToastClamp clamp={2} maxHeight="40px">
								<Text variant="body1">{title}</Text>
							</ToastClamp>

							{description && (
								<ToastClamp clamp={4} maxHeight="80px">
									<Text variant="caption1">{description}</Text>
								</ToastClamp>
							)}
						</Box>

						<Text variant="caption3" color={Color.spaceGray}>
							{time}
						</Text>
					</Box>

					<Box onClick={() => hideToast(id)}>
						<IconV2 name="close" size={10} color={Color.spaceGrayHover} />
					</Box>
				</Box>

				{type !== ToastType.PENDING ? (
					<TimerBar autoHide={autoHide} color={color} data-timer="" />
				) : (
					<LoadingBar />
				)}
			</ToastElement>
		</ToastContainer>
	);
};
