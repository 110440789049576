import hexToRgba from "hex-to-rgba";
// @ts-ignore
import { LightenDarkenColor } from "lighten-darken-color";
import { Text } from "src/elements";
import styled from "styled-components";

import { Item as ItemType } from "../../store/types";

const ItemElement = styled.div<{ width: number; height: number; x: number; y: number }>`
	position: absolute;
	width: ${({ width }) => `calc(var(--scale) * ${width}px)`};
	height: ${({ height }) => `calc(var(--scale) * ${height}px)`};
	left: ${({ x }) => `calc(var(--scale) * ${x}px)`};
	bottom: ${({ y }) => `calc(var(--scale) * ${y}px)`};
	background-color: #e2dcf3;
	box-shadow: inset 0 0 0 1px ${hexToRgba(LightenDarkenColor("#e2dcf3", -30), 0.4)};
	border-radius: 2px;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 2;
`;

interface Props {
	item: ItemType;
}

export const Item: React.FC<Props> = ({
	item: { item_id: id, width, height, start_x: x, start_y: y },
}) => (
	<ItemElement className="item" width={width} height={height} x={x} y={y}>
		<Text variant="caption2">{id}</Text>
	</ItemElement>
);
