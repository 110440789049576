import commaNumber from "comma-number";
import React from "react";
import { usePog } from "src/components/Bays/store/actions";
import { Box, Button, Text, textVariants } from "src/elements";
import { Color, Transition, tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import {
	baysFixturesFileAtom,
	baysObjectivesAtom,
	baysPoggerVersionAtom,
	baysProductsFileAtom,
	baysShowObjectivesAtom,
	useCalculateObjectives,
} from "../../store/atoms";

const ObjectivesContainer = styled.div`
	width: 315px;
	min-width: 315px;
	overflow: hidden;
	transition: width ${Transition.hover}, min-width ${Transition.hover};
	border-radius: 4px;

	&:not([data-open]) {
		width: 0;
		min-width: 0;
		pointer-events: none;
	}
`;

const ObjectivesInner = styled.div`
	width: 300px;
	min-width: 300px;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px;
	background: ${Color.opacitySky};
	border: 1px solid ${Color.opacitySkyHover};
	border-radius: 4px;
	margin-left: 15px;
`;

export const Objectives: React.FC = () => {
	const { pog } = usePog();
	const showObjectives = useOmniValue(baysShowObjectivesAtom);
	const poggerVersion = useOmniValue(baysPoggerVersionAtom);
	const objectives = useOmniValue(baysObjectivesAtom);
	const fixtureFile = useOmniValue(baysFixturesFileAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);

	const { calculateObjectives } = useCalculateObjectives();

	const getRelaxationCost = () => [
		{
			label: "Either Or",
			original: pog?.meta.objective.either_or,
			current: objectives?.either_or,
		},
		{
			label: "Both or None",
			original: pog?.meta.objective.both_or_none,
			current: objectives?.both_or_none,
		},
		{
			label: "Prereq",
			original: pog?.meta.objective.prereq,
			current: objectives?.prereq,
		},
		{
			label: "Shelf Item Count",
			original: pog?.meta.objective.shelf_item_count,
			current: objectives?.shelf_item_count,
		},
		{
			label: "Fixed Heights",
			original: pog?.meta.objective.fixed_heights,
			current: objectives?.fixed_heights,
		},
		{
			label: "Finger Space",
			original: pog?.meta.objective.finger_space,
			current: objectives?.finger_space,
		},
		{
			label: "CDT1 Contiguous",
			original: pog?.meta.objective.cdt1_contiguous,
			current: objectives?.cdt1_contiguous,
		},
		{
			label: "CDT2 Contiguous",
			original: pog?.meta.objective.cdt2_contiguous,
			current: objectives?.cdt2_contiguous,
		},
		{
			label: "CDT3 Contiguous",
			original: pog?.meta.objective.cdt3_contiguous,
			current: objectives?.cdt3_contiguous,
		},
		{
			label: "CDT1 Order",
			original: pog?.meta.objective.cdt1_order,
			current: objectives?.cdt1_order,
		},
		{
			label: "CDT2 Order",
			original: pog?.meta.objective.cdt2_order,
			current: objectives?.cdt2_order,
		},
		{
			label: "CDT3 Order",
			original: pog?.meta.objective.cdt3_order,
			current: objectives?.cdt3_order,
		},
		{
			label: "Facings Not Enough",
			original: pog?.meta.objective.facings_not_enough,
			current: objectives?.facings_not_enough,
		},
		{
			label: "Facings Too Many",
			original: pog?.meta.objective.facings_too_many,
			current: objectives?.facings_too_many,
		},
		{
			label: "Min Width",
			original: pog?.meta.objective.min_width,
			current: objectives?.min_width,
		},
		{
			label: "Units Not Enough (MOS)",
			original: pog?.meta.objective.units_not_enough_mos,
			current: objectives?.units_not_enough_dos,
		},
		{
			label: "Units Not Enough (DOS)",
			original: pog?.meta.objective.units_not_enough_dos,
			current: objectives?.units_not_enough_dos,
		},
		{
			label: "Units Too Many",
			original: pog?.meta.objective.units_too_many,
			current: objectives?.units_too_many,
		},
		{
			label: "Min Distribution",
			original: pog?.meta.objective.min_distribution,
			current: objectives?.min_distribution,
		},
	];

	const getSteeringCost = () => [
		{
			label: "Item Span",
			original: pog?.meta.objective.item_span,
			current: objectives?.item_span,
		},
		{
			label: "Width Slack",
			original: pog?.meta.objective.width_slack,
			current: objectives?.width_slack,
		},
	];

	return (
		<ObjectivesContainer {...(showObjectives && { "data-open": "" })}>
			<ObjectivesInner>
				<table style={{ borderSpacing: "0 5px", margin: "-5px 0" }}>
					<thead>
						<tr>
							<td>
								<Box height="100%" align="center">
									<Text variant="body1" color={Color.textMain}>
										Objectives
									</Text>
								</Box>
							</td>

							<td style={{ textAlign: "right", padding: "0 10px" }} width="1">
								<Box height="100%" align="center" justify="end">
									<Text variant="caption3" color={Color.textSecondary}>
										Original
									</Text>
								</Box>
							</td>

							<td style={{ textAlign: "right", padding: "0 10px" }} width="1">
								<Box height="100%" align="center" justify="end">
									<Text
										variant="caption3"
										color={
											objectives && pog && objectives.total > pog?.meta.objective.total
												? Color.primary
												: objectives && pog && objectives?.total < pog?.meta.objective.total
												? Color.redActive
												: Color.textSecondary
										}
									>
										Current
									</Text>
								</Box>
							</td>
						</tr>
					</thead>

					<tbody>
						<ObjectiveBlock
							title="Item Values"
							original={pog?.meta.objective.item_values}
							current={objectives?.item_values}
						></ObjectiveBlock>

						<ObjectiveBlock
							title="Relaxation Cost (TBC)"
							data={getRelaxationCost()}
						></ObjectiveBlock>

						<ObjectiveBlock title="Steering Cost (TBC)" data={getSteeringCost()}></ObjectiveBlock>

						<ObjectiveBlock
							title="Total"
							original={pog?.meta.objective.total}
							current={objectives?.total}
							bold
						></ObjectiveBlock>
					</tbody>
				</table>

				<Button
					width="100%"
					theme="primary"
					variant="inverted"
					watermark={poggerVersion}
					onClick={calculateObjectives}
					onMouseEnter={(event: React.MouseEvent) =>
						(!pog || !fixtureFile || !masterFile) &&
						tooltip({
							event,
							data: !pog
								? "You need to generate planogram before you can calculate objectives"
								: "Visualizer does not support calculating objectives",
						})
					}
					disabled={!pog || !fixtureFile || !masterFile}
				>
					Calculate Objectives
				</Button>
			</ObjectivesInner>
		</ObjectivesContainer>
	);
};

const ObjectiveHeader = styled.tr`
	td {
		padding: 5px 10px;
		background: ${Color.opacitySkyHover};
	}

	td:first-of-type {
		border-radius: 4px 0 0 4px;
	}

	td:last-of-type {
		border-radius: 0 4px 4px 0;
	}
`;

const ObjectiveBlock: React.FC<{
	title: string;
	original?: number;
	current?: number;
	data?: {
		label: string;
		original?: number;
		current?: number;
	}[];
	bold?: boolean;
}> = ({ title, original, current, data = [], bold = false }) => {
	const totalOriginal =
		original || data.reduce((acc, val) => (val.original ? acc + val.original : acc), 0);
	const totalCurrent =
		current ||
		data.reduce((acc, val) => {
			if (val.current) {
				return acc + val.current;
			}
			return acc;
		}, 0);

	return (
		<>
			{totalOriginal || totalCurrent ? (
				<ObjectiveHeader>
					<td>
						<Box width="100%" height="100%" align="center">
							<Text
								variant="caption1"
								color={Color.textMain}
								fontWeight={bold ? 800 : textVariants.caption1.fontWeight}
							>
								{title}:
							</Text>
						</Box>
					</td>

					<td>
						<Box height="100%" align="center" justify="end">
							<Text
								variant="caption1"
								color={Color.textMain}
								fontWeight={bold ? 800 : textVariants.caption1.fontWeight}
							>
								{totalOriginal ? commaNumber(totalOriginal) : "-"}
							</Text>
						</Box>
					</td>

					<td>
						<Box height="100%" align="center" justify="end">
							<Text
								variant="caption1"
								color={
									totalCurrent && totalCurrent > totalOriginal
										? Color.primary
										: totalCurrent && totalCurrent < totalOriginal
										? Color.redActive
										: Color.textMain
								}
								fontWeight={bold ? 800 : textVariants.caption1.fontWeight}
							>
								{totalCurrent ? commaNumber(totalCurrent) : "-"}
							</Text>
						</Box>
					</td>
				</ObjectiveHeader>
			) : null}

			{data.map(({ label, original, current }, index) =>
				original || current ? (
					<tr key={index}>
						<td>
							<Box height="100%" align="center" padding="0 0 0 15px">
								<Text variant="caption1" color={Color.textSecondary}>
									{label}:
								</Text>
							</Box>
						</td>

						<td>
							<Box height="100%" align="center" justify="end" padding="4px 10px">
								<Text variant="caption1" color={Color.textSecondary}>
									{original ? commaNumber(original) : "-"}
								</Text>
							</Box>
						</td>

						<td>
							<Box height="100%" align="center" justify="end" padding="4px 10px">
								<Text
									variant="caption1"
									color={
										current && original && current > original
											? Color.primary
											: current && original && current < original
											? Color.redActive
											: Color.textSecondary
									}
								>
									{current ? commaNumber(current) : "-"}
								</Text>
							</Box>
						</td>
					</tr>
				) : null,
			)}
		</>
	);
};
