import { useEffect, useRef } from "react";
import { ModeLabel, Settings } from "src/components";
import { InputFiles } from "src/components/InputFiles";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { ToastType, useToasts } from "src/components/Toasts";
import { Zoomer } from "src/components/Zoomer/Zoomer";
import { Box, Button, Scroller, StateDropdown, Tabs } from "src/elements";
import { useLogs } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import { verifyFileTypes } from "src/pages/Bays/utils/verifyFileTypes";
import {
	freezersInputFileAtom,
	freezersMudmapAtom,
	freezersOutputFileAtom,
	freezersPlanogramAtom,
	freezersStepAtom,
	freezersVisualizerColorsByAtom,
	freezersZoomAtom,
	useFreezersColors,
	useFreezersGenerateMudmap,
	useFreezersGeneratePlanogram,
} from "src/store/freezers";
import { Color } from "src/utils";
import { useOmniState } from "src/utils/atoms";
import styled from "styled-components";

import { exportFreezers } from "../Freezers/utils/export";
import { Settings as FreezersSettings } from "../Settings/Settings";
import { FreezersTab, FreezersVisualizerColorsBy } from "./types";

const ControlPanelElement = styled.div`
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
`;

const Separator = styled.div`
	width: 1px;
	height: 100%;
	background: ${Color.lightGrayHover};
`;

export const ControlPanel: React.FC = () => {
	const [step, setStep] = useOmniState(freezersStepAtom);
	const [freezersInputFile, setFreezersInputFile] = useOmniState(freezersInputFileAtom);
	const [freezersOutputFile, setFreezersOutputFile] = useOmniState(freezersOutputFileAtom);

	const [freezersMudmap, setFreezersMudmap] = useOmniState(freezersMudmapAtom);
	const [freezersPlanogram, setFreezersPlanogram] = useOmniState(freezersPlanogramAtom);

	const { generateMudmap } = useFreezersGenerateMudmap();
	const { generatePlanogram } = useFreezersGeneratePlanogram();
	const { generateColors } = useFreezersColors();
	const { toast } = useToasts();
	const { openModal } = useModals();
	const {
		setTab: setLogsTab,
		setFreezersMudmapPayload,
		setFreezersMudmapResponse,
		setFreezersPlanogramPayload,
		setFreezersPlanogramResponse,
	} = useLogs();

	const inputRef = useRef(null);

	// Input file uploaded.
	useEffect(() => {
		if (freezersInputFile) setStep(FreezersTab.MUDMAP);
	}, [freezersInputFile]);

	// Output file uploaded.
	useEffect(() => {
		if (freezersOutputFile) setStep(FreezersTab.PLANOGRAM);
	}, [freezersOutputFile]);

	// Mudmap generated.
	useEffect(() => {
		if (freezersMudmap) setStep(FreezersTab.PLANOGRAM);
	}, [freezersMudmap]);

	const onFileUpload = (files: FileList) => {
		// Check that both files use same extensions.
		const verifiedFilesType = verifyFileTypes(files);
		if (!verifiedFilesType) {
			return toast({
				title: "Invalid files",
				description: "All files need to use same format",
				type: ToastType.ERROR,
			});
		}

		if (verifiedFilesType === "json") {
			for (const file of files) {
				// FileReader as any to save fileName to it
				const fileReader = new FileReader();

				fileReader.onload = event => {
					try {
						const json = JSON.parse(event.target!.result!.toString());
						onJsonUpload(file, json);
					} catch (error) {
						toast({ title: "Invalid JSON file", type: ToastType.ERROR });
					}
				};

				fileReader.readAsText(file);
			}
		} else {
			toast({
				title: "Unsupported files",
				description: "Freezers only support JSON files for now",
				type: ToastType.ERROR,
			});
		}
	};

	const onJsonUpload = (file: File, json: any) => {
		// Freezers input file.
		if (json.freezers) {
			setFreezersOutputFile(null);
			setFreezersMudmap(null);
			setFreezersPlanogram(null);
			setFreezersMudmapPayload({});
			setFreezersMudmapResponse({});
			setFreezersPlanogramPayload({});
			setFreezersPlanogramResponse({});
			setFreezersInputFile(file);
			return;
		}

		// Freezers output file with multiple freezers
		if (json.planogram.freezers) {
			setFreezersInputFile(null);
			setFreezersMudmap(null);
			setFreezersMudmapPayload({});
			setFreezersMudmapResponse({});
			setFreezersPlanogramPayload({});
			setFreezersPlanogramResponse(json);
			setFreezersOutputFile(file);
			setFreezersPlanogram(json);
			generateColors("planogram", json);
			return;
		}

		// New freezer output file with 1 freezer
		if (json.planogram.freezer) {
			setFreezersInputFile(null);
			setFreezersMudmap(null);
			setFreezersMudmapPayload({});
			setFreezersMudmapResponse({});
			setFreezersPlanogramPayload({});
			setFreezersPlanogramResponse({ planogram: { freezers: [json.planogram.freezer] } });
			setFreezersOutputFile(file);
			setFreezersPlanogram({ planogram: { freezers: [json.planogram.freezer] } });
			generateColors("planogram", { planogram: { freezers: [json.planogram.freezer] } });
			return;
		}

		toast({
			title: "Invalid freezers file",
			type: ToastType.ERROR,
		});
	};

	return (
		<ControlPanelElement>
			{/* Top row */}
			<Box padding="10px" justify="between">
				{/* Left side */}
				<Box align="center" gap="10px">
					<Tabs
						tabs={[
							{ tab: FreezersTab.FILES },
							{ tab: FreezersTab.MUDMAP, disabled: !freezersInputFile },
							{ tab: FreezersTab.PLANOGRAM, disabled: !freezersMudmap && !freezersPlanogram },
						]}
						tab={step}
						setTab={setStep}
						tabWidth="110px"
						theme="steps"
					/>

					{freezersOutputFile && (
						<ModeLabel
							label="Visualizer Mode"
							tooltip="In visualizer mode you can't generate new mudmaps or planograms"
						/>
					)}
				</Box>

				{/* Right side */}

				<Box gap="20px" align="center" flex="1" justify="right">
					<Zoomer name="freezers" state={freezersZoomAtom} />

					<StateDropdown
						label="Colors by"
						width="110px"
						data={[
							{
								title: "Variant",
								value: FreezersVisualizerColorsBy.VARIANT,
							},
							{
								title: "Segment",
								value: FreezersVisualizerColorsBy.SEGMENT,
							},
							{
								title: "Block",
								value: FreezersVisualizerColorsBy.BLOCK,
							},
						]}
						state={freezersVisualizerColorsByAtom}
					/>

					<Separator />

					<Settings>
						<FreezersSettings />
					</Settings>
				</Box>
			</Box>

			<Box height="1px" background={Color.lightGrayHover} />

			<Scroller>
				<Box justify="between" margin="10px" align="center" gap="20px">
					{step === FreezersTab.FILES && (
						<>
							<Box gap="20px" align="center">
								<InputFiles
									data={
										freezersOutputFile
											? { "Output file": freezersOutputFile.name }
											: { "Input file": freezersInputFile?.name || null }
									}
									required
								/>
							</Box>

							<Button theme="primary" onClick={() => (inputRef.current as any)?.click()}>
								Import Files
							</Button>

							<input
								type="file"
								ref={inputRef}
								style={{ display: "none" }}
								onChange={event => {
									if (event.target.files) {
										onFileUpload(event.target.files);
									}
								}}
								accept=".csv,.json"
								multiple
							/>
						</>
					)}

					{step === FreezersTab.MUDMAP && (
						<>
							<Box>
								<InputFiles
									data={
										freezersOutputFile
											? { "Output file": freezersOutputFile.name }
											: { "Input file": freezersInputFile?.name || null }
									}
									required
								/>
							</Box>

							<Box gap="10px">
								<Button
									theme="greenSmoke"
									variant="inverted"
									onClick={() => {
										setLogsTab(LogsTab.FREEZERS_MUDMAP);
										openModal(ModalType.LOGS);
									}}
								>
									Logs
								</Button>

								<Button onClick={generateMudmap}>Generate Mudmap</Button>
							</Box>
						</>
					)}

					{step === FreezersTab.PLANOGRAM && (
						<>
							<Box>
								<InputFiles
									data={
										freezersOutputFile
											? { "Output file": freezersOutputFile.name }
											: { "Input file": freezersInputFile?.name || null }
									}
									required
								/>
							</Box>

							<Box gap="10px">
								<Button
									theme="greenSmoke"
									variant="inverted"
									onClick={() => {
										setLogsTab(LogsTab.FREEZERS_PLANOGRAM);
										openModal(ModalType.LOGS);
									}}
								>
									Logs
								</Button>

								<Button theme="greenSmoke" onClick={() => exportFreezers(freezersPlanogram!)}>
									Export
								</Button>

								<Button onClick={generatePlanogram} disabled={freezersOutputFile ? true : false}>
									Generate Planogram
								</Button>
							</Box>
						</>
					)}
				</Box>
			</Scroller>
		</ControlPanelElement>
	);
};
