export const enum ModalType {
	MINOR_REVIEW = "Minor Review",
	RELAXATIONS = "Relaxations",
	CONDITIONAL_ITEMS = "Conditional Items",
	CORE_RANGE = "Core Range",
	LOGS = "Logs",
	OUTPUT_EDITOR = "Output Editor",

	MERGE_RULES = "Merge Rules",
	MERGE_RULE = "New Merge Rule",
	GROUPS_PARAMETERS = "Groups Parameters",
}

export type ModalVariant = "modal" | "popup";
