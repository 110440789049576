// @ts-nocheck
import React from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { Box, Text } from "src/elements";

import { DragButton } from "./components/DragButton";

const reorderData = (list: any, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

interface Props {
	data: { label: string; value: any }[];
	setData: (priorities: { label: string; value: any }[]) => void;
	title: string;
}

export const DragButtons: React.FC<Props> = ({ data, setData, title }) => {
	const onDragEnd = (result: DropResult) => {
		if (!result.destination || result.destination.index === result.source.index) return;

		const prioritiesNew: any = reorderData(data, result.source.index, result.destination.index);
		setData(prioritiesNew);
	};

	return (
		<Box gap="15px" align="center">
			<Text variant="body1" whiteSpace="nowrap">
				{title}:
			</Text>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="list" direction="horizontal">
					{provided => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							style={{
								display: "flex",
								flexDirection: "row",
								margin: "0 -5px",
							}}
						>
							{data &&
								data.map((item, index) => (
									<Draggable draggableId={item.value} key={item.value} index={index}>
										{provided => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<DragButton>{item.label}</DragButton>
											</div>
										)}
									</Draggable>
								))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Box>
	);
};
