import { InputFilesV2 } from "src/components";
import {
	containersFilesCandidatesAtom,
	containersFilesFixturesAtom,
	containersFilesResponseAtom,
	containersGroupsResponseAtom,
	containersPlaceContainersResponseAtom,
	containersPlaceVariantsResponseAtom,
} from "src/pages/Containers/store/atoms";
import { useOmniState, useSetOmniValue } from "src/utils/atoms";

export const FilesUploader = () => {
	const [fileFixtures, setFileFixtures] = useOmniState(containersFilesFixturesAtom);
	const [fileCandidates, setFileCandidates] = useOmniState(containersFilesCandidatesAtom);
	const setFilesResponse = useSetOmniValue(containersFilesResponseAtom);
	const setGroupsResponse = useSetOmniValue(containersGroupsResponseAtom);
	const setPlaceContainersResponse = useSetOmniValue(containersPlaceContainersResponseAtom);
	const setPlaceVariantsResponse = useSetOmniValue(containersPlaceVariantsResponseAtom);

	const setFixtureFile = (file: File | null) => {
		setFileFixtures(file);
		setFilesResponse(null);
		setGroupsResponse(null);
		setPlaceContainersResponse(null);
		setPlaceVariantsResponse(null);
	};

	const setCandidatesFile = (file: File | null) => {
		setFileCandidates(file);
		setFilesResponse(null);
		setGroupsResponse(null);
		setPlaceContainersResponse(null);
		setPlaceVariantsResponse(null);
	};

	return (
		<InputFilesV2
			files={[
				{ title: "Fixtures", file: fileFixtures, setFile: setFixtureFile },
				{ title: "Candidates", file: fileCandidates, setFile: setCandidatesFile },
			]}
		/>
	);
};
