import { usePog } from "src/components/Bays/store/actions";
import { baysEditModeAtom, baysMudmapAtom } from "src/components/Bays/store/atoms";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { StateToggle } from "src/elements";
import { ButtonDropdown } from "src/elements/Button";
import { useLogs } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import {
	baysObjectivesAtom,
	baysShowObjectivesAtom,
	baysShowUnrangedItemsAtom,
} from "src/pages/Bays/store/atoms";
import { exportPlanogram } from "src/pages/Bays/utils/exportPlanogram";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { ButtonGeneratePlanogram } from "./ButtonGeneratePlanogram";

const VerticalDivider = styled.div`
	width: 1px;
	height: 100%;
	background: ${Color.lightGrayHover};
`;

export const PanelPlanogram = () => {
	const mudmap = useOmniValue(baysMudmapAtom);
	const objectives = useOmniValue(baysObjectivesAtom);

	// Actions.
	const { pog } = usePog();
	const { openModal } = useModals();
	const { setTab: setLogsTab } = useLogs();

	const editDropdown: ButtonDropdown[] = [
		{ title: ModalType.RELAXATIONS, onClick: () => openModal(ModalType.RELAXATIONS) },
		{ title: ModalType.CONDITIONAL_ITEMS, onClick: () => openModal(ModalType.CONDITIONAL_ITEMS) },
		{ title: ModalType.CORE_RANGE, onClick: () => openModal(ModalType.CORE_RANGE) },
		{ title: ModalType.MINOR_REVIEW, onClick: () => openModal(ModalType.MINOR_REVIEW) },
	];

	const openLogs = () => {
		setLogsTab(LogsTab.PLANOGRAM);
		openModal(ModalType.LOGS);
	};

	const exportPog = () => {
		if (pog) {
			exportPlanogram(pog, objectives || pog.meta.objective);
		}
	};

	return (
		<>
			<Box height="100%" gap="20px">
				<StateToggle label="Unranged Items" state={baysShowUnrangedItemsAtom} disabled={!pog} />

				<VerticalDivider />

				<StateToggle label="Objectives" state={baysShowObjectivesAtom} disabled={!pog} />

				<VerticalDivider />

				<StateToggle label="Edit Mode" state={baysEditModeAtom} disabled={!pog} />
			</Box>

			<Box gap="10px">
				<Button theme="greenSmoke" onClick={openLogs} variant="inverted">
					Logs
				</Button>

				{pog && (
					<Button theme="greenSmoke" onClick={exportPog}>
						Export
					</Button>
				)}

				<Button theme="yellow" dropdown={editDropdown} disabled={!mudmap}>
					Edit
				</Button>

				<ButtonGeneratePlanogram />
			</Box>
		</>
	);
};
