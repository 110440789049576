// @ts-ignore
import { LightenDarkenColor } from "lighten-darken-color";
import React from "react";
import styled from "styled-components";

const HANGCELL_HEIGHT = 10;

const HangcellElement = styled.div<{ color: string }>`
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	height: ${HANGCELL_HEIGHT}px;

	::before,
	::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 10px;
		height: 3px;
		border-color: ${({ color }) => LightenDarkenColor(color, -30)};
		border-style: solid;
		border-width: 1px;
	}

	::before {
		top: -3px;
		border-top: 0;
		border-bottom: 0;
	}

	::after {
		border-top: 0;
		top: 4px;
		border-radius: 0 0 3px 3px;
	}
`;

interface Props {
	color: string;
}

export const Hangcell: React.FC<Props> = ({ color }) => (
	<HangcellElement color={color}></HangcellElement>
);
