import { useEffect } from "react";
import { HorizontalDivider, Modal } from "src/components";
import { ModalType } from "src/components/Modals";
import { Box, NumberInputV2State, StateDropdown, Text } from "src/elements";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import {
	containerHeightLimitMaxAtom,
	containerHeightLimitMinAtom,
	containerHeightLimitOptionAtom,
	containerWidthLimitMaxAtom,
	containerWidthLimitMinAtom,
	containerWidthLimitOptionAtom,
	containersGroupsParametersAtom,
	extendOptionAtom,
} from "./store/atoms";

const Saver = () => {
	const setGroupsParameters = useSetOmniValue(containersGroupsParametersAtom);

	const containerWidthLimitMin = useOmniValue(containerWidthLimitMinAtom);
	const containerWidthLimitMax = useOmniValue(containerWidthLimitMaxAtom);
	const containerWidthLimitOption = useOmniValue(containerWidthLimitOptionAtom);

	const containerHeightLimitMin = useOmniValue(containerHeightLimitMinAtom);
	const containerHeightLimitMax = useOmniValue(containerHeightLimitMaxAtom);
	const containerHeightLimitOption = useOmniValue(containerHeightLimitOptionAtom);

	const extendOption = useOmniValue(extendOptionAtom);

	useEffect(() => {
		setGroupsParameters({
			container_width_limit: {
				min: containerWidthLimitMin,
				max: containerWidthLimitMax,
				option: containerWidthLimitOption,
			},
			container_height_limit: {
				min: containerHeightLimitMin,
				max: containerHeightLimitMax,
				option: containerHeightLimitOption,
			},
			extend_option: extendOption,
		});
	}, [
		containerWidthLimitMin,
		containerWidthLimitMax,
		containerWidthLimitOption,
		containerHeightLimitMin,
		containerHeightLimitMax,
		containerHeightLimitOption,
		extendOption,
	]);

	return null;
};

export const ModalGroupsParameters = () => (
	<Modal modal={ModalType.GROUPS_PARAMETERS} width="300px">
		<Box direction="column" padding="0 20px" gap="20px">
			<Text variant="body2">Container Width Limit</Text>
			<Box gap="10px">
				<NumberInputV2State width="70px" label="Min" state={containerWidthLimitMinAtom} />

				<NumberInputV2State width="70px" label="Max" state={containerWidthLimitMaxAtom} />

				<StateDropdown
					width="100px"
					label="Option"
					data={[{ value: "min" }, { value: "max" }, { value: "minmax" }]}
					state={containerWidthLimitOptionAtom}
				/>
			</Box>
			<HorizontalDivider />

			<Text variant="body2">Container Height Limit</Text>
			<Box gap="10px">
				<NumberInputV2State width="70px" label="Min" state={containerHeightLimitMinAtom} />

				<NumberInputV2State width="70px" label="Max" state={containerHeightLimitMaxAtom} />

				<StateDropdown
					width="100px"
					label="Option"
					data={[{ value: "min" }, { value: "max" }, { value: "minmax" }]}
					state={containerHeightLimitOptionAtom}
				/>
			</Box>
			<HorizontalDivider />
			<NumberInputV2State label="Extend option" state={extendOptionAtom} />
		</Box>

		<Saver />
	</Modal>
);
