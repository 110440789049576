import { useEffect, useRef } from "react";
import { Box, Button, Text } from "src/elements";
import { baysShowTooltipsAtom } from "src/pages/Bays/store/atoms";
import {
	FreezerItem as FreezerItemType,
	freezersOverlapsAtom,
	useFreezers,
	useFreezersColors,
} from "src/store/freezers";
import { Transition, tooltip } from "src/utils";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";
import styled, { StyledComponent } from "styled-components";

import { getOverlaps, onFreezerItemMouseDown, rotateFreezerItem } from "../utils";
import { FREEZERS, FREEZER_ITEM } from "../utils/classes";

interface FreezerItemProps {
	type: "mudmap" | "planogram";
	top: number;
	left: number;
	width: number;
	height: number;
	color?: string | null;
}

const FreezerItemElement: StyledComponent<
	"div",
	any,
	FreezerItemProps,
	never
> = styled.div.attrs<FreezerItemProps>(({ top, left, width, height, color }) => ({
	style: {
		bottom: `calc(var(--scale) * ${top}px)`,
		left: `calc(var(--scale) * ${left}px)`,
		width: `calc(var(--scale) * ${width}px)`,
		height: `calc(var(--scale) * ${height}px)`,
		backgroundColor: color || "#f5efd2",
	},
}))`
	position: absolute;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	user-select: none;
	will-change: transform;
	cursor: grab;

	* {
		pointer-events: none;
	}

	&[data-draggable] {
		z-index: 1;
	}

	&:not(:hover) {
		.rotate {
			transform: scale(0);
			opacity: 0;
		}
	}

	&[data-rotated] .freezer-item-text {
		transform: rotate(90deg);
	}

	${({ type }: FreezerItemProps) => type === "mudmap" && "resize: both; overflow: hidden;"}
`;

const Rotate = styled.div`
	position: absolute;
	right: 3px;
	bottom: 3px;
	width: 14px;
	height: 14px;
	will-change: transform;
	transform-origin: center;
	transition: transform ${Transition.fast}, opacity ${Transition.fast};
	z-index: 1;

	button {
		pointer-events: all;
	}
`;

interface Props {
	freezerId: string;
	sectionId: string;
	item: FreezerItemType;
	type: "mudmap" | "planogram";
}
const config = { attributes: true };

let timer: any = null;

export const FreezerItem: React.FC<Props> = ({ freezerId, sectionId, item, type }) => {
	const { getColor } = useFreezersColors();
	const showTooltips = useOmniValue(baysShowTooltipsAtom);
	const setFreezersOverlaps = useSetOmniValue(freezersOverlapsAtom);
	const { rotateFreezerItem: rotateFreezerItemData, updateFreezerItemSize } = useFreezers(type);

	const refItem = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!refItem.current) return;
		const observer = new MutationObserver(observerOverlaps);
		const resizeObserver = new ResizeObserver(observerResize);

		// Start observing the target node for configured mutations
		observer.observe(refItem.current, config);
		resizeObserver.observe(refItem.current);
	}, [refItem.current]);

	const observerOverlaps = (mutationList: MutationRecord[]) => {
		for (const mutation of mutationList) {
			const overlaps = getOverlaps(mutation.target as HTMLElement);
			setFreezersOverlaps(overlaps);
			return;
		}
	};

	const observerResize = (entries: ResizeObserverEntry[]) => {
		for (const entry of entries) {
			const itemB = entry.target as HTMLDivElement;
			const scale = parseFloat(itemB.closest(`.${FREEZERS}`)!.getAttribute("data-scale")!);
			const { width, height } = itemB.getBoundingClientRect();

			const a = Math.round(width / scale);
			const b = Math.round(height / scale);

			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(() => {
				itemB.style.width = `calc(${a}px * var(--scale))`;
				itemB.style.height = `calc(${b}px * var(--scale))`;

				updateFreezerItemSize({
					freezerId,
					sectionId,
					itemId: item.item_id,
					facingId: item.facing_id,
					width: a,
					height: b,
				});
			}, 500);
		}
	};

	return (
		<FreezerItemElement
			ref={refItem}
			className={FREEZER_ITEM}
			type={type}
			data-freezer={freezerId}
			data-section={sectionId}
			data-id={item.item_id}
			data-facing={item.facing_id}
			{...(type === "planogram" && item.width < item.height && { "data-rotated": "" })}
			top={item.start_y}
			left={item.start_x}
			width={item.width}
			height={item.height}
			color={getColor(type, item)}
			onMouseEnter={event =>
				showTooltips &&
				tooltip({
					event,
					data: {
						Id: item.item_id,
						Facing: item.facing_id,
						CDT0: item.cdt0,
						CDT1: item.cdt1,
						CDT2: item.cdt2,
						"Start X": item.start_x,
						"Start Y": item.start_y,
						Width: item.width,
						Height: item.height,
						Rotated: item.rotated ? true : false,
					},
				})
			}
			onMouseDown={onFreezerItemMouseDown}
		>
			<Box className="freezer-item-text">
				{type === "planogram" && <Text variant="caption2">{item.item_id}</Text>}

				{type === "mudmap" && (
					<Box direction="column" gap="3px" align="center" justify="center">
						<Text variant="caption2">{item.cdt0}</Text>
						<Text variant="caption2">{item.cdt1}</Text>
					</Box>
				)}
			</Box>

			{type === "planogram" && (
				<Rotate
					className="rotate"
					onClick={event => {
						rotateFreezerItem(event);
						rotateFreezerItemData({
							freezerId,
							sectionId,
							itemId: item.item_id,
							facingId: item.facing_id,
						});
					}}
				>
					<Button width="16px" height="16px" padding="0" icon="rotate" round />
				</Rotate>
			)}
		</FreezerItemElement>
	);
};
