import { useEffect, useState } from "react";

import { RangeV2 } from "./Range";

interface Props {
	value: string | number;
	setValue: (value: string | number) => void;
	title?: string;
	description?: string;
	unit?: string;
	datapoints: (string | number)[];
	theme?: "default" | "round";
	withoutValueLabel?: boolean;
	customFormatter?: (value: string | number) => string | number;
}

export const DatapointsRange: React.FC<Props> = ({
	value,
	setValue,
	title,
	description,
	unit = "",
	datapoints,
	theme = "default",
	withoutValueLabel,
	customFormatter = value => value,
}) => {
	const [innerValue, setInnerValue] = useState(
		Math.max(
			datapoints.findIndex(v => v === value),
			0,
		),
	);

	useEffect(() => {
		setValue(datapoints[innerValue]);
	}, [innerValue]);

	const getValue = () => customFormatter(datapoints[innerValue]);

	return (
		<RangeV2
			value={innerValue}
			setValue={setInnerValue}
			title={title}
			description={description}
			min={0}
			max={datapoints.length - 1}
			step={1}
			unit={unit}
			theme={theme}
			withoutValueLabel={withoutValueLabel}
			getValue={getValue}
		/>
	);
};
