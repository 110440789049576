import { Box, Button, Scroller, Text } from "src/elements";
import { useOmniState, useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import {
	containersFilesResponseAtom,
	containersPlaceContainersPlanogramNoAtom,
	containersPlaceContainersResponseAtom,
} from "../../store/atoms";
import { Container } from "../Container/Container";

// @ts-ignore
const PlanogramNavigator = styled(Button)``;

const PlanogramsElement = styled.div`
	min-height: 0;
	height: 100%;
	gap: 20px;
	flex-direction: column;
`;

export const Planograms = () => {
	const filesResponse = useOmniValue(containersFilesResponseAtom);
	const placeContainersResponse = useOmniValue(containersPlaceContainersResponseAtom);
	const [planogramNo, setPlanogramNo] = useOmniState(containersPlaceContainersPlanogramNoAtom);

	if (filesResponse === null || placeContainersResponse === null) {
		return null;
	}

	const planograms = placeContainersResponse.planogram_responses;

	const isPreviousDisabled = () => planogramNo === 1;

	const previousPlanogram = () => {
		if (planogramNo > 1) {
			setPlanogramNo(planogramNo - 1);
		}
	};

	const isNextDisabled = () => planogramNo === planograms.length;

	const nextPlanogram = () => {
		if (planogramNo < planograms.length) {
			setPlanogramNo(planogramNo + 1);
		}
	};

	return (
		<PlanogramsElement>
			{planograms.length > 1 && (
				<Text>
					Container {planogramNo}/{planograms.length}
				</Text>
			)}

			<Box gap="20px" flex="1">
				{planograms.length > 1 && (
					<PlanogramNavigator
						disabled={isPreviousDisabled()}
						theme="greenSmoke"
						onClick={previousPlanogram}
					>
						Previous
					</PlanogramNavigator>
				)}

				<Scroller padding="0" margin={0} gutter="auto">
					{planograms.length > 0 && (
						<Container
							freezer={planograms[planogramNo - 1].planogram.freezer}
							cumulativeShelfHeights={filesResponse.cumulative_shelf_heights}
						/>
					)}
				</Scroller>

				{planograms.length > 1 && (
					<PlanogramNavigator
						disabled={isNextDisabled()}
						theme="greenSmoke"
						onClick={nextPlanogram}
					>
						Next
					</PlanogramNavigator>
				)}
			</Box>
		</PlanogramsElement>
	);
};
