import { ToastType, useToasts } from "src/components/Toasts";
import { useLogs } from "src/modals/Logs/store/atoms";
import { saveErrorLog, saveResponseLog } from "src/modals/Logs/utils";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { useFreezersColors } from "./actions";
import { freezersInputFileAtom, freezersMudmapAtom, freezersPlanogramAtom } from "./atoms";
import { postFreezersGenerateMudmapReq, postFreezersGeneratePlanogramReq } from "./requests";

export const useFreezersGenerateMudmap = () => {
	const setMudmap = useSetOmniValue(freezersMudmapAtom);
	const inputFile = useOmniValue(freezersInputFileAtom);

	const { generateColors } = useFreezersColors();
	const { toast, hideToast } = useToasts();
	const { setFreezersMudmapPayload, setFreezersMudmapResponse } = useLogs();

	const generateMudmap = async () => {
		toast({
			id: "freezers/mudmap",
			title: "Generating freezers mudmap...",
			type: ToastType.PENDING,
		});

		try {
			if (!inputFile) throw "Input file missing";

			const payload = new FormData();
			payload.append("froggerVersion", "dev");
			payload.append("input", inputFile!, inputFile!.name);

			const { data } = await postFreezersGenerateMudmapReq(payload);
			saveResponseLog(data, setFreezersMudmapPayload, setFreezersMudmapResponse);
			setMudmap(data);
			generateColors("mudmap", data);

			toast({ title: "Freezers mudmap generated" });
		} catch (error) {
			saveErrorLog(error, setFreezersMudmapPayload, setFreezersMudmapResponse);
			toast({
				title: "Failed to generate freezers mudmap",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("freezers/mudmap");
	};

	return { generateMudmap };
};

export const useFreezersGeneratePlanogram = () => {
	const mudmap = useOmniValue(freezersMudmapAtom);
	const setPlanogram = useSetOmniValue(freezersPlanogramAtom);
	const inputFile = useOmniValue(freezersInputFileAtom);

	const { generateColors } = useFreezersColors();
	const { toast, hideToast } = useToasts();
	const { setFreezersPlanogramPayload, setFreezersPlanogramResponse } = useLogs();

	const generatePlanogram = async () => {
		toast({
			id: "freezers/planogram",
			title: "Generating freezers planogram...",
			type: ToastType.PENDING,
		});

		try {
			if (!inputFile) throw "Input file missing";

			const payload = new FormData();
			payload.append("froggerVersion", "dev");
			payload.append("input", inputFile, inputFile.name);
			payload.append("mudmap", JSON.stringify(mudmap!.planogram));

			const { data } = await postFreezersGeneratePlanogramReq(payload);
			saveResponseLog(data, setFreezersPlanogramPayload, setFreezersPlanogramResponse);
			setPlanogram(data);
			generateColors("planogram", data);

			toast({ title: "Freezers planogram generated" });
		} catch (error) {
			saveErrorLog(error, setFreezersPlanogramPayload, setFreezersPlanogramResponse);
			toast({
				title: "Failed to generate freezers planogram",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("freezers/planogram");
	};

	return { generatePlanogram };
};
