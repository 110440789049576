import { Box, Checkbox, IconV2, Text } from "src/elements";
import { inputHeight } from "src/elements/Input";
import { Color } from "src/utils";
import styled from "styled-components";

const Container = styled.div`
	border: 1px solid ${Color.opacitySkyHover};
	height: ${inputHeight};
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	gap: 10px;
`;

interface Props {
	variant: string;
	checked: boolean;
	toggleChecked: (variant: string) => void;
}

export const MergeRuleVariants: React.FC<Props> = ({ variant, checked, toggleChecked }) => (
	<Container>
		<Box gap="10px" padding="10px">
			<Text variant="small2">{variant}</Text>
		</Box>

		<Box position="relative" padding="0 10px" height="100%">
			<Checkbox
				height="100%"
				useParent
				checked={checked}
				setChecked={() => toggleChecked(variant)}
			/>
		</Box>
	</Container>
);
