import { useMemo, useState } from "react";
import { Modal } from "src/components";
import { Facing } from "src/components/Bays/components/Facing";
import { usePog } from "src/components/Bays/store/actions";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button, Dropdown, IconV2, Table, Tabs, Text } from "src/elements";
import { baysFilesAtom, baysPoggerVersionAtom } from "src/pages/Bays/store/atoms";
import { useGeneratePlanogram, useRebuildPlanogram } from "src/store/bays";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";

import {
	changeRelaxationsConditionalCoreTab,
	relaxationsConditionalCoreTabs,
} from "../data/relaxationsConditonalCore";
import { conditionalItemsAtom, useConditionalItems } from "./store/atoms";
import { ItemConstraintCondition } from "./store/types";

const conditions = [
	{
		value: ItemConstraintCondition.BOTH_OR_NONE,
	},
	{
		value: ItemConstraintCondition.EITHER_OR,
	},
	{
		value: ItemConstraintCondition.PREREQUISITE,
	},
];

export const ModalConditionalItems = () => {
	const { pog } = usePog();
	const { generatePlanogram } = useGeneratePlanogram();
	const { rebuildPlanogram } = useRebuildPlanogram();
	const baysFiles = useOmniValue(baysFilesAtom)!;
	const { addConditionalItem, removeConditionalItem } = useConditionalItems();
	const conditionalItems = useOmniValue(conditionalItemsAtom);
	const { openModal, closeModal } = useModals();

	const masterItemsForSelect = useMemo(() => {
		return baysFiles.master_list.map(masterItem => ({
			...masterItem,
			value: masterItem.id,
			title: `${masterItem.id}, ${masterItem.segment}, ${masterItem.block}`,
		}));
	}, [baysFiles]);

	const [firstItem, setFirstItem] = useState<string | null>(baysFiles.master_list[0].id);
	const [secondItem, setSecondItem] = useState<string | null>(baysFiles.master_list[0].id);
	const [constraint, setConstraint] = useState(ItemConstraintCondition.BOTH_OR_NONE);
	const poggerVersion = useOmniValue(baysPoggerVersionAtom);

	return (
		<Modal
			modal={ModalType.CONDITIONAL_ITEMS}
			height="100%"
			width="800px"
			buttons={
				pog
					? [
							{
								label: "Rebuild Planogram",
								watermark: poggerVersion,
								onClick: rebuildPlanogram,
							},
					  ]
					: [
							{
								label: "Generate Planogram",
								watermark: poggerVersion,
								onClick: generatePlanogram,
							},
					  ]
			}
		>
			<Box padding="0 20px">
				<Tabs
					width="100%"
					tabs={relaxationsConditionalCoreTabs}
					tab={ModalType.CONDITIONAL_ITEMS}
					setTab={tab => changeRelaxationsConditionalCoreTab(tab, openModal, closeModal)}
				/>
			</Box>

			<Box padding="0 20px">
				<Text variant="body3" color={Color.textMain}>
					Select special constraints to items for next planogram generation:
					<br />
					* Both or None - Include both items or neither.
					<br />
					* Either One - Only include one of the items.
					<br />* Prerequisite - Only include second Item if first item is included.
				</Text>
			</Box>

			<Box padding="0 20px" gap="10px">
				<Dropdown
					label="First Item"
					data={masterItemsForSelect}
					value={firstItem}
					setValue={setFirstItem}
					useSearch
					width="260px"
				/>

				<Dropdown
					label="Second Item"
					data={masterItemsForSelect}
					value={secondItem}
					setValue={setSecondItem}
					useSearch
					width="260px"
				/>

				<Dropdown
					label="Constraint"
					data={conditions}
					value={constraint}
					setValue={setConstraint}
					width="145px"
				/>

				<Button
					width="75px"
					onClick={() => {
						if (firstItem && secondItem) {
							addConditionalItem({ firstItem, secondItem, constraint });
						}
					}}
				>
					Add
				</Button>
			</Box>

			<Box padding="0 20px" flex="1" minHeight="0">
				<Table
					columns={[
						{
							id: "firstItem",
							title: "",
							justify: "center",
							body: ({ value }) => (
								<Facing item={masterItemsForSelect.find(item => item.value === value)!} fixedSize />
							),
							width: "40px",
						},
						{
							id: "firstItem",
							title: "First Item",
							body: ({ value }) => masterItemsForSelect.find(item => item.value === value)?.title,
							align: "center",
							width: "200px",
						},
						{
							id: "secondItem",
							title: "",
							justify: "center",
							body: ({ value }) => (
								<Facing item={masterItemsForSelect.find(item => item.value === value)!} fixedSize />
							),
							width: "40px",
						},
						{
							id: "secondItem",
							title: "Second Item",
							body: ({ value }) => masterItemsForSelect.find(item => item.value === value)?.title,
							align: "center",
							width: "200px",
						},
						{
							id: "constraint",
							title: "Constraint",
							align: "center",
							width: "90px",
						},
						{
							id: "delete",
							title: "",
							body: ({ index }) => (
								<Box
									align="center"
									gap="5px"
									hoverColor={Color.redHover}
									activeColor={Color.redActive}
									onClick={() => removeConditionalItem(index)}
								>
									<IconV2 name="delete" color={Color.red} />
									<Text variant="caption3" color={Color.red}>
										Delete
									</Text>
								</Box>
							),
							justify: "center",
							width: "70px",
						},
					]}
					data={conditionalItems}
					placeholder="No Conditional Items"
				/>
			</Box>
		</Modal>
	);
};
