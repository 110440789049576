import { PLANOGRAM_ITEM, PLANOGRAM_SHELF_THICKNESS } from "src/components/Bays/utils/classes";
import { FREEZER_ITEM } from "src/pages/Freezers/components/Freezers/utils/classes";
import { createGlobalStyle } from "styled-components";

import { Color } from "./colors";

export const GlobalStyle = createGlobalStyle`
  .workbench {
    * {
    display: inline-flex;
    box-sizing: border-box;
    }

    table {
    display: table;
    border-spacing: 0;
  }

    thead {
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
    }

    tfoot {
      display: table-footer-group;
    }

    tr {
      display: table-row;
    }

    td {
      display: table-cell;
      padding: 0;
      height: 0;
    }
  }

  html, body, #root {
    width: 100%;
  }

  body {
    overflow: hidden;
    font-size: 15px;
    background: ${Color.white};
    color: ${Color.textMain};

    &[data-dragging] {
      * {
        cursor: grabbing !important;
        user-select: none !important;
      }

      .${FREEZER_ITEM}, .${PLANOGRAM_ITEM}, .${PLANOGRAM_SHELF_THICKNESS} {
        pointer-events: none;
      }
    }

    &[data-editing] .tooltip {
      display: none;
    }
  }
  
  body,
  input,
  select,
  button,
  textarea {
    margin: 0;
    font-family: 'Mulish', sans-serif;
  }

  

  @-moz-document url-prefix() {
    tr, td {
      height: 100%;
    }
  }

  .react-json-view * {
    display: revert;
    user-select: none;
  }

  #root > .jotai-devtools-UnstyledButton-root {
    left: unset !important;
    right: 10px !important;
    width: 10px !important;
    height: 10px !important;
    opacity: 0;

    img {
      width: 15px !important;
      height: 15px !important;
    }
  }
`;
