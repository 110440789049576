export enum Color {
	// Main
	white = "#FFFFFF",

	// UI
	teal = "#009B9B",

	// Text
	textMain = "#00130A",
	textSecondary = "#575757",
	textDisabled = "#E4E4E4",

	// Border
	spaceGray = "#C3C5C7",
	spaceGrayHover = "#A9A9A9",
	spaceGrayDisabled = "#E4E4E4",

	// Light Gray
	lightGray = "#F7F7F7",
	lightGrayHover = "#EEEEEE",
	lightGrayDisabled = "#FCFCFC",

	// Opacity Sky
	opacitySky = "#F9FAFC",
	opacitySkyHover = "#EDEFF5",
	opacitySkyActive = "#A9BBCB",
	opacitySkyDisabled = "#F9FAFC",

	// Button - Primary
	primary = "#3F8E6A",
	primaryHover = "#27704F",
	primaryActive = "#226144",
	primaryDisabled = "#BFE6DE",

	// Button - Yellow
	yellow = "#FFAE35",
	yellowHover = "#E29C33",
	yellowActive = "#D89531",
	yellowDisabled = "#EBCA98",

	// Button - Danger
	danger = "#EF8763",
	dangerHover = "#DA7856",
	dangerActive = "#CC7050",
	dangerDisabled = "#F5BCA9",

	// Button - Red
	red = "#EE7766",
	redHover = "#D96959",
	redActive = "#d75341",
	redDisabled = "#F6BBB2",

	// Button - Green Smoke
	greenSmoke = "#DDEAE4",
	greenSmokeHover = "#C6D9D0",
	greenSmokeActive = "#B0CBBE",
	greenSmokeDisabled = "#EEF4F1",
	greenSmokeDisabledText = "#96C1AD",

	// Table Highlights
	tableGreen = "#F3FFEA",
	tableYellow = "#FBF8DF",
	tableRed = "#FEF0EC",

	// Toasts
	toastSuccess = "#7FD059",
	toastPending = "#7DABF1",
}
