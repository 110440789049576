import React from "react";
import { Freezer, FreezerItem, FreezerSection, freezersOverlapsAtom } from "src/store/freezers";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { FREEZER_OVERLAP, FREEZER_OVERLAP_MOVE } from "../utils/classes";

const Overlap = styled.div<{ x: number; y: number; width: number; height: number }>`
	position: absolute;
	width: ${({ width }) => `calc(var(--scale) * ${width}px)`};
	height: ${({ height }) => `calc(var(--scale) * ${height}px)`};
	left: ${({ x }) => `calc(var(--scale) * ${x}px)`};
	top: ${({ y }) => `calc(var(--scale) * ${y}px)`};
	background-color: ${Color.redDisabled};
	background-image: url("overlap.svg");
	border: 1px solid ${Color.redActive};
	border-radius: 2px;
	opacity: 0.3;
	pointer-events: none;
	z-index: 2;

	&[data-hidden] {
		display: none;
	}
`;

interface Props {
	freezer: Freezer;
}

interface OverlapType {
	item1Id: string;
	item1FacingId: number;
	item2Id: string;
	item2FacingId: number;
	startX: number;
	startY: number;
	width: number;
	height: number;
}

const findOverlaps = (freezer: Freezer): OverlapType[] => {
	const overlaps: OverlapType[] = [];

	const items = freezer.sections.reduce(
		(sectionItems: FreezerItem[], section: FreezerSection) => sectionItems.concat(section.items),
		[],
	);

	for (const item1 of items) {
		for (const item2 of items) {
			if (item1 === item2) continue;

			// calculate the x and y ranges of the two items
			const item1X1 = item1.start_x;
			const item1X2 = item1.start_x + item1.width;
			const item1Y1 = item1.start_y;
			const item1Y2 = item1.start_y + item1.height;

			const item2X1 = item2.start_x;
			const item2X2 = item2.start_x + item2.width;
			const item2Y1 = item2.start_y;
			const item2Y2 = item2.start_y + item2.height;

			// calculate the overlap between the two items
			const overlapX1 = Math.max(item1X1, item2X1);
			const overlapX2 = Math.min(item1X2, item2X2);
			const overlapY1 = Math.max(item1Y1, item2Y1);
			const overlapY2 = Math.min(item1Y2, item2Y2);

			const overlapStartX = Math.max(item1X1, item2X1);
			const overlapStartY = Math.max(item1Y1, item2Y1);
			const overlapWidth = overlapX2 - overlapX1;
			const overlapHeight = overlapY2 - overlapY1;

			if (
				overlapWidth <= 0 ||
				overlapHeight <= 0 ||
				overlaps.some(
					overlap =>
						overlap.startX === overlapStartX &&
						overlap.startY === overlapStartY &&
						overlap.width === overlapWidth &&
						overlap.height === overlapHeight,
				)
			)
				continue;

			// check if the items overlap
			overlaps.push({
				item1Id: item1.item_id,
				item1FacingId: item1.facing_id,
				item2Id: item2.item_id,
				item2FacingId: item2.facing_id,
				startX: overlapStartX,
				startY: overlapStartY,
				width: overlapWidth,
				height: overlapHeight,
			});
		}
	}

	return overlaps;
};

export const Overlaps: React.FC<Props> = ({ freezer }) => {
	const overlaps: OverlapType[] = findOverlaps(freezer);
	const overlapsFromAtom = useOmniValue(freezersOverlapsAtom);

	return (
		<React.Fragment>
			{overlaps.map(
				(
					{ startX, startY, width, height, item1Id, item1FacingId, item2Id, item2FacingId },
					index,
				) => (
					<Overlap
						className={FREEZER_OVERLAP}
						key={index}
						x={startX}
						y={startY}
						width={width}
						height={height}
						data-item1={item1Id}
						data-item1-facing={item1FacingId}
						data-item2={item2Id}
						data-item2-facing={item2FacingId}
					/>
				),
			)}
			{overlapsFromAtom.map(({ x, y, width, height }, index) => (
				<Overlap
					className={FREEZER_OVERLAP_MOVE}
					key={index}
					x={x}
					y={y}
					width={width}
					height={height}
				/>
			))}
		</React.Fragment>
	);
};
