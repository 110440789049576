import React from "react";
import { IconType } from "src/elements";
import { OmniState, useOmniState } from "src/utils/atoms";

import { Toggle } from "./Toggle";

interface Props {
	readonly label: string;
	readonly state: OmniState<boolean>;
	readonly disabled?: boolean;
	readonly icon?: IconType;
	readonly tooltip?: string;
}

export const StateToggle: React.FC<Props> = ({ label, icon, disabled, tooltip, state }) => {
	const [checked, setChecked] = useOmniState(state);

	return (
		<Toggle
			label={label}
			checked={checked}
			setChecked={setChecked}
			icon={icon}
			disabled={disabled}
			tooltip={tooltip}
		/>
	);
};
