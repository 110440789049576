import React from "react";
import { OmniState, useOmniState } from "src/utils/atoms";

import { NumberInputV2 } from "./NumberInputV2";
import { NumberInputProps } from "./types";

interface Props extends NumberInputProps {
	state: OmniState<number>;
}

export const NumberInputV2State: React.FC<Props> = ({
	state,
	label,
	min,
	max,
	increment,
	placeholder,
	disabled,
	width,
}) => {
	const [value, setValue] = useOmniState(state);

	return (
		<NumberInputV2
			value={value}
			setValue={setValue}
			label={label}
			min={min}
			max={max}
			increment={increment}
			placeholder={placeholder}
			disabled={disabled}
			width={width}
		/>
	);
};
