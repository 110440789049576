import { RefObject, useEffect } from "react";

const openDropdown = (event: MouseEvent, dropdown: HTMLElement) => {
	const { x, y, width, height } = (event.target as HTMLElement).getBoundingClientRect();
	dropdown.setAttribute(
		"style",
		`padding-top: ${height + 5}px; top: ${y}px; left: ${x}px; min-width: ${width}px;`,
	);
	dropdown.setAttribute("data-open", "");
};

const closeDropdown = (dropdown: HTMLElement) => {
	dropdown.removeAttribute("data-open");
};

export const useDropdown = (
	opener: RefObject<HTMLElement | null>,
	dropdown: RefObject<HTMLElement | null>,
) => {
	useEffect(() => {
		if (!opener.current || !dropdown.current) return;

		opener.current.addEventListener("mouseenter", event => {
			openDropdown(event, dropdown.current!);
		});

		dropdown.current.addEventListener("mouseleave", () => {
			closeDropdown(dropdown.current!);
		});
	}, []);
};
