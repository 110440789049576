export const PLANOGRAM_CONTAINER = "planogram-container";

export const PLANOGRAM_BAY = "planogram-bay";

export const PLANOGRAM_SHELF = "planogram-shelf";

export const PLANOGRAM_SHELF_THICKNESS = "planogram-shelf-thickness";

export const PLANOGRAM_ITEMS = "planogram-items";

export const PLANOGRAM_ITEM = "planogram-item";

export const PLANOGRAM_FACING = "planogram-facing";

export const UNRRANGED_ITEMS = "unranged-items";

export const MUDMAP_ITEM = "mudmap-item";
