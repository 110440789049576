import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { TextVariant, TextVariantType, getFontAttribute, textVariants } from "./variants";

const TextElement = styled.span<Props>`
	${Object.keys(textVariants).map(variant => {
		const fontSize = getFontAttribute(variant, TextVariantType.FONT_SIZE);
		const fontWeight = getFontAttribute(variant, TextVariantType.FONT_WEIGHT);
		const fontStyle = getFontAttribute(variant, TextVariantType.FONT_STYLE);
		const textTransform = getFontAttribute(variant, TextVariantType.TEXT_TRANSFORM);
		const letterSpacing = getFontAttribute(variant, TextVariantType.LETTER_SPACING);
		const lineHeight = getFontAttribute(variant, TextVariantType.LINE_HEIGHT);
		const color = getFontAttribute(variant, TextVariantType.COLOR);

		return `&[data-variant="${variant}"] {
			${fontSize ? `font-size: ${fontSize};` : ""}
			${fontWeight ? `font-weight: ${fontWeight};` : ""}
			${fontStyle ? `font-style: ${fontStyle};` : ""}
			${textTransform ? `text-transform: ${textTransform};` : ""}
			${letterSpacing ? `letter-spacing: ${letterSpacing};` : ""}
			${lineHeight ? `line-height: ${lineHeight};` : ""}
			${color ? `color: ${color};` : ""}
		}`;
	})}

	&[data-variant] {
		color: ${({ color }) => color};
		display: inline-block;
		text-transform: ${({ transform }) => transform};
		text-transform: ${({ uppercase }) => uppercase && "uppercase"};
		text-align: ${({ align }) => align};
		text-overflow: ${({ textOverflow }) => textOverflow};
		white-space: ${({ whiteSpace }) => whiteSpace};
		word-break: ${({ wordBreak }) => wordBreak};
		overflow: ${({ overflow }) => overflow};
		font-weight: ${({ fontWeight }) => fontWeight};
		${({ vertical }) =>
			vertical &&
			`writing-mode: vertical-rl;
	text-orientation: mixed;
	`}
	}
	padding: ${({ padding }) => padding};
	transition: color ${Transition.input};
`;

interface Props {
	variant?: TextVariant;
	align?: "left" | "right" | "center";
	color?: Color | string;
	textOverflow?: "ellipsis" | "clip";
	transform?: "capitalize" | "lowercase" | "uppercase" | "none" | "inherit";
	whiteSpace?: "nowrap" | "normal";
	wordBreak?: "break-all" | "break-word" | "keep-all" | "normal";
	overflow?: "hidden";
	vertical?: boolean;
	fontWeight?: number | "bold";
	padding?: string;
	uppercase?: boolean;
	children?: React.ReactNode;
}

export const Text: React.FC<Props> = ({
	variant,
	align,
	color,
	textOverflow,
	transform,
	whiteSpace,
	wordBreak,
	overflow,
	vertical,
	fontWeight,
	padding,
	uppercase,
	children,
}) => (
	<TextElement
		align={align}
		color={color}
		textOverflow={textOverflow}
		transform={transform}
		whiteSpace={whiteSpace}
		wordBreak={wordBreak}
		overflow={overflow}
		vertical={vertical}
		fontWeight={fontWeight}
		padding={padding}
		uppercase={uppercase}
		data-variant={variant}
	>
		{children}
	</TextElement>
);
