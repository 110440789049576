export const enum LogsTab {
	FILES = "Files",
	MUDMAP = "Mudmap",
	PLANOGRAM = "Planogram",
	REBUILD = "Rebuild",
	OBJECTIVES = "Objectives",
	MINOR_REVIEW = "Minor Review",

	FREEZERS_MUDMAP = "Freezers Mudmap",
	FREEZERS_PLANOGRAM = "Freezers Planogram",

	CONTAINERS_FILES = "CONTAINERS_FILES",
	CONTAINERS_GROUP_AND_SIZE_VARIANTS = "CONTAINERS_GROUP_AND_SIZE_VARIANTS",
	CONTAINERS_PLACE_CONTAINERS = "CONTAINERS_PLACE_CONTAINERS",
	CONTAINERS_PLACE_VARIANTS = "CONTAINERS_PLACE_VARIANTS",
}
