import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { logsTabAtom } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import { useApi } from "src/pages/Containers/store/api";
import {
	containersFilesCandidatesAtom,
	containersFilesFixturesAtom,
	containersFilesResponseAtom,
} from "src/pages/Containers/store/atoms";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { FilesUploader } from "../components/FilesUploader";

export const PanelFiles = () => {
	const fileFixtures = useOmniValue(containersFilesFixturesAtom);
	const fileCandidates = useOmniValue(containersFilesCandidatesAtom);
	const filesResponse = useOmniValue(containersFilesResponseAtom);

	const setLogsTab = useSetOmniValue(logsTabAtom);

	const { stepStartUploadFiles } = useApi();
	const { openModal } = useModals();

	const isFilesUploadDisabled = () => !fileFixtures || !fileCandidates;
	const isNextStepDisabled = () => !filesResponse;

	const openLogs = () => {
		setLogsTab(LogsTab.CONTAINERS_FILES);
		openModal(ModalType.LOGS);
	};

	return (
		<>
			<FilesUploader />

			<Box gap="10px">
				<Button theme="greenSmoke" variant="inverted" onClick={openLogs}>
					Logs
				</Button>

				<Button
					theme={isNextStepDisabled() ? "primary" : "greenSmoke"}
					disabled={isFilesUploadDisabled()}
					onClick={stepStartUploadFiles}
				>
					Upload Files
				</Button>
			</Box>
		</>
	);
};
