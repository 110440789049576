import { RefObject, useEffect, useState } from "react";
import React from "react";

import { ErrorBlock } from "../ErrorBlock";
import { IconType } from "../IconV2";
import { Icon } from "./components/Icon";
import { InputElement, InputInnerContainer, InputOuterContainer } from "./components/Input";
import { Label } from "./components/Label";

interface Props {
	readonly label?: string;
	readonly type?: "text" | "email" | "password";
	readonly defaultValue?: string;
	readonly placeholder?: string;
	readonly error?: { message: string | null };
	readonly disabled?: boolean;
	readonly width?: string;
	readonly leftIcon?: { icon: IconType; onClick?: () => void; tooltip?: string };
	readonly rightIcon?: { icon: IconType; onClick?: () => void; tooltip?: string };
	readonly onChange?: (value: string) => void;
	readonly onFocus?: () => void;
}

export interface TextInputElement extends HTMLInputElement {
	updateValue: (value?: string) => void;
}

export const TextInput = React.forwardRef<TextInputElement | null, Props>(
	(
		{
			label,
			type = "text",
			defaultValue,
			placeholder,
			error,
			disabled,
			width,
			leftIcon,
			rightIcon,
			onChange,
		},
		ref,
	) => {
		const [value, setValue] = useState(defaultValue || "");

		useEffect(() => {
			const input = ref as RefObject<TextInputElement | null>;
			if (!input || !input.current) return;

			input.current.updateValue = (value?: string) => {
				if (value !== undefined) setValue(value);
			};
		}, [ref]);

		return (
			<InputOuterContainer width={width} label={label}>
				<InputInnerContainer>
					<InputElement
						ref={ref}
						type={type}
						placeholder={placeholder}
						value={value}
						disabled={disabled}
						leftIcon={leftIcon?.icon ? true : false}
						rightIcon={rightIcon?.icon ? true : false}
						onChange={event => {
							const value = event.target.value;
							setValue(value);
							if (onChange) onChange(value);
						}}
					/>

					<Label label={label} />

					{leftIcon && (
						<Icon
							icon={leftIcon.icon}
							error={error?.message}
							side="left"
							tooltip={leftIcon.tooltip}
							onClick={leftIcon.onClick}
						/>
					)}

					{rightIcon && (
						<Icon
							icon={rightIcon.icon}
							error={error?.message}
							side="right"
							tooltip={rightIcon.tooltip}
							onClick={rightIcon.onClick}
						/>
					)}
				</InputInnerContainer>

				{error && <ErrorBlock>{error.message}</ErrorBlock>}
			</InputOuterContainer>
		);
	},
);
