import { getMudmapCdtColorByIndex } from "src/constants/mudmapCdtColors";
import { FreezersVisualizerColorsBy } from "src/pages/Freezers/components/ControlPanel/types";
import { useOmniState, useOmniValue } from "src/utils/atoms";

import {
	freezersMudmapAtom,
	freezersMudmapColorsAtom,
	freezersPlanogramAtom,
	freezersPlanogramColorsAtom,
	freezersVisualizerColorsByAtom,
} from "./atoms";
import {
	FreezerColors,
	FreezerDraggableEnd,
	FreezerDraggableStart,
	FreezerItem,
	Freezers,
} from "./types";

export interface MoveFreezerItem {
	start: FreezerDraggableStart;
	end: FreezerDraggableEnd;
}

export interface RotateFreezerItem {
	freezerId: string;
	sectionId: string;
	itemId: string;
	facingId: number;
}

export interface FreezerItemSize {
	freezerId: string;
	sectionId: string;
	itemId: string;
	facingId: number;
	width: number;
	height: number;
}

export const useFreezers = (type: "mudmap" | "planogram") => {
	const freezers = useOmniValue(type === "mudmap" ? freezersMudmapAtom : freezersPlanogramAtom);

	const moveFreezerItem = ({ start, end }: MoveFreezerItem) => {
		const freezerStart = freezers?.planogram.freezers?.find(
			freezer => freezer.freezer_id === start.freezer_id,
		);
		const sectionStart = freezerStart?.sections.find(
			section => section.section_id === start.section_id,
		);
		const item = sectionStart?.items.find(
			item => item.item_id === start.item_id && item.facing_id === start.facing_id,
		);
		const freezerEnd = freezers?.planogram.freezers?.find(
			freezer => freezer.freezer_id === end.freezer_id,
		);
		const sectionEnd = freezerEnd?.sections.find(section => section.section_id === end.section_id);

		if (!item) return;
		item.start_x = end.x;
		item.start_y = end.y;

		sectionStart?.items.splice(sectionStart.items.indexOf(item), 1);
		sectionEnd?.items.push(item);
	};

	const rotateFreezerItem = ({ freezerId, sectionId, itemId, facingId }: RotateFreezerItem) => {
		const item = freezers?.planogram.freezers
			.find(freezer => freezer.freezer_id === freezerId)
			?.sections.find(section => section.section_id === sectionId)
			?.items.find(item => item.item_id === itemId && item.facing_id === facingId);

		if (!item) return;
		const widthCopy = item.width;
		const heightCopy = item.height;
		item.width = heightCopy;
		item.height = widthCopy;
	};

	const updateFreezerItemSize = ({
		freezerId,
		sectionId,
		itemId,
		facingId,
		width,
		height,
	}: FreezerItemSize) => {
		const item = freezers?.planogram.freezers
			.find(freezer => freezer.freezer_id === freezerId)
			?.sections.find(section => section.section_id === sectionId)
			?.items.find(item => item.item_id === itemId && item.facing_id === facingId);

		if (!item) return;
		item.width = width;
		item.height = height;
	};

	return { moveFreezerItem, rotateFreezerItem, updateFreezerItemSize };
};

export const useFreezersColors = () => {
	const colorsBy = useOmniValue(freezersVisualizerColorsByAtom);
	const [mudmapColors, setMudmapColors] = useOmniState(freezersMudmapColorsAtom);
	const [planogramColors, setPlanogramColors] = useOmniState(freezersPlanogramColorsAtom);

	const generateColors = (type: "mudmap" | "planogram", freezers: Freezers) => {
		const colors = { cdt0: {}, cdt1: {}, cdt2: {} } as FreezerColors;

		for (const freezer of freezers.planogram.freezers) {
			for (const section of freezer.sections) {
				for (const item of section.items) {
					if (!colors.cdt0[item.cdt0]) {
						colors.cdt0[item.cdt0] = getMudmapCdtColorByIndex(Object.values(colors.cdt0).length);
					}

					if (!colors.cdt1[`${item.cdt0}_${item.cdt1}`]) {
						colors.cdt1[`${item.cdt0}_${item.cdt1}`] = getMudmapCdtColorByIndex(
							Object.values(colors.cdt1).length,
						);
					}

					if (!colors.cdt2[`${item.cdt0}_${item.cdt1}_${item.cdt2}`]) {
						colors.cdt2[`${item.cdt0}_${item.cdt1}_${item.cdt2}`] = getMudmapCdtColorByIndex(
							Object.values(colors.cdt2).length,
						);
					}
				}
			}
		}

		type === "mudmap" ? setMudmapColors(colors) : setPlanogramColors(colors);
	};

	const getColor = (type: "mudmap" | "planogram", item: FreezerItem) => {
		const key =
			colorsBy === FreezersVisualizerColorsBy.VARIANT
				? item.cdt0
				: colorsBy === FreezersVisualizerColorsBy.SEGMENT
				? `${item.cdt0}_${item.cdt1}`
				: `${item.cdt0}_${item.cdt1}_${item.cdt2}`;
		return type === "mudmap" ? mudmapColors[colorsBy][key] : planogramColors[colorsBy][key];
	};

	return { generateColors, getColor };
};
