import { Text } from "src/elements";
import { Color, Transition } from "src/utils";
import styled, { StyledComponent } from "styled-components";

import { RangeTheme } from "../types/types";

interface HandleProps {
	percentage: number;
	variant?: RangeTheme;
}

const HandleContainer: StyledComponent<
	"div",
	any,
	HandleProps,
	never
> = styled.div.attrs<HandleProps>(({ percentage, variant }) => ({
	style: {
		left: `calc(${percentage}% - ${(percentage / 100) * (variant === "default" ? 13 : 17)}px)`,
	},
}))`
	position: absolute;
	bottom: 0;
	z-index: 1;
`;

const HandleElement = styled.div<{ variant?: "default" | "round" }>`
	width: ${({ variant }) => (variant === "default" ? "13px" : "17px")};
	height: ${({ variant }) => (variant === "default" ? "14px" : "17px")};
	background: ${Color.white};
	pointer-events: none;
	gap: 2px;
	justify-content: center;
	align-items: center;
	filter: drop-shadow(0 0 1px var(--handle)) drop-shadow(0 0 1px var(--handle));
	border-radius: ${({ variant }) => variant === "round" && "50%"};
`;

const Pointer = styled.div`
	border-left: 6.5px solid transparent;
	border-right: 6.5px solid transparent;
	border-bottom: 4px solid ${Color.white};
	position: absolute;
	top: -4px;
	left: 0;
`;

const Gripper = styled.div`
	width: 1px;
	height: 8px;
	background-color: var(--handle);
	transition: background-color ${Transition.fast};
`;

const Value = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: -23px;
	justify-content: center;
`;

interface Props {
	percentage: number;
	value: number | string;
	withoutValueLabel?: boolean;
	variant?: "default" | "round";
}

export const Handle: React.FC<Props> = ({ percentage, value, withoutValueLabel, variant }) => (
	<HandleContainer variant={variant} percentage={percentage}>
		<HandleElement variant={variant}>
			{variant === "default" && <Pointer />}
			<Gripper />
			<Gripper />
			<Gripper />
		</HandleElement>

		{!withoutValueLabel && (
			<Value>
				<Text variant="small2">{value}</Text>
			</Value>
		)}
	</HandleContainer>
);
