export const saveResponseLog = (
	data: any,
	setPayload: ((data: any) => void) | null,
	setResponse?: (data: any) => void,
) => {
	// Set payload.
	if (setPayload) setPayload(JSON.parse(JSON.stringify(data.payload)));
	delete data.payload;

	// Set response.
	if (setResponse) setResponse(JSON.parse(JSON.stringify(data)));
};

export const saveErrorLog = (
	error: any,
	setPayload: ((data: any) => void) | null,
	setResponse?: (data: any) => void,
) => {
	if (error?.response?.data) {
		saveResponseLog(error.response.data, setPayload, setResponse);
	}
};
