import React, { useEffect } from "react";
import { useAuth } from "src/store/auth";
import { Color } from "src/utils";
import { useSetOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { Sidebar, sidebarPadding, sidebarWidth } from "../Sidebar/Sidebar";
import { pageAtom } from "./store/atoms";

const PageElement = styled.div<Props>`
	width: 100%;
	height: 100vh;
	padding: ${sidebarPadding}px;
	padding-left: ${({ sidebar }) => sidebar && `${sidebarWidth + 2 * sidebarPadding}px`};
	background: ${({ background }) => background};
	background-size: cover;
	background-position: center;
`;

const Main = styled.div<Props>`
	flex-direction: column;
	width: 100%;
	gap: ${({ gap }) => gap};
`;

interface Props {
	sidebar?: boolean;
	background?: Color | string;
	gap?: string;
	isAuth?: boolean;
	children: React.ReactNode;
}

export const Page: React.FC<Props> = ({
	sidebar = true,
	background = Color.white,
	gap = "20px",
	isAuth = true,
	children,
}) => {
	const setPage = useSetOmniValue(pageAtom);

	useEffect(() => {
		setPage(window.location.pathname);
	}, []);

	const { user } = useAuth();

	return isAuth && !user ? null : (
		<PageElement background={background} sidebar={sidebar}>
			{sidebar && <Sidebar />}
			<Main gap={gap}>{children}</Main>
		</PageElement>
	);
};
