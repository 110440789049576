export const enum ItemConstraintCondition {
	BOTH_OR_NONE = "Both or None",
	EITHER_OR = "Either One",
	PREREQUISITE = "Prerequisite",
}

export interface ConditionalItem {
	firstItem: string;
	secondItem: string;
	constraint: ItemConstraintCondition;
}
