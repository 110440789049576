import { Text } from "src/elements";
import styled from "styled-components";

const WatermarkElement = styled.div`
	position: absolute;
	top: 0;
	right: 2px;
	opacity: 0.5;
`;

export const Watermark: React.FC<{ children: string | number }> = ({ children }) => (
	<WatermarkElement>
		<Text variant="caption3" color="inherit">
			{children}
		</Text>
	</WatermarkElement>
);
