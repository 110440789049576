import React, { useEffect } from "react";
import { Box, Button } from "src/elements";
import { RangeV2 } from "src/elements/RangeV2/Range";
import { OmniState, useOmniState } from "src/utils/atoms";

const maxZoom = 3;
const minZoom = 0.5;
const zoomStep = 0.01;

interface Props {
	name: string;
	state: OmniState<number>;
}

export const Zoomer: React.FC<Props> = ({ name, state }) => {
	const [zoom, setZoom] = useOmniState(state);

	const zoomOut = () => {
		setZoom(Math.max(zoom - 0.5, minZoom));
	};

	const zoomIn = () => {
		setZoom(Math.min(zoom + 0.5, maxZoom));
	};

	useEffect(() => {
		document.body.style.setProperty(`--${name}-zoom`, String(zoom));
	}, [zoom]);

	return (
		<Box align="center" gap="5px">
			<Button icon="zoomOut" variant="borderless" size="small" onClick={zoomOut} />

			<RangeV2
				value={zoom}
				setValue={setZoom}
				theme="round"
				min={minZoom}
				max={maxZoom}
				step={zoomStep}
				withoutValueLabel
			/>

			<Button icon="zoomIn" variant="borderless" size="small" onClick={zoomIn} />
		</Box>
	);
};
