import { postRequest } from "src/utils";

import {
	ContainersFilesPayload,
	ContainersGroupsPayload,
	ContainersPlaceContainersPayload,
	ContainersPlaceVariantsPayload,
} from "./types";

const { REACT_APP_WB_BACKEND: URL_BACKEND } = process.env;

export const postStep0ContainersFilesReq = ({
	fixturesFile,
	candidatesFile,
}: ContainersFilesPayload) => {
	const data = new FormData();
	data.append("fixtures", fixturesFile, fixturesFile.name);
	data.append("candidates", candidatesFile, candidatesFile.name);

	return postRequest(`${URL_BACKEND}/containers/files`, data, {}, "multipart/form-data");
};

export const postStep0ContainersGroupAndSizeVariantsReq = ({
	version,
	freezer,
	candidates,
	cumulative_shelf_heights,
	merge_rules,
	parameters,
}: ContainersGroupsPayload) => {
	const data = new FormData();
	data.append("version", version);
	data.append("merge_rules", JSON.stringify(merge_rules));
	data.append("freezer", JSON.stringify(freezer));
	data.append("candidates", JSON.stringify(candidates));
	data.append("cumulative_shelf_heights", JSON.stringify(cumulative_shelf_heights));
	data.append("parameters", JSON.stringify(parameters));

	return postRequest(
		`${URL_BACKEND}/containers/group_and_size_variants`,
		data,
		{},
		"multipart/form-data",
	);
};

export const postStep1PlaceContainersReq = ({
	version,
	freezer,
	cumulative_shelf_heights,
	groups,
}: ContainersPlaceContainersPayload) => {
	const data = new FormData();
	data.append("version", version);
	data.append("freezer", JSON.stringify(freezer));
	data.append("cumulative_shelf_heights", JSON.stringify(cumulative_shelf_heights));
	data.append("groups", JSON.stringify(groups));

	return postRequest(`${URL_BACKEND}/containers/place_containers`, data, {}, "multipart/form-data");
};

export const postStep2PlaceVariantsReq = ({
	version,
	freezer,
	candidates,
}: ContainersPlaceVariantsPayload) => {
	const data = new FormData();
	data.append("version", version);
	data.append("freezer", JSON.stringify(freezer));
	data.append("candidates", JSON.stringify(candidates));

	return postRequest(`${URL_BACKEND}/containers/place_variants`, data, {}, "multipart/form-data");
};
