import { LayoutPriority, Priority } from "./types";

export const baysPrioritiesData: Priority[] = [
	{
		label: "Aesthetics",
		value: LayoutPriority.AESTHETICS,
	},
	{
		label: "Profit and Loss",
		value: LayoutPriority.PROFIT_AND_LOSS,
	},
	{
		label: "Segmentation",
		value: LayoutPriority.SEGMENTATION,
	},
];
