export const mudmapPlanogramFilesDetection = (file: File) => {
	const name = file.name.toLocaleLowerCase();

	if (name.includes("_ff_") || name.includes("fixture") || name.includes("ff")) {
		return "fixture";
	} else if (
		name.includes("_pm_") ||
		name.includes("master") ||
		name.includes("items") ||
		name.includes("pm") ||
		name.includes("candidate")
	) {
		return "master";
	}

	return false;
};
