import React from "react";
import { MoveFreezerItem } from "src/store/freezers";
import { FreezerDraggableStart } from "src/store/freezers";

import { FREEZER, FREEZERS, FREEZER_ITEM, FREEZER_OVERLAP } from "./classes";
import { freezerBorder } from "./data";

let draggableStartData: FreezerDraggableStart | null = null;
let originalCursorCoordinates: number[] | null = null;
let draggable: HTMLElement | null = null;
let section: HTMLElement | null = null;
let freezer: HTMLElement | null = null;
let items: NodeListOf<Element> | null = null;

export const onFreezerItemMouseDown = (event: React.MouseEvent) => {
	// Not left click.
	if (event.nativeEvent.button !== 0) return;

	// Not freezer item. (Probably rotate button)
	if (!(event.target as HTMLElement).classList.contains(FREEZER_ITEM)) return;

	// Find draggable item.
	draggable = event.target as HTMLElement;

	// Find item location on the screen.
	const { x: itemX, y: itemY, width, height } = draggable.getBoundingClientRect();

	const { offsetX, offsetY } = event.nativeEvent;

	// Resize handle clicked, cancel dragging.
	if (width - offsetX <= 18 && height - offsetY <= 18) {
		draggable = null;
		return;
	}

	// Generate draggable start data to find it from freezers data later on.
	draggableStartData = {
		freezer_id: draggable.getAttribute("data-freezer")!,
		section_id: draggable.getAttribute("data-section")!,
		item_id: draggable.getAttribute("data-id")!,
		facing_id: parseInt(draggable.getAttribute("data-facing")!),
		scale: parseFloat(draggable.closest(`.${FREEZERS}`)!.getAttribute("data-scale")!),
		coordinates: [itemX, itemY],
	};

	// Find cursor coordinates.
	const { screenX: cursorX, screenY: cursorY } = event;
	originalCursorCoordinates = [cursorX, cursorY];

	// Override draggable coordinates as position is changed to fixed.
	document.body.setAttribute("data-dragging", "");
	draggable.setAttribute("data-draggable", "");

	// Move draggable to be last element in freezer to show as top priority item.
	draggable.parentElement?.appendChild(draggable);
};

export const onFreezerItemMouseMove = (event: React.MouseEvent) => {
	if (!draggable || !draggableStartData || !originalCursorCoordinates || !freezer) return;

	const { screenX: cursorX, screenY: cursorY } = event;
	const { scale, item_id, facing_id } = draggableStartData;

	const screenRawX = draggableStartData.coordinates[0] + cursorX - originalCursorCoordinates[0];
	const screenRawY = draggableStartData.coordinates[1] + cursorY - originalCursorCoordinates[1];

	const { x: freezerX, y: freezerY } = freezer.getBoundingClientRect();
	const itemX = Math.round((screenRawX - freezerX - freezerBorder) / scale);
	const itemY = Math.round((screenRawY - freezerY - freezerBorder) / scale);

	const allOverlaps = freezer.querySelectorAll(`.${FREEZER_OVERLAP}`);
	allOverlaps.forEach(overlap => overlap.removeAttribute("data-hidden"));

	// Hide old generated overlaps when starting to move an item.
	const oldOverlaps = freezer.querySelectorAll(
		`[data-item1="${item_id}"][data-item1-facing="${facing_id}"], [data-item2="${item_id}"][data-item2-facing="${facing_id}"]`,
	);
	oldOverlaps.forEach(overlap => overlap.setAttribute("data-hidden", ""));

	draggable.style.top = `calc(var(--scale) * ${itemY}px)`;
	draggable.style.left = `calc(var(--scale) * ${itemX}px)`;
};

export const onFreezerSectionMouseUp = (
	event: React.MouseEvent,
	moveFreezerItem: (props: MoveFreezerItem) => void,
) => {
	if (!draggable || !draggableStartData || !freezer || !section) return;
	const { scale } = draggableStartData;

	// Find section and item coordinates on screen.
	const { x: freezerX, y: freezerY } = freezer.getBoundingClientRect();
	const { x: itemX, y: itemY } = draggable.getBoundingClientRect();

	// Calculate new actual coordinates in freezers own scale.
	const x = Math.round((itemX - freezerX - freezerBorder) / scale);
	const y = Math.round((itemY - freezerY - freezerBorder) / scale);

	// Set item position on screen based on new calculated coordinates and end dragging.
	document.body.removeAttribute("data-dragging");
	draggable.removeAttribute("data-draggable");
	draggable.style.top = `calc(var(--scale) * ${y}px)`;
	draggable.style.left = `calc(var(--scale) * ${x}px)`;
	draggable.setAttribute("data-freezer", freezer.getAttribute("data-id")!);
	draggable.setAttribute("data-section", section.getAttribute("data-id")!);
	draggable.setAttribute("data-x", `${x}`);
	draggable.setAttribute("data-y", `${y}`);

	// Save changes to freezers data.
	moveFreezerItem({
		start: draggableStartData,
		end: {
			freezer_id: freezer.getAttribute("data-id")!,
			section_id: section.getAttribute("data-id")!,
			x,
			y,
		},
	});

	draggable = null;
};

export const onFrezerSectionMouseEnter = (event: React.MouseEvent) => {
	section = event.target as HTMLElement;
	const freezerNew: HTMLElement | null = section.closest(`.${FREEZER}`);

	// Item dragged on top of new freezer, move item element from previous freezer to current one.
	if (freezer !== freezerNew && draggable) {
		freezer?.removeChild(draggable);
		freezerNew?.appendChild(draggable);
	}

	freezer = freezerNew;
};

export const rotateFreezerItem = (event: React.MouseEvent) => {
	const item = (event.target as HTMLElement).closest(`.${FREEZER_ITEM}`) as HTMLElement;

	const { width, height } = item.getBoundingClientRect();
	item.style.width = `${height}px`;
	item.style.height = `${width}px`;

	if (height < width) {
		item.setAttribute("data-rotated", "");
	} else {
		item.removeAttribute("data-rotated");
	}
};

export const getOverlaps = (draggable: HTMLElement) => {
	const overlaps: DOMRect[] = [];
	const freezerCurrent = draggable.closest(`.${FREEZER}`) as HTMLElement;

	// New freezer detected, need to query all freezer items again.
	if (freezerCurrent !== freezer) {
		freezer = freezerCurrent;
	}
	if (!items) items = freezer.querySelectorAll(`.${FREEZER_ITEM}`);

	if (!items) return overlaps;
	for (const item of items) {
		// Don't check draggable with itself.
		if (item === draggable) continue;

		const overlap = getOverlap(draggable, item);
		if (overlap) overlaps.push(overlap);
	}

	return overlaps;
};

const getOverlap = (el1: Element, el2: Element): DOMRect | null => {
	if (!freezer) return null;

	const rect1 = el1.getBoundingClientRect();
	const rect2 = el2.getBoundingClientRect();
	const scale = parseFloat(el1.closest(`.${FREEZERS}`)!.getAttribute("data-scale")!);

	const top = Math.max(rect1.top, rect2.top);
	const bottom = Math.min(rect1.bottom, rect2.bottom);
	const left = Math.max(rect1.left, rect2.left);
	const right = Math.min(rect1.right, rect2.right);

	return top < bottom && left < right
		? new DOMRect(
				(left - freezer.getBoundingClientRect().left - freezerBorder) / scale,
				(top - freezer.getBoundingClientRect().top - freezerBorder) / scale,
				(right - left) / scale,
				(bottom - top) / scale,
		  )
		: null;
};
