import { useState } from "react";
import { Modal, Uploader } from "src/components";
import { ModalType } from "src/components/Modals";
import { Box, Tabs, Text } from "src/elements";
import {
	baysPoggerVersionAtom,
	baysSegmentVersionAtom,
	useGenerateMinorReview,
} from "src/pages/Bays/store/atoms";
import { useOmniValue } from "src/utils/atoms";

import { minorReviewAtom, useMinorReview } from "./store/atoms";

const enum Tab {
	REMOVE,
	ADD,
}

export const ModalMinorReview = () => {
	const segmentVersion = useOmniValue(baysSegmentVersionAtom);
	const poggerVersion = useOmniValue(baysPoggerVersionAtom);
	const { addAdd, removeAdd, deleteAdd, addRemove, removeRemove, deleteRemove } = useMinorReview();
	const { generateMinorReview } = useGenerateMinorReview();

	const { add, remove } = useOmniValue(minorReviewAtom);

	const [tab, setTab] = useState(Tab.REMOVE);

	const resetFiles = () => {
		deleteRemove();
		deleteAdd();
	};

	return (
		<Modal
			modal={ModalType.MINOR_REVIEW}
			onClose={resetFiles}
			buttons={[
				{
					label: "Generate Mudmap and Planogram",
					watermark: segmentVersion + " & " + poggerVersion,
					onClick: generateMinorReview,
				},
			]}
		>
			<Box direction="column" gap="5px">
				<Text variant="h4">Upload products that will be removed or added accordingly</Text>

				<Text variant="body3">
					These are the products that are made available to the planogram.
				</Text>
			</Box>

			<Tabs
				width="100%"
				tabs={[
					{ title: "Remove Products", tab: Tab.REMOVE },
					{ title: "Add Products", tab: Tab.ADD },
				]}
				tab={tab}
				setTab={setTab}
			/>

			<Uploader
				files={tab === Tab.ADD ? add : remove}
				addFile={tab === Tab.ADD ? addAdd : addRemove}
				removeFile={tab === Tab.ADD ? removeAdd : removeRemove}
				deleteAll={tab === Tab.ADD ? deleteAdd : deleteRemove}
			/>
		</Modal>
	);
};
