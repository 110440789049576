import React from "react";
import { Box, Text } from "src/elements";
import { IconType, IconV2 } from "src/elements/IconV2";
import { inputHeight } from "src/elements/Input";
import { Color } from "src/utils";
import styled from "styled-components";

const OptionElement = styled.div<{ placeholdera?: boolean }>`
	width: 100%;
	min-height: calc(${inputHeight} - 2px);
	padding: 0 7px;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	user-select: none;
	color: ${Color.textSecondary};
	cursor: pointer;

	&:hover {
		background: ${Color.lightGray};
		color: ${Color.textMain};
	}

	&:active {
		background: ${Color.lightGrayHover};
		color: ${Color.textMain};
	}
`;

export const OptionDivider = styled.div`
	width: calc(100% - 20px);
	height: 1px;
	background: ${Color.lightGray};
	margin: 0 10px;

	&:last-of-type {
		display: none;
	}
`;

export const Option: React.FC<{
	children: React.ReactChild;
	value?: any;
	setValue?: (value: any) => void;
	active?: boolean;
	icon?: IconType | false;
	placeholder?: boolean;
}> = ({ value, setValue, active, icon, placeholder, children }) => {
	const selectOption = (event: React.MouseEvent) => {
		event.stopPropagation();

		if (value !== undefined && setValue !== undefined) {
			setValue(value);
		}
	};

	return (
		<>
			<OptionElement
				placeholdera={placeholder}
				onClick={value && setValue ? selectOption : undefined}
			>
				<Text
					variant={active ? "body1" : "body3"}
					color={active ? Color.primary : Color.textSecondary}
					textOverflow="ellipsis"
					overflow="hidden"
					whiteSpace="nowrap"
				>
					{children}
				</Text>

				{icon && (
					<Box marginTop="-2px" minWidth="14px">
						<IconV2 name={icon} />
					</Box>
				)}
			</OptionElement>

			<OptionDivider className="select-divider" />
		</>
	);
};
