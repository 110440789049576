import { SettingsDivider, SettingsSelectable } from "src/components";
import { Merchant } from "src/components/Settings/store/types";
import { StateToggle } from "src/elements";
import { isDevelopment } from "src/utils";
import { useOmniValue } from "src/utils/atoms";

import {
	baysMinStackAtom,
	baysPoggerVersionAtom,
	baysSegmentVersionAtom,
	baysShowPicturesAtom,
	baysShowTooltipsAtom,
	baysSnakingAtom,
	baysStepAtom,
	merchantAtom,
} from "../../../store/atoms";
import { BaysStep } from "../../../store/types";

export const Settings = () => {
	const step = useOmniValue(baysStepAtom);

	return (
		<>
			{/* General */}
			<StateToggle icon="tooltip" label="Tooltips" state={baysShowTooltipsAtom} />

			{/* Mudmap */}
			{step === BaysStep.MUDMAP && (
				<>
					<SettingsDivider />

					<SettingsSelectable
						icon="segment"
						title="Segment Renderer"
						options={[
							{ label: "v2-Dev", value: "v2-dev" },
							{ label: "QA", value: "qa" },
							{ label: "Demo", value: "demo" },
							...(isDevelopment ? [{ label: "Local", value: "local" }] : []),
						]}
						state={baysSegmentVersionAtom}
					/>
				</>
			)}

			{/* Planogram */}
			{step === BaysStep.PLANOGRAM && (
				<>
					<StateToggle icon="picture" label="Product Images" state={baysShowPicturesAtom} />

					<SettingsDivider />

					<StateToggle label="Snaking" state={baysSnakingAtom} />

					<StateToggle label="Stack facings" state={baysMinStackAtom} />

					<SettingsDivider />

					<SettingsSelectable
						icon="pogger"
						title="Pogger"
						options={[
							{ label: "Dev", value: "dev" },
							{ label: "QA", value: "qa" },
							{ label: "Demo", value: "demo" },
							...(isDevelopment ? [{ label: "Local", value: "local" }] : []),
						]}
						state={baysPoggerVersionAtom}
					/>

					<SettingsDivider />

					<SettingsSelectable
						icon="merchant"
						title="Merchant"
						options={[
							{ label: "Food Panda", value: Merchant.FOOD_PANDA },
							{ label: "7-Eleven", value: Merchant.SEVEN_ELEVEN },
							{ label: "Spar", value: Merchant.SPAR },
							{ label: "Coles", value: Merchant.COLES },
							{ label: "BigW", value: Merchant.BIGW },
							{ label: "HEB", value: Merchant.HEB },
							{ label: "Omni", value: Merchant.OMNI },
							{ label: "WOW", value: Merchant.WOW },
						]}
						state={merchantAtom}
					/>
				</>
			)}
		</>
	);
};
