import React from "react";
import { Box, Text } from "src/elements";
import { Color, Transition, zIndex } from "src/utils";
import styled, { StyledComponent } from "styled-components";

import { TooltipSide } from "../types";

export const TOOLTIP_TIP_SIZE = 7;

const TOOLTIP_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.85)";

const TOOLTIP_BLUR = "5px";

interface TooltipProps {
	x: number;
	y: number;
}

const TooltipElement: StyledComponent<
	"div",
	any,
	TooltipProps,
	never
> = styled.div.attrs<TooltipProps>(({ x, y }) => ({
	style: {
		left: x,
		top: y,
	},
}))`
	background: ${TOOLTIP_BACKGROUND_COLOR};
	padding: 10px;
	border-radius: 4px;
	position: fixed;
	color: ${Color.white};
	pointer-events: none;
	backdrop-filter: blur(${TOOLTIP_BLUR});
	z-index: ${zIndex.TOOLTIPS};

	&:not([data-active]) {
		opacity: 0;
	}

	&[data-active] {
		transition: left ${Transition.fast}, top ${Transition.fast};
	}

	&[data-side="bottom"][data-active] {
		animation: slide-in-up 0.1s forwards;
	}

	&[data-side="bottom"][data-active][data-hide] {
		animation: slide-out-down 0.1s forwards;
	}

	&[data-side="top"] {
	}

	&[data-side="left"] {
	}

	&[data-side="right"] {
	}

	@keyframes slide-in-up {
		from {
			opacity: 0;
			transform: translateY(5px);
		}
	}

	@keyframes slide-out-down {
		to {
			opacity: 0;
			transform: translateY(5px);
		}
	}
`;

const Content: React.FC<{ children: any }> = ({ children }) => {
	if (typeof children === "object" && !children._store) {
		return (
			<Box display="grid" gridColumns="auto 1fr" gap="5px 10px" align="center">
				{Object.entries(children).map(([key, value], index) => {
					if (typeof value === "object" || value === undefined) {
						return null;
					}

					return (
						<React.Fragment key={index}>
							<Text variant="caption2" color={Color.lightGray} align="right">
								{key}
							</Text>

							<Text variant="small1" color={Color.white} align="left">
								{String(value)}
							</Text>
						</React.Fragment>
					);
				})}
			</Box>
		);
	} else {
		return (
			<Text variant="caption2" color={Color.white}>
				{children}
			</Text>
		);
	}
};

const Tip = styled.div`
	position: absolute;
	width: 0px;
	height: 0px;
	backdrop-filter: blur(${TOOLTIP_BLUR});

	&[data-side="bottom"] {
		border-left: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-right: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-bottom: ${TOOLTIP_TIP_SIZE}px solid ${TOOLTIP_BACKGROUND_COLOR};
		top: -${TOOLTIP_TIP_SIZE}px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	&[data-side="top"] {
		border-left: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-right: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-top: ${TOOLTIP_TIP_SIZE}px solid ${TOOLTIP_BACKGROUND_COLOR};
		bottom: -${TOOLTIP_TIP_SIZE}px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	&[data-side="left"] {
		border-top: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-bottom: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-left: ${TOOLTIP_TIP_SIZE}px solid ${TOOLTIP_BACKGROUND_COLOR};
		right: -${TOOLTIP_TIP_SIZE}px;
		top: 0;
		bottom: 0;
		margin: auto 0;
	}

	&[data-side="right"] {
		border-top: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-bottom: ${TOOLTIP_TIP_SIZE}px solid transparent;
		border-right: ${TOOLTIP_TIP_SIZE}px solid ${TOOLTIP_BACKGROUND_COLOR};
		left: -${TOOLTIP_TIP_SIZE}px;
		top: 0;
		bottom: 0;
		margin: auto 0;
	}
`;

interface Props {
	x: number;
	y: number;
	side: TooltipSide | null;
	refTooltip: any;
	children: React.ReactNode;
}

export const Tooltip: React.FC<Props> = ({ x, y, side, refTooltip, children }) => (
	<TooltipElement className="tooltip" x={x} y={y} ref={refTooltip} data-side={side}>
		<Tip data-side={side} />

		<Content>{children}</Content>
	</TooltipElement>
);
