import { omniAtom } from "src/utils/atoms";

import { ContainersStep } from "../types";
import {
	Candidate,
	ColorsBy,
	ContainersFilesResponse,
	ContainersGroupsResponse,
	ContainersPlaceContainersResponse,
	ContainersPlaceVariantsResponse,
	CustomMergeRule,
	FilesFreezer,
	GroupsParameters,
} from "./types";

const prefix = "containers";

export const containersStepAtom = omniAtom({
	prefix,
	key: "step",
	value: ContainersStep.FILES,
});

export const containersFilesFixturesAtom = omniAtom({
	prefix,
	key: "files/fixtures",
	value: null as File | null,
});

export const containersFilesCandidatesAtom = omniAtom({
	prefix,
	key: "files/candidates",
	value: null as File | null,
});

export const containersFilesResponseAtom = omniAtom({
	prefix,
	key: "responses/files",
	value: null as ContainersFilesResponse | null,
});

export const containersFilesLogsAtom = omniAtom({
	prefix,
	key: "logs/files",
	value: {
		payload: null,
		response: null,
	},
});

export const containersGroupsResponseAtom = omniAtom({
	prefix,
	key: "groups",
	value: null as ContainersGroupsResponse | null,
});

export const containersGroupsLogsAtom = omniAtom({
	prefix,
	key: "logs/groupAndSizeVariants",
	value: {
		payload: null,
		response: null,
	},
});

export const containersPlaceContainersResponseAtom = omniAtom({
	prefix,
	key: "placeContainers",
	value: null as ContainersPlaceContainersResponse | null,
});

export const containersPlaceContainersPlanogramNoAtom = omniAtom({
	prefix,
	key: "placeContainers/placeContainersNo",
	value: 1,
});

export const containersLogsPlaceContainersAtom = omniAtom({
	prefix,
	key: "logs/placeContainers",
	value: {
		payload: null,
		response: null,
	},
});

export const containersPlaceVariantsResponseAtom = omniAtom({
	prefix,
	key: "placeVariants",
	value: null as ContainersPlaceVariantsResponse | null,
});

export const containersLogsPlaceVariantsAtom = omniAtom({
	prefix,
	key: "logs/placeVariants",
	value: {
		payload: null,
		response: null,
	},
});

export const containersAlgoVersionAtom = omniAtom({
	prefix,
	key: "version",
	value: "dev",
});

export const containersColorsByAtom = omniAtom({
	prefix,
	key: "colorsBy",
	value: ColorsBy.VARIANT,
});

export const containersMergeRulesAtom = omniAtom({
	prefix,
	key: "mergeRules",
	value: [] as CustomMergeRule[],
});

export const containersMergeRulesVariantsAtom = omniAtom({
	prefix,
	key: "mergeRules/variants",
	value: [] as string[],
});
