import { useEffect } from "react";
import { HorizontalDivider, Modal } from "src/components";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button, Text } from "src/elements";
import { containersMergeRulesAtom } from "src/pages/Containers/store/atoms";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { MergeRule } from "./components/MergeRule";

const Container = styled.div`
	flex-direction: column;
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
	padding: 10px;
	flex: 1;
`;

const MergeRules = styled.div`
	flex-direction: column;
	gap: 10px;
	flex: 1;
`;

const NoMergeRules = () => (
	<Box height="100px" justify="center" align="center" flex="1">
		<Text variant="h3" color={Color.spaceGrayHover}>
			No merge rules have been added
		</Text>
	</Box>
);

export const ModalMergeRules = () => {
	const mergeRules = useOmniValue(containersMergeRulesAtom);
	const { openModal } = useModals();

	const createMergeRule = () => {
		openModal(ModalType.MERGE_RULE);
	};

	return (
		<Modal modal={ModalType.MERGE_RULES} height="100%">
			<Box direction="column" padding="0 20px" gap="20px" flex="1">
				{mergeRules.length > 0 && (
					<MergeRules>
						{mergeRules.map(({ id, variants }, i) => (
							<MergeRule key={i} id={id} variants={variants} />
						))}
					</MergeRules>
				)}

				{mergeRules.length === 0 && <NoMergeRules />}

				<HorizontalDivider />

				<Box justify="center">
					<Button onClick={createMergeRule}>New Merge Rule</Button>
				</Box>
			</Box>
		</Modal>
	);
};
