import React from "react";
import { Color } from "src/utils";
import styled from "styled-components";

const SCROLLBAR_NORMAL = 6;
const SCROLLBAR_THIN = 4;

const ScrollerElement = styled.div<Props>`
	width: ${({ margin, size }) =>
		`calc(100% - ${
			margin !== undefined ? `${2 * margin}px` : size === "normal" ? "14px" : "2px"
		})`};
	height: ${({ height }) => height};
	min-height: 0;
	flex-direction: column;
	overflow-x: ${({ overflowX }) => overflowX};
	overflow-y: ${({ overflowY }) => overflowY};
	gap: ${({ gap, size }) => (gap !== undefined ? gap : size === "normal" ? "20px" : "0px")};
	border: ${({ border }) => border};
	border-radius: 4px;
	cursor: default;
	padding: ${({ padding, size }) =>
		padding !== undefined ? padding : size === "normal" ? "0 7px" : "0 4px"};
	margin: ${({ margin, size }) =>
		margin !== undefined ? `0 ${margin}px` : size === "normal" ? "0 7px" : "0 1px"};
	scrollbar-gutter: ${({ gutter }) => gutter};

	// Scrollbar style.
	::-webkit-scrollbar {
		width: ${({ size }) => (size === "normal" ? `${SCROLLBAR_NORMAL}px` : `${SCROLLBAR_THIN}px`)};
		height: ${({ size }) => (size === "normal" ? `${SCROLLBAR_NORMAL}px` : `${SCROLLBAR_THIN}px`)};
		border-radius: 10px;
	}

	::-webkit-scrollbar-track {
		background: ${({ variant }) => (variant === "primary" ? Color.white : Color.textDisabled)};
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: ${Color.primary};
		border-radius: 10px;
	}
`;

interface Props {
	height?: string;
	maxHeight?: string;
	overflowX?: string;
	overflowY?: string;
	variant?: "primary" | "secondary";
	size?: "normal" | "thin";
	gap?: string;
	padding?: string;
	margin?: number;
	border?: string;
	gutter?: "auto" | "stable both-edges";
	children?: React.ReactNode;
}

export const Scroller: React.FC<Props> = ({
	height,
	overflowX = "auto",
	overflowY = "auto",
	variant = "primary",
	size = "normal",
	gap,
	padding,
	margin,
	border,
	gutter = "stable both-edges",
	children,
}) => (
	<ScrollerElement
		className="scroller"
		height={height}
		size={size}
		overflowX={overflowX}
		overflowY={overflowY}
		variant={variant}
		border={border}
		gap={gap}
		padding={padding}
		margin={margin}
		gutter={gutter}
	>
		{children}
	</ScrollerElement>
);
