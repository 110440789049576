import { useEffect } from "react";
import { Box, Dropdown, TextInput, TextInputV2 } from "src/elements";
import { Color } from "src/utils";
import { useOmniState, useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import {
	containersAlgoVersionAtom,
	containersColorsByAtom,
	containersFilesResponseAtom,
	containersGroupsResponseAtom,
	containersPlaceContainersResponseAtom,
	containersPlaceVariantsResponseAtom,
	containersStepAtom,
} from "../../store/atoms";
import { ColorsBy } from "../../store/types";
import { ContainersStep } from "../../types";
import { Navigation } from "./components/Navigation";
import { PanelFiles, PanelGroupAndSizeVariants, PanelPlaceContainers } from "./panels";
import { PanelPlaceVariants } from "./panels/PlaceVariants";

const ControlPanelElement = styled.div`
	flex-direction: column;
	border: 1px solid ${Color.lightGray};
`;

const Row = styled.div`
	padding: 10px;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const HorizontalDivider = styled.div`
	height: 1px;
	width: 100%;
	background: ${Color.lightGray};
`;

export const ControlPanel = () => {
	const [step, setStep] = useOmniState(containersStepAtom);
	const [version, setVersion] = useOmniState(containersAlgoVersionAtom);
	const [colorsBy, setColorsBy] = useOmniState(containersColorsByAtom);

	const filesResponse = useOmniValue(containersFilesResponseAtom);
	const groupsResponse = useOmniValue(containersGroupsResponseAtom);
	const containersResponse = useOmniValue(containersPlaceContainersResponseAtom);
	const variantsResponse = useOmniValue(containersPlaceVariantsResponseAtom);

	useEffect(() => {
		if (!filesResponse) {
			setStep(ContainersStep.FILES);
		} else if (!groupsResponse) {
			setStep(ContainersStep.GROUPS);
		} else if (!containersResponse) {
			setStep(ContainersStep.PLACE_CONTAINERS);
		} else if (!variantsResponse) {
			setStep(ContainersStep.PLACE_VARIANTS);
		}
	}, [filesResponse, groupsResponse, containersResponse]);

	return (
		<ControlPanelElement>
			<Row>
				<Navigation />

				<Box gap="10px">
					{/* <Dropdown
						label="Colors by"
						width="110px"
						data={[
							{
								title: "Variant",
								value: ColorsBy.VARIANT,
							},
							{
								title: "Segment",
								value: ColorsBy.SEGMENT,
							},
							{
								title: "Block",
								value: ColorsBy.BLOCK,
							},
						]}
						value={colorsBy}
						setValue={setColorsBy}
					/> */}

					<Dropdown
						label="Version"
						width="80px"
						data={[
							{
								value: "dev",
							},
							{
								value: "29",
							},
							{
								value: "30",
							},
							{
								value: "local",
							},
						]}
						value={version}
						setValue={setVersion}
					/>
				</Box>
			</Row>

			<HorizontalDivider />

			<Row>
				{step === ContainersStep.FILES && <PanelFiles />}
				{step === ContainersStep.GROUPS && <PanelGroupAndSizeVariants />}
				{step === ContainersStep.PLACE_CONTAINERS && <PanelPlaceContainers />}
				{step === ContainersStep.PLACE_VARIANTS && <PanelPlaceVariants />}
			</Row>
		</ControlPanelElement>
	);
};
