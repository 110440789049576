import hexToRgba from "hex-to-rgba";
import { Box, IconV2, Text } from "src/elements";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { useBayPanel } from "../../store/actions";

const NewBayElement = styled.div<Props>`
	min-width: ${({ size }) => (size === "big" ? "200px" : "20px")};
	position: ${({ position }) => position};
	right: -22px;
	top: 0;
	bottom: 0;
	height: 100%;
	border-width: ${({ size }) => (size === "big" ? "1px" : "0")};
	border-style: solid;
	border-color: ${Color.opacitySky};
	border-radius: 4px;
	color: var(--color);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	transition: border-color ${Transition.hover}, background-color ${Transition.hover};

	&,
	> div {
		transition: background-color ${Transition.hover};
	}

	span {
		transition: color ${Transition.hover};
	}

	--color: ${hexToRgba(Color.primary, 0.7)};

	&:hover {
		background-color: ${Color.greenSmoke};
		--color: ${Color.primary};
	}

	&:hover:active {
		background-color: ${Color.greenSmokeActive};
	}
`;

interface Props {
	size: "small" | "big";
	position: "static" | "absolute";
}

export const NewBay: React.FC<Props> = ({ size, position }) => {
	const { open } = useBayPanel();

	return (
		<NewBayElement
			className="new-bay"
			size={size}
			position={position}
			onClick={() =>
				open({
					bay_no: 0,
					bay_height: 0,
					first_notch_y: 0,
					shelf_step: 0,
					shelves: [],
				})
			}
		>
			<Box
				background="var(--color)"
				width="18px"
				height="18px"
				borderRadius="50%"
				justify="center"
				align="center"
			>
				<IconV2 name="add" color={Color.white} />
			</Box>

			{size === "big" && (
				<Text variant="caption2" color="var(--color)" uppercase>
					Add New Bay
				</Text>
			)}
		</NewBayElement>
	);
};
