// @ts-nocheck
import ReactJson from "react-json-view";
import { Modal } from "src/components";
import { ModalType } from "src/components/Modals";
import { pageAtom } from "src/components/Page/store/atoms";
import { Box, Scroller, Tabs } from "src/elements";
import {
	containersFilesLogsAtom,
	containersGroupsLogsAtom,
	containersLogsPlaceContainersAtom,
	containersLogsPlaceVariantsAtom,
} from "src/pages/Containers/store/atoms";
import { pages } from "src/utils";
import { useOmniState, useOmniValue } from "src/utils/atoms";

import { logsTabAtom, useLogs } from "./store/atoms";
import { LogsTab } from "./types";

const getPayloadResponse = (payload: any, response: any) => ({
	payload: payload,
	response: response,
});

export const ModalLogs = () => {
	const [tab, setTab] = useOmniState(logsTabAtom);
	const page = useOmniValue(pageAtom);

	const containersLogsFiles = useOmniValue(containersFilesLogsAtom);
	const containersLogsGroupAndSizeVariants = useOmniValue(containersGroupsLogsAtom);
	const containersLogsPlaceContainers = useOmniValue(containersLogsPlaceContainersAtom);
	const containersLogsPlaceVariants = useOmniValue(containersLogsPlaceVariantsAtom);

	const {
		logs: {
			filesResponse,
			mudmapPayload,
			mudmapResponse,
			planogramPayload,
			planogramResponse,
			rebuildPayload,
			rebuildResponse,
			objectivesPayload,
			objectivesResponse,
			minorReviewPayload,
			freezersMudmapPayload,
			freezersMudmapResponse,
			freezersPlanogramPayload,
			freezersPlanogramResponse,
		},
	} = useLogs();

	return (
		<Modal modal={ModalType.LOGS} height="100%" width="900px">
			<Box padding="0 20px" justify="center">
				<Tabs
					width="100%"
					tabs={
						page === pages.bays
							? [
									{ tab: LogsTab.FILES },
									{ tab: LogsTab.MUDMAP },
									{ tab: LogsTab.PLANOGRAM },
									{ tab: LogsTab.REBUILD },
									{ tab: LogsTab.OBJECTIVES },
									{ tab: LogsTab.MINOR_REVIEW },
							  ]
							: page === pages.freezers
							? [
									{ tab: LogsTab.FREEZERS_MUDMAP, title: "Mudmap" },
									{ tab: LogsTab.FREEZERS_PLANOGRAM, title: "Planogram" },
							  ]
							: [
									{
										tab: LogsTab.CONTAINERS_FILES,
										title: "Files",
									},
									{
										tab: LogsTab.CONTAINERS_GROUP_AND_SIZE_VARIANTS,
										title: "Groups",
									},
									{
										tab: LogsTab.CONTAINERS_PLACE_CONTAINERS,
										title: "Place Containers",
									},
									{
										tab: LogsTab.CONTAINERS_PLACE_VARIANTS,
										title: "Place Variants",
									},
							  ]
					}
					tab={tab}
					setTab={setTab}
				/>
			</Box>

			<Scroller>
				<Box display={tab !== LogsTab.FILES ? "none" : undefined}>
					<ReactJson src={{ response: filesResponse }} collapsed={1} />
				</Box>

				<Box display={tab !== LogsTab.MUDMAP ? "none" : undefined}>
					<ReactJson src={getPayloadResponse(mudmapPayload, mudmapResponse)} collapsed={1} />
				</Box>

				<Box display={tab !== LogsTab.PLANOGRAM ? "none" : undefined}>
					<ReactJson src={getPayloadResponse(planogramPayload, planogramResponse)} collapsed={1} />
				</Box>

				<Box display={tab !== LogsTab.REBUILD ? "none" : undefined}>
					<ReactJson src={getPayloadResponse(rebuildPayload, rebuildResponse)} collapsed={1} />
				</Box>

				<Box display={tab !== LogsTab.OBJECTIVES ? "none" : undefined}>
					<ReactJson
						src={getPayloadResponse(objectivesPayload, objectivesResponse)}
						collapsed={1}
					/>
				</Box>

				<Box display={tab !== LogsTab.MINOR_REVIEW ? "none" : undefined}>
					<ReactJson src={{ payload: minorReviewPayload }} collapsed={1} />
				</Box>

				<Box display={tab !== LogsTab.FREEZERS_MUDMAP ? "none" : undefined}>
					<ReactJson
						src={getPayloadResponse(freezersMudmapPayload, freezersMudmapResponse)}
						collapsed={1}
					/>
				</Box>

				<Box display={tab !== LogsTab.FREEZERS_PLANOGRAM ? "none" : undefined}>
					<ReactJson
						src={getPayloadResponse(freezersPlanogramPayload, freezersPlanogramResponse)}
						collapsed={1}
					/>
				</Box>

				<Box display={tab !== LogsTab.CONTAINERS_FILES ? "none" : "flex"}>
					<ReactJson
						src={getPayloadResponse(containersLogsFiles.payload, containersLogsFiles.response)}
						collapsed={2}
					/>
				</Box>

				<Box display={tab !== LogsTab.CONTAINERS_GROUP_AND_SIZE_VARIANTS ? "none" : "flex"}>
					<ReactJson
						src={getPayloadResponse(
							containersLogsGroupAndSizeVariants.payload,
							containersLogsGroupAndSizeVariants.response,
						)}
						collapsed={2}
					/>
				</Box>

				<Box display={tab !== LogsTab.CONTAINERS_PLACE_CONTAINERS ? "none" : "flex"}>
					<ReactJson
						src={getPayloadResponse(
							containersLogsPlaceContainers.payload,
							containersLogsPlaceContainers.response,
						)}
						collapsed={2}
					/>
				</Box>

				<Box display={tab !== LogsTab.CONTAINERS_PLACE_VARIANTS ? "none" : "flex"}>
					<ReactJson
						src={getPayloadResponse(
							containersLogsPlaceVariants.payload,
							containersLogsPlaceVariants.response,
						)}
						collapsed={2}
					/>
				</Box>
			</Scroller>
		</Modal>
	);
};
