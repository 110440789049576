import { Scroller } from "src/elements";
import { freezersMudmapAtom, freezersPlanogramAtom, freezersStepAtom } from "src/store/freezers";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { ControlPanel } from "./components/ControlPanel/ControlPanel";
import { FreezersTab } from "./components/ControlPanel/types";
import { Freezers as FreezersElement } from "./components/Freezers/Freezers";

const FreezersInner = styled.div<{ background: boolean }>`
	flex: 1;
	min-height: 0;
	width: 100%;
	background: ${({ background }) => background && "url(freezers.svg)"};
	background-size: cover;
	background-position: center;
`;

export const Freezers = () => {
	const step = useOmniValue(freezersStepAtom);

	const freezersMudmap = useOmniValue(freezersMudmapAtom);
	const freezersPlanogram = useOmniValue(freezersPlanogramAtom);

	return (
		<>
			<ControlPanel />

			<FreezersInner
				background={
					(step === FreezersTab.MUDMAP && freezersMudmap) ||
					(step === FreezersTab.PLANOGRAM && (freezersMudmap || freezersPlanogram))
						? false
						: true
				}
			>
				<Scroller height="100%">
					{step === FreezersTab.MUDMAP && freezersMudmap && (
						<FreezersElement data={freezersMudmap} type="mudmap" />
					)}

					{step === FreezersTab.PLANOGRAM && freezersMudmap && !freezersPlanogram && (
						<FreezersElement data={freezersMudmap} type="mudmap" />
					)}

					{step === FreezersTab.PLANOGRAM && freezersPlanogram && (
						<FreezersElement data={freezersPlanogram} type="planogram" />
					)}
				</Scroller>
			</FreezersInner>
		</>
	);
};
