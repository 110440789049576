import React, { useMemo } from "react";
import { usePog } from "src/components/Bays/store/actions";
import {
	onUnrangedItemMouseDown,
	onUnrangedPanelMouseEnter,
	onUnrangedPanelMouseLeave,
} from "src/components/Bays/utils";
import { UNRRANGED_ITEMS } from "src/components/Bays/utils/classes";
import { Box, Scroller, Search, Text } from "src/elements";
import { useDebounce } from "src/hooks";
import { Color, Transition, tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { baysShowTooltipsAtom, baysShowUnrangedItemsAtom } from "../../store/atoms";
import { UnrangedItem } from "./components/UnrangedItem";

const UnrangedItemsContainer = styled.div`
	width: 315px;
	min-width: 315px;
	overflow: hidden;
	transition: width ${Transition.hover}, min-width ${Transition.hover};
	border-radius: 4px;

	&:not([data-open]) {
		width: 0;
		min-width: 0;
		pointer-events: none;
	}
`;

const UnrangedItemsInner = styled.div`
	width: 300px;
	min-width: 300px;
	flex-direction: column;
	gap: 10px;
	background: ${Color.opacitySky};
	border: 1px solid ${Color.opacitySkyHover};
	border-radius: 4px;
	margin-left: 15px;
`;

const DraggableBox = styled.div`
	min-height: 0;
	margin-left: -1px;
	flex: 1;
	align-items: flex-start;
	border-radius: 5px;
	border: 1px dashed transparent;
	transition-property: background-color, border-color, fill, color;
	transition-duration: 0.1s;

	&[data-dropping] {
		background: ${Color.greenSmoke};
		border-color: ${Color.greenSmokeActive};
	}
`;

export const UnrangedItems: React.FC = () => {
	const { pog, dragAndDrop } = usePog();

	const showUnrangedItems = useOmniValue(baysShowUnrangedItemsAtom);
	const showTooltips = useOmniValue(baysShowTooltipsAtom);

	const [search, setSearch] = useDebounce("", 200);

	const filteredUnrangedItems = useMemo(() => {
		const searchLowercase = search.toLocaleLowerCase();

		return pog?.meta.unranged_items?.filter(
			item =>
				item.item_id.toLowerCase().includes(searchLowercase) ||
				item.cdt1.toLowerCase().includes(searchLowercase) ||
				item.cdt2.toLowerCase().includes(searchLowercase),
		);
	}, [pog, search]);

	return (
		<UnrangedItemsContainer {...(showUnrangedItems && { "data-open": "" })}>
			<UnrangedItemsInner>
				<Box height="100%" padding="10px 0" gap="10px" direction="column" flex="1">
					<Box padding="0 10px" direction="column" gap="5px">
						<Text variant="body1" color={Color.textMain}>
							Unranged Items
						</Text>

						<Text variant="caption1" color={Color.textSecondary}>
							Add or remove items from the planogram by dragging them from this panel inside the
							planogram view
						</Text>
					</Box>

					<Box padding="0 10px">
						<Search width="100%" onChange={setSearch} />
					</Box>

					<DraggableBox
						className={UNRRANGED_ITEMS}
						onMouseEnter={onUnrangedPanelMouseEnter}
						onMouseLeave={onUnrangedPanelMouseLeave}
					>
						<Scroller height="100%" size="thin" gap="10px">
							{filteredUnrangedItems?.map((item, index) => (
								<UnrangedItem
									key={index}
									item={item}
									onMouseDown={event => {
										onUnrangedItemMouseDown(event, dragAndDrop as any);
									}}
									onMouseEnter={event =>
										showTooltips &&
										tooltip({
											event,
											data: {
												item_id: item.item_id,
												cdt1: item.cdt1,
												cdt2: item.cdt2,
												cdt3: item.cdt3,
												width: item.width,
												height: item.height,
												depth: item.depth,
												orientation: item.orientation,
												rotated: item.rotated,
												num_rows: item.num_rows,
												facings: item.facings,
											},
										})
									}
								/>
							))}
						</Scroller>
					</DraggableBox>
				</Box>
			</UnrangedItemsInner>
		</UnrangedItemsContainer>
	);
};
