import { postRequest } from "src/utils";

const { REACT_APP_WB_BACKEND: URL_BACKEND } = process.env;

export const postDndOptimizeReq = (data: any) => postRequest(`${URL_BACKEND}/dnd/optimize`, data);

export const postWorkbenchFilesReq = (data: any) =>
	postRequest(`${URL_BACKEND}/bays/files`, data, {}, "multipart/form-data");

export const postWorkbenchGenerateMudmapReq = (data: any) =>
	postRequest(`${URL_BACKEND}/bays/mudmap`, data, {}, "multipart/form-data");

export const postWorkbenchGeneratePlanogramReq = (data: any) =>
	postRequest(`${URL_BACKEND}/bays/planogram`, data, {}, "multipart/form-data");

export const postWorkbenchRebuildPlanogramReq = (data: any) =>
	postRequest(`${URL_BACKEND}/planograms/rebuild`, data, {}, "multipart/form-data");

export const postWorkbenchGenerateMinorReviewReq = (data: any) =>
	postRequest(`${URL_BACKEND}/workbench/minor-review`, data, {}, "multipart/form-data");

export const postWorkbenchCalculateObjectivesReq = (data: any) =>
	postRequest(`${URL_BACKEND}/workbench/objectives`, data, {}, "multipart/form-data");
