import { Box, Text } from "src/elements";
import { Color } from "src/utils";

interface Props {
	title: string;
	value: number;
}

export const Stat: React.FC<Props> = ({ title, value }) => (
	<Box gap="15px">
		<Box width="2px" height="100%" background={Color.textDisabled} />

		<Box direction="column">
			<Text variant="caption1" color={Color.textSecondary} uppercase>
				{title}:
			</Text>

			<Text variant="h5" color={Color.textMain}>
				{(value * 100).toString().replaceAll(/(\.\d{2})\d*/g, "$1")}%
			</Text>
		</Box>
	</Box>
);
