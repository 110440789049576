import { useSetOmniValue } from "src/utils/atoms";

import { ToastType } from "../types/types";
import { atomToasts } from "./atoms";

// Default auto hide time in ms.
const TOAST_AUTO_HIDE = 5000;

// Counter to get unique id for a new toast.
let TOAST_ID_COUNTER = 0;

export const useToasts = () => {
	const setToasts = useSetOmniValue(atomToasts);

	const toast: (toast: {
		id?: string;
		title: string;
		description?: string | unknown;
		autoHide?: number;
		type?: ToastType;
	}) => void = ({ id, title, description, autoHide, type }) => {
		if (type === ToastType.ERROR) {
			console.error((description as any)?.response?.data?.error || description);
		}

		description =
			(typeof description === "object" &&
				((description as any).response?.data?.error || (description as any).response?.data)) ||
			description;
		description = typeof description === "object" ? JSON.stringify(description) : description;

		const idCleaned = id ?? ++TOAST_ID_COUNTER;

		setToasts(toasts => [
			...toasts,
			{
				id: idCleaned,
				title,
				description: description as string,
				autoHide: type === ToastType.PENDING ? 0 : autoHide ?? TOAST_AUTO_HIDE,
				hidden: false,
				type: type ?? ToastType.SUCCESS,
			},
		]);
	};

	const hideToast = (id: number | string) => {
		setToasts(toasts =>
			toasts.map(toast => ({
				...toast,
				hidden: toast.id === id ? true : toast.hidden,
			})),
		);
	};

	return { toast, hideToast };
};
