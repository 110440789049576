// @ts-nocheck
import { DevTools as JotaiDevTools } from "jotai-devtools";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import { isDevelopment } from "./utils";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	<BrowserRouter>
		<App />
		{isDevelopment && <JotaiDevTools />}
	</BrowserRouter>,
);
