import axios from "axios";
import { getLoginToken } from "src/store/auth";

const axiosInstance = axios.create();

enum MethodEnum {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	DELETE = "DELETE",
}

const makeRequest = async (
	url: string,
	method: MethodEnum,
	data: Record<string, any> = {},
	responseType: any = "",
	params: Record<string, any> = {},
	contentType:
		| "application/json"
		| "x-www-form-urlencoded"
		| "multipart/form-data" = "application/json",
) => {
	const headers = {
		"Content-Type": contentType,
	};

	if (getLoginToken()) {
		headers["Authorization"] = `Bearer ${getLoginToken()}`;
	}

	return axiosInstance({
		url,
		method,
		data,
		headers,
		responseType,
		params,
	});
};

const getRequest = (url: string, params?: any, responseType = "") =>
	makeRequest(url, MethodEnum.GET, {}, responseType, params);

const postRequest = (
	url: string,
	data: Record<string, any>,
	params?: Record<string, any>,
	contentType?: "x-www-form-urlencoded" | "multipart/form-data",
) => makeRequest(url, MethodEnum.POST, data, "", params, contentType);

const putRequest = (url: string, data: any, params?: Record<string, any>) =>
	makeRequest(url, MethodEnum.PUT, data, "", params);

const deleteRequest = (url: string) => makeRequest(url, MethodEnum.DELETE);

export { getRequest, postRequest, putRequest, deleteRequest };
