import React from "react";
import { baysShowTooltipsAtom } from "src/pages/Bays/store/atoms";
import { PlanogramItem as PlanogramItemType } from "src/types";
import { Color, tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled, { StyledComponent } from "styled-components";

import { useItemPanel, usePog } from "../store/actions";
import { baysEditModeAtom } from "../store/atoms";
import { onPlanogramItemMouseDown } from "../utils";
import { PLANOGRAM_FACING } from "../utils/classes";

interface ItemProps {
	facings: number;
	rows: number;
}

const ItemElement: StyledComponent<"div", any, ItemProps, never> = styled.div.attrs<ItemProps>(
	({ facings, rows }) => ({
		style: {
			gridTemplateColumns: `repeat(${facings / rows}, auto)`,
			gridTemplateRows: `repeat(${rows}, auto)`,
		},
	}),
)`
	height: min-content;
	position: relative;
	display: grid;
	grid-auto-flow: column;
	user-select: none;
	pointer-events: all;
	user-select: none;
	cursor: grab;

	* {
		pointer-events: none;
	}

	&[data-editable] {
		cursor: pointer;
	}

	&[data-dragging] {
		position: fixed;
		pointer-events: none;
		border: none;
		transform-origin: top left;
		transform: scale(var(--zoom));
		z-index: 3;
	}

	&[data-placeholder] {
		.${PLANOGRAM_FACING} {
			visibility: hidden;
		}

		::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: calc(100% - 2px);
			height: calc(100% - 2px);
			background-color: ${Color.greenSmoke};
			border: 1px dashed ${Color.greenSmokeActive};
			border-radius: 2px;
		}
	}

	&[data-hidden] {
		display: none;
	}
`;

interface Props {
	item: PlanogramItemType;
	editable?: boolean;
	children: React.ReactNode;
}

export const PlanogramItem: React.FC<Props> = ({ item, editable = false, children }) => {
	const showTooltips = useOmniValue(baysShowTooltipsAtom);
	const editMode = useOmniValue(baysEditModeAtom);

	const { dragAndDrop } = usePog();
	const { toggle } = useItemPanel();

	const coordinates = {
		screenX: 0,
		screenY: 0,
	};

	return (
		<ItemElement
			className="planogram-item"
			data-id={item.item_id}
			facings={item.facings}
			rows={item.num_rows}
			onMouseEnter={event =>
				showTooltips &&
				tooltip({
					event,
					data: {
						Id: item.item_id,
						Segment: item.cdt1,
						Block: item.cdt2,
						Width: item.width,
						Height: item.height,
						Depth: item.depth,
						Orientation: item.orientation,
						Rotated: item.rotated,
						Rows: item.num_rows,
						Facings: item.facings,
					},
				})
			}
			onPointerDown={event => {
				coordinates.screenX = event.screenX;
				coordinates.screenY = event.screenY;

				onPlanogramItemMouseDown(event, dragAndDrop);
			}}
			onClick={() => {
				if (editMode) {
					toggle(item);
				}
			}}
			{...(editMode && editable && { "data-editable": "" })}
		>
			{children}
		</ItemElement>
	);
};
