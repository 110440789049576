import { Box } from "src/elements";
import styled from "styled-components";

import { FilesFreezer, Group as GroupType, PlanogramFreezer } from "../../store/types";
import { Group } from "./Group";
import { Section } from "./Section";

const ContainerElement = styled.div<{ width: number; height: number }>`
	position: relative;
	width: ${({ width }) => `calc(var(--scale) * ${width}px)`};
	height: ${({ height }) => `calc(var(--scale) * ${height}px)`};
	background-color: rgba(0, 0, 100, 0.1);
`;

interface Props {
	freezer: FilesFreezer | PlanogramFreezer;
	cumulativeShelfHeights: number[];
	groups?: GroupType[];
}

export const Container: React.FC<Props> = ({ freezer, cumulativeShelfHeights, groups }) => {
	return (
		<ContainerElement className="container" width={freezer.width} height={freezer.height}>
			{freezer.sections.map((section, i) => (
				<Section key={i} section={section} />
			))}

			{groups?.map((group, i) => (
				<Group key={i} group={group} />
			))}
		</ContainerElement>
	);
};
