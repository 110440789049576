import React, { useState } from "react";
import styled from "styled-components";

import { InputElement, InputOuterContainer } from "./components/Input";
import { Label } from "./components/Label";
import { NumberArrows } from "./components/NumberArrows";
import { NumberInputProps } from "./types";

const InputNumberContainer = styled.div<{ width?: string }>`
	position: relative;
	width: ${({ width }) => width};

	input {
		padding-right: 16px;
	}
`;

interface Props extends NumberInputProps {
	value: number;
	setValue: (value: number) => void;
}

export const NumberInputV2: React.FC<Props> = ({
	value,
	setValue,
	label,
	min,
	max,
	increment = 1,
	placeholder,
	disabled,
	width,
}) => {
	const [hovered, setHovered] = useState(false);
	const [focused, setFocused] = useState(false);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(Number(event.target.value));
	};

	const onFocus = () => {
		setFocused(true);
	};

	const onBlur = () => {
		setFocused(false);
	};

	const onEnter = () => {
		setHovered(true);
	};

	const onLeave = () => {
		setHovered(false);
	};

	return (
		<InputOuterContainer
			label={label}
			width={width}
			onPointerEnter={onEnter}
			onPointerLeave={onLeave}
		>
			<InputNumberContainer>
				<InputElement
					type="number"
					min={min}
					max={max}
					placeholder={placeholder}
					value={value}
					disabled={disabled}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
				/>

				<NumberArrows
					visible={hovered}
					value={value}
					setValue={setValue}
					min={min}
					max={max}
					increment={increment}
				/>

				<Label label={label} active={focused} />
			</InputNumberContainer>
		</InputOuterContainer>
	);
};
