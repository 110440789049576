import React, { RefObject, useMemo, useState } from "react";
import { Box, Checkbox, IconV2, Scroller, Search, Text } from "src/elements";
import { inputHeight } from "src/elements/Input";
import { Color } from "src/utils";
import styled from "styled-components";

import { useCoreRangeFilters } from "../../store/atoms";
import { FilterCategory as FilterCategoryType } from "./types";

const FiltersContainer = styled.div`
	position: fixed;
	z-index: 1;
	user-select: none;

	&:not([data-open]) {
		display: none;
	}
`;

const FiltersElement = styled.div`
	min-width: 160px;
	border: 1px solid ${Color.primary};
	border-radius: 3px;
	background-color: ${Color.white};
	flex-direction: column;
`;

const FilterCategory = styled.div<{ onClick?: () => void }>`
	position: relative;
	min-height: ${inputHeight};
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	padding: 0 3px 0 10px;
	color: ${Color.textSecondary};

	&:last-of-type {
		border-radius: 0 0 3px 3px;
	}

	${({ onClick }) =>
		onClick
			? `
   cursor: pointer;

	&:hover {
		background-color: ${Color.greenSmoke};
		color: ${Color.primary};

		.expand path { fill: ${Color.primary};}
	}
   &:not(:hover) {
		.subfilters {
      	display: none;
   	}

		.expand path { fill: ${Color.spaceGray};}
	}
   `
			: "padding-right: 10px; cursor: default;"};
`;

const Divider = styled.div`
	min-height: 1px;
	width: calc(100% - 20px);
	margin-left: 10px;
	background-color: ${Color.lightGray};
`;

const SubfiltersContainer = styled.div`
	position: absolute;
	top: -40px;
	left: 100%;
	padding: 40px 40px 40px 3px;
	cursor: default;
`;

const Subfilters = styled.div`
	max-height: 400px;
	min-width: 200px;
	background-color: ${Color.white};
	border: 1px solid ${Color.primary};
	border-radius: 3px;
	flex-direction: column;
	overflow: hidden;
`;

const Filter = styled.div`
	position: relative;
	min-height: ${inputHeight};
	padding: 0 10px;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	color: ${Color.textSecondary};
	cursor: pointer;

	&:hover {
		background-color: ${Color.greenSmoke};
		color: ${Color.textMain};
	}
`;

const ActiveFilterCount: React.FC<{ count: number }> = ({ count }) =>
	count === 0 ? null : (
		<Box
			minWidth="16px"
			minHeight="16px"
			justify="center"
			align="center"
			alignSelf="center"
			background={Color.primary}
			borderRadius="2px"
		>
			<Text variant="caption3" color={Color.white} align="center">
				{count}
			</Text>
		</Box>
	);

interface Props {
	categories: FilterCategoryType[];
	data: any[];
	filterItems: any[];
	refFilters: RefObject<HTMLDivElement>;
}

export const Filters: React.FC<Props> = ({ categories, data, filterItems, refFilters }) => {
	const [search, setSearch] = useState("");
	const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
	const { coreRangeFilters, addFilter, removeFilter } = useCoreRangeFilters();

	// Find all possible filters.
	const filters = useMemo(() => {
		const filters: { [column: string]: string[] } = {};

		for (const category of categories) {
			const values = [...new Set(data.map(item => item[category.column]))];
			if (values.length > 1) {
				filters[category.column] = values;
			}
		}

		return filters;
	}, [categories]);

	// Find all filterable value counts.
	const filtersCounts = useMemo(() => {
		const filtersCounts: { [column: string]: { [value: string]: number }[] } = {};
		for (const filterableColumn of Object.keys(filters)) {
			for (const filterableValue of filters[filterableColumn]) {
				const filteredItem: { [value: string]: number } = {};
				if (filteredColumns.includes(filterableColumn)) {
					filteredItem[filterableValue] = data.filter(item => {
						for (const column of Object.keys(coreRangeFilters)) {
							if (column === filterableColumn) {
								if (item[column] !== filterableValue) {
									return false;
								}
								continue;
							}
							if (!coreRangeFilters[column]?.includes(item[column])) {
								return false;
							}
						}

						return true;
					}).length;
				} else {
					filteredItem[filterableValue] = filterItems.filter(
						item => item[filterableColumn] === filterableValue,
					).length;
				}
				if (filtersCounts[filterableColumn]) {
					filtersCounts[filterableColumn].push(filteredItem);
				} else {
					filtersCounts[filterableColumn] = [filteredItem];
				}
			}
		}

		return filtersCounts;
	}, [filteredColumns]);

	const addLocalFilter = (column: string, value: string) => {
		addFilter(column, value);
		setFilteredColumns([...filteredColumns, column]);
	};

	const removeLocalFilter = (column: string, value: string) => {
		removeFilter(column, value);
		const columns = [...filteredColumns];
		columns.splice(columns.indexOf(column), 1);
		setFilteredColumns(columns);
	};

	return (
		<FiltersContainer ref={refFilters}>
			<FiltersElement>
				<FilterCategory>
					<Text variant="body2" color={Color.textMain}>
						Choose Filter
					</Text>
				</FilterCategory>
				{Object.keys(filtersCounts)
					.sort()
					.map((filterableColumn, index) => (
						<React.Fragment key={index}>
							<Divider />

							<FilterCategory onClick={() => {}}>
								<Box gap="5px" align="center">
									<Text variant="body3" color="inherit">
										{categories.find(c => c.column === filterableColumn)?.title}
									</Text>

									<ActiveFilterCount
										count={filteredColumns.filter(column => column === filterableColumn).length}
									/>
								</Box>

								<Box align="center">
									<Text variant="caption2" color="inherit">
										{Object.values(filtersCounts[filterableColumn])
											.map(filterableEntry => Object.values(filterableEntry)[0])
											.reduce((sum, count) => sum + count, 0)}
									</Text>

									<Box className="expand" rotate={90} marginTop="1px">
										<IconV2 name="expandUp" />
									</Box>
								</Box>

								<SubfiltersContainer className="subfilters">
									<Subfilters>
										<Box padding="10px">
											<Search onChange={setSearch} />
										</Box>

										<Scroller gutter="auto" size="thin" gap="0" padding="0" margin={0}>
											{(() => {
												const filteredFilters = filtersCounts[filterableColumn].filter(
													filterableEntry => {
														if (!search) return true;

														const filterableValue = Object.keys(filterableEntry)[0];
														return filterableValue
															.toLocaleLowerCase()
															.includes(search.toLocaleLowerCase());
													},
												);

												if (filteredFilters.length > 0) {
													return (
														<>
															{Object.values(filteredFilters)
																.sort((entryA, entryB) => {
																	const valueA: string = Object.keys(entryA)[0];
																	const valueB: string = Object.keys(entryB)[0];
																	return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
																})
																.map((filterableEntry, index) => {
																	const filterableValue = Object.keys(filterableEntry)[0];
																	const filterableCount = Object.values(filterableEntry)[0];

																	return (
																		<React.Fragment key={index}>
																			<Divider />

																			<Filter>
																				<Box gap="5px" align="center">
																					<Checkbox
																						useParent
																						checked={
																							coreRangeFilters[filterableColumn]?.includes(
																								filterableValue,
																							) || false
																						}
																						setChecked={checked => {
																							if (checked) {
																								addLocalFilter(filterableColumn, filterableValue);
																							} else {
																								removeLocalFilter(
																									filterableColumn,
																									filterableValue,
																								);
																							}
																						}}
																					/>
																					<Text variant="body3" color="inherit" whiteSpace="nowrap">
																						{filterableValue}
																					</Text>
																				</Box>

																				<Text variant="caption2" color="inherit">
																					{filterableCount}
																				</Text>
																			</Filter>
																		</React.Fragment>
																	);
																})}
														</>
													);
												} else {
													return (
														<Box padding="0 10px 10px 10px" justify="center">
															<Text variant="body3" color={Color.spaceGrayHover} align="center">
																No Filters
															</Text>
														</Box>
													);
												}
											})()}
										</Scroller>
									</Subfilters>
								</SubfiltersContainer>
							</FilterCategory>
						</React.Fragment>
					))}
			</FiltersElement>
		</FiltersContainer>
	);
};
