import { useAtom } from "jotai";
import { PlanogramBay, PlanogramItem, PlanogramShelf } from "src/types";

import { DragAndDropPayload, HoverSide } from "../types";
import { baysPanelBayAtom, baysPanelItemAtom, baysPanelShelfAtom, baysPogAtom } from "./atoms";

export const usePog = () => {
	const [pog, setPog] = useAtom(baysPogAtom);

	const dragAndDrop = (payload: DragAndDropPayload) => {
		setPog(pog => {
			if (!pog) return pog;

			const { currentItem, targetItem } = payload;

			// Current items.
			const currentFoundItems =
				currentItem.bayNo !== "unranged" && currentItem.shelfNo !== "unranged"
					? // Search from planogram
					  pog.planogram.bays
							.find(bay => bay.bay_no === currentItem.bayNo)
							?.shelves.find(shelf => shelf.shelf_no === currentItem.shelfNo)?.items
					: // Search from unranged items.
					  pog.meta.unranged_items;
			if (!currentFoundItems) return pog;

			// Current item index.
			const currentFoundItemIndex = currentFoundItems.findIndex(
				item => item.item_id === currentItem.id,
			);
			if (currentFoundItemIndex === -1) return pog;

			// Current item.
			const currentFoundItem = currentFoundItems.at(currentFoundItemIndex);
			if (!currentFoundItem) return pog;

			// Remove item from current items if it was in the planogram.
			currentFoundItems.splice(currentFoundItemIndex, 1);

			// Item is moved to unranged items, reset rows and facings to 1.
			if (targetItem === "unranged") {
				pog.meta.unranged_items.unshift({
					...currentFoundItem,
					num_rows: 1,
					facings: 1,
				});
				return { ...pog };
			}

			// Find planogram target items.
			const targetFoundItems = pog.planogram.bays
				.find(bay => bay.bay_no === targetItem.bayNo)
				?.shelves.find(shelf => shelf.shelf_no === targetItem.shelfNo)?.items;
			if (!targetFoundItems) return pog;

			// Item is moved to empty shelf.
			if (targetItem.id === "empty" || targetItem.side === "empty") {
				targetFoundItems?.push(currentFoundItem);
				return { ...pog };
			}

			// Find index of item where to move.
			const targetFoundItemIndex = targetFoundItems?.findIndex(
				item => item.item_id === targetItem.id,
			);
			if (targetFoundItemIndex === -1) return pog;

			// Item is moved to next to other item.
			targetFoundItems.splice(
				targetFoundItemIndex + (targetItem.side === HoverSide.RIGHT ? 1 : 0),
				0,
				currentFoundItem,
			);

			return { ...pog };
		});
	};

	const saveBay = (payload: PlanogramBay) => {
		setPog(state => {
			if (!state) return state;
			const pog = { ...state };

			const bay = pog.planogram.bays.find(bay => bay.bay_no === payload.bay_no);

			// Editing previous bay.
			if (bay) {
				for (let key in payload) {
					bay[key] = payload[key];
				}
			}

			// Adding new bay.
			if (!bay) {
				pog.planogram.bays.push({
					...payload,
					bay_no: pog.planogram.bays.length
						? pog.planogram.bays[pog.planogram.bays.length - 1].bay_no + 1
						: 1,
				});
			}

			return pog;
		});
	};

	const saveShelf = (bayNo: number, payload: PlanogramShelf) => {
		setPog(state => {
			if (!state) return state;

			const pog = { ...state };
			const bay = pog.planogram.bays.find(bay => bay.bay_no === bayNo);

			if (!bay) return state;
			const shelf = bay.shelves.find(shelf => shelf.shelf_no === payload.shelf_no);

			// Editing previous shelf.
			if (shelf) {
				for (let key in payload) {
					shelf[key] = payload[key];
				}
			}

			// Adding new shelf.
			if (!shelf) {
				bay.shelves.push({
					...payload,
					shelf_no: bay.shelves.length ? bay.shelves[bay.shelves.length - 1].shelf_no + 1 : 1,
				});
			}

			return pog;
		});
	};

	const saveItem = (payload: PlanogramItem) => {
		const { item_id } = payload;

		setPog(pog => {
			if (!pog) return pog;

			for (const bay of pog.planogram.bays) {
				for (const shelf of bay.shelves) {
					for (const item of shelf.items) {
						if (item.item_id === item_id) {
							for (let k in payload) {
								item[k] = payload[k];
							}
						}
					}
				}
			}

			return { ...pog };
		});
	};

	const deleteBay = (bayNo: number) => {
		setPog(status => {
			if (!status) return status;

			const pog = { ...status };

			const index = pog.planogram.bays.findIndex(bay => bay.bay_no === bayNo);
			if (index !== -1) {
				pog.planogram.bays.splice(index, 1);
			}

			return pog;
		});
	};

	const deleteShelf = (bayNo: number, shelfNo: number) => {
		setPog(status => {
			if (!status) return status;
			const pog = { ...status };

			const bay = pog.planogram.bays.find(bay => bay.bay_no === bayNo);
			if (!bay) return pog;

			const index = bay.shelves.findIndex(shelf => shelf.shelf_no === shelfNo);
			if (index !== -1) {
				bay.shelves.splice(index, 1);
			}

			return pog;
		});
	};

	return { pog, setPog, dragAndDrop, saveBay, saveShelf, saveItem, deleteBay, deleteShelf };
};

export const useBayPanel = () => {
	const [panel, setPanel] = useAtom(baysPanelBayAtom);

	const close = () => {
		setPanel(state => ({ ...state, open: false }));
	};

	const open = (bay: PlanogramBay | null) => {
		// Duplicate data, so we can modify it without interfering with original data.
		setPanel({ bay: JSON.parse(JSON.stringify(bay)), open: true, modified: false });
	};

	const toggle = (bay: PlanogramBay | null) => {
		panel.open ? close() : open(bay);
	};

	const setModified = () => {
		if (!panel.modified) {
			setPanel(state => ({ ...state, modified: true }));
		}
	};

	return {
		bay: panel.bay,
		isOpen: panel.open,
		open,
		close,
		toggle,
		modified: panel.modified,
		setModified,
	};
};

export const useShelfPanel = () => {
	const [panel, setPanel] = useAtom(baysPanelShelfAtom);

	const close = () => {
		setPanel(state => ({ ...state, open: false }));
	};

	const open = (bayNo: number, shelf: PlanogramShelf | null) => {
		// Duplicate data, so we can modify it without interfering with original data.
		setPanel({ bayNo, shelf: JSON.parse(JSON.stringify(shelf)), open: true, modified: false });
	};

	const toggle = (bayNo: number, shelf: PlanogramShelf | null) => {
		panel.open ? close() : open(bayNo, shelf);
	};

	const setModified = () => {
		if (!panel.modified) {
			setPanel(state => ({ ...state, modified: true }));
		}
	};

	return {
		bayNo: panel.bayNo,
		shelf: panel.shelf,
		isOpen: panel.open,
		open,
		close,
		toggle,
		modified: panel.modified,
		setModified,
	};
};

export const useItemPanel = () => {
	const [panel, setPanel] = useAtom(baysPanelItemAtom);

	const close = () => {
		setPanel(state => ({ ...state, open: false }));
	};

	const open = (item: PlanogramItem | null) => {
		// Duplicate data, so we can modify it without interfering with original data.
		setPanel({ item: JSON.parse(JSON.stringify(item)), open: true, modified: false });
	};

	const toggle = (item: PlanogramItem | null) => {
		panel.open ? close() : open(item);
	};

	const setModified = () => {
		if (!panel.modified) {
			setPanel(state => ({ ...state, modified: true }));
		}
	};

	return {
		item: panel.item,
		isOpen: panel.open,
		open,
		close,
		toggle,
		modified: panel.modified,
		setModified,
	};
};
