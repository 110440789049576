import { ShelfType } from "src/types";
import { Color } from "src/utils";
import styled, { StyledComponent } from "styled-components";

interface ShelfThicknessProps {
	readonly width: number;
	readonly thickness: number;
	readonly type: ShelfType;
	readonly editMode: boolean;
}

export const ShelfThickness: StyledComponent<
	"div",
	any,
	ShelfThicknessProps,
	never
> = styled.div.attrs<ShelfThicknessProps>(
	({ width: shelfWidth, thickness: shelfThickness, type: shelfType }) => ({
		style: {
			width: `calc(var(--scale) * ${shelfWidth}px)`,
			height: `calc(${shelfThickness}px * var(--scale))`,
		},
		"data-type": shelfType,
	}),
)`
	position: relative;
	background: ${Color.white};
	border-radius: 3px;
	background: ${Color.white};
	box-shadow: 0px 10px 20px rgba(179, 186, 197, 0.6), 0px 4px 4px rgba(77, 77, 77, 0.05),
		inset 0px 2px 8px rgba(64, 64, 64, 0.15);
	z-index: 1;
	cursor: ${({ editMode }: ShelfThicknessProps) => editMode && "pointer"};
`;
