import { Transition } from "src/utils";
import styled, { StyledComponent } from "styled-components";

import { RangeTheme } from "../types/types";

export const TrackElement = styled.div<{ them?: RangeTheme }>`
	width: 100%;
	position: absolute;
	height: 6px;
	top: ${({ them }) => (them === "default" ? "8px" : "6px")};
	border-radius: 6px;
`;

interface TrackBarProps {
	percentage?: number;
	background: string;
}

const TrackBar: StyledComponent<"div", any, TrackBarProps, never> = styled.div.attrs<TrackBarProps>(
	({ percentage }) => ({
		style: {
			minWidth: percentage && `${percentage}%`,
		},
	}),
)`
	height: 100%;
	background: ${(props: TrackBarProps) => props.background};
	transition: background-color ${Transition.hover};
	border-radius: 6px;

	&:last-of-type {
		flex: 1;
	}
`;

export const Track: React.FC<{ theme?: RangeTheme; percentage: number }> = ({
	theme,
	percentage,
}) => (
	<TrackElement them={theme}>
		<TrackBar percentage={percentage} background="var(--track-left)" />
		<TrackBar background="var(--track-right)" />
	</TrackElement>
);
