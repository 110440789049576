import {
	baysRelaxationBothOrNoneAtom,
	baysRelaxationCdt1ContiguousAtom,
	baysRelaxationCdt1OrderAtom,
	baysRelaxationCdt1SpanAtom,
	baysRelaxationCdt2ContiguousAtom,
	baysRelaxationCdt2OrderAtom,
	baysRelaxationCdt2SpanAtom,
	baysRelaxationCdt3ContiguousAtom,
	baysRelaxationCdt3OrderAtom,
	baysRelaxationCdt3SpanAtom,
	baysRelaxationEitherOrAtom,
	baysRelaxationFacingsNotEnoughAtom,
	baysRelaxationFacingsTooManyAtom,
	baysRelaxationFingerSpaceAtom,
	baysRelaxationFixedNotchNumAtom,
	baysRelaxationItemIdOrderAtom,
	baysRelaxationItemSpanAtom,
	baysRelaxationMinDistributionAtom,
	baysRelaxationMinWidthAtom,
	baysRelaxationPreReqAtom,
	baysRelaxationShelfItemCountAtom,
	baysRelaxationUnitsNotEnoughDosAtom,
	baysRelaxationUnitsNotEnoughMosAtom,
	baysRelaxationUnitsTooManyAtom,
	baysRelaxationWidthSlackAtom,
} from "../store/atoms";
import { Relaxation } from "../store/types";

export const relaxationsCore: Relaxation[] = [
	{
		title: "Min Distribution",
		description: "Increase the likelihood of core range items being ranged ",
		state: baysRelaxationMinDistributionAtom,
		min: 0,
		max: 10,
		step: 0.01,
	},
];

export const relaxationsQuantityOnShelf: Relaxation[] = [
	{
		title: "Units Not Enough (DOS)",
		description: "Maximum fraction of days_of_supply that can be relaxed",
		state: baysRelaxationUnitsNotEnoughDosAtom,
		min: 0,
		max: 1,
		step: 0.01,
	},
	{
		title: "Units Not Enough (MOS)",
		description: "Maximum fraction of min_on_shelf that can be relaxed",
		state: baysRelaxationUnitsNotEnoughMosAtom,
		min: 0,
		max: 1,
		step: 0.01,
	},
	{
		title: "Units Too Many",
		description: "Maximum fraction of max_units that can be relaxed",
		state: baysRelaxationUnitsTooManyAtom,
		min: 0,
		max: 10,
		step: 0.1,
	},
	{
		title: "Facings Not Enough",
		description: "Fraction of item value that will be applied as penalty per under-ranged facing",
		state: baysRelaxationFacingsNotEnoughAtom,
		max: "inf",
	},
	{
		title: "Facings Too Many",
		description: "Fraction of item value that will be applied as penalty per over-ranged facing",
		state: baysRelaxationFacingsTooManyAtom,
		max: "inf",
	},
	{
		title: "Min Width",
		description:
			"Fraction of item value that will be applied as penalty if item violates min width constraint",
		state: baysRelaxationMinWidthAtom,
		max: "inf",
	},
];

export const relaxationsFixtureRules: Relaxation[] = [
	{
		title: "Fixed Shelf Heights",
		description: "Allow planogram to adjust shelf heights or disallow this option",
		state: baysRelaxationFixedNotchNumAtom,
		max: "10inf",
	},
	{
		title: "Finger Space",
		description: "Allow planogram to change specified finger spacing or disallow this option",
		state: baysRelaxationFingerSpaceAtom,
		max: "inf",
	},
];

export const relaxationsSteering: Relaxation[] = [
	{
		title: "Width Slack",
		description: "Reduce unwanted empty space on the shelves",
		state: baysRelaxationWidthSlackAtom,
		max: "inf",
	},
	{
		title: "Item Span",
		description: "Penalty for crossing shelves",
		state: baysRelaxationItemSpanAtom,
		max: "inf",
	},
	{
		title: "Item ID Order",
		description:
			"Fraction of product value to use as relaxation cost to break candidate list ordering",
		state: baysRelaxationItemIdOrderAtom,
		min: 0,
		max: 10,
		step: 0.01,
	},
];

export const relaxationsConditionalRangeRules: Relaxation[] = [
	{
		title: "Either Or",
		description: "Increase the likelihood of either/or conditional range rules being followed",
		state: baysRelaxationEitherOrAtom,
		max: "inf",
	},
	{
		title: "Both or None",
		description: "Increase the likelihood of both/none conditional range rules being followed",
		state: baysRelaxationBothOrNoneAtom,
		max: "inf",
	},
	{
		title: "Prereq",
		description:
			"Fraction of both item values that will be applied as penalty if the prerequisite constraint is violated",
		state: baysRelaxationPreReqAtom,
		max: "inf",
	},
	{
		title: "Shelf Item Count",
		description:
			"Fraction of all items' values on that shelf that will be applied as penalty if the shelf item count constraint is violated",
		state: baysRelaxationShelfItemCountAtom,
		max: "inf",
	},
];

export const relaxationsCdtContiguous: Relaxation[] = [
	{
		title: "CDT1 Contiguous",
		description: "Fraction of product value to use as relaxation cost to break cdt1 contiguity",
		state: baysRelaxationCdt1ContiguousAtom,
		max: "inf",
	},
	{
		title: "CDT2 Contiguous",
		description: "Fraction of product value to use as relaxation cost to break cdt2 contiguity",
		state: baysRelaxationCdt2ContiguousAtom,
		max: "inf",
	},
	{
		title: "CDT3 Contiguous",
		description: "Fraction of product value to use as relaxation cost to break cdt3 contiguity",
		state: baysRelaxationCdt3ContiguousAtom,
		max: "inf",
	},
];

export const relaxationsCdtOrder: Relaxation[] = [
	{
		title: "CDT1 Order",
		description: "Fraction of product value to use as relaxation cost to break cdt ordering",
		state: baysRelaxationCdt1OrderAtom,
		max: "inf",
	},
	{
		title: "CDT2 Order",
		description: "Fraction of product value to use as relaxation cost to break cdt ordering",
		state: baysRelaxationCdt2OrderAtom,
		max: "inf",
	},
	{
		title: "CDT3 Order",
		description: "Fraction of product value to use as relaxation cost to break cdt ordering",
		state: baysRelaxationCdt3OrderAtom,
		max: "inf",
	},
];

export const relaxationsCdtSpan: Relaxation[] = [
	{
		title: "CDT1 Span",
		description: "Penalty for cdt1 spanning multiple shelves",
		state: baysRelaxationCdt1SpanAtom,
		min: 0,
		max: "inf",
	},
	{
		title: "CDT2 Span",
		description: "Penalty for cdt2 spanning multiple shelves",
		state: baysRelaxationCdt2SpanAtom,
		min: 0,
		max: "inf",
	},
	{
		title: "CDT3 Span",
		description: "Penalty for cdt3 spanning multiple shelves",
		state: baysRelaxationCdt3SpanAtom,
		min: 0,
		max: "inf",
	},
];
