import { Box } from "src/elements";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { baysStepAtom } from "../../store/atoms";
import { BaysStep } from "../../store/types";
import { PanelFiles } from "./components/PanelFiles";
import { PanelMudmap } from "./components/PanelMudmap";
import { PanelPlanogram } from "./components/PanelPlanogram";
import { TopCenter } from "./components/TopCenter";
import { TopLeft } from "./components/TopLeft";
import { TopRight } from "./components/TopRight";

const ControlPanelElement = styled.div`
	flex-direction: column;
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
`;

const HorizontalDivider = styled.div`
	width: 100%;
	height: 1px;
	background: ${Color.lightGrayHover};
`;

export const ControlPanel: React.FC = () => {
	const step = useOmniValue(baysStepAtom);

	return (
		<ControlPanelElement>
			<Box padding="10px" justify="between">
				<TopLeft />
				<TopCenter />
				<TopRight />
			</Box>

			<HorizontalDivider />

			<Box justify="between" padding="10px" align="center" gap="20px">
				{step === BaysStep.FILES && <PanelFiles />}
				{step === BaysStep.MUDMAP && <PanelMudmap />}
				{step === BaysStep.PLANOGRAM && <PanelPlanogram />}
			</Box>
		</ControlPanelElement>
	);
};
