import jwt_decode from "jwt-decode";
import { pages } from "src/utils";
import { useOmniState } from "src/utils/atoms";
import { useRedirect } from "src/utils/common";

import { postLoginReq } from "./api";
import { userAtom } from "./atoms";
import { User } from "./types";

export { IsAuth } from "./middleware";

export const useAuth = () => {
	const { redirect } = useRedirect();
	const [user, setUser] = useOmniState(userAtom);

	const login = async (email: string, password: string) => {
		try {
			// Get token from login api.
			const {
				data: { token },
			} = await postLoginReq(email, password);

			// Get user from token.
			const user = await getUserFromToken(token);

			// Invalid token.
			if (!user || user.email !== email) throw { message: ["Invalid token"] };

			// Set user states.
			localStorage.setItem("token", token);
			setUser(user);
			return {
				status: true,
			};
		} catch (error) {
			const messages: string[] = (error as any)?.response?.data?.message || (error as any)?.message;
			return { status: false, errors: messages || ["Error"] };
		}
	};

	const logout = () => {
		setUser(null);
		localStorage.removeItem("token");
		redirect(pages.login);
	};

	const getUserFromToken = async (token: string) => {
		try {
			const { user }: { user: User } = await jwt_decode(token);
			return user;
		} catch {
			return null;
		}
	};

	const verifyUser = async (token: string) => {
		const user = await getUserFromToken(token);
		if (user) {
			setUser(user);
		} else {
			logout();
		}
	};

	return { user, login, logout, getUserFromToken, verifyUser };
};

export const getLoginToken = () => localStorage.getItem("token");
