import { baysMudmapAtom } from "src/components/Bays/store/atoms";
import { sidebarWidth } from "src/components/Sidebar/Sidebar";
import { Box } from "src/elements";
import { baysStepAtom } from "src/pages/Bays/store/atoms";
import { BaysStep } from "src/pages/Bays/store/types";
import { useOmniValue } from "src/utils/atoms";

import { Stat } from "./Stat";

export const TopCenter = () => {
	const step = useOmniValue(baysStepAtom);
	const mudmap = useOmniValue(baysMudmapAtom);

	return (
		<>
			{(step === BaysStep.MUDMAP || step === BaysStep.PLANOGRAM) && mudmap?.meta && (
				<Box gap="20px" flex="1" justify="center" margin={`0 0 0 -${sidebarWidth}px`}>
					<Stat title="Total Basket" value={mudmap.meta.dispersion_performance_pct} />
					<Stat title="Total Sales" value={mudmap.meta.sales_performance_pct} />
					<Stat title="Total Profit" value={mudmap.meta.profit_performance_pct} />
				</Box>
			)}
		</>
	);
};
