import React from "react";
import { useLocation } from "react-router-dom";
import { Link, Text } from "src/elements";
import { IconType, IconV2 } from "src/elements/IconV2";
import { Color, Transition, pages } from "src/utils";
import styled from "styled-components";

export const sidebarWidth = 70;
export const sidebarPadding = 13;

const SidebarElement = styled.div`
	position: fixed;
	top: ${sidebarPadding}px;
	bottom: ${sidebarPadding}px;
	left: ${sidebarPadding}px;

	flex-direction: column;
	width: ${sidebarWidth}px;
	border-radius: 4px;
	background: ${Color.white};
	box-shadow: 0 6px 25px rgba(223, 223, 239, 0.8);
`;

const Menu = styled.div`
	flex: 1;

	flex-direction: column;
	align-items: stretch;
	width: 100%;
`;

const MenuItemContainer = styled.div`
	width: 100%;
	min-height: 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
	padding: 10px 0;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	transition: background ${Transition.hover};

	&:hover {
		background: ${Color.lightGray};
	}

	&[data-active] {
		border-right-color: ${Color.primary};
	}
`;

const MenuItem: React.FC<{
	title?: string;
	path: string;
	icon: IconType;
	active?: boolean;
}> = ({ title, path, icon, active }) => (
	<Link to={path} width="100%">
		<MenuItemContainer {...(active && { "data-active": "" })}>
			<IconV2 name={icon} color={active ? Color.primary : Color.spaceGray} />

			{title && (
				<Text variant="small3" color={active ? Color.primary : Color.spaceGray}>
					{title}
				</Text>
			)}
		</MenuItemContainer>
	</Link>
);

const menu: { icon: IconType; path: string; title: string }[] = [
	{
		icon: "bays",
		path: pages.bays,
		title: "Bays",
	},

	{
		icon: "freezer",
		path: pages.freezers,
		title: "Freezers",
	},

	{
		icon: "workbench",
		path: pages.containers,
		title: "Containers",
	},
];

export const Sidebar = () => {
	const { pathname } = useLocation();

	return (
		<SidebarElement>
			<MenuItem path={pages.home} icon="logo" />

			<Menu>
				{menu.map((item, index) => (
					<MenuItem
						key={index}
						title={item.title}
						path={item.path}
						icon={item.icon}
						active={item.path === pathname}
					/>
				))}
			</Menu>

			<MenuItem title="Log Out" path={pages.logout} icon="logout" />
		</SidebarElement>
	);
};
