import hexToRgba from "hex-to-rgba";
import { useEffect, useMemo, useState } from "react";
import { Page } from "src/components";
import { Box, Button, ErrorBlock, Form, IconV2, Text, TextInput, TextInputV2 } from "src/elements";
import { getLoginToken, useAuth } from "src/store/auth";
import { Color, pages } from "src/utils";
import { useRedirect } from "src/utils/common";
import styled from "styled-components";

const PageInner = styled.div`
	width: 100%;
	height: 100%;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 35px;
`;

const Header = styled.div`
	flex-direction: column;
	align-items: center;
	gap: 5px;

	svg {
		width: 150px;
		height: 92px;
	}
`;

const LoginCard = styled.div`
	padding: 30px;
	width: 350px;
	background: ${Color.white};
	box-shadow: 0px 10px 25px ${hexToRgba(Color.primary, 0.1)};
	border-radius: 5px;
	justify-self: center;
	align-self: center;

	button > span {
		height: 23px;
	}
`;

const LoadingAnimation = styled.div`
	animation: spin 1s infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const Login = () => {
	const { redirect } = useRedirect();
	const { user, login } = useAuth();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		if (user) redirect(pages.home);
	}, [user]);

	const emailError = useMemo(() => {
		for (const error of errors) {
			if (error.includes("email")) {
				if (error.includes("valid")) {
					return "Invalid e-mail";
				} else if (error.includes("empty")) {
					return "E-mail cannot be empty";
				}
			}
		}

		return null;
	}, [errors]);

	const passwordError = useMemo(() => {
		for (const error of errors) {
			if (error.includes("password")) {
				if (error.includes("empty")) {
					return "Password cannot be empty";
				}
			}
		}

		return null;
	}, [errors]);

	useEffect(() => {
		if (getLoginToken()) redirect(pages.home);
	}, []);

	if (getLoginToken()) {
		return null;
	}

	const logina = async () => {
		resetErrors();
		setLoading(true);
		const { status, errors } = await login(email, password);

		// Disable loading only on failure.
		if (!status) setLoading(false);
		if (errors) setErrors(errors);
	};

	const resetErrors = () => {
		if (errors.length > 0) setErrors([]);
	};

	const getFooterYearName = () => {
		const year = new Date().getFullYear();
		return `${year}, Omnistream©`;
	};

	const getFooterVersion = () => process.env.REACT_APP_VERSION;

	const togglePassword = () => setShowPassword(!showPassword);

	return (
		<Page sidebar={false} background="url(background.svg)" isAuth={false}>
			<PageInner>
				<Box flex="1" direction="column" gap="40px" justify="center">
					<Header>
						<IconV2 name="logo" />
						<Text variant="subtitle1" color={Color.textMain}>
							Workbench
						</Text>
					</Header>

					<LoginCard>
						<Form onSubmit={logina} gap="10px">
							<Box margin="0 0 20px 0" justify="center">
								<Text variant="h4" transform="uppercase">
									Login
								</Text>
							</Box>

							<TextInputV2
								label="E-mail"
								placeholder="email@omnistream.co"
								rightIcon={{ icon: "email" }}
								value={email}
								setValue={setEmail}
								onFocus={resetErrors}
								error={{ message: emailError }}
							/>

							<TextInputV2
								label="Password"
								placeholder="Password"
								type={showPassword ? "text" : "password"}
								rightIcon={{
									icon: showPassword ? "eyeOff" : "eyeOn",
									onClick: togglePassword,
									tooltip: "Toggle password",
								}}
								value={password}
								setValue={setPassword}
								onFocus={resetErrors}
								error={{ message: passwordError }}
							/>

							<Box justify="center">
								<ErrorBlock>
									{errors.length > 0 && !emailError && !passwordError && "Failed to login"}
								</ErrorBlock>
							</Box>

							<Button size="big" width="100%" submit>
								{loading ? (
									<LoadingAnimation>
										<IconV2 name="loadingInline" color={Color.white} size={23} />
									</LoadingAnimation>
								) : (
									"Login"
								)}
							</Button>
						</Form>
					</LoginCard>
				</Box>

				<Box justify="end" align="center" direction="column" gap="3px">
					<Text variant="small3" color={Color.primaryDisabled}>
						{getFooterYearName()}
					</Text>

					<Text variant="small3" color={Color.primaryDisabled}>
						{getFooterVersion()}
					</Text>
				</Box>
			</PageInner>
		</Page>
	);
};
