import { ReactElement } from "react";
import { Box, Text } from "src/elements";
import styled from "styled-components";

import { Radio } from "./components/Radio";

const RadiosElement = styled.div<{ direction: "row" | "column" }>`
	gap: 0px 15px;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ direction }) => direction === "row" && "center"};
	justify-content: ${({ direction }) => direction === "column" && "center"};
`;

interface Props {
	radios: { label: string | ReactElement; description?: string; value: any }[];
	checked: any;
	setChecked: (checked: any) => void;
	title?: string;
	direction?: "row" | "column";
}

export const Radios: React.FC<Props> = ({
	radios,
	checked,
	setChecked,
	title,
	direction = "row",
}) => (
	<Box width="100%" gap="15px" align="center">
		{title && (
			<Text variant="body1" whiteSpace="nowrap">
				{title}:
			</Text>
		)}

		<RadiosElement direction={direction}>
			{radios.map((radio, index) => (
				<Radio
					key={index}
					label={radio.label}
					description={radio.description}
					value={radio.value}
					checked={radio.value === checked}
					setChecked={setChecked}
				></Radio>
			))}
		</RadiosElement>
	</Box>
);
