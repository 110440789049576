import hexToRgba from "hex-to-rgba";
import { IconV2, Text } from "src/elements";
import { Color, tooltip } from "src/utils";
import styled from "styled-components";

const VisualizerModeElement = styled.div<{ color: Color }>`
	gap: 5px;
	align-items: center;
	background: ${({ color }) => hexToRgba(color, 0.2)};
	padding: 5px;
	border-radius: 3px;

	// For tooltip location
	* {
		pointer-events: none;
	}
`;

interface Props {
	readonly label: string;
	readonly tooltip: string;
	readonly color?: Color;
}

export const ModeLabel: React.FC<Props> = ({
	label,
	tooltip: tooltipData,
	color = Color.yellow,
}) => (
	<VisualizerModeElement
		color={color}
		onMouseEnter={event =>
			tooltip({
				event,
				data: tooltipData,
			})
		}
	>
		<IconV2 name="info" size={10} color={color} />

		<Text variant="caption3" color={color}>
			{label}
		</Text>
	</VisualizerModeElement>
);
