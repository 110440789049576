import { Modal } from "src/components";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Scroller, Tabs, Text } from "src/elements";
import { ButtonGeneratePlanogram } from "src/pages/Bays/components/ControlPanel/components/ButtonGeneratePlanogram";

import {
	changeRelaxationsConditionalCoreTab,
	relaxationsConditionalCoreTabs,
} from "../data/relaxationsConditonalCore";
import { RelaxationBlock } from "./components/RelaxationBlock";
import {
	relaxationsCdtContiguous,
	relaxationsCdtOrder,
	relaxationsCdtSpan,
	relaxationsConditionalRangeRules,
	relaxationsCore,
	relaxationsFixtureRules,
	relaxationsQuantityOnShelf,
	relaxationsSteering,
} from "./data/relaxations";

export const ModalRelaxations = () => {
	const { openModal, closeModal } = useModals();

	return (
		<Modal modal={ModalType.RELAXATIONS} height="100%" width="800px">
			<Box padding="0 20px" justify="center">
				<Tabs
					width="100%"
					tabs={relaxationsConditionalCoreTabs}
					tab={ModalType.RELAXATIONS}
					setTab={tab => changeRelaxationsConditionalCoreTab(tab, openModal, closeModal)}
				/>
			</Box>

			<Scroller gap="20px">
				<Box direction="column" gap="10px">
					<Text variant="h4">Time to break some rules!</Text>

					<Text variant="body3">
						Here you can set the relative cost of breaking certain rules against others. The higher
						the cost, the less likely these rules break during optimization and the more likely the
						planogram follows your specified inputs. Toggle right for rules you don't want broken,
						and left for rules that can be broken.
					</Text>

					<Text variant="body3">
						Don't want to mess around with the algorithm? Not to worry, default values already
						prioritise PnL in the selection and arrangement of products. Hit reset at any time to
						undo your changes.
					</Text>
				</Box>

				<Box direction="column" gap="20px">
					<RelaxationBlock
						title="Quantity on Shelf"
						relaxations={relaxationsQuantityOnShelf}
						columns="1fr 1fr"
					/>

					<RelaxationBlock
						title="Fixture Rules"
						relaxations={relaxationsFixtureRules}
						columns="1fr 1fr"
					/>

					<RelaxationBlock title="Core Range" relaxations={relaxationsCore} />

					<RelaxationBlock
						title="Conditional Range Rules"
						relaxations={relaxationsConditionalRangeRules}
						columns="1fr 1fr"
					/>

					<Box gap="20px" width="100%">
						<RelaxationBlock
							title="CDT Contiguous"
							relaxations={relaxationsCdtContiguous}
							columns="1fr"
						/>

						<RelaxationBlock title="CDT Order" relaxations={relaxationsCdtOrder} columns="1fr" />

						<RelaxationBlock title="CDT Span" relaxations={relaxationsCdtSpan} columns="1fr" />
					</Box>

					<RelaxationBlock
						title="Steering"
						relaxations={relaxationsSteering}
						columns="1fr 1fr 1fr"
					/>
				</Box>
			</Scroller>

			<Box justify="center">
				<ButtonGeneratePlanogram />
			</Box>
		</Modal>
	);
};
