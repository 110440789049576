import { omniAtom, useSetOmniValue } from "src/utils/atoms";

export const loaderState = omniAtom({
	key: "loader",
	value: [] as string[],
});

export const useLoader = () => {
	const setLoader = useSetOmniValue(loaderState);

	const addLoader = (loader: string) => setLoader(state => [...state, loader]);

	const removeLoader = (loader: string) =>
		setLoader(state => {
			let loaders = [...state];

			const index = loaders.findIndex(l => l === loader);
			loaders.splice(index, 1);

			return loaders;
		});

	return { addLoader, removeLoader };
};
