import { Box, IconV2, Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

const FileElement = styled.div`
	background-color: ${Color.opacitySky};
	border: 1px solid ${Color.opacitySkyHover};
	border-radius: 4px;
	padding: 5px;
	justify-content: space-between;
	align-items: center;
`;

export const File: React.FC<{
	file: File;
	removeFile: (file: File) => void;
}> = ({ file, removeFile }) => (
	<FileElement>
		<Box align="center" gap="5px">
			<IconV2 name="fileCSV" size={20} />

			<Text variant="small2">{file.name}</Text>
		</Box>

		<Box align="center" gap="5px" onClick={() => removeFile(file)}>
			<Text variant="caption3" color={Color.red}>
				Delete
			</Text>

			<IconV2 name="delete" color={Color.red} />
		</Box>
	</FileElement>
);
