import { omniAtom, useSetOmniValue } from "src/utils/atoms";

import { ModalType } from "../types";

export const modalsState = omniAtom({
	key: "modals",
	value: [] as ModalType[],
});

export const useModals = () => {
	const setModals = useSetOmniValue(modalsState);

	const openModal = (modal: ModalType) =>
		setModals(state => {
			let modals = [...state];

			if (!modals.includes(modal)) modals.push(modal);

			return modals;
		});

	const closeModal = (modal: string) =>
		setModals(state => {
			let modals = [...state];

			const index = modals.findIndex(m => m === modal);
			if (index !== -1) modals.splice(index, 1);

			return modals;
		});

	return { openModal, closeModal };
};
