import { useState } from "react";

export function useDebounce<T>(initialValue: T, delay: number) {
	const [value, setValue] = useState(initialValue);
	let timeout: NodeJS.Timeout | null = null;

	const setDebounceValue = (value: T) => {
		if (timeout) clearTimeout(timeout);

		timeout = setTimeout(() => {
			setValue(value);
		}, delay);
	};

	return [value, setDebounceValue] as [T, (value: T) => void];
}
