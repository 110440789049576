import hexToRgba from "hex-to-rgba";
import React, { useRef } from "react";
import { IconType, IconV2, Link, Text } from "src/elements";
import { useDropdown } from "src/hooks";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { Dropdown } from "./components/Dropdown";
import { Watermark } from "./components/Watermark";
import { ButtonDropdown, ButtonSize, ButtonTheme } from "./types/types";
import { variants } from "./variants";

const ButtonElement = styled.button<{
	width?: string;
	height?: string;
	padding?: string;
	round?: boolean;
	them: ButtonTheme;
	dropdown?: ButtonDropdown[];
}>`
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	position: relative;
	align-items: center;
	justify-content: center;
	align-self: center;
	border-width: 1px;
	border-radius: ${({ round }) => (round ? "50%" : "3px")};
	border-style: solid;
	outline: 0;
	user-select: none;
	transition: background-color ${Transition.fast}, border-color ${Transition.fast};
	cursor: pointer;

	background-color: var(--color);
	border-color: var(--border);
	color: var(--text);

	span,
	svg {
		pointer-events: none;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&[data-loading] {
		visibility: hidden;
	}

	&[disabled],
	&[data-loading] {
		cursor: default;

		a {
			pointer-events: none;
		}
	}

	&[data-size="small"] {
		min-height: 25px;
		padding: 7px 10px;
		gap: 5px;
	}

	&[data-size="normal"] {
		min-height: 35px;
		padding: 9px 15px;
		gap: 10px;
	}

	&[data-size="big"] {
		min-height: 45px;
		padding: 11px 20px;
		gap: 15px;
	}

	&[data-size] {
		padding: ${({ padding }) => padding};
		min-height: ${({ height }) => height};
	}

	${({ them }) => `
		&[data-theme="${them}"] {
			--color: ${variants[them].default};
			--text: ${variants[them].text ?? Color.white};
			--border: var(--color);

			--dropdown-text: ${
				variants[them].text !== Color.white ? variants[them].text : variants[them].default
			};
			--dropdown-color-hover: ${hexToRgba(variants[them].default, 0.15)};
			--dropdown-color-active: ${hexToRgba(variants[them].default, 0.3)};
		}

		&[data-theme="${them}"][data-variant="inverted"] {
			--color: ${Color.white};
			--text: ${variants[them].textInverted ?? variants[them].default};
			--border: ${variants[them].default};
		}

		&[data-theme="${them}"][data-variant="borderless"] {
			--color: ${Color.white};
			--text: ${variants[them].textInverted ?? variants[them].default};
			border-width: 0;
		}

		&[data-theme="${them}"][disabled] {
			--color: ${variants[them].disabled};
			--text: ${variants[them].textDisabled ?? Color.white};
			--border: var(--color);
		}

		&[data-theme="${them}"][data-variant="inverted"][disabled] {
			--color: ${Color.white};
			--text: ${variants[them].textDisabled || variants[them].disabled};
			--border: ${variants[them].disabled};
		}
	`}

	${({ them, dropdown, disabled }) =>
		!dropdown &&
		!disabled &&
		`
		&[data-theme="${them}"]:hover {
			--color: ${variants[them].hover};
			--text: ${variants[them].textHover ?? Color.white};
			--border: var(--color);
		}

		&[data-theme="${them}"]:active {
			--color: ${variants[them].active};
			--text: ${variants[them].textActive ?? Color.white};
			--border: var(--color);
		}
	`}
`;

interface Props {
	width?: string;
	height?: string;
	padding?: string;
	theme?: ButtonTheme;
	variant?: "normal" | "inverted" | "borderless";
	icon?: IconType;
	size?: ButtonSize;
	disabled?: boolean;
	to?: string;
	submit?: boolean;
	watermark?: string | number;
	round?: boolean;
	dropdown?: ButtonDropdown[];
	children?: React.ReactNode;
	onClick?: () => void;
	onMouseEnter?: (event: React.MouseEvent) => void;
}

export const Button: React.FC<Props> = ({
	theme = "primary",
	variant = "normal",
	size = "normal",
	submit,
	width,
	height,
	padding,
	icon,
	disabled,
	to,
	watermark,
	round,
	dropdown,
	children,
	onClick,
	onMouseEnter,
}) => {
	const refButton = useRef<HTMLButtonElement>(null);
	const refDropdown = useRef<HTMLDivElement>(null);
	useDropdown(refButton, refDropdown);

	const onClickHandler = () => {
		if (!disabled && onClick) {
			onClick();
		}
	};

	const onMouseEnterHandler = (event: React.MouseEvent) => {
		if (onMouseEnter) onMouseEnter(event);
	};

	return (
		<ButtonElement
			width={width}
			height={height}
			padding={padding}
			round={round}
			them={theme}
			data-size={size}
			data-theme={theme}
			type={submit ? "submit" : "button"}
			disabled={disabled}
			dropdown={dropdown}
			onClick={onClickHandler}
			onMouseEnter={onMouseEnterHandler}
			{...(variant && { "data-variant": variant })}
			ref={refButton}
		>
			{to && <Link to={to} />}

			{icon && <IconV2 name={icon} color="var(--text)" />}

			{children && (
				<Text
					variant={size === "small" ? "caption3" : size === "big" ? "h4" : "small1"}
					color="var(--text)"
					whiteSpace="nowrap"
				>
					{children}
				</Text>
			)}

			{watermark !== undefined && <Watermark>{watermark}</Watermark>}

			{dropdown && <Dropdown dropdown={dropdown} refDropdown={refDropdown} />}
		</ButtonElement>
	);
};
