import React, { useRef, useState } from "react";
import { ToastType, useToasts } from "src/components/Toasts";
import { Box, IconType, IconV2, Scroller, Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

import { File as FileElement } from "./components/File";

const UploaderContainer = styled.div`
	flex-direction: column;
	gap: 20px;
`;

const UploaderElement = styled.div`
	width: 100%;
	height: 140px;
	background: ${Color.opacitySky};
	border: 1px dashed ${Color.opacitySkyHover};
	border-radius: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	user-select: none;
	transition-property: background-color, border-color, fill, color;
	transition-duration: 0.1s;
	cursor: pointer;

	* {
		pointer-events: none;
	}

	svg path {
		transition: fill 0.1s;
	}

	&[data-dropping] {
		background: ${Color.greenSmoke};
		border-color: ${Color.greenSmokeActive};
	}

	input {
		display: none;
	}
`;

interface Props {
	files: File[];
	addFile: (file: File) => void;
	removeFile: (file: File) => void;
	deleteAll: () => void;
	icon?: IconType;
	label?: string;
	fileType?: string;
	multiple?: boolean;
}

export const Uploader: React.FC<Props> = ({
	files,
	addFile,
	removeFile,
	deleteAll,
	icon = "fileCSV",
	label = "Click or drag your files here to upload",
	fileType = "csv",
	multiple,
}) => {
	const { toast } = useToasts();

	const inputRef = useRef<HTMLInputElement>(null);

	const [dropping, setDropping] = useState(false);

	const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDropping(true);
	};

	const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDropping(false);
	};

	const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();

		for (let i = 0; i < event.dataTransfer.items.length; i++) {
			const file = event.dataTransfer.items[i].getAsFile();
			if (file) {
				const verify = verifyFile(file);
				if (verify) addFile(file);
			}
		}
	};

	const onUpload = (files: FileList) => {
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const verify = verifyFile(file);

			if (verify) addFile(file);
		}
	};

	const verifyFile = (file: File) => {
		const type = file.name.match(".*?\\.(.*)");

		if (!type || type.length < 2 || type[1] !== fileType) {
			toast({
				title: "Invalid file",
				description: `${file.name} is not valid ${fileType} file`,
				type: ToastType.ERROR,
			});
			return false;
		}

		return true;
	};

	return (
		<UploaderContainer>
			<UploaderElement
				onMouseEnter={() => setDropping(true)}
				onMouseLeave={() => setDropping(false)}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
				onClick={() => inputRef.current?.click()}
				{...(dropping && { "data-dropping": "" })}
			>
				<IconV2 name={icon} color={dropping ? Color.primary : Color.opacitySkyActive} />

				<Text variant="body2" color={Color.textSecondary}>
					{label}
				</Text>

				<Text variant="caption1" color={dropping ? Color.primary : Color.opacitySkyActive}>
					{fileType} File
				</Text>

				<input
					type="file"
					multiple={multiple}
					onChange={event => {
						if (event.target.files) {
							onUpload(event.target.files);
						}
					}}
					ref={inputRef}
				/>
			</UploaderElement>

			<Box direction="column" gap="5px">
				<Box justify="end" visibility={files.length > 0 ? "visible" : "hidden"}>
					<Box padding="5px" onClick={deleteAll}>
						<Text variant="small1" color={Color.red}>
							Delete All
						</Text>
					</Box>
				</Box>

				<Scroller height="118px">
					{files.map((file, index) => (
						<FileElement key={index} file={file} removeFile={removeFile} />
					))}
				</Scroller>
			</Box>
		</UploaderContainer>
	);
};
