import { Color } from "src/utils";

export type TextVariant =
	| "h3"
	| "h4"
	| "h5"
	| "subtitle1"
	| "subtitle2"
	| "subtitle3"
	| "body1"
	| "body2"
	| "body3"
	| "small1"
	| "small2"
	| "small3"
	| "caption1"
	| "caption3"
	| "caption2"
	| "italic1";

export const textVariants: {
	[key in TextVariant]: {
		fontSize: string;
		fontWeight: number;
		lineHeight: string;
		letterSpacing?: string;
		textTransform?: "uppercase";
		fontStyle?: "italic";
		color?: Color;
	};
} = {
	h3: {
		fontSize: "20px",
		fontWeight: 700,
		lineHeight: "25px",
	},

	h4: {
		fontSize: "18px",
		fontWeight: 700,
		lineHeight: "23px",
	},

	h5: {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "20px",
	},

	subtitle1: {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "20px",
	},

	subtitle2: {
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: "20px",
	},

	subtitle3: {
		fontSize: "16px",
		fontWeight: 400,
		lineHeight: "20px",
	},

	body1: {
		fontSize: "14px",
		fontWeight: 700,
		lineHeight: "18px",
	},

	body2: {
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "18px",
	},

	body3: {
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "18px",
		color: Color.textMain,
	},

	small1: {
		fontSize: "12px",
		fontWeight: 700,
		lineHeight: "15px",
		color: Color.textSecondary,
	},

	small2: {
		fontSize: "12px",
		fontWeight: 400,
		lineHeight: "15px",
		color: Color.textSecondary,
	},

	small3: {
		fontSize: "9px",
		fontWeight: 500,
		lineHeight: "11px",
		letterSpacing: "0.02em",
		color: Color.textSecondary,
	},

	caption1: {
		fontSize: "10px",
		fontWeight: 600,
		lineHeight: "13px",
	},

	caption2: {
		fontSize: "10px",
		fontWeight: 400,
		lineHeight: "13px",
	},

	caption3: {
		fontSize: "8px",
		fontWeight: 700,
		lineHeight: "10px",
		textTransform: "uppercase",
		letterSpacing: "0.02em",
	},

	italic1: {
		fontSize: "10px",
		fontWeight: 600,
		lineHeight: "13px",
		fontStyle: "italic",
	},
};

export const enum TextVariantType {
	FONT_SIZE = "fontSize",
	FONT_STYLE = "fontStyle",
	FONT_WEIGHT = "fontWeight",
	TEXT_TRANSFORM = "textTransform",
	LINE_HEIGHT = "lineHeight",
	LETTER_SPACING = "letterSpacing",
	COLOR = "color",
}

export const getFontAttribute = (variant: string, type: TextVariantType) =>
	textVariants[variant] && textVariants[variant][type];
