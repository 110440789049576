import React, { useEffect, useRef } from "react";
import { baysShowTooltipsAtom } from "src/pages/Bays/store/atoms";
import { MudmapShelf, PlanogramShelf, ShelfType } from "src/types";
import { MudmapItem as MudmapItemType, PlanogramItem as PlanogramItemType } from "src/types";
import { tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled, { StyledComponent } from "styled-components";

import { useShelfPanel } from "../../store/actions";
import { baysEditModeAtom } from "../../store/atoms";
import { PlanogramVariant } from "../../types";
import { onShelfMouseMove } from "../../utils";
import { PLANOGRAM_ITEMS, PLANOGRAM_SHELF, PLANOGRAM_SHELF_THICKNESS } from "../../utils/classes";
import { Facing } from "../Facing";
import { MudmapItem } from "../MudmapItem";
import { PlanogramItem } from "../PlanogramItem";
import { ValidArea } from "../ValidArea";
import { ShelfThickness } from "./Thickness";

interface StyleProps {
	width: number;
	height: number;
	shelfY: number | null;
}

const ShelfElementContainer: StyledComponent<
	"div",
	any,
	{ width: number },
	never
> = styled.div.attrs<StyleProps>(({ width }) => ({
	style: {
		maxWidth: `calc(${width}px * var(--scale))`,
	},
}))``;

const ShelfElement: StyledComponent<"div", any, StyleProps, never> = styled.div.attrs<StyleProps>(
	({ width, height, shelfY }) => ({
		style: {
			maxWidth: `calc(${width}px * var(--scale))`,
			minHeight: `calc(${height}px * var(--scale))`,
			left: shelfY !== null && "0px",
			bottom: shelfY !== null && `calc(${shelfY}px * var(--scale))`,
			position: shelfY !== null ? "absolute" : "relative",
		},
	}),
)`
	position: relative;
	flex-direction: column;
`;

interface FingerSpaceProps {
	fingerSpace: number;
}

const FingerSpace: StyledComponent<
	"div",
	any,
	FingerSpaceProps,
	never
> = styled.div.attrs<FingerSpaceProps>(({ fingerSpace }) => ({
	style: {
		height: fingerSpace,
	},
}))`
	width: 100%;
	pointer-events: none;
`;

const Air = styled.div`
	width: 100%;
	flex: 1;
`;

interface ShelfItemsProps {
	type: ShelfType;
	variant: PlanogramVariant;
	height: number;
	thickness: number;
	fingerSpace: number;
}

const ShelfItems: StyledComponent<
	"div",
	any,
	ShelfItemsProps,
	never
> = styled.div.attrs<ShelfItemsProps>(({ height, fingerSpace, thickness }) => ({
	style: {
		minHeight: `calc(var(--scale) * ${height - fingerSpace - thickness}px)`,
	},
}))`
	position: relative;
	width: min-content;
	align-items: ${({ type }: ShelfItemsProps) => (type === "REGULAR" ? "flex-end" : "flex-start")};
	height: ${({ variant }: ShelfItemsProps) => variant === PlanogramVariant.MUDMAP && "100%"};
	width: ${({ variant }: ShelfItemsProps) => variant === PlanogramVariant.MUDMAP && "100%"};
	pointer-events: none;
`;

const Pegboard = styled.div<{ width: number; shelfHeight: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: ${({ width: shelfWidth }) => `calc(${shelfWidth}px * var(--scale))`};
	height: ${({ shelfHeight }) => `calc(${shelfHeight}px * var(--scale))`};
	height: 100%;
	background-image: url("/pegboard.png");
	border-radius: 3px;
	overflow: hidden;
	pointer-events: none;
`;

interface Props {
	readonly bayNo: number;
	readonly variant: PlanogramVariant;
	readonly shelves: (MudmapShelf | PlanogramShelf)[];
}

const shelfDataForTooltip = (shelf: MudmapShelf | PlanogramShelf) => ({
	Shelf: shelf.shelf_no,
	Type: shelf.shelf_type,
	Width: shelf.shelf_width,
	Height: shelf.shelf_height,
	Depth: shelf.shelf_depth,
	Thickness: shelf.shelf_thickness,
	"Finger space": shelf.finger_space,
	Slope: (shelf as PlanogramShelf).shelf_slope,
	"Slope height": (shelf as PlanogramShelf).slope_height,
	Notch: (shelf as PlanogramShelf).notch_no || (shelf as MudmapShelf).notch,
	"Notch spacing": (shelf as MudmapShelf).notch_spacing,
	"Placement type": (shelf as MudmapShelf).shelf_placement_type,
	Y: shelf.shelf_y,
});

export const Shelf: React.FC<Props> = ({ bayNo, variant, shelves }) => {
	const showTooltips = useOmniValue(baysShowTooltipsAtom);
	const editMode = useOmniValue(baysEditModeAtom);

	const { toggle } = useShelfPanel();

	const refItems = useRef<HTMLDivElement>(null);
	const refValidArea = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (refItems.current) {
			shelfObserver.observe(refItems.current);
		}
	}, [refItems]);

	const shelfObserver = new ResizeObserver(() => {
		if (!refItems.current || !refValidArea.current) return;

		const itemsWidth = Math.floor(refItems.current.getBoundingClientRect().width);
		const itemsHeight = Math.floor(refItems.current.getBoundingClientRect().height);

		const validAreaWidth = Math.floor(refValidArea.current.getBoundingClientRect().width);
		const validAreaHeight = Math.floor(refValidArea.current.getBoundingClientRect().height);

		if (itemsWidth > validAreaWidth || itemsHeight > validAreaHeight) {
			refValidArea.current.setAttribute("data-invalid", "");
		} else {
			refValidArea.current.removeAttribute("data-invalid");
		}
	});

	return (
		<ShelfElementContainer width={shelves[0].shelf_width}>
			{shelves.map((shelf, index) => {
				const {
					shelf_no: no,
					shelf_type: type,
					shelf_width: width,
					shelf_height: height,
					shelf_y: shelfY,
					shelf_thickness: thickness,
					finger_space: fingerSpace,
				} = shelf;

				return (
					<ShelfElement
						key={index}
						className={PLANOGRAM_SHELF}
						data-no={no}
						width={width}
						height={height}
						shelfY={shelfY}
						onMouseMove={onShelfMouseMove}
					>
						{type === ShelfType.HANGCELL && <Pegboard width={width} shelfHeight={height} />}

						<FingerSpace className="planogram-shelf-fingerspace" fingerSpace={fingerSpace} />

						{variant === PlanogramVariant.PLANOGRAM && (
							<ValidArea
								ref={refValidArea}
								shelfWidth={width}
								shelfHeight={height}
								fingerSpace={fingerSpace}
								shelfThickness={thickness}
							/>
						)}

						<ShelfItems
							className={PLANOGRAM_ITEMS}
							type={type}
							variant={variant}
							height={height}
							fingerSpace={fingerSpace}
							thickness={thickness}
							ref={refItems}
						>
							{variant === PlanogramVariant.MUDMAP &&
								(shelf.items as MudmapItemType[])
									.filter(
										(item, i) =>
											(i === 0 && true) ||
											(shelf.items as MudmapItemType[])[i - 1].block !== item.block,
									)
									.map((item: MudmapItemType, index: number) => (
										<MudmapItem
											key={index}
											item={item}
											variant={(shelf as MudmapShelf).variant}
											showTooltips={showTooltips}
											flex={
												(shelf.items as MudmapItemType[]).filter(
													m => m.segment === item.segment && m.block === item.block,
												).length
											}
										/>
									))}
							{variant === PlanogramVariant.PLANOGRAM &&
								(shelf.items as PlanogramItemType[]).map(
									(item: PlanogramItemType, index: number) => (
										<PlanogramItem key={index} item={item} editable>
											{Array.from(Array(item.facings).keys()).map(facing => (
												<Facing
													variant=""
													key={facing}
													shelf_type={shelf.shelf_type}
													item={{ ...item, id: item.item_id, segment: item.cdt1, block: item.cdt2 }}
												/>
											))}
										</PlanogramItem>
									),
								)}
						</ShelfItems>

						{type === ShelfType.HANGCELL && <Air className="shelf-air" />}

						<ShelfThickness
							className={PLANOGRAM_SHELF_THICKNESS}
							type={type}
							width={width}
							thickness={thickness}
							editMode={editMode}
							onMouseEnter={event =>
								showTooltips && tooltip({ event, data: shelfDataForTooltip(shelf) })
							}
							onClick={() => editMode && toggle(bayNo, shelf as PlanogramShelf)}
						/>
					</ShelfElement>
				);
			})}
		</ShelfElementContainer>
	);
};
