import { useEffect } from "react";
import { Outlet } from "react-router";
import { Page } from "src/components";
import { pages } from "src/utils";
import { useRedirect } from "src/utils/common";

import { getLoginToken, useAuth } from ".";

export const IsAuth = () => {
	const { user, verifyUser } = useAuth();
	const { redirect } = useRedirect();
	const token = getLoginToken();

	useEffect(() => {
		if (token) {
			// Logged in, need to get user from token.
			if (!user) verifyUser(token);
		} else {
			// Not logged in
			redirect(pages.login);
		}
	}, []);

	return token ? (
		<Page>
			<Outlet />
		</Page>
	) : null;
};
