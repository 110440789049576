import { useRef } from "react";
import { InputFiles } from "src/components/InputFiles";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { useLogs } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import {
	baysFixturesFileAtom,
	baysOutputFileAtom,
	baysProductsFileAtom,
} from "src/pages/Bays/store/atoms";
import { useOmniValue } from "src/utils/atoms";

import { useBaysControlPanel } from "../utils/utils";

export const PanelFiles = () => {
	const fixtureFile = useOmniValue(baysFixturesFileAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);
	const outputFile = useOmniValue(baysOutputFileAtom);

	const { openModal } = useModals();
	const { setTab: setLogsTab } = useLogs();
	const { onFileUpload } = useBaysControlPanel();

	const inputRef = useRef<HTMLInputElement>(null);

	const openFileUploader = () => {
		inputRef.current?.click();
	};

	const openLogs = () => {
		setLogsTab(LogsTab.FILES);
		openModal(ModalType.LOGS);
	};

	const openOutputEditor = () => {
		openModal(ModalType.OUTPUT_EDITOR);
	};

	const fileUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		onFileUpload(event.target.files);

		if (inputRef.current) {
			inputRef.current.files = null;
		}
	};

	return (
		<>
			<Box height="100%" flex="1" gap="20px" align="center">
				<InputFiles
					data={
						outputFile
							? {
									"Output file": outputFile?.name || null,
							  }
							: {
									Fixtures: fixtureFile?.name || null,
									Products: masterFile?.name || null,
							  }
					}
					required
				/>
			</Box>

			<Box gap="10px">
				<Button theme="greenSmoke" onClick={openLogs} variant="inverted">
					Logs
				</Button>

				<Button theme="greenSmoke" variant="inverted" onClick={openOutputEditor}>
					Import from Clipboard
				</Button>

				<Button theme="primary" onClick={openFileUploader}>
					Import CSV or JSON Files
				</Button>

				<input
					type="file"
					ref={inputRef}
					style={{ display: "none" }}
					onChange={fileUploadHandler}
					accept=".csv,.json"
					multiple
				/>
			</Box>
		</>
	);
};
