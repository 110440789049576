export const FREEZERS = "freezers";

export const FREEZER = "freezer";

export const FREEZER_SECTION = "freezer-section";

export const FREEZER_ITEM = "freezer-item";

export const FREEZER_OVERLAP = "freezer-overlap";

export const FREEZER_OVERLAP_MOVE = "freezer-overlap";
