import { Text } from "src/elements";
import { StateDatapointsRange } from "src/elements/RangeV2/StateDatapointsRange";
import { StateRange } from "src/elements/RangeV2/StateRange";
import { Color } from "src/utils";
import { OmniState } from "src/utils/atoms";
import styled from "styled-components";

import { datapointsFrom0To10Inf, datapointsFrom0ToInf } from "../data/datapoints";
import { Relaxation } from "../store/types";

const RelaxationBlockContainer = styled.div`
	width: 100%;
	flex-direction: column;
	gap: 5px;
`;

const RelaxationBlockElement = styled.div<{ columns?: string }>`
	width: 100%;
	display: grid;
	grid-template-columns: ${({ columns }) => columns};
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
	padding: 10px;
	gap: 20px 40px;
`;

interface Props {
	title: string;
	relaxations: Relaxation[];
	columns?: string;
}

export const RelaxationBlock: React.FC<Props> = ({ title, relaxations, columns }) => {
	const customFormatter = (value: string | number) => {
		if (typeof value === "string") {
			return value;
		} else if (value === 1_000_000) {
			return "1M";
		} else if (value >= 1_000) {
			return value / 1_000 + "K";
		} else if (value < 1) {
			return value.toFixed(2);
		} else if (value < 5) {
			return value.toFixed(1);
		}

		return value;
	};

	return (
		<RelaxationBlockContainer>
			<Text variant="body1" color={Color.textMain}>
				{title}
			</Text>

			<RelaxationBlockElement columns={columns}>
				{relaxations.map(({ title, description, state, min, max, step }, index) => {
					const maxCustom = typeof max === "number" ? max : undefined;
					const datapoints =
						max === "inf"
							? datapointsFrom0ToInf
							: max === "10inf"
							? datapointsFrom0To10Inf
							: undefined;

					return datapoints ? (
						<StateDatapointsRange
							key={index}
							title={title}
							description={description}
							state={state as OmniState<string>}
							datapoints={datapoints}
							customFormatter={customFormatter}
						/>
					) : (
						<StateRange
							key={index}
							title={title}
							description={description}
							state={state as OmniState<number>}
							min={min}
							max={maxCustom}
							step={step}
						/>
					);
				})}
			</RelaxationBlockElement>
		</RelaxationBlockContainer>
	);
};
