import { useState } from "react";

import { TextInput } from "../Input/TextInput";

interface Props {
	onChange?: (value: string) => void;
	placeholder?: string;
	width?: string;
	label?: string;
}

export const Search: React.FC<Props> = ({ placeholder = "Search", width, label, onChange }) => {
	const [searchInner, setSearchInner] = useState("");

	return (
		<TextInput
			defaultValue={searchInner}
			onChange={value => {
				setSearchInner(value);
				if (onChange) onChange(value);
			}}
			placeholder={`${placeholder}...`}
			leftIcon={{ icon: "search" }}
			rightIcon={
				searchInner
					? {
							icon: "clear",
							onClick: () => {
								setSearchInner("");
								if (onChange) onChange("");
							},
					  }
					: undefined
			}
			label={label}
			width={width}
		/>
	);
};
