import { useMemo, useRef } from "react";
import { NoDataPlaceholder } from "src/components";
import { Box, Button, Scroller, Search, Text } from "src/elements";
import { useDebounce, useDropdown } from "src/hooks";
import { baysFilesAtom } from "src/pages/Bays/store/atoms";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { useCoreRange, useCoreRangeFilters } from "../store/atoms";
import { Filters } from "./Filters/Filters";
import { Item } from "./Item";

const ItemsElement = styled.div`
	width: 100%;
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
	overflow: hidden;
	flex-direction: column;
`;

const Header = styled.div`
	background-color: ${Color.lightGray};
	border-bottom: 1px solid ${Color.lightGrayHover};
	justify-content: space-between;
	align-items: center;
	padding: 10px;
`;

const Content = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
`;

export const Items: React.FC = () => {
	const { coreRangeFilters } = useCoreRangeFilters();
	const { coreRange, addItems, removeItems } = useCoreRange();

	const refButton = useRef(null);
	const refFilters = useRef(null);
	useDropdown(refButton, refFilters);

	const [search, setSearch] = useDebounce("", 200);

	const baysFiles = useOmniValue(baysFilesAtom)!;

	const filterItems = useMemo(() => {
		if (Object.values(coreRangeFilters).length === 0) {
			return baysFiles.master_list;
		}

		return baysFiles.master_list.filter(masterItem => {
			for (const column of Object.keys(coreRangeFilters)) {
				if (!coreRangeFilters[column]?.includes(masterItem[column])) {
					return false;
				}
			}

			return true;
		});
	}, [coreRangeFilters]);

	const searchItems = useMemo(() => {
		if (!search) return filterItems;

		const searchLowercase = search.toLocaleLowerCase();

		return filterItems.filter(
			masterItem =>
				masterItem.id.toLocaleLowerCase().includes(searchLowercase) ||
				masterItem.segment.toLocaleLowerCase().includes(searchLowercase) ||
				masterItem.block.toLocaleLowerCase().includes(searchLowercase),
		);
	}, [search, filterItems]);

	const areAllFilteredMasterItemsSelected = useMemo(() => {
		for (const masterItem of searchItems) {
			if (!coreRange.includes(masterItem.id)) {
				return false;
			}
		}

		return true;
	}, [coreRange, searchItems]);

	const toggleFilteredItems = () => {
		if (areAllFilteredMasterItemsSelected) {
			removeItems(searchItems.map(masterItem => masterItem.id));
		} else {
			addItems(searchItems.map(masterItem => masterItem.id));
		}
	};

	return (
		<Box direction="column" gap="5px" flex="1" minHeight="0">
			<Text variant="body1" color={Color.textSecondary}>
				Core Range Items
			</Text>

			<ItemsElement>
				<Header>
					<Box gap="10px" align="center">
						<Search width="200px" onChange={setSearch} />

						<Box ref={refButton}>
							<Button icon="filter">Filters</Button>
						</Box>

						<Filters
							refFilters={refFilters}
							categories={[
								{ title: "Brand", column: "brand" },
								{ title: "Segment", column: "segment" },
								{ title: "Block", column: "block" },
								{ title: "Shelf Type", column: "shelf_type" },
								{ title: "Subcategory", column: "subcategory" },
								{ title: "Type", column: "type" },
								{ title: "Pack Type", column: "pack_type" },
							]}
							data={baysFiles.master_list}
							filterItems={filterItems}
						/>
					</Box>

					<Box gap="10px" align="center">
						{searchItems.length > 0 && (
							<Button width="85px" theme="greenSmoke" onClick={toggleFilteredItems}>
								{areAllFilteredMasterItemsSelected ? "Unselect all" : "Select all"}
							</Button>
						)}
					</Box>
				</Header>

				<Box padding="10px 0">
					<Scroller size="thin">
						<Content>
							{searchItems.map((item, index) => (
								<Item key={index} item={item} />
							))}
						</Content>

						{searchItems.length === 0 && <NoDataPlaceholder placeholder={"No Core Range items"} />}
					</Scroller>
				</Box>
			</ItemsElement>
		</Box>
	);
};
