import { ModeLabel } from "src/components";
import { usePog } from "src/components/Bays/store/actions";
import { baysEditModeAtom, baysMudmapAtom } from "src/components/Bays/store/atoms";
import { Box, Tabs } from "src/elements";
import {
	baysFilesAtom,
	baysFixturesFileAtom,
	baysOutputFileAtom,
	baysProductsFileAtom,
	baysStepAtom,
} from "src/pages/Bays/store/atoms";
import { BaysStep } from "src/pages/Bays/store/types";
import { Color } from "src/utils";
import { useOmniState, useOmniValue } from "src/utils/atoms";

export const TopLeft = () => {
	const [step, setStep] = useOmniState(baysStepAtom);

	const masterItems = useOmniValue(baysFilesAtom);
	const fixtureFile = useOmniValue(baysFixturesFileAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);
	const mudmap = useOmniValue(baysMudmapAtom);
	const outputFile = useOmniValue(baysOutputFileAtom);
	const baysEditMode = useOmniValue(baysEditModeAtom);

	// Actions.
	const { pog } = usePog();

	return (
		<Box gap="10px" align="center" flex="1">
			<Tabs
				tabs={[
					{ tab: BaysStep.FILES, title: "Files" },
					{
						tab: BaysStep.MUDMAP,
						title: "Mudmap",
						disabled: !fixtureFile || !masterFile || !masterItems,
					},
					{
						tab: BaysStep.PLANOGRAM,
						title: "Planogram",
						disabled: !mudmap && !pog,
					},
				]}
				tab={step}
				setTab={setStep}
				tabWidth="110px"
				theme="steps"
			/>

			{(outputFile || (pog && !fixtureFile && !masterFile && !outputFile)) && (
				<ModeLabel
					label="Visualizer Mode"
					tooltip="In visualizer mode you can't generate new mudmaps or planograms"
				/>
			)}

			{baysEditMode && (
				<ModeLabel
					label="Edit Mode"
					tooltip="Advanced mode to edit bays/shelves/items"
					color={Color.red}
				/>
			)}
		</Box>
	);
};
