import hexToRgba from "hex-to-rgba";
import { NoDataPlaceholder } from "src/components/NoDataPlaceholder/NoDataPlaceholder";
import { Scroller } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

import { Cell } from "./components/Cell";

interface Props {
	columns: {
		id: string;
		title: string;
		body?: ({ index, value }: { index: number; value: string }) => any;
		width?: string;
		justify?: "center" | "right";
		align?: "center";
	}[];
	data: any[];
	placeholder?: any;
}

const TableElement = styled.table`
	tr td:last-of-type > .table-cell {
		border-right: 0;
	}

	thead {
		position: sticky;
		top: 0;

		.table-cell {
			background: ${hexToRgba(Color.white, 0.7)};
			backdrop-filter: blur(4px);
		}
	}
`;

export const Table: React.FC<Props> = ({ columns, data, placeholder }) => {
	return (
		<Scroller border={`1px solid ${Color.lightGrayHover}`} height="100%" gap="0">
			<TableElement>
				<thead>
					<tr>
						{columns.map(({ title, width }, index) => (
							<td key={index} width={width}>
								<Cell variant="small1">{title}</Cell>
							</td>
						))}
					</tr>
				</thead>

				<tbody>
					{data.map((item, indexRow) => (
						<tr key={indexRow}>
							{columns.map(({ id, body, justify, align }, indexColumn) => (
								<td key={indexColumn}>
									<Cell justify={justify} align={align} variant="small2">
										{typeof body === "function"
											? body({ index: indexRow, value: item[id] })
											: body
											? body
											: item[id]}
									</Cell>
								</td>
							))}
						</tr>
					))}
				</tbody>

				<tfoot></tfoot>
			</TableElement>

			{!data ||
				(data.length === 0 && placeholder && <NoDataPlaceholder placeholder={placeholder} />)}
		</Scroller>
	);
};
