import { usePog } from "src/components/Bays/store/actions";
import { baysColorsAtom, baysMudmapAtom } from "src/components/Bays/store/atoms";
import { ToastType, useToasts } from "src/components/Toasts";
import { useLogs } from "src/modals/Logs/store/atoms";
import {
	baysFilesAtom,
	baysFixturesFileAtom,
	baysObjectivesAtom,
	baysOutputFileAtom,
	baysProductsFileAtom,
	useGenerateColors,
} from "src/pages/Bays/store/atoms";
import { mudmapPlanogramFilesDetection } from "src/pages/Bays/utils/mudmapPlanogramFilesDetection";
import { verifyFileTypes } from "src/pages/Bays/utils/verifyFileTypes";
import { verifyPlanogram } from "src/pages/Bays/utils/verifyPlanogram";
import { useSetOmniValue } from "src/utils/atoms";

export const useBaysControlPanel = () => {
	const setMasterItems = useSetOmniValue(baysFilesAtom);
	const setFixtureFile = useSetOmniValue(baysFixturesFileAtom);
	const setMasterFile = useSetOmniValue(baysProductsFileAtom);
	const setMudmap = useSetOmniValue(baysMudmapAtom);
	const setOutputFile = useSetOmniValue(baysOutputFileAtom);
	const setObjectives = useSetOmniValue(baysObjectivesAtom);
	const setColors = useSetOmniValue(baysColorsAtom);

	// Actions.
	const { setPog } = usePog();
	const { generateColors } = useGenerateColors();
	const { setPlanogramResponse } = useLogs();
	const { toast } = useToasts();

	const onJsonUpload = (file: File, json: any) => {
		// Check if planogram is valid
		const { verified, message } = verifyPlanogram(json);
		if (!verified) {
			return toast({
				title: "Invalid planogram",
				description: message,
				type: ToastType.ERROR,
			});
		}

		toast({ title: "Planogram imported" });

		setFixtureFile(null);
		setMasterFile(null);
		setMudmap(null);
		setObjectives(null);
		setMasterItems(null);
		setOutputFile(file);
		setPog(json);
		setPlanogramResponse(json);
		generateColors({ pog: json });
	};

	const onFileUpload = (files: FileList | null) => {
		if (!files) return;

		// Check that both files use same extensions.
		const verifiedFilesType = verifyFileTypes(files);
		if (!verifiedFilesType) {
			return toast({
				title: "Invalid files",
				description: "All files need to use same format",
				type: ToastType.ERROR,
			});
		}

		// CSV files
		if (verifiedFilesType === "csv") {
			// Detect and set fixture/master files based on file names.
			for (const file of files) {
				const type = mudmapPlanogramFilesDetection(file);
				if (type === "fixture") {
					setOutputFile(null);
					setMudmap(null);
					setPog(null);
					setObjectives(null);
					setMasterItems(null);
					setColors(null);
					setFixtureFile(file);
				} else if (type === "master") {
					setOutputFile(null);
					setMudmap(null);
					setPog(null);
					setObjectives(null);
					setMasterItems(null);
					setColors(null);
					setMasterFile(file);
				} else {
					toast({
						title: "Unknown file",
						description: `${file.name} doesn't include fixture or master file prefixes`,
						type: ToastType.WARNING,
					});
				}
			}
		}

		// JSON files.
		if (verifiedFilesType === "json") {
			for (const file of files) {
				// FileReader as any to save fileName to it
				const fileReader = new FileReader();

				fileReader.onload = event => {
					try {
						const json = JSON.parse(event.target!.result!.toString());
						onJsonUpload(file, json);
					} catch (error) {
						toast({ title: "Invalid JSON file", type: ToastType.ERROR });
					}
				};

				fileReader.readAsText(file);
			}
		}
	};

	return { onFileUpload };
};
