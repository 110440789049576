import React from "react";
import { Text } from "src/elements";
import { baysShowTooltipsAtom } from "src/pages/Bays/store/atoms";
import { MudmapBay, PlanogramBay } from "src/types";
import { Color, tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { useBayPanel } from "../store/actions";
import { baysEditModeAtom } from "../store/atoms";
import { PLANOGRAM_BAY } from "../utils/classes";

const BayContainer = styled.div<{ mergedToLeft?: boolean }>`
	height: 100%;
	flex-direction: column;
	gap: 10px;
	justify-content: space-between;

	&:not(:first-of-type) {
		margin-left: ${({ mergedToLeft }) => !mergedToLeft && "20px"};
	}

	&:last-of-type .new-bay {
		display: none;
	}
`;

const BayHeader = styled.div`
	justify-content: center;
	background-color: ${Color.white};
	border: 2px solid ${Color.opacitySky};
	border-radius: 4px;
	user-select: none;
	position: relative;
`;

const BayHeaderInner = styled.div<{ editMode: boolean }>`
	width: 100%;
	height: 100%;
	padding: 10px;
	justify-content: center;
	cursor: ${({ editMode }) => editMode && "pointer"};

	* {
		pointer-events: none;
	}
`;

const BayElement = styled.div<{ bayHeight: number; bayWidth: number }>`
	position: relative;
	height: ${({ bayHeight }) => `calc(${bayHeight}px * var(--scale))`};
	width: ${({ bayWidth }) => `calc(${bayWidth}px * var(--scale))`};
	min-width: 100%;
	flex-direction: column;
	justify-content: flex-end;
	background-color: ${Color.opacitySky};
	border-radius: 4px;
`;

interface Props {
	readonly bay: MudmapBay | PlanogramBay;
	readonly width: number;
	readonly mergedToLeft?: boolean;
	readonly type: "mudmap" | "planogram";
	readonly children: React.ReactNode;
}

export const Bay: React.FC<Props> = ({ bay, width, mergedToLeft, children }) => {
	const showTooltips = useOmniValue(baysShowTooltipsAtom);
	const editMode = useOmniValue(baysEditModeAtom);

	const { toggle } = useBayPanel();

	return (
		<BayContainer mergedToLeft={mergedToLeft}>
			<BayHeader>
				<BayHeaderInner
					editMode={editMode}
					onClick={() => editMode && toggle(bay as PlanogramBay)}
					onMouseEnter={event =>
						showTooltips && tooltip({ event, data: { Bay: bay.bay_no, Height: bay.bay_height } })
					}
				>
					<Text variant="body1">Bay {bay.bay_no}</Text>
				</BayHeaderInner>

				{/* {type === "planogram" && editMode && <NewBay size="small" position="absolute" />} */}
			</BayHeader>

			<BayElement
				className={PLANOGRAM_BAY}
				data-no={bay.bay_no}
				bayHeight={bay.bay_height}
				bayWidth={width}
			>
				{children}
			</BayElement>
		</BayContainer>
	);
};
