import { Box, IconV2 } from "src/elements";
import { Transition } from "src/utils";
import styled from "styled-components";

const ArrowsElement = styled.div`
	display: grid;
	justify-content: center;
	flex-direction: column;

	.dropdown-up,
	.dropdown-down {
		grid-row: 1 / 2;
		grid-column: 1 / 2;
		transition: opacity ${Transition.hover}, transform ${Transition.hover};
	}

	&[data-open="false"] {
		.dropdown-up {
			opacity: 0;
			transform: translateY(-7px);
		}
	}

	&[data-open="true"] {
		.dropdown-down {
			opacity: 0;
			transform: translateY(7px);
		}
	}
`;

interface Props {
	open: boolean;
}

export const Arrows: React.FC<Props> = ({ open }) => (
	<ArrowsElement data-open={open}>
		<Box className="dropdown-up">
			<Box rotate={180}>
				<IconV2 name="dropdown" color="var(--text)" />
			</Box>
		</Box>

		<Box className="dropdown-down">
			<IconV2 name="dropdown" color="var(--text)" />
		</Box>
	</ArrowsElement>
);
