import hexToRgba from "hex-to-rgba";
import { IconV2, Text } from "src/elements";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

const CHECKBOX_SIZE = "14px";

const CheckboxContainer = styled.div<{ useParent?: boolean; height?: string }>`
	position: ${({ useParent }) => !useParent && "relative"};
	height: ${({ height }) => height};
	gap: 5px;
	align-items: center;
`;

const CheckboxElement = styled.div`
	width: ${CHECKBOX_SIZE};
	height: ${CHECKBOX_SIZE};

	transition: background-color ${Transition.fast}, border-color ${Transition.fast};
	justify-content: center;
	align-items: center;
	pointer-events: none;

	border-width: 1px;
	border-style: solid;
	border-radius: 2px;

	background-color: var(--background);
	border-color: var(--border);

	&[data-checked="false"] {
		--background: ${Color.white};
		--border: ${Color.spaceGray};
		--checkmark: transparent;
	}

	&[data-checked="true"] {
		--background: ${Color.primary};
		--border: ${Color.primary};
		--checkmark: ${Color.white};
	}
`;

const Checkmark = styled.div`
	transition: opacity ${Transition.fast};
	pointer-events: none;
`;

const CheckboxClickArea = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	user-select: none;

	&:hover ~ .checkbox {
		&[data-checked="false"] {
			--background: ${Color.greenSmoke};
			--checkmark: ${Color.primary};
			--border: ${Color.greenSmokeActive};
		}

		&[data-checked="true"] {
			--background: ${hexToRgba(Color.primary, 0.85)};
			--border: ${hexToRgba(Color.primary, 0.85)};
		}
	}

	&:hover:active ~ .checkbox {
		&[data-checked="false"] {
			--background: ${Color.greenSmokeHover};
			--border: ${Color.greenSmokeActive};
			--checkmark: ${Color.primaryActive};
		}

		&[data-checked="true"] {
			--background: ${Color.greenSmokeHover};
			--border: ${Color.greenSmokeActive};
			--checkmark: ${Color.white};
		}
	}
`;

interface Props {
	checked: boolean;
	setChecked: (checked: boolean) => void;
	label?: string;
	useParent?: boolean;
	height?: string;
}

export const Checkbox: React.FC<Props> = ({ checked, setChecked, label, useParent, height }) => {
	const toggleChecked = () => {
		setChecked(!checked);
	};

	return (
		<CheckboxContainer className="checkbox-container" useParent={useParent} height={height}>
			<CheckboxClickArea className="checkbox-click-area" onClick={toggleChecked} />

			<CheckboxElement className="checkbox" data-checked={checked}>
				<Checkmark>
					<IconV2 name="checkmark" color={"var(--checkmark)"} />
				</Checkmark>
			</CheckboxElement>

			{label && <Text variant="small2">{label}</Text>}
		</CheckboxContainer>
	);
};
