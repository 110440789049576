import { Freezers } from "src/store/freezers";

export const exportFreezers = (freezers: Freezers) => {
	const download = document.createElement("a");

	download.setAttribute(
		"href",
		"data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(freezers, null, 2)),
	);
	download.setAttribute("download", "freezers_export.json");

	download.style.display = "none";
	document.body.appendChild(download);

	download.click();

	document.body.removeChild(download);
};
