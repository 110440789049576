import { useEffect } from "react";
import { HorizontalDivider, Modal } from "src/components";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button, Text } from "src/elements";
import {
	containersFilesResponseAtom,
	containersMergeRulesAtom,
	containersMergeRulesVariantsAtom,
} from "src/pages/Containers/store/atoms";
import { CustomMergeRule } from "src/pages/Containers/store/types";
import { useOmniState, useOmniValue } from "src/utils/atoms";

import { MergeRuleVariants } from "./components/MergeRuleVariants";

export const ModalMergeRule = () => {
	const filesResponse = useOmniValue(containersFilesResponseAtom)!;
	const [mergeRules, setMergeRules] = useOmniState<CustomMergeRule[]>(containersMergeRulesAtom);
	const [variants, setVariants] = useOmniState(containersMergeRulesVariantsAtom);
	const unusedVariants = filesResponse.variants.filter(variant => !variants.includes(variant));

	const { closeModal } = useModals();

	const close = () => {
		closeModal(ModalType.MERGE_RULE);
	};

	const toggleVariantChecked = (variant: string) => {
		const index = variants.findIndex(v => v === variant);

		if (index !== -1) {
			const copyMergeRulesVariants = [...variants];
			copyMergeRulesVariants.splice(index, 1);
			setVariants(copyMergeRulesVariants);
		} else {
			setVariants([...variants, variant]);
		}
	};

	const saveMergeRule = () => {
		setMergeRules([
			...mergeRules,
			{ id: mergeRules.length > 0 ? mergeRules[mergeRules.length - 1].id + 1 : 1, variants },
		]);
		close();
	};

	useEffect(() => {
		return () => {
			setVariants([]);
		};
	}, []);

	return (
		<Modal modal={ModalType.MERGE_RULE} width="400px">
			<Box direction="column" padding="0 20px" gap="20px">
				<Box direction="column" gap="30px">
					<Box direction="column" gap="10px">
						<Text variant="body2">Used Variants</Text>

						{variants.map((variant, i) => (
							<MergeRuleVariants
								key={i}
								variant={variant}
								toggleChecked={toggleVariantChecked}
								checked
							/>
						))}
					</Box>

					<HorizontalDivider />

					<Box direction="column" gap="10px">
						<Text variant="body2">Unused Variants</Text>

						{unusedVariants.map((variant, i) => (
							<MergeRuleVariants
								key={i}
								variant={variant}
								checked={false}
								toggleChecked={toggleVariantChecked}
							/>
						))}
					</Box>
				</Box>

				<Box justify="between">
					<Button theme="red" variant="inverted" onClick={close}>
						Cancel
					</Button>

					<Button theme="primary" onClick={saveMergeRule} disabled={variants.length === 0}>
						Save
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};
