import { pages } from "src/utils";
import styled from "styled-components";

import { DashButton } from "./components/DashButton";

const DashboardContainer = styled.div`
	display: grid;
	height: 100%;
	background-image: url(background.svg);
	background-size: cover;
	background-position: center;
`;

const DashboardButtons = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 40px;
	justify-self: center;
	align-self: center;
`;

export const Dashboard = () => (
	<>
		<DashboardContainer>
			<DashboardButtons>
				<DashButton title="Bays" url={pages.bays} icon="bays" />

				<DashButton title="Freezers" url={pages.freezers} icon="freezer" />

				<DashButton title="Containers" url={pages.containers} icon="workbench" />
			</DashboardButtons>
		</DashboardContainer>
	</>
);
