export const enum FreezersTab {
	FILES = "Files",
	MUDMAP = "Mudmap",
	PLANOGRAM = "Planogram",
}

export const enum FreezersVisualizerColorsBy {
	VARIANT = "cdt0",
	SEGMENT = "cdt1",
	BLOCK = "cdt2",
}
