import React from "react";
import { Box, IconV2, Text } from "src/elements";
import { Color } from "src/utils";

interface Props {
	data: { [key: string]: string | null };
	required?: boolean;
}

export const InputFiles: React.FC<Props> = ({ data, required }) => (
	<Box gap="15px" justify="center">
		{Object.entries(data).map(([label, file], index) => (
			<Box gap="5px" align="center" key={index}>
				<Text variant="small2" color={Color.textSecondary} whiteSpace="nowrap">{`${label}:`}</Text>

				<Box align="center" gap="5px">
					<IconV2
						name={file ? "infoSuccess" : "infoWarning"}
						color={file ? Color.primary : required ? Color.yellow : Color.textSecondary}
					/>

					<Text
						variant="small1"
						color={file ? Color.primary : required ? Color.yellow : Color.textSecondary}
					>{` ${file || "Not uploaded"}`}</Text>
				</Box>
			</Box>
		))}
	</Box>
);
