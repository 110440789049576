import { useEffect } from "react";
import { Login } from "src/pages";
import { useAuth } from "src/store/auth";

export const Logout = () => {
	const { logout } = useAuth();

	useEffect(() => {
		logout();
	}, []);

	// Show login page for performance
	return <Login />;
};
