import { omniAtom, useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { LogsTab } from "../types";

const prefix = "logs";

const logsAtom = omniAtom({
	key: "logs",
	value: {
		filesResponse: {},
		mudmapPayload: {},
		mudmapResponse: {},
		planogramPayload: {},
		planogramResponse: {},
		rebuildPayload: {},
		rebuildResponse: {},
		objectivesPayload: {},
		objectivesResponse: {},
		minorReviewPayload: {},

		freezersMudmapPayload: {},
		freezersMudmapResponse: {},
		freezersPlanogramPayload: {},
		freezersPlanogramResponse: {},
	},
});

export const logsTabAtom = omniAtom({
	prefix,
	key: "tab",
	value: LogsTab.FILES,
});

export const useLogs = () => {
	const logs = useOmniValue(logsAtom);

	const setTab = useSetOmniValue(logsTabAtom);

	const setFilesResponse = (data: any) => {
		logs.filesResponse = data;
	};

	const setMudmapPayload = (data: any) => {
		logs.mudmapPayload = data;
	};

	const setMudmapResponse = (data: any) => {
		logs.mudmapResponse = data;
	};

	const setPlanogramPayload = (data: any) => {
		logs.planogramPayload = data;
	};

	const setPlanogramResponse = (data: any) => {
		logs.planogramResponse = data;
	};

	const setRebuildPayload = (data: any) => {
		logs.rebuildPayload = data;
	};

	const setRebuildResponse = (data: any) => {
		logs.rebuildResponse = data;
	};

	const setObjectivesPayload = (data: any) => {
		logs.objectivesPayload = data;
	};

	const setObjectivesResponse = (data: any) => {
		logs.objectivesResponse = data;
	};

	const setMinorReviewPayload = (data: any) => {
		logs.minorReviewPayload = data;
	};

	const setFreezersMudmapPayload = (data: any) => {
		logs.freezersMudmapPayload = data;
	};

	const setFreezersMudmapResponse = (data: any) => {
		logs.freezersMudmapResponse = data;
	};

	const setFreezersPlanogramPayload = (data: any) => {
		logs.freezersPlanogramPayload = data;
	};

	const setFreezersPlanogramResponse = (data: any) => {
		logs.freezersPlanogramResponse = data;
	};

	return {
		setTab,
		logs,
		setFilesResponse,
		setMudmapPayload,
		setMudmapResponse,
		setPlanogramPayload,
		setPlanogramResponse,
		setRebuildPayload,
		setRebuildResponse,
		setObjectivesPayload,
		setObjectivesResponse,
		setMinorReviewPayload,
		setFreezersMudmapPayload,
		setFreezersMudmapResponse,
		setFreezersPlanogramPayload,
		setFreezersPlanogramResponse,
	};
};
