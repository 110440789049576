import { useEffect } from "react";
import { Box, Button } from "src/elements";
import { ButtonTheme } from "src/elements/Button";
import { Color, zIndex } from "src/utils";
import styled from "styled-components";

import { useModals } from "../store/atoms";
import { ModalType, ModalVariant } from "../types";
import { Header } from "./Header";

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: grid;
	z-index: ${zIndex.MODALS};

	> * {
		grid-column: 1;
		grid-row: 1;
	}
`;

const Dimmer = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
`;

const ModalElement = styled.div<{ variant: ModalVariant; width: string; height?: string }>`
	justify-self: center;
	align-self: center;
	flex-direction: column;
	width: 100%;
	max-width: ${({ width }) => width};
	height: ${({ height }) => height};
	max-height: 80vh;
`;

const Content = styled.div`
	flex: 1;
	min-height: 0;
	background-color: ${Color.white};
	padding: 20px 0;
	gap: 20px;
	border-radius: 0 0 5px 5px;
	flex-direction: column;
	overflow: hidden;
`;

interface Props {
	modal: ModalType;
	variant?: "modal" | "popup";
	width?: string;
	height?: string;
	children: React.ReactNode;
	buttons?: {
		label: string;
		variant?: ButtonTheme;
		watermark?: string;
		disabled?: boolean;
		onClick: () => void;
	}[];
	onClose?: () => void;
	overflowY?: "unset";
}

export const Modal: React.FC<Props> = ({
	modal,
	variant = "modal",
	width = "800px",
	height,
	children,
	buttons,
	onClose,
}) => {
	const { closeModal } = useModals();

	useEffect(() => {
		return () => {
			if (onClose) onClose();
		};
	}, []);

	const close = () => {
		closeModal(modal);
	};

	return (
		<ModalContainer>
			<Dimmer />

			<ModalElement width={width} height={height} variant={variant}>
				<Header close={close}>{modal}</Header>

				<Content>
					{children}

					{buttons && (
						<Box justify="center" direction="row-reverse">
							<Box alignSelf="end">
								{buttons?.map(({ label, variant, watermark, disabled, onClick }, index) => (
									<Button
										key={index}
										theme={variant}
										watermark={watermark}
										disabled={disabled}
										onClick={onClick}
									>
										{label}
									</Button>
								))}
							</Box>
						</Box>
					)}
				</Content>
			</ModalElement>
		</ModalContainer>
	);
};
