import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { DragButtons, Radios } from "src/elements";
import { ButtonDropdown } from "src/elements/Button";
import { useLogs } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import { baysOptimizeAtom, baysPrioritiesAtom } from "src/pages/Bays/store/atoms";
import { OptimizeOn } from "src/pages/Bays/store/types";
import { Color } from "src/utils";
import { useOmniState } from "src/utils/atoms";
import styled from "styled-components";

import { ButtonGenerateMudmap } from "./ButtonGenerateMudmap";

const VerticalDivider = styled.div`
	width: 1px;
	height: 100%;
	background: ${Color.lightGrayHover};
`;

export const PanelMudmap = () => {
	const [priorities, setPriorities] = useOmniState(baysPrioritiesAtom);
	const [optimize, setOptimize] = useOmniState(baysOptimizeAtom);

	const { openModal } = useModals();
	const { setTab: setLogsTab } = useLogs();

	const optimizeRadios = [
		{ label: "Profit", value: OptimizeOn.PROFIT },
		{ label: "Sales", value: OptimizeOn.SALES },
		{ label: "Dispersion", value: OptimizeOn.DISPERSION },
	];

	const editDropdown: ButtonDropdown[] = [
		{ title: "Core Range", onClick: () => openModal(ModalType.CORE_RANGE) },
	];

	const openLogs = () => {
		setLogsTab(LogsTab.MUDMAP);
		openModal(ModalType.LOGS);
	};

	return (
		<>
			<Box height="100%" gap="20px">
				<DragButtons data={priorities} setData={setPriorities} title="Priorities" />

				<VerticalDivider />

				<Radios
					title="Optimize"
					radios={optimizeRadios}
					checked={optimize}
					setChecked={setOptimize}
				/>
			</Box>

			<Box gap="10px">
				<Button theme="greenSmoke" onClick={openLogs} variant="inverted">
					Logs
				</Button>

				<Button theme="yellow" dropdown={editDropdown}>
					Edit
				</Button>

				<ButtonGenerateMudmap />
			</Box>
		</>
	);
};
