import { Button } from "src/elements";
import { baysSegmentVersionAtom } from "src/pages/Bays/store/atoms";
import { useGenerateMudmap } from "src/store/bays";
import { tooltip } from "src/utils";
import { useOmniValue } from "src/utils/atoms";

interface Props {
	disabled?: boolean;
}

export const ButtonGenerateMudmap: React.FC<Props> = ({ disabled }) => {
	const segmentVersion = useOmniValue(baysSegmentVersionAtom);
	const { generateMudmap } = useGenerateMudmap();

	return (
		<Button
			theme="primary"
			watermark={segmentVersion}
			onClick={generateMudmap}
			disabled={disabled}
			onMouseEnter={event => disabled && tooltip({ event, data: "Disabled in visualizer mode" })}
		>
			Generate Mudmap
		</Button>
	);
};
