import { useEffect, useRef, useState } from "react";
import { Box, Scroller, TextInput, TextInputV2 } from "src/elements";
import { TextInputElement } from "src/elements/Input/TextInput";
import { Color } from "src/utils";
import styled from "styled-components";

import { MenuState, Option as OptionType } from "../types";
import { OptionDivider } from "./Option";
import { Option } from "./Option";

const MenuElement = styled.div<{ x?: number; y?: number; width?: number; open: boolean }>`
	position: fixed;
	top: ${({ y }) => `${y}px`};
	left: ${({ x }) => `${x}px`};
	min-width: ${({ width }) => `${width}px`};
	width: min-content;
	flex-direction: column;
	background: ${Color.white};
	border: 1px solid ${Color.primary};
	border-radius: 4px;
	box-shadow: 0px 4px 7px rgba(157, 173, 184, 0.2);
	cursor: default;

	${({ open }) => !open && "display: none;"}

	input {
		border: none;
	}
`;

interface Props {
	state: MenuState;
	value: any;
	setValue: (value: any) => void;
	close: () => void;
	refMenu: React.ForwardedRef<HTMLDivElement>;
	useSearch?: boolean;
	data: OptionType[];
	placeholder?: string;
}

export const Menu: React.FC<Props> = ({
	state: { x, y, width, open },
	value,
	setValue,
	close,
	refMenu,
	useSearch,
	placeholder,
	data,
}) => {
	const [search, setSearch] = useState("");
	const refSearch = useRef<HTMLInputElement>(null);

	const filteredData = (
		useSearch && search
			? data.filter(option =>
					String(option.title || option.value)
						.toLocaleLowerCase()
						.includes(search.toLocaleLowerCase()),
			  )
			: data
	).sort((o1, o2) => (o1.value === value ? -1 : 1));

	const selectOption = (value: any) => {
		setValue(value);
		close();
		clearSearch();
	};

	const clearSearch = () => {
		setSearch("");
		focusSearch();
	};

	const focusSearch = () => {
		refSearch.current?.focus();
	};

	useEffect(() => {
		if (open) {
			focusSearch();
		}
	}, [open]);

	return (
		<MenuElement ref={refMenu} x={x} y={y} width={width} open={open}>
			{useSearch && (
				<TextInputV2
					refInput={refSearch}
					width="100%"
					value={search}
					setValue={setSearch}
					placeholder={placeholder}
					leftIcon={useSearch && { icon: "search" }}
					rightIcon={{
						icon: useSearch && search ? "clear" : "expandUp",
						onClick: useSearch && search ? clearSearch : close,
					}}
				/>
			)}

			<Box minWidth="100%" direction="column">
				{useSearch && <OptionDivider />}

				<Scroller gutter="auto" padding="0" margin={0} size="thin" gap="0" maxHeight="300px">
					{filteredData.map((option, i) => (
						<Option
							key={i}
							value={option.value}
							setValue={selectOption}
							active={option.value === value}
							icon={!useSearch && i === 0 && "expandUp"}
						>
							{option.title || option.value}
						</Option>
					))}
				</Scroller>

				{useSearch && data.length === 0 && "No results found..."}
			</Box>
		</MenuElement>
	);
};
