import { atom } from "jotai";
import { ToastType, useToasts } from "src/components/Toasts";
import { omniAtom, useSetOmniValue } from "src/utils/atoms";

import { ConditionalItem, ItemConstraintCondition } from "./types";

export const conditionalItemsAtom = omniAtom({
	key: "conditionalItems",
	value: [] as ConditionalItem[],
});

export const useConditionalItems = () => {
	const { toast } = useToasts();
	const setConditionalItems = useSetOmniValue(conditionalItemsAtom);

	const addConditionalItem = (conditionalItem: ConditionalItem) => {
		if (conditionalItem.firstItem === conditionalItem.secondItem) {
			return toast({
				title: "Failed to add conditional items",
				description: "Items need to be different",
				type: ToastType.ERROR,
			});
		}

		setConditionalItems(state => [...state, conditionalItem]);
	};

	const removeConditionalItem = (index: number) => {
		setConditionalItems(state => {
			const conditionalItems = [...state];
			conditionalItems.splice(index, 1);
			return conditionalItems;
		});
	};

	return { addConditionalItem, removeConditionalItem };
};

export const conditionalItemsBothOrNoneAtom = atom(get =>
	get(conditionalItemsAtom)
		.filter(conditionalItem => conditionalItem.constraint === ItemConstraintCondition.EITHER_OR)
		.map(eitherOr => [eitherOr.firstItem, eitherOr.secondItem]),
);
conditionalItemsBothOrNoneAtom.debugLabel = "conditionalItems/bothOrNone";

export const conditionalItemsEitherOrAtom = atom(get =>
	get(conditionalItemsAtom)
		.filter(conditionalItem => conditionalItem.constraint === ItemConstraintCondition.EITHER_OR)
		.map(eitherOr => [eitherOr.firstItem, eitherOr.secondItem]),
);
conditionalItemsEitherOrAtom.debugLabel = "conditionalItems/eitherOr";

export const conditionalItemsPrerequisitesAtom = atom(get =>
	get(conditionalItemsAtom)
		.filter(conditionalItem => conditionalItem.constraint === ItemConstraintCondition.PREREQUISITE)
		.map(conditionalItem => ({
			item: conditionalItem.firstItem,
			requires: conditionalItem.secondItem,
		})),
);
conditionalItemsPrerequisitesAtom.debugLabel = "conditionalItems/prerequisites";
