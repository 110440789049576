import { RefObject, useEffect, useState } from "react";
import React from "react";
import styled from "styled-components";

import { InputElement, InputOuterContainer } from "./components/Input";
import { Label } from "./components/Label";
import { NumberArrows } from "./components/NumberArrows";

const InputNumberContainer = styled.div`
	position: relative;
	width: 100%;

	&:hover .input-number-arrows {
		opacity: 1;
	}
`;

interface Props {
	readonly defaultValue?: number;
	readonly label?: string;
	readonly min?: number;
	readonly max?: number;
	readonly increment?: number;
	readonly placeholder?: string;
	readonly disabled?: boolean;
	readonly onChange?: (value: number) => void;
}

export interface NumberInputElement extends HTMLInputElement {
	updateValue: (value?: number) => void;
}

export const NumberInput = React.forwardRef<NumberInputElement | null, Props>(
	({ label, defaultValue = 0, min, max, increment = 1, placeholder, disabled, onChange }, ref) => {
		const [value, setValue] = useState(defaultValue || 0);

		useEffect(() => {
			const input = ref as RefObject<NumberInputElement | null>;
			if (!input || !input.current) return;

			input.current.updateValue = (value?: number) => {
				if (value !== undefined) setValue(value);
			};
		}, [ref]);

		return (
			<InputOuterContainer label={label}>
				<InputNumberContainer>
					<InputElement
						ref={ref}
						type="number"
						min={min}
						max={max}
						placeholder={placeholder}
						value={value}
						disabled={disabled}
						rightIcon={true}
						onChange={event => {
							const value = Number(event.target.value);
							setValue(value);
							if (onChange) onChange(value);
						}}
					/>

					<NumberArrows
						value={value}
						setValue={value => {
							setValue(value);
							if (onChange) onChange(value);
						}}
						min={min}
						max={max}
						increment={increment}
					/>

					<Label label={label} />
				</InputNumberContainer>
			</InputOuterContainer>
		);
	},
);
