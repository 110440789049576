import hexToRgba from "hex-to-rgba";
import React from "react";
import { Text } from "src/elements";
import styled from "styled-components";

import { Group as GroupType } from "../../store/types";

const GroupElement = styled.div<{ width: number; height: number; x: number; y: number }>`
	position: absolute;
	justify-content: center;
	align-items: center;
	width: ${({ width }) => `calc(var(--scale) * ${width}px)`};
	height: ${({ height }) => `calc(var(--scale) * ${height}px)`};
	left: ${({ x }) => `calc(var(--scale) * ${x}px)`};
	bottom: ${({ y }) => `calc(var(--scale) * ${y}px)`};
	background-color: ${hexToRgba("#ffc0cb", 0.5)};
	box-shadow: inset 0 0 0 1px rgba(255, 0, 0, 0.1);
	font-weight: bold;
	color: rgba(255, 0, 0, 0.7);
`;

interface Props {
	group: GroupType;
}

export const Group: React.FC<Props> = ({ group: { group_id: id, width, height, x, y } }) => (
	<GroupElement className="group" width={width} height={height} x={x} y={y}>
		<Text>g{id}</Text>
	</GroupElement>
);
