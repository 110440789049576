import React from "react";
import { OmniState, useOmniState } from "src/utils/atoms";

import { Dropdown } from "./Dropdown";
import { DropdownProps } from "./types";

interface Props extends DropdownProps {
	state: OmniState<any>;
}

export const StateDropdown: React.FC<Props> = ({
	state,
	data,
	label,
	placeholder,
	useSearch,
	width,
}) => {
	const [value, setValue] = useOmniState(state);

	return (
		<Dropdown
			data={data}
			label={label}
			value={value}
			setValue={setValue}
			placeholder={placeholder}
			useSearch={useSearch}
			width={width}
		/>
	);
};
