import React, { useRef } from "react";
import { Box, IconV2, Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

import { InputFile } from "./types";

const Input = styled.input`
	display: none;
`;

interface Props {
	files: InputFile[];
}

const InputFileElement: React.FC<InputFile> = ({ title, file, setFile }) => {
	const refFile = useRef<HTMLInputElement>(null);

	const openFileUploader = () => {
		if (refFile.current) {
			refFile.current.click();
		}
	};

	const onFileUploaded = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const file = event.target.files.item(0);
			setFile(file);
		}
	};

	return (
		<Box gap="5px" align="center" onClick={openFileUploader} minWidth="0">
			<Text variant="small2" color={Color.textSecondary} whiteSpace="nowrap">
				{title}:
			</Text>

			<Box align="center" gap="5px" minWidth="0">
				<Box minWidth="14px">
					<IconV2
						name={file ? "infoSuccess" : "infoWarning"}
						color={file ? Color.primary : Color.yellow}
					/>
				</Box>

				<Text
					variant="small1"
					color={file ? Color.primary : Color.yellow}
					whiteSpace="nowrap"
					textOverflow="ellipsis"
					overflow="hidden"
				>{` ${file?.name || "Not uploaded"}`}</Text>
			</Box>

			<Input ref={refFile} type="file" accept=".csv" onChange={onFileUploaded} />
		</Box>
	);
};

export const InputFilesV2: React.FC<Props> = ({ files }) => (
	<Box gap="15px" minWidth="0">
		{files.map(({ title, file, setFile }, index) => (
			<InputFileElement key={index} title={title} file={file} setFile={setFile} />
		))}
	</Box>
);
