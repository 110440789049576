import hexToRgba from "hex-to-rgba";
import React from "react";
import { Text } from "src/elements";
import { Color } from "src/utils";
import styled from "styled-components";

const LabelElement = styled.div`
	position: absolute;
	top: -5px;
	left: 4px;
	width: min-content;
	padding: 0 3px;
	border-radius: 3px;
	background: linear-gradient(
		90deg,
		${hexToRgba(Color.white, 0.5)} 0%,
		${Color.white} 10%,
		${Color.white} 90%,
		${hexToRgba(Color.white, 0.5)} 100%
	);
	pointer-events: none;
	user-select: none;
	z-index: 1;
	white-space: nowrap;
`;

interface Props {
	label?: string;
	required?: boolean;
	active?: boolean;
}

export const Label: React.FC<Props> = ({ label, required, active }) =>
	label ? (
		<LabelElement>
			<Text variant="small3" color={active ? Color.primaryActive : Color.textSecondary}>
				{`${label}:`}
			</Text>

			{required && (
				<Text variant="small3" color={Color.red}>
					{"\u00a0*"}
				</Text>
			)}
		</LabelElement>
	) : null;
