import React from "react";
import {
	ModalConditionalItems,
	ModalCoreRange,
	ModalGroupsParameters,
	ModalLogs,
	ModalMergeRule,
	ModalMergeRules,
	ModalMinorReview,
	ModalOutputEditor,
	ModalRelaxations,
} from "src/modals";
import { useOmniValue } from "src/utils/atoms";

import { modalsState } from "./store/atoms";
import { ModalType } from "./types";

export const Modals = () => {
	const modals = useOmniValue(modalsState);

	return (
		<React.Fragment>
			{modals.map((modal, i) => {
				switch (modal) {
					case ModalType.MINOR_REVIEW:
						return <ModalMinorReview key={i} />;

					case ModalType.RELAXATIONS:
						return <ModalRelaxations key={i} />;

					case ModalType.LOGS:
						return <ModalLogs key={i} />;

					case ModalType.CONDITIONAL_ITEMS:
						return <ModalConditionalItems key={i} />;

					case ModalType.CORE_RANGE:
						return <ModalCoreRange key={i} />;

					case ModalType.OUTPUT_EDITOR:
						return <ModalOutputEditor key={i} />;

					case ModalType.MERGE_RULES:
						return <ModalMergeRules key={i} />;

					case ModalType.MERGE_RULE:
						return <ModalMergeRule key={i} />;

					case ModalType.GROUPS_PARAMETERS:
						return <ModalGroupsParameters key={i} />;
				}

				// Needs to be unreachable
				return null;
			})}
		</React.Fragment>
	);
};
