import { Modal } from "src/components";
import { baysMudmapAtom } from "src/components/Bays/store/atoms";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Tabs, Text } from "src/elements";
import { StateRange } from "src/elements/RangeV2/StateRange";
import { ButtonGenerateMudmap } from "src/pages/Bays/components/ControlPanel/components/ButtonGenerateMudmap";
import { ButtonGeneratePlanogram } from "src/pages/Bays/components/ControlPanel/components/ButtonGeneratePlanogram";
import { baysChurnLimitAtom } from "src/pages/Bays/store/atoms";
import { useOmniValue } from "src/utils/atoms";

import {
	changeRelaxationsConditionalCoreTab,
	relaxationsConditionalCoreTabs,
} from "../data/relaxationsConditonalCore";
import { Items } from "./components/Items";

export const ModalCoreRange = () => {
	const mudmap = useOmniValue(baysMudmapAtom);
	const { openModal, closeModal } = useModals();

	return (
		<Modal modal={ModalType.CORE_RANGE} height="100%" width="800px">
			{mudmap && (
				<Box padding="0 20px">
					<Tabs
						width="100%"
						tabs={relaxationsConditionalCoreTabs}
						tab={ModalType.CORE_RANGE}
						setTab={tab => changeRelaxationsConditionalCoreTab(tab, openModal, closeModal)}
					/>
				</Box>
			)}

			<Box padding="0 20px" direction="column" gap="5px">
				<Text variant="body3">
					Churn capping will be used to calculate the amount of products that can be removed from
					the Planogram.
				</Text>

				<Box width="200px">
					<StateRange min={0} max={100} unit="%" state={baysChurnLimitAtom} />
				</Box>
			</Box>

			<Box padding="0 20px" flex="1" minHeight="0">
				<Items />
			</Box>

			<Box justify="center">{!mudmap ? <ButtonGenerateMudmap /> : <ButtonGeneratePlanogram />}</Box>
		</Modal>
	);
};
