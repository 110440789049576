import { Settings, Zoomer } from "src/components";
import { VisualizerColorBy } from "src/components/Bays/types";
import { Box } from "src/elements";
import { StateDropdown } from "src/elements";
import { baysColorsByAtom, baysZoomAtom } from "src/pages/Bays/store/atoms";
import { Color } from "src/utils";
import styled from "styled-components";

import { Settings as BaysSettings } from "./Settings";

const VerticalDivider = styled.div`
	width: 1px;
	height: 100%;
	background: ${Color.lightGrayHover};
`;

export const TopRight = () => (
	<Box gap="20px" align="center" flex="1" justify="right">
		<Zoomer name="bays" state={baysZoomAtom} />

		<StateDropdown
			label="Colors by"
			width="110px"
			data={[
				{
					title: "Segment",
					value: VisualizerColorBy.SEGMENT,
				},
				{
					title: "Block",
					value: VisualizerColorBy.BLOCK,
				},
				{
					title: "Seg/Block",
					value: VisualizerColorBy.SEG_BLOCK,
				},
			]}
			state={baysColorsByAtom}
		/>

		<VerticalDivider />

		<Settings>
			<BaysSettings />
		</Settings>
	</Box>
);
