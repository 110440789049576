import { Color } from "src/utils";
import styled from "styled-components";

export const HorizontalDivider = styled.div`
	height: 1px;
	width: 100%;
	background: ${Color.lightGrayHover};
`;

export const VerticalDivider = styled.div`
	width: 1px;
	height: 100%;
	background: ${Color.lightGrayHover};
`;
