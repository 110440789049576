import React, { useEffect, useState } from "react";
import { Box, IconType, IconV2, Radios, Text, TextInput } from "src/elements";
import { Color } from "src/utils";
import { OmniState, useOmniState } from "src/utils/atoms";
import styled from "styled-components";

const Options = styled.div`
	background-color: ${Color.opacitySky};
	border: 1px solid ${Color.opacitySkyHover};
	border-radius: 4px;
	padding: 0 10px 10px 10px;
	margin-top: 10px;
	gap: 15px;
`;

const CustomOption: React.FC<{
	customValue: string;
	setCustomValue: (customValue: string) => void;
}> = ({ customValue, setCustomValue }) => (
	<Box>
		<TextInput defaultValue={customValue} placeholder="Custom" onChange={setCustomValue} />
	</Box>
);

interface Props {
	icon: IconType;
	title: string;
	options: { label: string; value: string }[];
	state: OmniState<string>;
}

export const SettingsSelectable: React.FC<Props> = ({ icon, title, options, state }) => {
	const [value, setValue] = useOmniState(state);
	const [customValue, setCustomValue] = useState<string | null>(null);

	useEffect(() => {
		if (customValue !== null) setValue(customValue);
	}, [customValue]);

	return (
		<Box direction="column">
			<Box justify="between" gap="10px">
				<Box gap="10px" align="center">
					<IconV2 name={icon} size={14} />
					<Text variant="body3" color={Color.textSecondary} whiteSpace="nowrap">
						{title}
					</Text>
				</Box>

				<Box
					background={Color.lightGrayHover}
					padding="3px"
					borderRadius="4px"
					align="center"
					minWidth="18px"
					maxWidth="48px"
					justify="center"
				>
					<Text
						variant="caption3"
						color={Color.spaceGrayHover}
						whiteSpace="nowrap"
						textOverflow="ellipsis"
						overflow="hidden"
					>
						{value}
					</Text>
				</Box>
			</Box>

			<Options>
				<Radios
					direction="column"
					radios={[
						...options,
						{
							label: (
								<CustomOption customValue={customValue || ""} setCustomValue={setCustomValue} />
							),
							value: customValue,
						},
					]}
					checked={value}
					setChecked={setValue}
				/>
			</Options>
		</Box>
	);
};
