import { omniAtom, useSetOmniValue } from "src/utils/atoms";

export const minorReviewAtom = omniAtom({
	key: "minorReview",
	value: { add: [], remove: [] } as { add: File[]; remove: File[] },
});

export const useMinorReview = () => {
	const setMinorReview = useSetOmniValue(minorReviewAtom);

	const addAdd = (file: File) =>
		setMinorReview(state => {
			const add = [...state.add];
			if (!add.find(f => f.name === file.name)) add.push(file);
			return { ...state, add };
		});

	const removeAdd = (file: File) =>
		setMinorReview(state => {
			const add = [...state.add];
			const index = add.findIndex(f => f.name === file.name);
			if (index !== -1) add.splice(index, 1);
			return { ...state, add };
		});

	const deleteAdd = () => setMinorReview(state => ({ ...state, add: [] }));

	const addRemove = (file: File) =>
		setMinorReview(state => {
			const remove = [...state.remove];
			if (!remove.find(f => f.name === file.name)) remove.push(file);
			return { ...state, remove };
		});

	const removeRemove = (file: File) =>
		setMinorReview(state => {
			const remove = [...state.remove];
			const index = remove.findIndex(f => f.name === file.name);
			if (index !== -1) remove.splice(index, 1);
			return { ...state, remove };
		});

	const deleteRemove = () => setMinorReview(state => ({ ...state, remove: [] }));

	return {
		addAdd,
		removeAdd,
		deleteAdd,
		addRemove,
		removeRemove,
		deleteRemove,
	};
};
