import React, { useEffect } from "react";

function useOnClickOutside(ref: React.RefObject<HTMLElement | undefined>, handler: () => void) {
	useEffect(() => {
		if (!ref.current) return;

		const listener = (event: any) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler();
		};

		setTimeout(() => {
			document.addEventListener("click", listener);
		}, 0);

		return () => {
			document.removeEventListener("click", listener);
		};
	}, [ref, handler]);
}

export { useOnClickOutside };
