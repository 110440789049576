import {
	Atom as JotaiAtom,
	atom as jotaiAtom,
	useAtom as useJotaiState,
	useAtomValue as useJotaiValue,
	useSetAtom,
} from "jotai";

export interface OmniState<T> extends JotaiAtom<T> {}

export function omniAtom<T>({ prefix, key, value }: { prefix?: string; key: string; value: T }) {
	const atom = jotaiAtom(value);
	atom.debugLabel = `${prefix ? prefix + "/" : ""}${key}`;
	return atom;
}

export function useOmniState<T>(state: JotaiAtom<T>) {
	const [value, setValue] = useJotaiState(state);
	return [value, setValue] as [value: T, setValue: (value: T) => void];
}

export { useSetAtom as useSetOmniValue };
export { useJotaiValue as useOmniValue };
