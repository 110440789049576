import { useState } from "react";
import { Box, IconV2, Text } from "src/elements";
import { inputHeight } from "src/elements/Input";
import { containersMergeRulesAtom } from "src/pages/Containers/store/atoms";
import { CustomMergeRule } from "src/pages/Containers/store/types";
import { Color, Transition } from "src/utils";
import { useSetOmniValue } from "src/utils/atoms";
import styled from "styled-components";

const Delete = styled.div<{ hovered: boolean }>`
	width: ${inputHeight};
	height: 100%;
	align-items: center;
	justify-content: center;
	transition: opacity ${Transition.fast};
	opacity: ${({ hovered }) => (hovered ? 1 : 0)};
	user-select: none;
	cursor: pointer;
`;

const MergeRuleElement = styled.div`
	justify-content: space-between;
	align-items: center;
	border: 1px solid ${Color.lightGrayHover};
	border-radius: 4px;
	user-select: none;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`;

export const MergeRule: React.FC<CustomMergeRule> = ({ id, variants }) => {
	const [hovered, setHovered] = useState(false);
	const setMergeRules = useSetOmniValue(containersMergeRulesAtom);

	const deleteMergeRule = () => {
		setMergeRules(mergeRules => mergeRules.filter(mergeRule => mergeRule.id !== id));
	};

	const onEnter = () => {
		setHovered(true);
	};

	const onLeave = () => {
		setHovered(false);
	};

	return (
		<MergeRuleElement onPointerEnter={onEnter} onPointerLeave={onLeave}>
			<Box gap="10px" align="center" padding="10px">
				<IconV2 name="arrange" />

				<Text variant="small2">{variants.join(", ")}</Text>
			</Box>

			<Delete hovered={hovered} onClick={deleteMergeRule}>
				<IconV2 name="delete" color={Color.red} />
			</Delete>
		</MergeRuleElement>
	);
};
