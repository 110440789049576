import { StateToggle } from "src/elements";
import { baysShowTooltipsAtom } from "src/pages/Bays/store/atoms";

export const Settings = () => {
	return (
		<>
			<StateToggle icon="tooltip" label="Tooltips" state={baysShowTooltipsAtom} />
		</>
	);
};
