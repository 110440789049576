import { Button, IconType, IconV2 } from "src/elements";
import { Text } from "src/elements";
import { Transition, dataDisabled } from "src/utils";
import { useRedirect } from "src/utils/common";
import styled from "styled-components";

const DashButtonContainer = styled.div`
	transition: transform ${Transition.hover};

	button {
		border: 0;
	}

	&:not([data-disabled]):hover {
		transform: scale(1.05);
	}
`;

const DashButtonElement = styled.div`
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 0 80px;
	height: 90px;
`;

interface Props {
	title: string;
	url: string;
	icon: IconType;
	watermark?: string;
	disabled?: boolean;
}

export const DashButton: React.FC<Props> = ({ title, url, icon, watermark, disabled }) => {
	const { redirect } = useRedirect();

	return (
		<DashButtonContainer {...dataDisabled(disabled)}>
			<Button
				theme="greenSmoke"
				variant="inverted"
				width="100%"
				watermark={watermark}
				onClick={() => redirect(url)}
				disabled={disabled}
			>
				<DashButtonElement>
					<IconV2 name={icon} size={22} color="var(--text)" />
					<Text variant="subtitle3" color="var(--text)">
						{title}
					</Text>
				</DashButtonElement>
			</Button>
		</DashButtonContainer>
	);
};
