import { useEffect, useRef, useState } from "react";
import { useCustomEventListener } from "react-custom-events";

import { TooltipSide } from "../types";
import { EVENT_TOOLTIP, content, showTooltip, side } from "../utils";
import { Tooltip } from "./Tooltip";

export const Tooltips = () => {
	const refTooltip = useRef(null);

	// Side override if there is not enough room for the requested side.
	const [sideOverride, setSideOverride] = useState<TooltipSide | null>(null);

	// Inner state for rerender.
	const [rendered, render] = useState([]);

	// Event listener to set inner state to force rerender.
	useCustomEventListener(EVENT_TOOLTIP, _ => {
		render([]);
	});

	useEffect(() => {
		if (refTooltip.current) {
			showTooltip(refTooltip, setSideOverride);
		}
	}, [rendered]);

	if (!content) {
		return null;
	}

	return (
		<Tooltip x={100} y={200} side={sideOverride || side} refTooltip={refTooltip}>
			{content}
		</Tooltip>
	);
};
