import { Tabs } from "src/elements";
import { Tab } from "src/elements/Tabs";
import {
	containersFilesResponseAtom,
	containersGroupsResponseAtom,
	containersPlaceContainersResponseAtom,
	containersStepAtom,
} from "src/pages/Containers/store/atoms";
import { ContainersStep } from "src/pages/Containers/types";
import { useOmniState, useOmniValue } from "src/utils/atoms";

export const Navigation = () => {
	const [step, setStep] = useOmniState(containersStepAtom);

	const filesResponse = useOmniValue(containersFilesResponseAtom);
	const groupsResponse = useOmniValue(containersGroupsResponseAtom);
	const placeContainersResponse = useOmniValue(containersPlaceContainersResponseAtom);

	const tabs = [
		{
			tab: ContainersStep.FILES,
			title: "Files",
		},
		{
			tab: ContainersStep.GROUPS,
			title: "Groups",
			disabled: !filesResponse,
		},
		{
			tab: ContainersStep.PLACE_CONTAINERS,
			title: "Place Containers",
			disabled: !groupsResponse,
		},
		{
			tab: ContainersStep.PLACE_VARIANTS,
			title: "Place Variants",
			disabled: !placeContainersResponse,
		},
	] as Tab<ContainersStep>[];

	return <Tabs tabs={tabs} tab={step} setTab={setStep} theme="steps" />;
};
