import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import { pageAtom } from "src/components/Page/store/atoms";
import { Text } from "src/elements";
import { Color } from "src/utils";
import { useSetOmniValue } from "src/utils/atoms";
import styled from "styled-components";

// @ts-ignore
const LinkElem = styled(LinkRouter)<Props>`
	width: ${({ width }) => width};
	cursor: pointer;
	text-decoration: none;

	> span {
		width: ${({ width }) => width};
	}
`;

interface LinkProps {
	width?: string;
	size?: string;
	to: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

interface Props extends LinkProps {
	children?: React.ReactNode;
}

export const Link: React.FC<Props> = ({ to = "#", size, width, onClick, children }) => {
	const setPage = useSetOmniValue(pageAtom);

	const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (onClick) {
			onClick(event);
		}
		setPage(to);
	};

	return (
		<LinkElem size={size} onClick={onClickHandler} to={to} width={width}>
			<Text color={Color.primary}>{children}</Text>
		</LinkElem>
	);
};
