import { ModalType } from "src/components/Modals";

export const relaxationsConditionalCoreTabs = [
	{ tab: ModalType.RELAXATIONS },
	{ tab: ModalType.CONDITIONAL_ITEMS },
	{ tab: ModalType.CORE_RANGE },
];

export const changeRelaxationsConditionalCoreTab = (
	tab: ModalType,
	openModal: (modal: ModalType) => void,
	closeModal: (modal: ModalType) => void,
) => {
	closeModal(ModalType.CORE_RANGE);
	closeModal(ModalType.RELAXATIONS);
	closeModal(ModalType.CONDITIONAL_ITEMS);
	openModal(tab);
};
