import React, { useState } from "react";
import { Color } from "src/utils";
import styled from "styled-components";

import { textVariants } from "../Text/variants";
import { Label } from "./components/Label";

const TextareaContainer = styled.div<{ height?: string }>`
	position: relative;
	width: 100%;
	height: ${({ height }) => height};
`;

const TextareaElement = styled.textarea<{ height?: string }>`
	width: 100%;
	height: ${({ height }) => height};
	border: 1px solid ${Color.primary};
	border-radius: 4px;
	padding: 10px;
	font-size: ${textVariants.body3.fontSize};
	line-height: ${textVariants.body3.lineHeight};
	font-weight: ${textVariants.body3.fontWeight};
	resize: none;
	outline: none;

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		border-radius: 10px;
	}

	::-webkit-scrollbar-track {
		background: ${Color.white};
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: ${Color.primary};
		border-radius: 10px;
	}
`;

interface Props {
	title: string;
	value: string;
	setValue: (value: string) => void;
	height?: string;
}

export const Textarea: React.FC<Props> = ({ title, value, setValue, height }) => {
	const [focused, setFocused] = useState(false);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(event.currentTarget.value);
	};

	const onFocus = () => {
		setFocused(true);
	};

	const onBlur = () => {
		setFocused(false);
	};

	return (
		<TextareaContainer height={height}>
			<Label label={title} active={focused} />

			<TextareaElement
				height={height}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
		</TextareaContainer>
	);
};
