import { ReactNode } from "react";
import { Box, Text } from "src/elements";
import { Color, Transition, dataOpen, zIndex } from "src/utils";
import styled from "styled-components";

const PanelElement = styled.div`
	flex-direction: column;
	width: 300px;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: ${zIndex.PANELS};
	background-color: ${Color.white};
	box-shadow: -8px 4px 20px rgba(36, 35, 35, 0.2);
	transition: transform ${Transition.slide};

	&:not([data-open]) {
		transform: translateX(calc(100% + 50px));
		pointer-events: none;
	}
`;

interface PanelProps {
	isOpen: boolean;
	children: ReactNode;
}

export const Panel: React.FC<PanelProps> = ({ isOpen, children }) => (
	<PanelElement {...dataOpen(isOpen)}>{children}</PanelElement>
);

interface Props {
	readonly title: string;
	readonly children: ReactNode;
}

export const PanelGroup: React.FC<Props> = ({ title, children }) => (
	<Box gap="5px" column>
		<Text variant="body1">{title}</Text>

		<Box gap="16px" column>
			{children}
		</Box>
	</Box>
);
