import React, { useEffect, useRef } from "react";
import { MudmapBay, MudmapShelf, Mudmap as MudmapType } from "src/types";
import styled from "styled-components";

import { Bay } from "./components/Bay";
import { Shelf } from "./components/Shelf/Shelf";
import { PlanogramVariant } from "./types";
import { onPlanogramMouseMove } from "./utils";
import { PLANOGRAM_CONTAINER } from "./utils/classes";

const MudmapContainer = styled.div`
	height: 100%;
	align-items: flex-end;
	margin-bottom: 10px;
	transform: scale(var(--bays-zoom));
	transform-origin: top left;
`;

interface Props {
	data: MudmapType;
}

export const Mudmap: React.FC<Props> = ({ data: mudmap }) => {
	const refMudmap = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.body.addEventListener("mousemove", onPlanogramMouseMove as any);

		return function cleanup() {
			document.body.removeEventListener("mousemove", onPlanogramMouseMove as any);
		};
	}, []);

	/**
	 * Update planogram scale based on its content.
	 */
	useEffect(() => {
		if (mudmap) updateScaleRatio(mudmap);
	}, [mudmap, refMudmap]);

	const updateScaleRatio = (mudmap: MudmapType) => {
		if (!refMudmap.current) return;

		const planogramHeight = refMudmap.current.parentElement!.offsetHeight - 60;

		let highestBayHeight = 0;
		mudmap.bays.forEach(bay => {
			let shelfHeights = 0;

			bay.shelves.forEach(shelf => {
				shelfHeights += shelf.shelf_thickness;
			});

			highestBayHeight = Math.max(highestBayHeight, bay.bay_height + shelfHeights);
		});

		const scale = Math.floor((planogramHeight / highestBayHeight) * 100) / 100;

		document.body.style.setProperty("--scale", String(scale));
	};

	const getBayWidth = (bay: MudmapBay) => {
		let bayWidth = 0;

		bay.shelves.forEach(shelf => {
			const isShelfMerged = mudmap.meta.merged_shelves
				? mudmap.meta.merged_shelves.some(
						mergedShelvesPair =>
							(mergedShelvesPair[0].bay_no === bay.bay_no &&
								mergedShelvesPair[0].shelf_no === shelf.shelf_no) ||
							(mergedShelvesPair[1].bay_no === bay.bay_no &&
								mergedShelvesPair[1].shelf_no === shelf.shelf_no),
				  )
				: false;

			if (!isShelfMerged && shelf.shelf_width > bayWidth) {
				bayWidth = shelf.shelf_width;
			}
		});

		return bayWidth;
	};

	const getMergedShelves: (
		bay: MudmapBay,
		shelf: MudmapShelf,
		shelves: MudmapShelf[],
	) => MudmapShelf[] = (bay: MudmapBay, shelf: MudmapShelf, shelves: MudmapShelf[]) => {
		// Shelf already used during a merge.
		if (
			mergedShelves.find(
				mergedShelf => mergedShelf.bay_no === bay.bay_no && mergedShelf.shelf_no === shelf.shelf_no,
			)
		) {
			return shelves;
		}

		// Use shelf.
		shelves.push(shelf);
		mergedShelves.push({ bay_no: bay.bay_no, shelf_no: shelf.shelf_no });

		const mergedShelvesPair = mudmap.meta.merged_shelves
			? mudmap.meta.merged_shelves.find(
					mergedShelvesPair =>
						mergedShelvesPair[0].bay_no === bay.bay_no &&
						mergedShelvesPair[0].shelf_no === shelf.shelf_no,
			  )
			: null;

		return mergedShelvesPair
			? getMergedShelves(
					mudmap.bays.find(b => b.bay_no === mergedShelvesPair[1].bay_no)!,
					mudmap.bays
						.find(b => b.bay_no === mergedShelvesPair[1].bay_no)!
						.shelves.find(s => s.shelf_no === shelf.shelf_no)!,
					shelves,
			  )
			: shelves;
	};

	const isMergedToLeft = (bay: MudmapBay) =>
		mudmap.meta.merged_shelves
			? mudmap.meta.merged_shelves.some(pair => pair[1].bay_no === bay.bay_no)
			: false;

	const mergedShelves: {
		bay_no: number;
		shelf_no: number;
	}[] = [];

	return (
		<MudmapContainer ref={refMudmap} className={PLANOGRAM_CONTAINER}>
			{mudmap?.bays.map(bay => (
				<Bay
					key={`bay_${bay.bay_no}`}
					bay={bay}
					type="mudmap"
					width={getBayWidth(bay)}
					mergedToLeft={isMergedToLeft(bay)}
				>
					{bay.shelves.map(shelf => {
						const shelves = getMergedShelves(bay, shelf, []);

						return shelves.length ? (
							<Shelf
								key={`bay_${bay.bay_no}_shelf_${shelf.shelf_no}`}
								bayNo={bay.bay_no}
								shelves={shelves}
								variant={PlanogramVariant.MUDMAP}
							/>
						) : null;
					})}
				</Bay>
			))}
		</MudmapContainer>
	);
};
