import { textVariants } from "src/elements/Text/variants";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { iconPadding, inputHeight } from "../data/constants";

export const InputOuterContainer = styled.div<{ label?: string; width?: string }>`
	width: ${({ width }) => width};
	height: min-content;
	position: relative;
	flex-direction: column;
`;

export const InputInnerContainer = styled.div`
	width: 100%;
	flex-direction: column;
	position: relative;
`;

export const InputElement = styled.input<{
	leftIcon?: boolean;
	rightIcon?: boolean;
}>`
	width: 100%;
	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	background: ${Color.white};
	height: ${inputHeight};
	min-height: ${inputHeight};
	outline: none;

	// Number arrows.
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		appearance: textfield;
	}

	// Transitions for input and placeholder
	&,
	::placeholder {
		transition: border-color ${Transition.input}, color ${Transition.input};
	}

	font-size: ${textVariants.body3.fontSize};
	line-height: ${textVariants.body3.lineHeight};
	font-weight: ${textVariants.body3.fontWeight};

	// Default padding
	padding: 0 7px;

	// Left icon padding
	padding-left: ${({ leftIcon }) => leftIcon && iconPadding};

	// Right icon padding
	padding-right: ${({ rightIcon }) => rightIcon && iconPadding};

	// Default state
	border-color: ${Color.spaceGray};
	color: ${Color.textMain};
	::placeholder {
		color: ${Color.spaceGray};
	}

	// Hover state
	:hover {
		border-color: ${Color.spaceGrayHover};
		::placeholder {
			color: ${Color.spaceGrayHover};
		}
	}

	// Focus and Active state
	:focus,
	:active {
		border-color: ${Color.primary};
		box-shadow: 0px 4px 7px rgba(157, 173, 184, 0.2);
		::placeholder {
			color: ${Color.spaceGrayHover};
		}
	}

	// Error state
	&[data-error] {
		border-color: ${Color.red} !important;

		&,
		::placeholder {
			color: ${Color.red} !important;
		}
	}

	// Disabled state
	&[disabled] {
		border-color: ${Color.lightGrayHover} !important;
		box-shadow: none !important;

		&,
		::placeholder {
			color: ${Color.textDisabled} !important;
		}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0px 1000px ${Color.white} inset;
		box-shadow: 0 0 0px 1000px ${Color.white} inset;
	}
`;
