export interface ItemColor {
	segment: { [k: string]: string };
	block: { [k: string]: string };
}

export interface Request {
	payload: any;
	response: any;
}

export const enum BaysStep {
	FILES = "FILES",
	MUDMAP = "MUDMAP",
	PLANOGRAM = "PLANOGRAM",
}

export const enum LayoutPriority {
	AESTHETICS = "Aesthetics",
	PROFIT_AND_LOSS = "PnL",
	SEGMENTATION = "Segment Priority",
}

export enum OptimizeOn {
	PROFIT = "profit",
	SALES = "sales_amount",
	DISPERSION = "dispersion",
}

export interface Priority {
	label: string;
	value: LayoutPriority;
}
