import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { Box, Button } from "src/elements";
import { logsTabAtom } from "src/modals/Logs/store/atoms";
import { LogsTab } from "src/modals/Logs/types";
import { useApi } from "src/pages/Containers/store/api";
import {
	containersPlaceContainersPlanogramNoAtom,
	containersPlaceContainersResponseAtom,
	containersPlaceVariantsResponseAtom,
} from "src/pages/Containers/store/atoms";
import { Item } from "src/pages/Containers/store/types";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { FilesUploader } from "../components/FilesUploader";

export const PanelPlaceVariants = () => {
	const placeContainersResponse = useOmniValue(containersPlaceContainersResponseAtom);
	const placeContainersPlanogramNo = useOmniValue(containersPlaceContainersPlanogramNoAtom);
	const placeVariantsResponse = useOmniValue(containersPlaceVariantsResponseAtom);

	const setLogsTab = useSetOmniValue(logsTabAtom);

	const { step2PlaceVariants } = useApi();
	const { openModal } = useModals();

	const openLogs = () => {
		setLogsTab(LogsTab.CONTAINERS_PLACE_VARIANTS);
		openModal(ModalType.LOGS);
	};

	const placeVariants = () => {
		if (placeContainersResponse) {
			const items: Item[] = [];

			for (const section of placeContainersResponse.planogram_responses[
				placeContainersPlanogramNo - 1
			].planogram.freezer.sections) {
				for (const item of section.items) {
					items.push(item);
				}
			}

			step2PlaceVariants();
		}
	};

	return (
		<>
			<FilesUploader />

			<Box gap="10px">
				<Button theme="greenSmoke" variant="inverted" onClick={openLogs}>
					Logs
				</Button>

				<Button theme={!placeVariantsResponse ? "primary" : "greenSmoke"} onClick={placeVariants}>
					Place Variants
				</Button>
			</Box>
		</>
	);
};
