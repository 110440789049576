import { useRef } from "react";
import { Box, IconV2, Scroller, Text } from "src/elements";
import { inputHeight } from "src/elements/Input";
import { useOnClickOutside } from "src/hooks";
import { Color, Transition } from "src/utils";
import { useOmniState } from "src/utils/atoms";
import styled from "styled-components";

import { settingsAtom } from "./store/atoms";

const SettingsMenu = styled.div`
	position: absolute;
	top: calc(100% + 18px);
	right: 0;
	width: 227px;
	max-height: 80vh;
	z-index: 100;
	flex-direction: column;
	gap: 5px;
	background: ${Color.white};
	border-radius: 4px;
	filter: drop-shadow(0px 20px 40px rgba(19, 19, 19, 0.1));
	transition: transform ${Transition.input}, opacity ${Transition.input};

	&:not([data-open]) {
		transform: translateY(-20px);
		opacity: 0;
		pointer-events: none;
	}

	&::before {
		content: "";
		width: 16px;
		height: 16px;
		background: ${Color.white};
		transform: rotate(45deg);
		position: absolute;
		top: -8px;
		right: 22px;
		z-index: -1;
	}
`;

export const SettingsDivider = styled.div`
	width: 100%;
	min-height: 1px;
	background-color: ${Color.lightGray};
`;

interface Props {
	children: React.ReactNode;
}

export const Settings: React.FC<Props> = ({ children }) => {
	const [showSettings, setShowSettings] = useOmniState(settingsAtom);

	const refSettings = useRef<HTMLDivElement>(null);
	useOnClickOutside(refSettings, () => showSettings && setShowSettings(false));

	return (
		<Box position="relative" ref={refSettings}>
			<Box
				gap="5px"
				onClick={() => setShowSettings(!showSettings)}
				minHeight={inputHeight}
				align="center"
				hoverColor={Color.primaryHover}
				activeColor={Color.primaryActive}
			>
				<IconV2 name="settings" color={Color.primary} />
				<Text variant="body3" color={Color.primary}>
					Settings
				</Text>
			</Box>

			<SettingsMenu {...(showSettings && { "data-open": "" })}>
				<Scroller size="thin" gap="5px">
					{children}
				</Scroller>
			</SettingsMenu>
		</Box>
	);
};
