import React from "react";
import { IconType, IconV2 } from "src/elements/IconV2";
import { Color, tooltip as showTooltip } from "src/utils";
import styled from "styled-components";

import { iconPadding } from "../data/constants";

const IconElement = styled.div`
	position: absolute;
	top: 0;
	width: ${iconPadding};
	height: 100%;

	justify-content: center;
	align-items: center;
	user-select: none;

	&:not([data-clickable]) {
		pointer-events: none;
	}

	&[data-clickable] {
		cursor: pointer;
	}

	&[data-direction="left"] {
		left: 0;
	}

	&[data-direction="right"] {
		right: 0;
	}
`;

export const Icon: React.FC<{
	icon?: IconType;
	error?: string | null | boolean;
	side: "left" | "right";
	tooltip?: string;
	onClick?: () => void;
}> = ({ icon, error, side, tooltip, onClick }) => {
	if (!icon) return null;

	return (
		<IconElement
			data-direction={side}
			{...(onClick && { "data-clickable": "" })}
			onClick={onClick}
			onMouseEnter={event => {
				if (icon && tooltip) {
					showTooltip({ event, data: tooltip });
				}
			}}
		>
			<IconV2 name={icon} color={error ? Color.red : Color.primary} />
		</IconElement>
	);
};
