import { Box, Text } from "src/elements";
import { PlanogramShelf } from "src/types";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

import { useShelfPanel } from "../../store/actions";

const ShelfElement = styled.div`
	background-color: ${Color.opacitySky};
	min-height: 35px;
	padding: 5px;
	align-items: center;
	justify-content: space-between;
	border-radius: 3px;
	transition: background-color ${Transition.hover};
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: ${Color.opacitySkyHover};
	}

	&:hover:active {
		background-color: ${Color.opacitySkyActive};
	}
`;

interface Props {
	readonly bayNo: number;
	readonly shelf: PlanogramShelf;
}

export const Shelf: React.FC<Props> = ({ bayNo, shelf }) => {
	const { open } = useShelfPanel();

	if (!shelf) return null;

	return (
		<ShelfElement onClick={() => open(bayNo, shelf)}>
			<Text variant="body1" color={Color.textSecondary}>
				Shelf No. {shelf.shelf_no}
			</Text>

			<Box gap="5px">
				<Box background={Color.opacitySkyHover} padding="3px" borderRadius="3px">
					<Text variant="caption3" color={Color.textSecondary}>
						Y: {shelf.shelf_y}
					</Text>
				</Box>

				<Box background={Color.opacitySkyHover} padding="3px" borderRadius="3px">
					<Text variant="caption3" color={Color.textSecondary}>
						Height: {shelf.shelf_height}
					</Text>
				</Box>
			</Box>
		</ShelfElement>
	);
};
