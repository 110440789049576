import emotionStyled from "@emotion/styled";
import hexToRgba from "hex-to-rgba";
// @ts-ignore
import { LightenDarkenColor } from "lighten-darken-color";
import React from "react";
import { getMudmapCdtColorByIndex } from "src/constants/mudmapCdtColors";
import { Box, IconV2, Text } from "src/elements";
import { baysColorsByAtom, baysShowPicturesAtom } from "src/pages/Bays/store/atoms";
import { BasicItem } from "src/store/bays";
import { ShelfType } from "src/types";
import { Color, Transition } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled, { StyledComponent } from "styled-components";

import { baysColorsAtom } from "../store/atoms";
import { VisualizerColorBy } from "../types";
import { PLANOGRAM_FACING } from "../utils/classes";
import { Hangcell } from "./Hangcell";
import { ProductImage } from "./ProductImage";

interface FacingProps {
	background: string;
	width: number;
	height: number;
	itemId: string;
	fixedSize?: boolean;
	color: string;
}

const FacingElementContainer = styled.div<{ fixedSize?: boolean }>`
	width: ${({ fixedSize }) => fixedSize && "40px"};
	height: ${({ fixedSize }) => fixedSize && "40px"};
	justify-content: center;
	align-items: center;
`;

const FacingElement: StyledComponent<
	"div",
	any,
	FacingProps,
	never
> = styled.div.attrs<FacingProps>(({ background, width, height }) => ({
	style: {
		backgroundColor: background,
		width: `calc(var(--scale) * ${width}px)`,
		height: `calc(var(--scale) * ${height}px)`,
	},
}))`
	position: relative;
	background-color: #e2dcf3;
	box-shadow: ${({ color }) => `inset 0 0 0 1px ${hexToRgba(LightenDarkenColor(color, -30), 0.4)}`};
	border-radius: 2px;
	user-select: none;
	pointer-events: none;
	container-type: size;

	&:not(:hover) .show-actions > * {
		opacity: 0;
		pointer-events: none;
		transform: scale(0.3);
	}

	${({ fixedSize, width, height }: FacingProps) =>
		fixedSize && height > width
			? `height: 40px !important; width: 0px !important; padding-left: ${(width * 40) / height}px;`
			: ""};

	${({ fixedSize, width, height }: FacingProps) =>
		fixedSize && width > height
			? `width: 40px !important; height: 0px !important; padding-top: ${(height * 40) / width}px;`
			: ""};
`;

const FacingText = emotionStyled.div<{ width: number; height: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 5px;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	${({ width, height }) =>
		width > height &&
		`
	@container (max-width: 45px) {
		span {
			font-size: min(20cqw, 10px) !important;
		}
	}`}

	${({ width, height }) =>
		height > width &&
		`
	@container (max-height: 45px) {
		span {
			font-size: min(20cqh, 10px) !important;
		}
	}`}

	@container (max-width: 10px) {
		span {
			display: none !important;
		}
	}

	@container (max-height: 10px) {
		span {
			display: none !important;
		}
	}
`;

const ActionItem = styled.div`
	pointer-events: all;
	cursor: pointer;
	transition: opacity ${Transition.fast}, transform ${Transition.fast};

	&:not(:hover) {
		opacity: 0.5;
	}

	&:active {
		transform: scale(0.9);
	}
`;

interface Props {
	item: BasicItem;
	fixedSize?: boolean;
	variant?: string;
	shelf_type?: ShelfType;
}

export const Facing: React.FC<Props> = ({ item, fixedSize, shelf_type }) => {
	const colors = useOmniValue(baysColorsAtom);
	const colorsBy = useOmniValue(baysColorsByAtom);
	const showPictures = useOmniValue(baysShowPicturesAtom);

	const isHangcell = shelf_type === "HANGCELL";

	const getColor = (colorsBy: VisualizerColorBy) => {
		if (colors && colors.segment && colorsBy === VisualizerColorBy.SEGMENT) {
			return colors.segment[`${item.segment}`];
		}

		if (colors && colors.block && colorsBy === VisualizerColorBy.BLOCK) {
			return colors.block[`${item.segment}-${item.block}`];
		}

		return getMudmapCdtColorByIndex(0);
	};

	const color = getColor(
		colorsBy === VisualizerColorBy.SEG_BLOCK ? VisualizerColorBy.BLOCK : colorsBy,
	);

	return (
		<FacingElementContainer className={PLANOGRAM_FACING} fixedSize={fixedSize}>
			<FacingElement
				background={color}
				width={item.width}
				height={item.height}
				itemId={item.id}
				fixedSize={fixedSize}
				data-type={shelf_type}
				color={color}
			>
				<FacingText width={item.width} height={item.height}>
					<Text
						variant="caption2"
						color={hexToRgba(Color.textMain, 0.85)}
						vertical={item.width < item.height}
					>
						{item.id}
					</Text>
				</FacingText>

				{showPictures && <ProductImage itemId={item.id} />}

				{isHangcell && <Hangcell color={color} />}

				<Box
					className="show-actions"
					position="absolute"
					top="0"
					right="0"
					padding="5px"
					gap="5px"
					display="none"
				>
					<ActionItem>
						<IconV2 name="remove" color={Color.red} />
					</ActionItem>

					<ActionItem>
						<IconV2 name="add" color={Color.primary} />
					</ActionItem>
				</Box>
			</FacingElement>
		</FacingElementContainer>
	);
};
