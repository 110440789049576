import React from "react";
import { Box, Text } from "src/elements";
import { Color, Transition } from "src/utils";
import styled from "styled-components";

const DragButtonElement = styled.div`
	gap: 10px;
	align-items: center;
	padding: 7px 20px 7px 15px;
	min-height: 35px;
	margin: 0 5px;
	border: 1px solid ${Color.lightGrayHover};
	background: ${Color.lightGray};
	border-radius: 4px;
	user-select: none;
	cursor: grab;

	&,
	> div > div,
	span {
		transition: background ${Transition.fast}, color 0.05s, border-color ${Transition.fast};
	}

	> * {
		pointer-events: none;
	}

	:hover {
		background: ${Color.greenSmoke};
		border-color: ${Color.greenSmokeHover};
		color: ${Color.primary};

		> div > div {
			background: ${Color.primary};
		}
	}

	&[dragging],
	:active {
		background: ${Color.primary};
		border-color: ${Color.primaryHover};
		color: ${Color.white};
		cursor: grabbing;

		> div > div {
			background: ${Color.white};
		}
	}
	&[dragging] {
		position: absolute;
		pointer-events: none;
		transition-property: background, border-color;
	}

	&[draggable] {
		visibility: hidden;
		pointer-events: none;
	}
`;

const HandleBar = styled.div`
	width: 2px;
	height: 10px;
	background: ${Color.spaceGray};
	transition: background ${Transition.hover};
`;

interface Props {
	children: React.ReactChild;
}

export const DragButton: React.FC<Props> = ({ children }) => (
	<DragButtonElement>
		<Box gap="2px">
			<HandleBar />
			<HandleBar />
			<HandleBar />
		</Box>

		<Text variant="body2" color="inherit" whiteSpace="nowrap">
			{children}
		</Text>
	</DragButtonElement>
);
