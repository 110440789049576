import { ToastType, useToasts } from "src/components/Toasts";
import { useOmniState, useOmniValue, useSetOmniValue } from "src/utils/atoms";

import { containersGroupsParametersAtom } from "../modals/GroupsParameters/store/atoms";
import {
	containersAlgoVersionAtom,
	containersFilesCandidatesAtom,
	containersFilesFixturesAtom,
	containersFilesLogsAtom,
	containersFilesResponseAtom,
	containersGroupsLogsAtom,
	containersGroupsResponseAtom,
	containersLogsPlaceContainersAtom,
	containersLogsPlaceVariantsAtom,
	containersMergeRulesAtom,
	containersPlaceContainersPlanogramNoAtom,
	containersPlaceContainersResponseAtom,
	containersPlaceVariantsResponseAtom,
} from "./atoms";
import {
	postStep0ContainersFilesReq,
	postStep0ContainersGroupAndSizeVariantsReq,
	postStep1PlaceContainersReq,
	postStep2PlaceVariantsReq,
} from "./requests";
import {
	ContainersFilesResponse,
	ContainersGroupsResponse,
	ContainersPlaceContainersResponse,
	Item,
	PlanogramSection,
} from "./types";

export const useApi = () => {
	const [filesResponse, setFilesResponse] = useOmniState(containersFilesResponseAtom);
	const [groups, setGroups] = useOmniState(containersGroupsResponseAtom);
	const [placeContainersResponse, setPlaceContainersResponse] = useOmniState(
		containersPlaceContainersResponseAtom,
	);
	const [containersPlaceContainersPlanogramNo, setContainersPlaceContainersPlanogramNoAtom] =
		useOmniState(containersPlaceContainersPlanogramNoAtom);
	const [placeVariantsResponse, setPlaceVariantsResponse] = useOmniState(
		containersPlaceVariantsResponseAtom,
	);

	const version = useOmniValue(containersAlgoVersionAtom);
	const fixturesFile = useOmniValue(containersFilesFixturesAtom)!;
	const candidatesFile = useOmniValue(containersFilesCandidatesAtom)!;
	const mergeRules = useOmniValue(containersMergeRulesAtom);
	const groupsParameters = useOmniValue(containersGroupsParametersAtom);

	const setLogsFiles = useSetOmniValue(containersFilesLogsAtom);
	const setLogsGroupAndSizeVariants = useSetOmniValue(containersGroupsLogsAtom);
	const setLogsPlaceContainers = useSetOmniValue(containersLogsPlaceContainersAtom);
	const setLogsPlaceVariants = useSetOmniValue(containersLogsPlaceVariantsAtom);

	const { toast, hideToast } = useToasts();

	const stepStartUploadFiles = async () => {
		const toastId = "containers/files";

		toast({
			id: toastId,
			title: "Reading containers data from CSV files...",
			type: ToastType.PENDING,
		});

		try {
			const { data }: { data: ContainersFilesResponse } = await postStep0ContainersFilesReq({
				fixturesFile,
				candidatesFile,
			});

			saveSuccessLogs(data, setLogsFiles);
			setFilesResponse(data);

			toast({
				title: "Successfully read containers files",
			});
		} catch (error) {
			saveErrorLogs("Failed to read containers files data", error, setLogsFiles);
		}

		hideToast(toastId);
	};

	const step0GroupAndSizeVariants = async () => {
		const toastId = "containers/groupAndSizeVariants";

		toast({
			id: toastId,
			title: "Generating group and size variants...",
			type: ToastType.PENDING,
		});

		try {
			const { data }: { data: ContainersGroupsResponse } =
				await postStep0ContainersGroupAndSizeVariantsReq({
					version,
					freezer: filesResponse!.freezer,
					candidates: filesResponse!.candidates,
					cumulative_shelf_heights: filesResponse!.cumulative_shelf_heights,
					merge_rules: mergeRules.map(({ variants }) => ({ variants })),
					parameters: groupsParameters,
				});

			saveSuccessLogs(data, setLogsGroupAndSizeVariants);
			setGroups(data);

			toast({
				title: "Successfully generated group and size variants",
			});
		} catch (error) {
			saveErrorLogs(
				"Failed to generate group and size variants",
				error,
				setLogsGroupAndSizeVariants,
			);
		}

		hideToast(toastId);
	};

	const step1PlaceContainers = async () => {
		const toastId = "containers/placeContainers";

		toast({
			id: toastId,
			title: "Placing containers...",
			type: ToastType.PENDING,
		});

		try {
			const { data }: { data: ContainersPlaceContainersResponse } =
				await postStep1PlaceContainersReq({
					version,
					freezer: filesResponse!.freezer,
					cumulative_shelf_heights: filesResponse!.cumulative_shelf_heights,
					groups: groups?.groups!,
				});
			saveSuccessLogs(data, setLogsPlaceContainers);
			setPlaceContainersResponse(data);
			toast({
				title: "Successfully placed containers",
			});
		} catch (error) {
			saveErrorLogs("Failed to place containers", error, setLogsPlaceContainers);
		}

		hideToast(toastId);
	};

	const step2PlaceVariants = async () => {
		const toastId = "containers/placeVariants";

		toast({
			id: toastId,
			title: "Placing variants...",
			type: ToastType.PENDING,
		});

		try {
			const { data }: { data: ContainersPlaceContainersResponse } = await postStep2PlaceVariantsReq(
				{
					version,
					freezer: placeContainersResponse?.planogram_responses[
						containersPlaceContainersPlanogramNo
					]!.planogram.freezer as any,
					candidates: filesResponse!.candidates,
				},
			);
			saveSuccessLogs(data, setLogsPlaceVariants);
			setPlaceVariantsResponse(data);
			toast({
				title: "Successfully placed variants",
			});
		} catch (error) {
			saveErrorLogs("Failed to place variants", error, setLogsPlaceVariants);
		}

		hideToast(toastId);
	};

	const saveSuccessLogs = (
		data: any,
		setLogsValue: (value: { payload: any; response: any }) => void,
	) => {
		const logs = { payload: { ...data.payload }, response: {} };

		delete data.payload;
		logs.response = { ...data };

		setLogsValue(logs);
	};

	const saveErrorLogs = (
		title: string,
		error: any,
		setLogsValue: (value: { payload: any; response: any }) => void,
	) => {
		try {
			const logs = { payload: { ...error.response.data.payload }, response: {} };
			logs.response = { error: error.response.data.error };

			setLogsValue(logs);
			toast({
				title: error.response.data.error.message || title,
				description: error.response.data.error.body,
				type: ToastType.ERROR,
			});
		} catch {}
	};

	return {
		stepStartUploadFiles,
		step0GroupAndSizeVariants,
		step1PlaceContainers,
		step2PlaceVariants,
	};
};
