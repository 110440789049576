import { OmniState, useOmniState } from "src/utils/atoms";

import { DatapointsRange } from "./DatapointsRange";

interface Props {
	state: OmniState<string | number>;
	title?: string;
	description?: string;
	unit?: string;
	datapoints: (string | number)[];
	theme?: "default" | "round";
	withoutValueLabel?: boolean;
	customFormatter?: (value: string | number) => string | number;
}

export const StateDatapointsRange: React.FC<Props> = ({
	state,
	title,
	description,
	unit = "",
	datapoints,
	theme = "default",
	withoutValueLabel,
	customFormatter,
}) => {
	const [value, setValue] = useOmniState(state);

	return (
		<DatapointsRange
			value={value}
			setValue={setValue}
			title={title}
			description={description}
			unit={unit}
			datapoints={datapoints}
			theme={theme}
			withoutValueLabel={withoutValueLabel}
			customFormatter={customFormatter}
		/>
	);
};
