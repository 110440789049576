import React from "react";
import { Text } from "src/elements";
import styled from "styled-components";

import { FilesSection, PlanogramSection } from "../../store/types";
import { Item } from "./Item";
import { Shelf } from "./Shelf";

const SectionElement = styled.div<{ width: number; height: number; x: number; y: number }>`
	position: absolute;
	justify-content: center;
	align-items: center;
	width: ${({ width }) => `calc(var(--scale) * ${width}px)`};
	height: ${({ height }) => `calc(var(--scale) * ${height}px)`};
	left: ${({ x }) => `calc(var(--scale) * ${x}px)`};
	bottom: ${({ y }) => `calc(var(--scale) * ${y}px)`};
	background-color: rgba(0, 0, 100, 0.1);
`;

interface Props {
	section: FilesSection | PlanogramSection;
}

export const Section: React.FC<Props> = ({
	section,
	section: { section_id: id, width, height, start_x: startX, start_y: startY },
}) => (
	<>
		<SectionElement
			className="section"
			width={width}
			height={height}
			x={startX}
			y={startY}
		></SectionElement>

		{section.cumulative_shelf_heights.slice(0, -1).map((cumulativeShelfHeight, i) => (
			<Shelf
				key={i}
				sectionNo={id}
				shelfNo={i}
				width={width}
				height={section.cumulative_shelf_heights[i + 1] - cumulativeShelfHeight}
				x={startX}
				y={cumulativeShelfHeight}
			/>
		))}

		{(section as PlanogramSection).items?.map((item, i) => (
			<Item key={i} item={item} />
		))}
	</>
);
