import {
	FreezersTab,
	FreezersVisualizerColorsBy,
} from "src/pages/Freezers/components/ControlPanel/types";
import { omniAtom } from "src/utils/atoms";

import { FreezerColors, Freezers } from "./types";

const prefix = "freezers";

export const freezersStepAtom = omniAtom({
	prefix,
	key: "step",
	value: FreezersTab.FILES,
});

export const freezersInputFileAtom = omniAtom({
	prefix,
	key: "inputFile",
	value: null as File | null,
});

export const freezersOutputFileAtom = omniAtom({
	prefix,
	key: "outputFile",
	value: null as File | null,
});

export const freezersMudmapAtom = omniAtom({
	prefix,
	key: "mudmap",
	value: null as Freezers | null,
});

export const freezersPlanogramAtom = omniAtom({
	prefix,
	key: "planogram",
	value: null as Freezers | null,
});

export const freezersOverlapsAtom = omniAtom({
	prefix,
	key: "overlaps",
	value: [] as DOMRect[],
});

export const freezersMudmapColorsAtom = omniAtom({
	prefix,
	key: "colors/mudmap",
	value: { cdt0: {}, cdt1: {}, cdt2: {} } as FreezerColors,
});

export const freezersPlanogramColorsAtom = omniAtom({
	prefix,
	key: "colors/planogram",
	value: { cdt0: {}, cdt1: {}, cdt2: {} } as FreezerColors,
});

export const freezersVisualizerColorsByAtom = omniAtom({
	prefix,
	key: "settings/colorsBy",
	value: FreezersVisualizerColorsBy.SEGMENT,
});

export const freezersZoomAtom = omniAtom({
	prefix,
	key: "zoom",
	value: 1,
});
