import { usePog } from "src/components/Bays/store/actions";
import { baysMudmapAtom } from "src/components/Bays/store/atoms";
import { ModalType } from "src/components/Modals";
import { useModals } from "src/components/Modals/store/atoms";
import { ToastType, useToasts } from "src/components/Toasts";
import {
	conditionalItemsBothOrNoneAtom,
	conditionalItemsEitherOrAtom,
	conditionalItemsPrerequisitesAtom,
} from "src/modals/ConditionalItems/store/atoms";
import { useChurnLimit, useCoreRange } from "src/modals/CoreRange/store/atoms";
import { useLogs } from "src/modals/Logs/store/atoms";
import { saveErrorLog, saveResponseLog } from "src/modals/Logs/utils";
import { baysRelaxationsAtom } from "src/modals/Relaxations/store/atoms";
import {
	baysFilesAtom,
	baysFixturesFileAtom,
	baysMinStackAtom,
	baysOptimizeAtom,
	baysPoggerVersionAtom,
	baysProductsFileAtom,
	baysSegmentVersionAtom,
	baysSnakingAtom,
	useGenerateColors,
	usePriorities,
} from "src/pages/Bays/store/atoms";
import { Mudmap } from "src/types";
import { useOmniValue, useSetOmniValue } from "src/utils/atoms";

import {
	postWorkbenchFilesReq,
	postWorkbenchGenerateMudmapReq,
	postWorkbenchGeneratePlanogramReq,
	postWorkbenchRebuildPlanogramReq,
} from "./requests";
import { FilesResponse } from "./types";

export const useFiles = () => {
	const setMasterItems = useSetOmniValue(baysFilesAtom);
	const fixturesFile = useOmniValue(baysFixturesFileAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);

	const { generateColors } = useGenerateColors();
	const { setFilesResponse } = useLogs();
	const { toast, hideToast } = useToasts();

	const startUsingFiles = async () => {
		toast({
			id: "bays/files",
			title: "Getting data from CSV files",
			type: ToastType.PENDING,
		});

		try {
			const payload = new FormData();
			payload.append("fixtures", fixturesFile!, fixturesFile!.name);
			payload.append("master", masterFile!, masterFile!.name);

			const { data }: { data: FilesResponse } = await postWorkbenchFilesReq(payload);
			saveResponseLog(data, null, setFilesResponse);
			setMasterItems(data);
			generateColors({ masterItems: data.master_list });
			toast({
				title: "Data read from CSV files",
			});
		} catch (error) {
			saveErrorLog(error, null, setFilesResponse);
			toast({
				title: "Invalid files",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("bays/files");
	};

	return { startUsingFiles };
};

export const useGenerateMudmap = () => {
	const segmentVersion = useOmniValue(baysSegmentVersionAtom);
	const { toast, hideToast } = useToasts();
	const { setMudmapPayload, setMudmapResponse } = useLogs();
	const { getPriorities } = usePriorities();
	const { closeModal } = useModals();
	const { coreRange } = useCoreRange();

	const baysFiles = useOmniValue(baysFilesAtom);
	const setMudmap = useSetOmniValue(baysMudmapAtom);

	const optimizeOn = useOmniValue(baysOptimizeAtom);

	const generateMudmap: () => void = async () => {
		toast({
			id: "bays/mudmap",
			title: "Generating mudmap",
			type: ToastType.PENDING,
		});

		try {
			const payload = new FormData();
			payload.append("segmentVersion", segmentVersion);
			payload.append("fixtures", JSON.stringify(baysFiles?.fixtures));
			payload.append("master_list", JSON.stringify(baysFiles?.master_list));
			payload.append("priorities", JSON.stringify(getPriorities()));
			payload.append("optimize", optimizeOn);
			payload.append("core_range_items", JSON.stringify(coreRange));

			const { data }: { data: Mudmap } = await postWorkbenchGenerateMudmapReq(payload);
			saveResponseLog(data, setMudmapPayload, setMudmapResponse);
			setMudmap(data);

			closeModal(ModalType.CORE_RANGE);
			toast({ title: "Mudmap generated" });
		} catch (error) {
			saveErrorLog(error, setMudmapPayload, setMudmapResponse);
			toast({
				title: "Failed to generate mudmap",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("bays/mudmap");
	};

	return { generateMudmap };
};

export const useGeneratePlanogram = () => {
	const { setPog } = usePog();
	const poggerVersion = useOmniValue(baysPoggerVersionAtom);
	const { toast, hideToast } = useToasts();
	const { setPlanogramPayload, setPlanogramResponse } = useLogs();
	const bothOrNoneItems = useOmniValue(conditionalItemsBothOrNoneAtom);
	const eitherOrItems = useOmniValue(conditionalItemsEitherOrAtom);
	const prerequisiteItems = useOmniValue(conditionalItemsPrerequisitesAtom);
	const { closeModal } = useModals();
	const mudmap = useOmniValue(baysMudmapAtom);
	const relaxations = useOmniValue(baysRelaxationsAtom);

	const baysFiles = useOmniValue(baysFilesAtom);
	const optimizeOn = useOmniValue(baysOptimizeAtom);
	const snaking = useOmniValue(baysSnakingAtom);
	const minStack = useOmniValue(baysMinStackAtom);

	const generatePlanogram = async () => {
		toast({
			id: "bays/planogram",
			title: "Generating planogram",
			type: ToastType.PENDING,
		});

		try {
			const payload = new FormData();
			payload.append("poggerVersion", poggerVersion);
			payload.append("fixtures", JSON.stringify(baysFiles?.fixtures));
			payload.append("master_list", JSON.stringify(baysFiles?.master_list));
			payload.append("optimize", optimizeOn);
			payload.append("snaking", snaking ? "normal" : "none");
			payload.append("min_stack", JSON.stringify(minStack));
			payload.append("mudmap", JSON.stringify(mudmap));
			payload.append("relaxations", JSON.stringify(relaxations));
			payload.append("either_or_items", JSON.stringify(eitherOrItems));
			payload.append("both_or_none_items", JSON.stringify(bothOrNoneItems));
			payload.append("item_prerequisites", JSON.stringify(prerequisiteItems));

			const { data } = await postWorkbenchGeneratePlanogramReq(payload);
			saveResponseLog(data, setPlanogramPayload, setPlanogramResponse);
			setPog(data);

			closeModal(ModalType.RELAXATIONS);
			closeModal(ModalType.MINOR_REVIEW);
			closeModal(ModalType.CONDITIONAL_ITEMS);
			closeModal(ModalType.CORE_RANGE);
			toast({ title: "Planogram generated" });
		} catch (error) {
			saveErrorLog(error, setPlanogramPayload, setPlanogramResponse);
			toast({
				title: "Failed to generate planogram",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("bays/planogram");
	};

	return { generatePlanogram };
};

export const useRebuildPlanogram = () => {
	const { setPog } = usePog();
	const poggerVersion = useOmniValue(baysPoggerVersionAtom);
	const { toast, hideToast } = useToasts();
	const { setRebuildPayload, setRebuildResponse } = useLogs();
	const bothOrNoneItems = useOmniValue(conditionalItemsBothOrNoneAtom);
	const eitherOrItems = useOmniValue(conditionalItemsEitherOrAtom);
	const prerequisiteItems = useOmniValue(conditionalItemsPrerequisitesAtom);
	const { closeModal } = useModals();
	const mudmap = useOmniValue(baysMudmapAtom);
	const { pog } = usePog();
	const { coreRange } = useCoreRange();
	const { getChurnLimit } = useChurnLimit();
	const relaxations = useOmniValue(baysRelaxationsAtom);

	const baysFiles = useOmniValue(baysFilesAtom);
	const optimizeOn = useOmniValue(baysOptimizeAtom);
	const min_stack = useOmniValue(baysMinStackAtom);

	const rebuildPlanogram = async () => {
		toast({
			id: "bays/rebuild",
			title: "Rebuilding planogram",
			type: ToastType.PENDING,
		});

		try {
			const payload = new FormData();
			payload.append("poggerVersion", poggerVersion);
			payload.append("planogram", JSON.stringify(pog!.planogram));
			payload.append("master_list", JSON.stringify(baysFiles?.master_list));
			payload.append("core_range_items", JSON.stringify(coreRange));
			payload.append("churn_limit", `${getChurnLimit()}`);
			payload.append("optimize", optimizeOn);
			payload.append("mudmap", JSON.stringify(mudmap));
			payload.append("min_stack", JSON.stringify(min_stack));
			payload.append("relaxations", JSON.stringify(relaxations));
			payload.append("either_or_items", JSON.stringify(eitherOrItems));
			payload.append("both_or_none_items", JSON.stringify(bothOrNoneItems));
			payload.append("item_prerequisites", JSON.stringify(prerequisiteItems));

			const { data } = await postWorkbenchRebuildPlanogramReq(payload);
			saveResponseLog(data, setRebuildPayload, setRebuildResponse);
			setPog(data);

			closeModal(ModalType.RELAXATIONS);
			closeModal(ModalType.MINOR_REVIEW);
			closeModal(ModalType.CONDITIONAL_ITEMS);
			closeModal(ModalType.CORE_RANGE);
			toast({ title: "Planogram rebuilt" });
		} catch (error) {
			saveErrorLog(error, setRebuildPayload, setRebuildResponse);
			toast({
				title: "Failed to rebuild planogram",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("bays/rebuild");
	};

	return { rebuildPlanogram };
};
