import { atom } from "jotai";
import { omniAtom } from "src/utils/atoms";

const prefix = "bays/relaxations";

export const baysRelaxationEitherOrAtom = omniAtom({
	prefix,
	key: "eitherOr",
	value: 1,
});

export const baysRelaxationBothOrNoneAtom = omniAtom({
	prefix,
	key: "bothOrNone",
	value: 1,
});

export const baysRelaxationPreReqAtom = omniAtom({
	prefix,
	key: "preReq",
	value: 1,
});

export const baysRelaxationShelfItemCountAtom = omniAtom({
	prefix,
	key: "shelfItemCount",
	value: 1,
});

export const baysRelaxationFixedNotchNumAtom = omniAtom({
	prefix,
	key: "fixedNotchNum",
	value: 0.2,
});

export const baysRelaxationFingerSpaceAtom = omniAtom({
	prefix,
	key: "fingerSpace",
	value: 5,
});

export const baysRelaxationUnitsNotEnoughDosAtom = omniAtom({
	prefix,
	key: "unitsNotEnoughDos",
	value: 0.5,
});

export const baysRelaxationUnitsNotEnoughMosAtom = omniAtom({
	prefix,
	key: "unitsNotEnoughMos",
	value: 0.15,
});

export const baysRelaxationUnitsTooManyAtom = omniAtom({
	prefix,
	key: "unitsTooMany",
	value: 0.3,
});

export const baysRelaxationFacingsNotEnoughAtom = omniAtom({
	prefix,
	key: "facingsNotEnough",
	value: 0.3,
});

export const baysRelaxationFacingsTooManyAtom = omniAtom({
	prefix,
	key: "facingsTooMany",
	value: 10,
});

export const baysRelaxationMinWidthAtom = omniAtom({
	prefix,
	key: "minWidth",
	value: 0.5,
});

export const baysRelaxationMinDistributionAtom = omniAtom({
	prefix,
	key: "minDistribution",
	value: 0.01,
});

export const baysRelaxationWidthSlackAtom = omniAtom({
	prefix,
	key: "widthSlack",
	value: 0.1,
});

export const baysRelaxationCdt1ContiguousAtom = omniAtom({
	prefix,
	key: "cdt1Contiguous",
	value: 1,
});

export const baysRelaxationCdt2ContiguousAtom = omniAtom({
	prefix,
	key: "cdt2Contiguous",
	value: 1,
});

export const baysRelaxationCdt3ContiguousAtom = omniAtom({
	prefix,
	key: "cdt3Contiguous",
	value: 1,
});

export const baysRelaxationCdt1OrderAtom = omniAtom({
	prefix,
	key: "cdt1Order",
	value: 1,
});

export const baysRelaxationCdt2OrderAtom = omniAtom({
	prefix,
	key: "cdt2Order",
	value: 1,
});

export const baysRelaxationCdt3OrderAtom = omniAtom({
	prefix,
	key: "cdt3Order",
	value: 1,
});

export const baysRelaxationItemIdOrderAtom = omniAtom({
	prefix,
	key: "itemIdOrder",
	value: 0.1,
});

export const baysRelaxationItemSpanAtom = omniAtom({
	prefix,
	key: "itemSpan",
	value: 0.1,
});

export const baysRelaxationCdt1SpanAtom = omniAtom({
	prefix,
	key: "cdt1Span",
	value: 0,
});

export const baysRelaxationCdt2SpanAtom = omniAtom({
	prefix,
	key: "cdt2Span",
	value: 0.1,
});

export const baysRelaxationCdt3SpanAtom = omniAtom({
	prefix,
	key: "cdt3Span",
	value: 0.2,
});

export const baysRelaxationsAtom = atom(get => ({
	either_or: get(baysRelaxationEitherOrAtom),
	both_or_none: get(baysRelaxationBothOrNoneAtom),
	prereq: get(baysRelaxationPreReqAtom),
	shelf_item_count: get(baysRelaxationShelfItemCountAtom),
	fixed_notch_num: get(baysRelaxationFixedNotchNumAtom),
	finger_space: get(baysRelaxationFingerSpaceAtom),
	units_not_enough_dos: get(baysRelaxationUnitsNotEnoughDosAtom),
	units_not_enough_mos: get(baysRelaxationUnitsNotEnoughMosAtom),
	units_too_many: get(baysRelaxationUnitsTooManyAtom),
	facings_not_enough: get(baysRelaxationFacingsNotEnoughAtom),
	min_width: get(baysRelaxationMinWidthAtom),
	min_distribution: get(baysRelaxationMinDistributionAtom),
	width_slack: get(baysRelaxationWidthSlackAtom),
	cdt1_contiguous: get(baysRelaxationCdt1ContiguousAtom),
	cdt2_contiguous: get(baysRelaxationCdt2ContiguousAtom),
	cdt3_contiguous: get(baysRelaxationCdt3ContiguousAtom),
	cdt1_order: get(baysRelaxationCdt1OrderAtom),
	cdt2_order: get(baysRelaxationCdt2OrderAtom),
	cdt3_order: get(baysRelaxationCdt3OrderAtom),
	item_id_order: get(baysRelaxationItemIdOrderAtom),
	item_span: get(baysRelaxationItemSpanAtom),
	cdt1_span: get(baysRelaxationCdt1SpanAtom),
	cdt2_span: get(baysRelaxationCdt2SpanAtom),
	cdt3_span: get(baysRelaxationCdt3SpanAtom),
}));
baysRelaxationsAtom.debugLabel = "bays/relaxations";
