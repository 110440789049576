import { usePog } from "src/components/Bays/store/actions";
import { baysColorsAtom, baysMudmapAtom } from "src/components/Bays/store/atoms";
import { VisualizerColorBy } from "src/components/Bays/types";
import { Merchant } from "src/components/Settings/store/types";
import { ToastType, useToasts } from "src/components/Toasts";
import { getMudmapCdtColorByIndex } from "src/constants/mudmapCdtColors";
import { useLogs } from "src/modals/Logs/store/atoms";
import { saveErrorLog, saveResponseLog } from "src/modals/Logs/utils";
import { minorReviewAtom } from "src/modals/MinorReview/store/atoms";
import { Fixture, MasterItem, useGeneratePlanogram } from "src/store/bays";
import { Objectives, Pog } from "src/types";
import { omniAtom, useOmniValue, useSetOmniValue } from "src/utils/atoms";

import {
	postWorkbenchCalculateObjectivesReq,
	postWorkbenchGenerateMinorReviewReq,
} from "../../../store/bays/requests";
import { baysPrioritiesData } from "./data";
import { BaysStep, OptimizeOn } from "./types";

const prefix = "bays";

export const baysChurnLimitAtom = omniAtom({
	prefix,
	key: "churnLimit",
	value: 0,
});

export const baysFilesAtom = omniAtom({
	prefix,
	key: "files/data",
	value: null as {
		fixtures: Fixture[];
		master_list: MasterItem[];
	} | null,
});

export const baysFixturesFileAtom = omniAtom({
	prefix,
	key: "files/fixtures",
	value: null as File | null,
});

export const baysProductsFileAtom = omniAtom({
	prefix,
	key: "files/products",
	value: null as File | null,
});

export const baysOutputFileAtom = omniAtom({
	prefix,
	key: "outputFile",
	value: null as File | null,
});

export const baysObjectivesAtom = omniAtom({
	prefix,
	key: "objectives",
	value: null as Objectives | null,
});

export const baysZoomAtom = omniAtom({ prefix, key: "zoom", value: 1 });

export const baysShowPicturesAtom = omniAtom({
	prefix,
	key: "settings/pictures",
	value: true,
});

export const baysShowTooltipsAtom = omniAtom({
	prefix,
	key: "settings/tooltips",
	value: true,
});

export const baysColorsByAtom = omniAtom({
	prefix,
	key: "settings/colorsBy",
	value: VisualizerColorBy.SEGMENT as VisualizerColorBy,
});

export const baysStepAtom = omniAtom({
	prefix,
	key: "step",
	value: BaysStep.FILES,
});

export const baysPrioritiesAtom = omniAtom({
	prefix,
	key: "priorities",
	value: baysPrioritiesData,
});

export const baysSegmentVersionAtom = omniAtom({
	prefix,
	key: "segmentVersion",
	value: "v2-dev",
});

export const baysPoggerVersionAtom = omniAtom({
	prefix,
	key: "poggerVersion",
	value: "dev",
});

export const baysSnakingAtom = omniAtom({
	prefix,
	key: "snaking",
	value: false,
});

export const baysMinStackAtom = omniAtom({
	prefix,
	key: "minStack",
	value: false,
});

export const baysShowObjectivesAtom = omniAtom({
	prefix,
	key: "showObjectives",
	value: false,
});

export const baysShowUnrangedItemsAtom = omniAtom({
	prefix,
	key: "showUnrangedItems",
	value: false,
});

export const usePriorities = () => {
	const priorities = useOmniValue(baysPrioritiesAtom);

	const getPriorities = () => priorities.map(priority => priority.value);

	return { getPriorities };
};

export const baysOptimizeAtom = omniAtom({
	prefix,
	key: "optimize",
	value: OptimizeOn.PROFIT,
});

export const merchantAtom = omniAtom({
	prefix,
	key: "merchant",
	value: Merchant.FOOD_PANDA,
});

export const useCalculateObjectives = () => {
	const { pog } = usePog();
	const { toast, hideToast } = useToasts();
	const { setObjectivesPayload, setObjectivesResponse } = useLogs();

	const poggerVersion = useOmniValue(baysPoggerVersionAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);
	const optimizeOn = useOmniValue(baysOptimizeAtom);
	const mudmap = useOmniValue(baysMudmapAtom);

	const setObjectives = useSetOmniValue(baysObjectivesAtom);

	const calculateObjectives: () => void = async () => {
		toast({
			id: "bays/objectives",
			title: "Calculating objectives...",
			type: ToastType.PENDING,
		});

		try {
			const payload = new FormData();
			payload.append("poggerVersion", poggerVersion);
			payload.append("master", masterFile!, masterFile!.name);
			payload.append("optimize", optimizeOn);
			payload.append("mudmap", JSON.stringify(mudmap));
			payload.append("planogram", JSON.stringify(pog!.planogram));

			const { data } = await postWorkbenchCalculateObjectivesReq(payload);
			saveResponseLog(data, setObjectivesPayload, setObjectivesResponse);
			setObjectives(data.meta.objective);

			toast({ title: "Objectives calculated" });
		} catch (error) {
			saveErrorLog(error, setObjectivesPayload, setObjectivesResponse);
			toast({
				title: "Failed to calculate objectives",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("bays/objectives");
	};

	return {
		calculateObjectives,
	};
};

export const useGenerateMinorReview = () => {
	const { pog } = usePog();
	const segmentVersion = useOmniValue(baysSegmentVersionAtom);
	const masterFile = useOmniValue(baysProductsFileAtom);
	const mudmap = useOmniValue(baysMudmapAtom);
	const { getPriorities } = usePriorities();
	const { toast, hideToast } = useToasts();
	const { setMinorReviewPayload } = useLogs();
	const { add, remove } = useOmniValue(minorReviewAtom);
	const { generatePlanogram } = useGeneratePlanogram();

	const generateMinorReview = async () => {
		toast({
			id: "bays/minorReview",
			title: "Generating minor review",
			type: ToastType.PENDING,
		});

		try {
			const payload = new FormData();
			payload.append("segmentVersion", segmentVersion);
			payload.append("master", masterFile!, masterFile!.name);
			payload.append("priorities", JSON.stringify(getPriorities()));
			payload.append("mudmap", JSON.stringify(mudmap));
			payload.append("planogram", JSON.stringify(pog!.planogram));

			for (let i = 0; i < remove.length; i++) {
				payload.append("remove", remove[i]);
			}

			for (let i = 0; i < add.length; i++) {
				payload.append("add", add[i]);
			}

			const { data: mudmapNew } = await postWorkbenchGenerateMinorReviewReq(payload);
			saveResponseLog(mudmapNew, setMinorReviewPayload);

			await generatePlanogram();

			toast({ title: "Minor review generated" });
		} catch (error) {
			saveErrorLog(error, setMinorReviewPayload);
			toast({
				title: "Failed to generate minor_review",
				description: error,
				type: ToastType.ERROR,
			});
		}

		hideToast("bays/minorReview");
	};

	return {
		generateMinorReview,
	};
};

export const useGenerateColors = () => {
	const setColors = useSetOmniValue(baysColorsAtom);

	const generateColors = ({ masterItems, pog }: { masterItems?: MasterItem[]; pog?: Pog }) => {
		const colors = {
			segment: {},
			block: {},
		};

		masterItems?.forEach(item => {
			if (!colors.segment[`${item.segment}`]) {
				colors.segment[`${item.segment}`] = getMudmapCdtColorByIndex(
					Object.values(colors.segment).length,
				);
			}

			if (!colors.block[`${item.segment}-${item.block}`]) {
				colors.block[`${item.segment}-${item.block}`] = getMudmapCdtColorByIndex(
					Object.values(colors.block).length,
				);
			}
		});

		pog?.planogram.bays.forEach(bay =>
			bay.shelves.forEach(shelf => {
				shelf.items.forEach(item => {
					if (!colors.segment[`${item.cdt1}`]) {
						colors.segment[`${item.cdt1}`] = getMudmapCdtColorByIndex(
							Object.values(colors.segment).length,
						);
					}

					if (!colors.block[`${item.cdt1}-${item.cdt2}`]) {
						colors.block[`${item.cdt1}-${item.cdt2}`] = getMudmapCdtColorByIndex(
							Object.values(colors.block).length,
						);
					}
				});
			}),
		);

		pog?.meta.unranged_items?.forEach(item => {
			if (!colors.segment[`${item.cdt1}`]) {
				colors.segment[`${item.cdt1}`] = getMudmapCdtColorByIndex(
					Object.values(colors.segment).length,
				);
			}

			if (!colors.block[`${item.cdt1}-${item.cdt2}`]) {
				colors.block[`${item.cdt1}-${item.cdt2}`] = getMudmapCdtColorByIndex(
					Object.values(colors.block).length,
				);
			}
		});

		setColors(colors);
	};

	return { generateColors };
};
